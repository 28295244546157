import { S3File } from "../shared"

export enum HiringLimitDateEnum {
  proposalLetter_Pj = "proposal-letter-pj",
  proposalLetter_Clt = "proposal-letter-clt",
  proposalLetter_Estag = "proposal-letter-estag",
  sendDocuments_Pj = "send-documents-pj",
  sendDocuments_Clt = "send-documents-clt",
  sendDocuments_Estag = "send-documents-estag",
  signContract_Pj = "sign-contract-pj",
  signContract_Clt = "sign-contract-clt",
  signContract_Estag = "sign-contract-estag",
  signContract_Resignation = "sign-contract-resignation",
  aso_Ctl = "aso-ctl",
  aso_Estag = "aso-estag",
}

export enum ResignationLimitDateEnum {
  aso_Resignation = "aso-resignation",
}

export interface ILimitDate<T> {
  step: T
  date: string
}

export interface Author {
  _id: string
  name: string
  profilePicture: S3File
}

export interface Comment {
  message: string
  authorEmployeeId: string
  authorName: string
  sentDate: Date
  timeSince: string
  profilePicture?: S3File
}

export interface Follower {
  _id: string
  name: string
  profilePicture?: S3File
}

export enum ResignationExamStatus {
  pending = "pending",
  sent = "sent",
  resent = "resent",
  filled = "filled",
  manual = "manual",
}

export enum ValidationStatusOptions {
  approved = "approved",
  declined = "declined",
  refused = "refused",
  waiting = "waiting",
}

export type ValidationResignationExam = {
  status: ValidationStatusOptions
  statusComment: string
}

export interface ResignationExam {
  validation?: ValidationResignationExam
  medicalReferral?: S3File
  limitDate?: Date
  date?: Date
  status?: ResignationExamStatus
  file?: S3File
}

export enum AdmissionalExamStatus {
  sent = "sent",
  resent = "resent",
  filled = "filled",
  declined = "declined",
  approved = "approved",
}

export interface AdmissionalExam {
  validation?: {
    status: "approved" | "declined" | "waiting"
    statusComment: string
  }
  medicalReferral?: S3File
  limitDate?: Date
  date?: Date
  status?: AdmissionalExamStatus
  file?: S3File
}

export type EmailLink = {
  name: string
  columnId: string
  link: string
  expired?: boolean
}

export type StatusHistory = {
  status: string
  columnId: string
}

export type ChecklistCard = {
  columnId: string
  items: {
    checked: boolean
    label: string
  }[]
}

export interface Card {
  _id: string
  flowId: string
  name: string
  description: string
  status: string
  code: number
  color: string
  active: boolean
  position: number
  columnId: string
  companyId: string
  companyName: string
  setupId: string
  metadata?: any
  version: number
  followers?: Follower[]
  emailLink: EmailLink[]
  statusHistory: StatusHistory[]
  checklists: ChecklistCard[]
  comments: Comment[]
  authors: Author[]
  limitDates: ILimitDate<any>[]
}

export interface HiringCard extends Card {
  documentNumber: string
  email: string
  candidateId: string
  modelProposedLetterId: string
  approvalStatus?: "approved" | "refused"
  approvalCommentary?: string
  updatedAt: Date
  createdAt: Date
  formConfigId: string
  formExtensionId: string
  admissionalExam: AdmissionalExam
  attachments: any
  limitDates: ILimitDate<HiringLimitDateEnum>[]
}

export interface ResignationCard extends Card {
  employeeId: string
  resignationInterviewFile?: S3File
  resignationInterviewObservation?: string
  asoFile?: S3File
  medicalReferral?: S3File
  asoDate: Date
  resignationExam: ResignationExam
  asoResult: string
  lastWorkingDate: Date
  serveNoticePeriod?: boolean
  resignation: string
  resignationEmail?: string
  resignationReason?: string
  resignationType: string
  resignationLetter?: S3File
  resignationRequestDate: string
  limitDates: ILimitDate<ResignationLimitDateEnum>[]
}
