import gql from "graphql-tag"
import { request } from "../client"
import { FlowSubcategory, ModelArchive } from "../../types"

const GET_ADD_MASS_CANDIDATE_MODELS = gql`
  query GetAddMassCandidateModel($params: InputGetAddMassCandidateModel) {
    getAddMassCandidateModel(params: $params) {
      path
      type
      s3PresignedValue
    }
  }
`

export const getAddMassCandidateModels = async (
  flowType: FlowSubcategory,
): Promise<ModelArchive[]> => {
  const { getAddMassCandidateModel } = await request(
    GET_ADD_MASS_CANDIDATE_MODELS,
    {
      params: { flowType },
    },
  )
  return getAddMassCandidateModel as ModelArchive[]
}
