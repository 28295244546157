import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 110px 30px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const LeftColumnContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-right: 35px;
  gap: 10px;
`

export const RightColumnContainer = styled.div`
  width: 70%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  display: flex;
  max-width: 650px;
  padding: 40px;
  gap: 20px;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`
