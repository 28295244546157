import React from "react"
import { FlowBigNumbers, FlowWithCards, HiringCard } from "../../../types"
import { FilterRef, HiringFilter, Insights } from "../../common"
import { SearchField } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { Board } from "../Board"

export type KanbanProps = {
  flow: FlowWithCards<HiringCard>
  bigNumbers: FlowBigNumbers
}

export const Kanban = ({ flow: flowOg, bigNumbers }: KanbanProps) => {
  const [flow, setFlow] = React.useState<FlowWithCards<HiringCard>>(flowOg)
  const [searchField, setSearchField] = React.useState<string>("")

  const filterRef = React.useRef<FilterRef>(null)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchField(value || "")

    filterRef.current?.applyExternalFilter({
      identifier: "searchField",
      key: ["name", "email", "documentNumber"],
      value: value,
    })
  }

  return (
    <>
      <Insights bigNumbers={bigNumbers} style={{ marginBottom: 32 }} />

      <SearchField
        value={searchField}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleSearchField(e)
        }
        label={t("home.options.searchField")}
        style={{ marginBottom: 32 }}
      />

      <HiringFilter
        ref={filterRef}
        originalFlow={flowOg}
        flow={flow}
        handleUpdateFlow={(filteredFlow) => {
          setFlow(filteredFlow)
        }}
        hideFilter={{
          step: true,
        }}
        style={{ marginBottom: 32 }}
      />

      <Board flow={flow} />
    </>
  )
}
