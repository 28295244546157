import { useEffect, forwardRef, useImperativeHandle } from "react"
import { FormikErrors, useFormik } from "formik"
import { useTranslation } from "react-i18next"
import {
  TextField,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import * as yup from "yup"

const validationSchema = yup.object({
  name: yup.string().required("Favor preencher o nome do modelo"),
  description: yup.string(),
})

export type FormBasicRef = {
  getValues: () => { name: string; description: string }
  handleSubmit: () => void
  validateForm: () => Promise<
    FormikErrors<{ name: string; description: string }>
  >
}

interface FormBasicProps {
  isEdit: boolean
  data?: {
    name?: string
    description?: string
  }
  loading?: boolean
  onSubmit: ({
    name,
    description,
  }: {
    name: string
    description: string
  }) => void
}

export const FormBasicInfo = forwardRef<FormBasicRef, FormBasicProps>(
  ({ isEdit, data, onSubmit, loading }, ref) => {
    const formik = useFormik({
      initialValues: {
        name: "",
        description: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => onSubmit(values),
    })

    useImperativeHandle(ref, () => ({
      getValues: () => formik.values,
      handleSubmit: () => formik.handleSubmit(),
      validateForm: () => formik.validateForm(),
    }))

    useEffect(() => {
      if (isEdit && data && Object.keys(data).length) {
        formik.setValues({
          name: data?.name || "",
          description: data?.description || "",
        })
      }
    }, [data])

    const [t] = useTranslation("translations", {
      keyPrefix: "page.settings.proposedLetterModel.basicDataForm",
    })

    return (
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="headline8"
            style={{
              color: "#53464f",
            }}
          >
            {t("title")}
          </Typography>
          <Typography
            variant="body3"
            style={{
              color: "#53464F",
            }}
          >
            <span style={{ color: "#C96C01", marginRight: 4 }}>*</span>
            {t("requiredFieldSpan")}
          </Typography>
        </div>

        <Typography
          variant="body4"
          style={{
            marginBottom: 32,
            color: "#83727D",
          }}
        >
          {t("description")}
        </Typography>

        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
          >
            <Skeleton variant="rounded" height={"56px"} />
            <Skeleton variant="rounded" height={"129px"} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
          >
            <div>
              <TextField
                id={"name"}
                name={"name"}
                label={t("inputName")}
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={Boolean(formik.errors.name)}
                helperText={formik.errors.name || undefined}
              />
            </div>
            <div>
              <TextField
                id={"description"}
                name={"description"}
                label={t("textareaDescription")}
                multiline={true}
                rows={4}
                fullWidth
                value={formik.values.description}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.description)}
                helperText={formik.errors.description || undefined}
              />
            </div>
          </div>
        )}
      </form>
    )
  },
)
