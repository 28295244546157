import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

import styled from "styled-components"
import { DeprecatedModal } from "../../../../../components"

export const StyledModal = styled(DeprecatedModal)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 645px;
      max-height: 95vh;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 40px 112px;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  width: 64px;
  height: 64px;

  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 200px;
  margin-bottom: 16px;
`

export const StyledTitle = styled(Typography)<{
  setColor?: string
  disabled?: boolean
}>`
  && {
    font-weight: 700;

    color: ${({ theme, setColor }) => {
      switch (setColor) {
        case "primary":
          return theme.colors.primary
        case "secondary50":
          return theme.colors.secondary[50]
        case "neutral10":
          return theme.colors.neutral[10]
        case "neutral20":
          return theme.colors.neutral[20]
        case "neutral30":
          return theme.colors.neutral[30]
        case "neutral40":
          return theme.colors.neutral[40]
        case "neutral50":
          return theme.colors.neutral[50]
        case "neutral60":
          return theme.colors.neutral[60]
        case "neutral70":
          return theme.colors.neutral[70]
        case "error40":
          return theme.colors.feedback.error[40]
        case "info10":
          return theme.colors.feedback.info[10]
        default:
          ""
          break
      }
    }};

    ${({ theme, disabled }) => {
      if (disabled) {
        return `color: ${theme.colors.neutral[70]}`
      }
    }}
  }
`

export const StyledText = styled(Typography)<{ setColor?: string }>`
  && {
    color: ${({ theme, setColor }) => {
      switch (setColor) {
        case "neutral30":
          return theme.colors.neutral[30]
        case "neutral40":
          return theme.colors.neutral[40]
        case "neutral50":
          return theme.colors.neutral[50]
        case "neutral60":
          return theme.colors.neutral[60]
        case "neutral70":
          return theme.colors.neutral[70]
        case "secondary50":
          return theme.colors.secondary[50]
        case "primary":
          return theme.colors.primary
        case "error40":
          return theme.colors.feedback.error[40]
        case "info10":
          return theme.colors.feedback.info[10]
        default:
          ""
          break
      }
    }};
  }
`

export const StyledIcon = styled(Icons)<{
  setColor?: string
  disabled?: boolean
}>`
  && {
    color: ${({ theme, setColor }) => {
      switch (setColor) {
        case "neutral30":
          return theme.colors.neutral[30]
        case "primary":
          return theme.colors.primary
        case "secondary50":
          return theme.colors.secondary[50]
        case "secondary80":
          return theme.colors.secondary[80]
        case "secondary95":
          return theme.colors.secondary[95]
        case "error40":
          return theme.colors.feedback.error[40]
        case "error50":
          return theme.colors.feedback.error[50]
        case "success40":
          return theme.colors.feedback.success[40]
        case "neutral100":
          return theme.colors.neutral[100]
        default:
          ""
          break
      }
    }};

    ${({ theme, disabled }) => {
      if (disabled) {
        return `color: ${theme.colors.neutral[70]}`
      }
    }}
  }
`
