import { IconButton, Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { useState } from "react"
import {
  StyledPOneLine,
  UploadFileName,
  UploadIconContainer,
  UploadPreview,
  UploadPreviewArea,
} from "../styles"
import { removeDropzoneTimestamp } from "src/utils"

export const PreviewUploadComponent = ({
  template,
  handleSetTemplate,
}: {
  template: any[]
  handleSetTemplate: (templates: any[]) => void
}) => {
  const [loadingOpenRemoveFile, setLoadingOpenRemoveFile] = useState<any>({
    loading: false,
    key: "",
  })

  return (
    <>
      {template?.map(({ key = "", path = "", value = "" }, index) => {
        const lastIndexName = key ? key.lastIndexOf("/") : ""
        const nameOfArchive =
          key && lastIndexName
            ? removeDropzoneTimestamp(key.substring(lastIndexName + 1))
            : "Nome não identificado"

        const filteredTemplate = template?.filter((c) => c.path !== path)

        const removeValueTemplate =
          filteredTemplate?.map((f) => {
            return { key: f.key, path: f.path, type: f.type }
          }) || []

        return (
          <div key={index}>
            {loadingOpenRemoveFile?.loading &&
            loadingOpenRemoveFile?.key === key ? (
              <Skeleton variant="rectangular" width="100%" height="114px" />
            ) : (
              <div
                role="presentation"
                onClick={async (e) => {
                  setLoadingOpenRemoveFile({ loading: true, key: key })

                  const readFile = await fetch(value)
                  const fileToArrayBuffer = await readFile.arrayBuffer()
                  const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
                  const a = document.createElement("a")
                  a.href = res
                  a.download = nameOfArchive
                  a.click()

                  setLoadingOpenRemoveFile({ loading: false, key: key })

                  e.stopPropagation()
                }}
              >
                <UploadPreview>
                  <UploadPreviewArea>
                    <UploadIconContainer>
                      <Icons
                        name={"IconFile"}
                        style={{ fill: "transparent" }}
                      />
                    </UploadIconContainer>
                    <UploadFileName>
                      <StyledPOneLine numberOfLines={1}>
                        {nameOfArchive}
                      </StyledPOneLine>
                    </UploadFileName>
                  </UploadPreviewArea>
                  <IconButton
                    size="medium"
                    variant="filled"
                    icon="IconTrash"
                    onClick={async (e) => {
                      e.stopPropagation()
                      e.preventDefault()

                      setLoadingOpenRemoveFile({ loading: true, key: key })

                      handleSetTemplate(removeValueTemplate)
                      setLoadingOpenRemoveFile({ loading: false, key: key })
                    }}
                  />
                </UploadPreview>
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}
