import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import {
  FilledProgressBar,
  BackgroundProgressBar,
  TextProgressBar,
  Container,
  ContainerBar,
  IconContainer,
  IconAnimated,
  Icon,
  Title,
  Description,
  ProgressWraper,
} from "./styles"

export type ProgressBarProps = {
  title: string
  description: string
  info?: string
  variantType: "default" | "error" | "success"
  value: number
  widthBar?: number
}

export const ProgressBar = ({
  title,
  description,
  info,
  variantType,
  value,
  widthBar = 393,
}: ProgressBarProps) => {
  return (
    <Container>
      <IconContainer variantType={variantType}>
        {variantType === "default" ? (
          <IconAnimated
            variantType={variantType}
            name={"IconRefresh"}
            size={24}
          />
        ) : (
          <Icon
            variantType={variantType}
            name={variantType === "error" ? "IconAlertTriangle" : "IconCheck"}
            size={24}
          />
        )}
      </IconContainer>

      <Title variant="headline8">{title}</Title>
      <Description variant="body4" style={{ marginTop: 5 }}>
        {description}
      </Description>

      <ProgressWraper style={{ marginTop: 20 }}>
        <ContainerBar widthBar={widthBar}>
          <BackgroundProgressBar variantType={variantType} />
          <FilledProgressBar
            variantType={variantType}
            value={value > 100 ? 100 : value}
          />
        </ContainerBar>

        <TextProgressBar variantType={variantType} variant={"caption"}>
          {`${value > 100 ? 100 : Math.round(value)}%`}
        </TextProgressBar>
      </ProgressWraper>

      {info ? (
        <Description
          variant="body4"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            lineHeight: "18px",
          }}
        >
          <Icons
            size={16}
            name={"IconInfoCircle"}
            color={"#000000"}
            style={{ paddingRight: "4px" }}
            aria-hidden={true}
          />
          {info}
        </Description>
      ) : (
        <></>
      )}
    </Container>
  )
}
