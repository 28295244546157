import {
  StyledAccordion,
  SubTitle,
  Title,
  StyledContainerIcon,
  AccordionTitle,
  AccordionDescription,
  Text,
} from "./styles"

import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

import { AccordionDetails, AccordionSummary } from "@mui/material"
import { UploadPreview } from "../../../components/common/DeprecatedUploadPreview"

export const InterviewContent = ({ resignationFlowInfo }) => {
  return (
    <StyledAccordion
      style={{
        marginBottom: "24px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            marginRight: "20px",
          }}
        >
          <AccordionTitle variant="headline7">
            Entrevista de desligamento
          </AccordionTitle>
          <AccordionDescription variant="body4">
            Inclua detalhes relacionados a entrevista de desligamento.
          </AccordionDescription>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px 44px 40px 44px" }}>
        <Title variant={"headline8"}>Entrevista de desligamento</Title>

        <div style={{ marginTop: "24px" }}>
          {resignationFlowInfo?.resignationInterviewFile?.value ? (
            <UploadPreview
              uploadItem={resignationFlowInfo?.resignationInterviewFile}
              hasShareLink
            />
          ) : (
            <></>
          )}
        </div>

        <div style={{ marginTop: "24px" }}>
          <SubTitle variant="body4" style={{ fontWeight: 700 }}>
            Observações
          </SubTitle>
          <Text
            variant="body3"
            hasValue={!!resignationFlowInfo?.resignationInterviewObservation}
          >
            {resignationFlowInfo?.resignationInterviewObservation ||
              "Não preenchido"}
          </Text>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  )
}
