import gql from "graphql-tag"
import { request } from "../client"
import { ChecklistCard } from "../../types"

const RESIGNATION_TOGGLE_CHECKLIST_ITEM = gql`
  mutation ResignationToggleChecklistItem($params: ToggleChecklistInput) {
    resignationToggleChecklistItem(params: $params) {
      checklists {
        items {
          label
          checked
        }
        columnId
      }
    }
  }
`

export const checklistToggleResignation = async (params: {
  columnId: string
  flowCardId: string
  item: string
  version: number
}): Promise<ChecklistCard[]> => {
  const { resignationToggleChecklistItem } = await request(
    RESIGNATION_TOGGLE_CHECKLIST_ITEM,
    { params },
  )
  return resignationToggleChecklistItem.checklists as ChecklistCard[]
}
