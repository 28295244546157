import gql from "graphql-tag"

export const GET_MODEL_CONTRACT_BY_ID = gql`
  query GetModelContractById($modelContractId: String) {
    getModelContractById(modelContractId: $modelContractId) {
      _id
      name
      description
      tags
      status
      companyId
      html
      active
      preview {
        key
        path
        value
        type
      }
      file {
        key
        path
        value
        type
      }
    }
  }
`
