import { Select } from "@mui/material"
import styled from "styled-components"

export const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    padding: 0 10px;
    height: 42px;

    text-align: center;
    font-family: "Nunito", sans-serif;
    color: #6b5b66;
    font-weight: 500;

    border: 1px solid var(--color-neutral-light2);
    border-radius: 100px;

    fieldset {
      border: none !important;
    }
  }
`
