import { Container, StyledIcon, StyledText, TextContainer } from "./styles"

export const OrgchartInfoBox = ({ plural = false }: { plural?: boolean }) => {
  return (
    <Container>
      <div>
        <StyledIcon name="IconAlertCircle" fill="transparent" size={24} />
      </div>
      <TextContainer>
        <StyledText variant="body3" weight={600}>
          Não existe alguém para substituir o líder?
        </StyledText>
        <StyledText variant="body4">
          Caso não exista um substituto, os <b>membros da equipe</b> passarão a{" "}
          <b>
            reportar para o líder no nível acima{" "}
            {plural ? "dos atuais" : "do atual"}
          </b>{" "}
          dentro da hierarquia empresarial. Não se preocupe, você poderá{" "}
          {plural ? "substituir os líderes" : "substitui-lo"} novamente através
          do{" "}
          <a href="/orgchart">
            <u>
              <b>Organograma</b>
            </u>
          </a>
          .
        </StyledText>
      </TextContainer>
    </Container>
  )
}
