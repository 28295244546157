import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin: 40px 0;
`

export const CategoryContainer = styled.div`
  width: calc(30% - 12px);
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`

export const DocumentsContainer = styled.div`
  width: calc(70% - 12px);
  display: flex;
  flex-direction: column;

  margin-left: 24px;
`

export const CardAddSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 12px;

  padding: 40px;
  margin-bottom: 24px;
`

export const Title = styled(Typography)`
  color: #3b2e37;
`

export const Description = styled(Typography)`
  color: #83727d;
`
