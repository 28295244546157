import { Outlet, Navigate, useLocation, matchPath } from "react-router-dom"

import {
  getFromLS,
  useContractModules,
} from "@flash-tecnologia/hros-web-utility"

import { useAuth } from "@flash-hros/auth-helper"

import { flatMap } from "lodash"

import { slugRoutes } from "./permission"
import { useContext } from "react"
import { globalContext } from "../context"

export const RoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const location = useLocation()

  const isLoggedIn = getFromLS("hrosAccessToken")
  const { companyPermissions } = useContext(globalContext)

  const contractStatus = useAuth.getState().contractStatus || null
  const routesBySlug = slugRoutes.filter((route) =>
    companyPermissions?.some(
      (permission) => permission === "*" || permission === route.slug,
    ),
  )

  const peopleContractModules = useContractModules()
  const hasModule = peopleContractModules?.modules?.includes("flows")

  if (!hasModule) {
    return <Navigate to={"/home"} />
  }

  const routes = flatMap(routesBySlug, "routes")

  const isRouteAllowed = routes.some((c) =>
    matchPath({ path: c, end: true }, location.pathname),
  )

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />
  }

  if (!isRouteAllowed) {
    return <Navigate to={"/home"} />
  }

  if (contractStatus?.url) {
    return <Navigate to={contractStatus?.url} state={{ from: location }} />
  }

  return children ? children : <Outlet />
}
