import gql from "graphql-tag"
import { request } from "../client"
import { Contract } from "../../types"

export const GET_CONTRACT_BY_ID = gql`
  query GetContractById($flowCardId: ID!) {
    getContractById(flowCardId: $flowCardId) {
      _id
      companyId
      contractDuration
      flowCardId
      status
      template {
        key
        path
        value
        type
      }
      signature {
        employeeId
        candidateId
        name
        type
        email
        phone
        signedAt
        token
        signatureFile
        status
        ip
        confirmationInfo
      }
      contractOutput {
        key
        path
        value
        type
        version
      }
      lastContractOutput {
        key
        path
        value
        type
        version
      }
    }
  }
`

export const getContractById = async (flowCardId: string) => {
  const { getContractById } = await request(GET_CONTRACT_BY_ID, {
    flowCardId,
  })
  return getContractById as Contract
}
