import { MenuItem } from "@mui/material"
import { StyledSelect } from "./styles"

type SelectOption = {
  label: string
  value: string | number
}

export type IRoundedSelect = {
  onSelectChange: (value) => void
  options: SelectOption[]
  value: string | number
}

export const RoundedSelect = ({
  onSelectChange,
  options,
  value,
}: IRoundedSelect) => {
  return (
    <StyledSelect
      onChange={(e) => onSelectChange(e.target.value)}
      value={value}
    >
      {options?.map((option, idx) => (
        <MenuItem key={idx} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}
