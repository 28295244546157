import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"

import styled from "styled-components"
import { DeprecatedModal } from "../../../../components"

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      border-radius: 16px;
      width: 870px;
      overflow: hidden;
    }
    .modal-content-area {
      padding: 0px;
      overflow: auto;
    }
  }
`

const StyledLinkButton = styled(LinkButton)``

const StyledTextLinkButton = styled(Typography)`
  font-weight: 700 !important;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const StyledOrgTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
`

const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 12px;
  padding-right: 24px;
`

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 112px 40px 112px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 112px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`

const StyledContainer = styled.div`
  padding: 40px 112px;
`

export {
  StyledOrgTitle,
  StyledHeader,
  CloseContainer,
  StyledModal,
  StyledContainer,
  StyledFooter,
  StyledLinkButton,
  StyledTextLinkButton,
  StyledTitle,
  StyledDescription,
}
