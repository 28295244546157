import { HiringLimitDateEnum } from "bff/src/types/hiring"
import { FlowCategory } from "../types"

export enum HiringColumnNameEnum {
  admission = "admission",
  archived = "archived",
  candidates = "candidates",
  documentsValidation = "documentsValidation",
  documentsSend = "documentsSend",
  done = "done",
  proposedLetter = "proposedLetter",
  initialConfiguration = "initialConfiguration",
  signing = "signing",
}

export enum ResignationColumnNameEnum {
  onHold = "onHold",
  startProcess = "startProcess",
  interview = "interview",
  exam = "exam",
  rescission = "rescission",
  signature = "signature",
  documentUpdate = "documentUpdate",
  done = "done",
  archived = "archived",
}

export interface BoardColumns<ColumnNameEnum> {
  name: ColumnNameEnum
  columnsId: string[]
  route: string
}

export const resignationColumnsId = {
  onHold: ["NorGGe2Kt_JCq65zz4Pt6"],
  startProcess: ["Xc8aMfjDVTZjR32e9KlTl"],
  interview: ["f1PAn4f42SGP1YbiDnUJp"],
  exam: ["9UCVbL4llwtcBJ3vqm1eo"],
  rescission: ["rCUyF82cXlfUIrdHDpMsX"],
  signature: ["4TJMj1Dqwk_F_RaAfPmw1"],
  documentUpdate: ["uz6g7xOUBzoyRR35cgorn"],
  done: ["X1Slh7uf-YK4GgXj0ZUY4"],
  archived: ["OjYDb3IEUtyis_aOZdM4f"],
}

export const hiringColumnsId = {
  admission: ["2BuJf8_cHjLYdzkw3mfqr", "7Xox8DiCYKqyetiQaY3qC"],
  archived: [
    "1UuK88MXEDxiv6NG8iRU8",
    "g8yuhwNSdLrR5EbiWFAq_",
    "0t4GFQdJYuIagxzxpogfs",
  ],
  candidates: [
    "tRJpyyDaWvTtwQEWqB8BO",
    "SGHourMe5ZuWbKcNTufdp",
    "gRVF22nRm8Vv_NvXkczxR",
  ],
  documentsSend: [
    "79VS8G8CfV53uQmJYQ_dR",
    "tWrA2o2pD_jYj_5jgIk8f",
    "8ESbDkaIj7IvuDbNSWoFi",
  ],
  documentsValidation: [
    "QK-1JLmCDHdOV71keXtO4",
    "qpG65fUAKteLAwGZUO-gv",
    "CxlaQY9Ey_ZNwn1wKId4X",
  ],
  done: [
    "uEZYkovMfvdt5Nc1dJjAz",
    "eIFKwtQ3YwUScPG11-lAs",
    "ajk_XIs6V1VlE_BqHtXuv",
    "X6K51-NNZZ8DXjjbCboWP",
  ],
  proposedLetter: [
    "iBQ6kyM0cmPVDoDZv9BI7",
    "mL60LCZrDoobUCEs4Uwbz",
    "yytMYZdnZ6sEDTTEwQUC6",
  ],
  signing: [
    "eIFKwtQ3YwUScPG1-l8As",
    "FDcChp56n_l-AM7wNLDeL",
    "cFZfUaMVksdXqQRQn14z0",
  ],
  initialConfiguration: [
    "lOxA6ManLAMTlTudlBf7D",
    "9l54f3jIUV3BQTe9z2Ejk",
    "wWpn0E3uxTMB1iIMlT44r",
  ],
}

export const hiringFlowColumns: BoardColumns<HiringColumnNameEnum>[] = [
  {
    name: HiringColumnNameEnum.admission,
    columnsId: hiringColumnsId.admission,
    route: "/flows/hiring/admission/",
  },
  {
    name: HiringColumnNameEnum.archived,
    columnsId: hiringColumnsId.archived,
    route: "/flows/hiring/archived/",
  },
  {
    name: HiringColumnNameEnum.candidates,
    columnsId: hiringColumnsId.candidates,
    route: "/flows/hiring/candidate/",
  },
  {
    name: HiringColumnNameEnum.documentsSend,
    columnsId: hiringColumnsId.documentsSend,
    route: "/flows/hiring/documents/send/",
  },
  {
    name: HiringColumnNameEnum.documentsValidation,
    columnsId: hiringColumnsId.documentsValidation,
    route: "/flows/hiring/documents/validation/",
  },
  {
    name: HiringColumnNameEnum.done,
    columnsId: hiringColumnsId.done,
    route: "/flows/hiring/done/",
  },
  {
    name: HiringColumnNameEnum.proposedLetter,
    columnsId: hiringColumnsId.proposedLetter,
    route: "/flows/hiring/proposal/",
  },
  {
    name: HiringColumnNameEnum.signing,
    columnsId: hiringColumnsId.signing,
    route: "/flows/hiring/signature/",
  },
  {
    name: HiringColumnNameEnum.initialConfiguration,
    columnsId: hiringColumnsId.initialConfiguration,
    route: "/flows/hiring/initial-config/",
  },
]

export const resignationFlowColumns: BoardColumns<ResignationColumnNameEnum>[] =
  [
    {
      name: ResignationColumnNameEnum.onHold,
      columnsId: resignationColumnsId.onHold,
      route: "/flows/resignation/onHold/",
    },
    {
      name: ResignationColumnNameEnum.startProcess,
      columnsId: resignationColumnsId.startProcess,
      route: "/flows/resignation/startProcess/",
    },
    {
      name: ResignationColumnNameEnum.interview,
      columnsId: resignationColumnsId.interview,
      route: "/flows/resignation/interview/",
    },
    {
      name: ResignationColumnNameEnum.exam,
      columnsId: resignationColumnsId.exam,
      route: "/flows/resignation/exam/",
    },
    {
      name: ResignationColumnNameEnum.rescission,
      columnsId: resignationColumnsId.rescission,
      route: "/flows/resignation/rescission/",
    },
    {
      name: ResignationColumnNameEnum.signature,
      columnsId: resignationColumnsId.signature,
      route: "/flows/resignation/signature/",
    },
    {
      name: ResignationColumnNameEnum.documentUpdate,
      columnsId: resignationColumnsId.documentUpdate,
      route: "/flows/resignation/document/update/",
    },
    {
      name: ResignationColumnNameEnum.done,
      columnsId: resignationColumnsId.done,
      route: "/flows/resignation/done/",
    },
    {
      name: ResignationColumnNameEnum.archived,
      columnsId: resignationColumnsId.archived,
      route: "/flows/resignation/archived/",
    },
  ]

const boardColumns: {
  [key in FlowCategory]: BoardColumns<
    HiringColumnNameEnum | ResignationColumnNameEnum
  >[]
} = {
  hiring: hiringFlowColumns,
  resignation: resignationFlowColumns,
}

export const getColumnById = (id: string, category: FlowCategory) => {
  return boardColumns[category].find((data) => data.columnsId.includes(id))
}

export const hiringLimitDateMap: { [key in string]: HiringLimitDateEnum[] } = {
  [HiringColumnNameEnum.admission]: [
    HiringLimitDateEnum.aso_Ctl,
    HiringLimitDateEnum.aso_Estag,
  ],
  [HiringColumnNameEnum.proposedLetter]: [
    HiringLimitDateEnum.proposalLetter_Clt,
    HiringLimitDateEnum.proposalLetter_Estag,
    HiringLimitDateEnum.proposalLetter_Pj,
  ],
  [HiringColumnNameEnum.documentsSend]: [
    HiringLimitDateEnum.sendDocuments_Clt,
    HiringLimitDateEnum.sendDocuments_Estag,
    HiringLimitDateEnum.sendDocuments_Pj,
  ],
  [HiringColumnNameEnum.signing]: [
    HiringLimitDateEnum.signContract_Clt,
    HiringLimitDateEnum.signContract_Estag,
    HiringLimitDateEnum.signContract_Pj,
  ],
}
