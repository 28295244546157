import { CSSProperties, Ref, forwardRef, useImperativeHandle } from "react"
import {
  DatePicker,
  SelectField,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../utils/dispatchToast"
import { FormikErrors, useFormik } from "formik"

import * as yup from "yup"
import { trpc } from "@api/client"
import {
  HiringLimitDateEnum,
  ILimitDate,
  ResignationLimitDateEnum,
} from "bff/src/types/hiring"
import { useTheme } from "styled-components"

export type LimitDateRef = {
  isValid: boolean
  submit: () => void
  validate: () => Promise<
    FormikErrors<{ limitDate: string; limitTime: string }>
  >
}

type LimitDateProps<LimitDateEnum> = {
  limitDate?: ILimitDate<LimitDateEnum>
  cardId: string
  step: LimitDateEnum
  title?: string
  description?: string
  style?: CSSProperties
}

type UpdateCallback = {
  cardId: string
  date: string
  step: HiringLimitDateEnum | ResignationLimitDateEnum
}

type ComponentProps = {
  updateCallback: (params: UpdateCallback) => void
} & LimitDateProps<HiringLimitDateEnum | ResignationLimitDateEnum>

export const HiringLimitDate = forwardRef(
  (props: LimitDateProps<HiringLimitDateEnum>, ref: Ref<LimitDateRef>) => {
    const { mutateAsync: hiringSetLimitDate } =
      trpc.card.hiringSetLimitDate.useMutation()

    return (
      <LimitDate ref={ref} updateCallback={hiringSetLimitDate} {...props} />
    )
  },
)

export const ResignationLimitDate = forwardRef(
  (props: LimitDateProps<ResignationLimitDateEnum>, ref: Ref<LimitDateRef>) => {
    const { mutateAsync: resignationSetLimitDate } =
      trpc.resignationCard.resignationSetLimitDate.useMutation()

    return (
      <LimitDate
        ref={ref}
        updateCallback={resignationSetLimitDate}
        {...props}
      />
    )
  },
)

const LimitDate = forwardRef(
  (props: ComponentProps, ref: Ref<LimitDateRef>) => {
    const {
      limitDate: ogLimitDate,
      cardId,
      step,
      style,
      updateCallback,
      title = "Prazo de resposta",
      description = " Por motivos de segurança, a inclusão de uma data é permitida apenas para um período de até 15 dias.",
    } = props

    const theme = useTheme()

    const formik = useFormik({
      initialValues: {
        limitDate: ogLimitDate?.date || "",
        limitTime: ogLimitDate?.date
          ? dayjs(ogLimitDate?.date).format("HH:mm:ss")
          : "",
      },
      validationSchema: yup.object({
        limitDate: yup.string().required("Campo obrigatório"),
        limitTime: yup.string().required("Campo obrigatório"),
      }),
      validate: (values) => {
        const errors: any = {}

        const todayNow = dayjs()
        const limitDateParsed = dayjs(values.limitDate)
        if (limitDateParsed.isBefore(todayNow, "day")) {
          errors.limitDate = "A data deve ser igual ou posterior à atual"
        }

        if (limitDateParsed.isSame(todayNow, "day")) {
          const limitTimeParsed = dayjs(
            `${todayNow.format("YYYY-MM-DD")} ${values.limitTime}`,
            "YYYY-MM-DD HH:mm:ss",
          )

          if (limitTimeParsed.isBefore(todayNow)) {
            errors.limitTime = "O horário deve ser posterior que o atual"
          }
        }

        if (limitDateParsed.isAfter(todayNow.add(15, "day"), "day")) {
          errors.limitDate =
            "A data limite permitida maior que 15 dias excedida"
        }

        return errors
      },
      onSubmit: async (values) => {
        try {
          const hourParsed = dayjs(
            `1970-01-01 ${values.limitTime}`,
            "YYYY-MM-DD HH:mm:ss",
          )

          const dateCombined = dayjs(values.limitDate)
            .set("hour", hourParsed.hour())
            .set("minute", hourParsed.minute())
            .set("second", hourParsed.second())

          await updateCallback({
            cardId,
            date: dateCombined.toISOString(),
            step: step as any,
          })
        } catch (err: any) {
          console.error(err)
          dispatchToast({
            content: "Algo aconteceu ao salvar a data limite de envio",
            type: "error",
          })
          throw err
        }
      },
    })

    useImperativeHandle(ref, () => ({
      isValid: formik.isValid,
      submit: formik.submitForm,
      validate: () => formik.validateForm(),
    }))

    return (
      <div style={style}>
        <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
          {title}
        </Typography>
        <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
          {description}
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "24px",
          }}
        >
          <div style={{ width: "48%" }}>
            <DatePicker
              label={"Data limite"}
              fromDate={dayjs()}
              toDate={dayjs().add(14, "day")}
              value={formik.values.limitDate}
              error={Boolean(formik.errors.limitDate)}
              helperText={formik.errors.limitDate || undefined}
              onDateChange={async (value) => {
                if (!value || value.toISOString() === formik.values.limitTime)
                  return
                formik.handleChange({
                  target: { name: "limitDate", value: value.toISOString() },
                })
              }}
            />
          </div>
          <div style={{ width: "48%" }}>
            <SelectField
              label={"Horário limite"}
              value={formik.values.limitTime}
              error={Boolean(formik.errors.limitTime)}
              helperText={formik.errors.limitTime || undefined}
              onSelectChange={(_, { value }) =>
                formik.handleChange({
                  target: { name: "limitTime", value },
                })
              }
              options={[
                { label: "Manhã (8h)", value: "08:00:00" },
                { label: "Tarde (12h)", value: "12:00:00" },
                { label: "Noite (18h)", value: "18:00:00" },
              ]}
            />
          </div>
        </div>
      </div>
    )
  },
)
