import { Button, IconButton } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 40px;
  border: 1px solid #d1c7ce;
  border-radius: 12px;
`

export const Content = styled.div<{ hasChecklist: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ hasChecklist }) =>
    hasChecklist
      ? css`
          width: calc(100% - 75px);
        `
      : css`
          width: 100%;
        `}
`

export const FloatingIconButton = styled(IconButton)`
  position: absolute;
  top: 40px;
  right: 40px;
`

export const EmptyStateContainer = styled.div`
  background-color: #f8f6f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
  padding: 25px;
`

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`

export const ChecklistItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #53464f;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  gap: 20px;
`

export const StyledButton = styled(Button)`
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
