import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
  overflow-y: auto;
  max-height: 50vh;
  padding: ${({ theme }) => `0px ${theme.spacings.xs} ${theme.spacings.xs}`};
`

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`
