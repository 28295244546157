import gql from "graphql-tag"
import { request } from "../client"
import { CompanyDepartments } from "../../types"

const GET_DEPARTMENTS_BY_COMPANY_ID = gql`
  query GetDepartmentsByCompanyId($companyId: ID!) {
    getDepartmentsByCompanyId(companyId: $companyId) {
      id
      name
      description
      isActive
      createdAt
      updatedAt
    }
  }
`

export const getDepartmentsByCompanyId = async ({
  companyId,
}: {
  companyId: string
}) => {
  const { getDepartmentsByCompanyId } = await request(
    GET_DEPARTMENTS_BY_COMPANY_ID,
    {
      companyId,
    },
  )
  return getDepartmentsByCompanyId as CompanyDepartments[]
}
