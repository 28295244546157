import { InputContainedContainer } from "./styles"

const ContainedInput = ({
  hasNotDisableDesign = false,
  value,
  defaultValue = "",
  disabled = false,
  error = false,
  maxLength,
  name,
  onChange,
  onBlur,
  label,
  type,
  placeholder,
}: {
  value?: string
  defaultValue?: string
  disabled?: boolean
  hasNotDisableDesign?: boolean
  error?: boolean
  name?: string
  maxLength?: number
  onChange?: any
  onBlur?: any
  label: string
  type: string
  placeholder: string
}) => {
  return (
    <InputContainedContainer
      disabled={disabled && !hasNotDisableDesign}
      error={error}
    >
      <label>{label}</label>
      <input
        defaultValue={defaultValue}
        value={value}
        maxLength={maxLength}
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    </InputContainedContainer>
  )
}

export { ContainedInput }
