import gql from "graphql-tag"
import { request } from "../client"
import { MassCandidateProcess } from "../../types"

const DISMISS_CANDIDATE_PROCESS = gql`
  mutation DismissCandidateProcess($params: InputDismissCandidateProcess) {
    dismissCandidateProcess(params: $params) {
      _id
      notificationEmployeeId
      companyId
      flowId
      completionPercentage
      validationPercentage
      lastReadPosition
      lastValidatedPosition
      fileKey
      active
      dismiss
      errorReport {
        line
        reason
      }
      fileType
      initialPosition
      validationProgress
    }
  }
`

export type InputDismissCandidateProcess = {
  processId: string
}

export const dismissCandidateProcess = async (
  params: InputDismissCandidateProcess,
): Promise<MassCandidateProcess> => {
  const response = await request<{
    dismissCandidateProcess: MassCandidateProcess
  }>(DISMISS_CANDIDATE_PROCESS, {
    params,
  })
  return response.dismissCandidateProcess
}
