import styled from "styled-components"

export const EmptySectionContainer = styled.div`
  display: grid;
  padding: ${(props) => props.theme.spacings.xs1};
  text-align: center;
`

export const EmptySectionImage = styled.img`
  margin-bottom: ${(props) => props.theme.spacings.xs2};
  margin: 0 auto;
`
