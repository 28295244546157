import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

import {
  BackButton,
  Container,
  ContinueButton,
  Wrapper,
  StyledLinkButton,
} from "./styled"

type ButtonProps = {
  title?: React.ReactNode
  callback?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  loading?: boolean
  hasToShow?: boolean
}

export type FooterProps = {
  cancelProps: ButtonProps
  goBackProps?: ButtonProps
  confirmProps: ButtonProps
  skipProps?: ButtonProps
  draftProps?: ButtonProps
}

export const Footer = ({
  cancelProps,
  goBackProps,
  confirmProps,
  skipProps,
  draftProps,
}: FooterProps) => {
  return (
    <Container>
      <Wrapper>
        <StyledLinkButton
          variant="secondary"
          disabled={cancelProps.disabled || cancelProps.loading || false}
          onClick={cancelProps.callback}
        >
          {cancelProps.title ? cancelProps.title : "Cancelar"}
        </StyledLinkButton>
        {draftProps && (
          <StyledLinkButton
            variant="secondary"
            disabled={draftProps.disabled || draftProps.loading || false}
            onClick={draftProps.callback}
          >
            {draftProps.title ? draftProps.title : "Sair e salvar rascunho"}
          </StyledLinkButton>
        )}
        {skipProps && (
          <StyledLinkButton
            variant="secondary"
            disabled={skipProps.disabled || skipProps.loading || false}
            onClick={skipProps.callback}
          >
            {skipProps.title ? skipProps.title : "Pular e finalizar"}
          </StyledLinkButton>
        )}
      </Wrapper>
      <Wrapper>
        {goBackProps && goBackProps.hasToShow !== false && (
          <BackButton
            size="medium"
            variant="secondary"
            loading={goBackProps.loading || false}
            disabled={goBackProps.disabled || goBackProps.loading || false}
            onClick={goBackProps.callback}
          >
            {goBackProps.title ? (
              goBackProps.title
            ) : (
              <>
                <Icons name="IconArrowLeft" />
                Voltar
              </>
            )}
          </BackButton>
        )}
        <ContinueButton
          size="medium"
          variant="primary"
          loading={confirmProps.loading || false}
          disabled={confirmProps.disabled || confirmProps.loading || false}
          onClick={confirmProps.callback}
        >
          {confirmProps.title ? (
            confirmProps.title
          ) : (
            <>
              Concluir <Icons name="IconCheck" fill="transparent" />
            </>
          )}
        </ContinueButton>
      </Wrapper>
    </Container>
  )
}
