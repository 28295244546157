import { ConfirmationModal } from "../../common"

type ResetConfirmationModalProps = {
  isOpen: boolean
  onConfirm: () => Promise<void> | void
  onClose: () => Promise<void> | void
  isLoading?: boolean
}

export const ResetStepConfirmationModal = ({
  isOpen,
  onConfirm,
  isLoading = false,
  onClose,
}: ResetConfirmationModalProps) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      variant="primary"
      confirmButton={{
        text: "Reiniciar etapa",
        onClick: onConfirm,
        icon: "IconCheck",
        isLoading: isLoading,
      }}
      title="Tem certeza que deseja reiniciar a etapa?"
      description="Ao reiniciar a etapa, todo o processo retornará ao estado inicial. Todas as informações preenchidas serão perdidas e essa ação não poderá ser desfeita."
      onClose={onClose}
    ></ConfirmationModal>
  )
}
