import gql from "graphql-tag"
import { request } from "../client"

const GET_LEADERS_FROM_ORGCHART = gql`
  query Query {
    getLeadersFromOrgchart
  }
`

export const getLeadersFromOrgchart = async () => {
  const { getLeadersFromOrgchart } = await request(GET_LEADERS_FROM_ORGCHART)
  return getLeadersFromOrgchart?.value
}
