import styled from "styled-components"

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 100%;
  height: 100%;
`

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid #ebe6e9;
`

export const PageBody = styled.div`
  width: 100%;
  heigh: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`
