import { gql } from "graphql-request"
import { request } from "../client"

const CHECKLIST_TEMPLATE_ADD = gql`
  mutation TemplateAddChecklist($params: AddTemplateChecklistInput) {
    templateAddChecklist(params: $params)
  }
`

export const checklistTemplateAdd = async (params: {
  checklistId: string
  newItem: string
}) => {
  await request(CHECKLIST_TEMPLATE_ADD, { params })
}
