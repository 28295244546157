import { gql } from "@apollo/client"
import { request } from "../client"
import { IModelProposedLetter, S3File } from "../../types"

const UPDATE_MODEL_PROPOSED_LETTER = gql`
  mutation UpdateModelProposedLetter($params: InputUpdateModelProposedLetter) {
    updateModelProposedLetter(params: $params) {
      _id
      name
      flowId
      companyId
      description
      status
      createdAt
      proposedLetter {
        aboutCompany
        benefits
        role
        department
        group
        assignments
        salary
        variableSalary
        workload
        workship
      }
    }
  }
`

export const updateModelProposedLetter = async (params: {
  modelId: string
  name?: string
  description?: string
  status?: "published" | "draft"
  proposedLetter?: {
    aboutCompany?: S3File[]
    benefits?: S3File[]
    role?: {
      _id: string
      name: string
    }
    department?: {
      _id: string
      name: string
    }
    group?: {
      _id: string
      name: string
    }
    assignments?: string
    salary?: number
    variableSalary?: string
    workload?: string
    workship?: string
  }
}) => {
  const { updateModelProposedLetter } = await request(
    UPDATE_MODEL_PROPOSED_LETTER,
    {
      params,
    },
  )
  return updateModelProposedLetter as IModelProposedLetter
}
