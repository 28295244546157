import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  ModalConfirmation,
  SettingPageTemplate,
} from "../../../../../../components"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { FormBasicInfo, FormBasicRef } from "../../../components/FormBasicInfo"
import { useContext, useRef, useState } from "react"
import { Grid } from "@mui/material"

import {
  FormWrapper,
  MainContainer,
  StyledDescription,
  StyledSubtitle,
  Wrapper,
} from "../styles"
import { useMutation, useQuery } from "@tanstack/react-query"
import { api } from "../../../../../../api"
import { isObjectEmpty } from "../../../../../../utils"
import { globalContext } from "../../../../../../context"
import dispatchToast from "../../../../../../utils/dispatchToast"

interface BasicInfoContentProps {
  flowId: string
  modelId?: string
  mode: "edit" | "create"
}

export const BasicInfoContent = ({
  flowId,
  modelId,
  mode,
}: BasicInfoContentProps) => {
  const [modalControl, setModalControl] = useState<{
    isOpen: boolean
    modalName?: string
  }>({
    isOpen: false,
  })

  const { companyId = '' } = useContext(globalContext)
  const navigate = useNavigate()
  const formRef = useRef<FormBasicRef>(null)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.settings.proposedLetterModel",
  })

  const { data: modelProposedLetter, isInitialLoading: isLoading } = useQuery(
    ["model-proposed-letter", modelId],
    () => api.query.hiring.modelProposedLetter.getById(modelId as string),
    {
      enabled: !!modelId,
      onError: (err) => {
        console.log(err)
        dispatchToast({
          content: "Houve um erro ao buscar o modelo de carta proposta",
          type: "error",
        })
        navigate(`/flows/settings/${flowId}/proposedLetter`)
      },
    },
  )

  const { mutateAsync: createModelProposedLetter } = useMutation(
    api.mutation.hiring.modelProposedLetter.create,
  )

  const { mutateAsync: updateModelProposedLetter } = useMutation(
    api.mutation.hiring.modelProposedLetter.update,
  )

  const handleSubmit = async () => {
    const errors = await formRef.current?.validateForm()
    if (!isObjectEmpty(errors)) return
    if (!formRef.current) return
    const { name, description } = formRef.current?.getValues()

    let localModelId = modelId
    if (mode === "create") {
      const { _id } = await createModelProposedLetter({
        flowId,
        companyId,
        name,
        description,
        status: "draft",
      })

      localModelId = _id
      return navigate(
        `/flows/settings/${flowId}/proposedLetter/create/proposed-info?modelId=${localModelId}`,
      )
    } else {
      if (
        modelProposedLetter?.name !== name ||
        modelProposedLetter?.description !== description
      ) {
        await updateModelProposedLetter({
          modelId: localModelId || '',
          description,
          name,
          status: "draft",
        })
      }

      return navigate(
        `/flows/settings/${flowId}/proposedLetter/edit/proposed-info?modelId=${localModelId}`,
      )
    }
  }

  return (
    <SettingPageTemplate
      stepper={{
        steps: ["Informações básicas", "Carta proposta"],
        activeStep: 0,
      }}
      routes={[
        {
          label: "Configurações",
          route: `/flows/settings/${flowId}/proposedLetter`,
        },
        {
          label:
            mode === "create" ? t("createModelTitle") : t("editModelTitle"),
        },
      ]}
      footer={{
        cancelProps: {
          title: t("footerLeave"),
          callback: () => {
            setModalControl({
              isOpen: true,
              modalName: "footerLeave",
            })
          },
        },
        draftProps: {
          title:
            mode === "edit"
              ? t("footerLeaveAndSave")
              : t("footerLeaveAndSaveDraft"),
          disabled: false,
          callback: () => {
            setModalControl({
              isOpen: true,
              modalName: "footerLeaveAndSave",
            })
          },
        },
        confirmProps: {
          title: (
            <>
              {t("footerContinue")}
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: false,
          disabled: false,
          callback: handleSubmit,
        },
      }}
    >
      <MainContainer>
        <Wrapper>
          <Typography variant="headline6">
            {mode === "create" ? t("createModelTitle") : t("editModelTitle")}
          </Typography>
        </Wrapper>

        <Grid container spacing={2}>
          <Grid item sm={12} md={5} lg={4}>
            <StyledSubtitle variant="headline8">
              {t("basicDataTitle")}
            </StyledSubtitle>
            <StyledDescription variant="body3">
              {t("basicDataDescription")}
            </StyledDescription>
          </Grid>
          <Grid item sm={12} md={7} lg={8}>
            <FormWrapper>
              <FormBasicInfo
                ref={formRef}
                isEdit={mode === "edit"}
                data={modelProposedLetter}
                onSubmit={handleSubmit}
                loading={isLoading}
              />
            </FormWrapper>
          </Grid>
        </Grid>
      </MainContainer>

      <ModalConfirmation
        isOpen={modalControl.isOpen && modalControl.modalName === "footerLeave"}
        title={t("modalLeave.title")}
        description={t("modalLeave.description")}
        confirmLabel={t("modalLeave.confirmButton")}
        cancelLabel={t("modalLeave.cancelButton")}
        onClose={() =>
          setModalControl({
            isOpen: false,
            modalName: undefined,
          })
        }
        onConfirm={async () => {
          setModalControl({
            isOpen: false,
            modalName: undefined,
          })
          navigate(`/flows/settings/${flowId}/proposedLetter`)
        }}
        confirmIcon="IconArrowRight"
        variant="error"
      />

      <ModalConfirmation
        isOpen={
          modalControl.isOpen && modalControl.modalName === "footerLeaveAndSave"
        }
        title={t("modalLeaveAndSave.title")}
        description={
          mode === "create"
            ? t("modalLeaveAndSave.descriptionDraft")
            : t("modalLeaveAndSave.description")
        }
        confirmLabel={
          mode === "create"
            ? t("modalLeaveAndSave.confirmButtonDraft")
            : t("modalLeaveAndSave.confirmButton")
        }
        cancelLabel={t("modalLeaveAndSave.cancelButton")}
        onClose={() =>
          setModalControl({
            isOpen: false,
            modalName: undefined,
          })
        }
        onConfirm={async () => {
          const errors = await formRef.current?.validateForm()
          if (!isObjectEmpty(errors)) {
            setModalControl({
              isOpen: false,
              modalName: undefined,
            })
            return
          }
          if (!formRef.current) return
          const { name, description } = formRef.current?.getValues()

          if (mode === "create") {
            await createModelProposedLetter({
              flowId,
              companyId,
              name,
              description,
              status: "draft",
            })
          } else {
            if (
              modelProposedLetter?.name !== name ||
              modelProposedLetter?.description !== description
            )
              await updateModelProposedLetter({
                modelId: modelId || '',
                description,
                name,
              })
          }

          navigate(`/flows/settings/${flowId}/proposedLetter`)
        }}
        confirmIcon="IconArrowRight"
        variant="default"
      />
    </SettingPageTemplate>
  )
}
