import { gql } from "graphql-request"
import { request } from "../client"
import { CompanyEmployee } from "../../types"

const GET_PARTIAL_EMPLOYEE = gql`
  query GetEmployeeByPartialName($searchParam: String) {
    getEmployeeByPartialName(searchParam: $searchParam) {
      id
      name
      documentNumber
      phoneNumber
      email
      corporateEmail
      status
      companyId
      groups
      roles
      invitationDate
    }
  }
`

export const getEmployeeByPartialName = async (searchParam: string) => {
  const { getEmployeeByPartialName } = await request(GET_PARTIAL_EMPLOYEE, {
    searchParam,
  })
  return getEmployeeByPartialName as CompanyEmployee[]
}
