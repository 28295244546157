import { useState } from "react"
import { Flow, HiringCard, ResignationCard } from "../../../types"
import { Container, StyledPOneLine, TabButton, TabContainer } from "./styles"

import { HiringHistoryTab, ResignationHistoryTab } from "./History"
import { HiringInformationTab, ResignationInformationTab } from "./Information"

interface CandidateMenu {
  card: HiringCard | ResignationCard
  flow: Flow
}

export const CandidateMenu = ({ card, flow }: CandidateMenu) => {
  const [tab, setTab] = useState<"information" | "historic">("information")
  
  const InformationTab = () => {
    switch (flow.category) {
      case "resignation":
        return (
          <ResignationInformationTab
            card={card as ResignationCard}
            flow={flow}
          />
        )
      case "hiring":
        return <HiringInformationTab card={card as HiringCard} flow={flow} />
      default:
        throw new Error("Invalid flow category")
    }
  }

  const HistoryTab = () => {
    switch (flow.category) {
      case "resignation":
        return (
          <ResignationHistoryTab flow={flow} card={card as ResignationCard} />
        )
      case "hiring":
        return <HiringHistoryTab flow={flow} card={card as HiringCard} />
      default:
        throw new Error("Invalid flow category")
    }
  }

  const Content = () => {
    switch (tab) {
      case "historic":
        return <HistoryTab />

      case "information":
        return <InformationTab />
      default:
        return null
    }
  }

  return (
    <Container>
      <TabContainer>
        <TabButton
          active={tab === "information"}
          onClick={() => setTab("information")}
        >
          <StyledPOneLine numberOfLines={1}>
            {
              flow.category === 'resignation' 
                ? 'Colaborador'
                : 'Candidato'
            }
          </StyledPOneLine>
        </TabButton>
        <TabButton
          active={tab === "historic"}
          onClick={() => setTab("historic")}
        >
          <StyledPOneLine numberOfLines={1}>Histórico</StyledPOneLine>
        </TabButton>
      </TabContainer>

      <Content />
    </Container>
  )
}
