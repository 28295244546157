import {
  IconButton,
  IconTypes,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { S3File } from "../../../types"
import { Container, IconContainer } from "./styles"
import { useTheme } from "styled-components"
import { CSSProperties } from "react"

export type DownloadVariants = "default" | "error"

export type DownloadPanelProps = {
  download: Blob | S3File
  icon: IconTypes
  variant: DownloadVariants
  fileName: string
  style?: CSSProperties
}

async function handleDownload(download: Blob | S3File, fileName: string) {
  let blob
  if (!(download instanceof Blob)) {
    const readFile = await fetch(download.value as string)
    const fileToArrayBuffer = await readFile.arrayBuffer()
    blob = new Blob([fileToArrayBuffer])
  } else {
    blob = download
  }

  const a = document.createElement("a")
  a.href = URL.createObjectURL(blob)
  a.download = fileName
  a.click()
}

export const DownloadPanel = ({
  download,
  fileName,
  icon,
  variant,
  style,
}: DownloadPanelProps) => {
  const theme = useTheme()

  return (
    <Container style={style}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
        }}
      >
        <IconContainer variant={variant}>
          <Icons
            name={icon}
            fill="transparent"
            color={theme.colors.feedback.error[40]}
            size={24}
          />
        </IconContainer>

        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[20]}
          weight={700}
        >
          {fileName}
        </Typography>
      </div>

      <IconButton
        size="medium"
        variant="line"
        icon="IconDownload"
        onClick={async () => handleDownload(download, fileName)}
      />
    </Container>
  )
}
