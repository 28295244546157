import gql from "graphql-tag"
import { request } from "../client"
import { FlowWithCards } from "../../types"

export const GET_FLOW_BY_ID = gql`
  query GetFlowById($flowId: ID!) {
    getFlowById(flowId: $flowId) {
      _id
      name
      description
      type
      category
      subcategory
      active
      companyId
      setupId
      columns {
        _id
        name
        description
        locked
        editable
        color
        position
        hidden
        tags
        cards
      }
    }
  }
`

export const getFlowById = async <CardType>({ flowId }: { flowId: string }) => {
  const { getFlowById } = await request(GET_FLOW_BY_ID, { flowId })
  return getFlowById as FlowWithCards<CardType>
}
