export const slugRoutes = [
  {
    slug: "people_flows_error_pages",
    routes: ["/flows/default-error-page"],
  },
  {
    slug: "people_flows_view_admission_kanban",
    routes: [
      "/flows/hiring",
      "/flows/hiring/admission/:id",
      "/flows/hiring/archived/:id",
      "/flows/hiring/candidate/:id",
      "/flows/hiring/documents/send/:id",
      "/flows/hiring/documents/validation/:id",
      "/flows/hiring/done/:id",
      "/flows/hiring/initial-config/:id",
      "/flows/hiring/initial-config/workshift/:id",
      "/flows/hiring/initial-config/card-delivery/:id",
      "/flows/hiring/initial-config/benefits/:id",
      "/flows/hiring/proposal/:id",
      "/flows/hiring/signature/:id",
      "/flows/hiring/addMassCandidates/:flowId",
      "/flows/actions/signing",
    ],
  },
  {
    slug: "people_flows_view_resignation_kanban",
    routes: [
      "/flows/resignation",
      "/flows/resignation/onHold/:id",
      "/flows/resignation/startProcess/:id",
      "/flows/resignation/interview/:id",
      "/flows/resignation/exam/:id",
      "/flows/resignation/rescission/:id",
      "/flows/resignation/signature/:id",
      "/flows/resignation/document/update/:id",
      "/flows/resignation/done/:id",
      "/flows/resignation/archived/:id",
    ],
  },
  {
    slug: "people_flows_select_candidate",
    routes: [
      "/flows/resignation/selectPeople/:flowId",
      "/flows/resignation/selectPeople/:flowId/replaceLeader",
    ],
  },
  {
    slug: "people_flows_create_checklist_template",
    routes: [
      "/flows/settings/:flowId/documents",
      "/flows/settings/:flowId/documents/:id",
      "/flows/settings/:flowId/documents/:id/section/:sectionId",
      "/flows/settings/:flowId/checklist",
      "/flows/settings/:flowId/contracts",
      "/flows/settings/:flowId/contracts/create/:step",
      "/flows/settings/:flowId/contracts/edit/:step/:modelId",
      "/flows/settings/previewContract",
      "/flows/settings/:flowId/proposedLetter",
      "/flows/settings/:flowId/proposedLetter/preview/:modelId",
      "/flows/settings/:flowId/proposedLetter/:mode/:step",
      "/flows/settings/:flowId/notification",
      "/flows/settings/notification/movimentation/:flowId",
      "/flows/settings/notification/commentary/:flowId",
      "/flows/settings/notification/expiration/:flowId",
      "/flows/settings/notification/probationary/:flowId",
    ],
  },
]
