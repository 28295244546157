import styled from "styled-components"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

export const Container = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #d1c7ce;
  width: 100%;
  height: 54px;
`

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`
