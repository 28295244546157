import { PhoneNumberUtil } from "google-libphonenumber"
import {
  ConfirmationDependent,
  ConfirmationDocumentsWithValue,
  S3File,
} from "../types"
import {
  getS3Presigned,
  segmentTracking,
  uploadHelper,
} from "@flash-tecnologia/hros-web-utility"
import { ObjectLiteral, dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import { Dispatch } from "react"
import { EnumGlobalActions, IActions } from "../context/reducer"

export * from "./masks"
export * from "./parsers"

const phoneUtil = PhoneNumberUtil.getInstance()

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(",") || []
  const mime = arr[0]?.match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const uploadFile = async (
  folder: string,
  fileName: string,
  file: any,
  module: "hiring" | "employee-pii" = "hiring",
): Promise<S3File> => {
  const { key, path } = await uploadHelper({
    key: folder,
    file,
    fileName,
    module: module,
  })
  return { key, path, type: "internal" }
}

export const modalAction = async ({
  param,
  callback,
  dispatch,
}: {
  param: { [key: string]: any }
  dispatch: Dispatch<IActions<EnumGlobalActions>>
  callback?: any
}) => {
  try {
    dispatch({
      type: EnumGlobalActions.SET_MODAL_STATUS,
      payload: param,
    })

    if (typeof callback === "function") callback.call()

    return
  } catch ({ message = "" }: any) {
    console.log(message)
  }
}

export const processingFile = async ({
  file,
}: {
  file: S3File
}): Promise<{
  value: S3File
}> => {
  const value = file?.path || ""

  if (value) {
    const item = await getS3Presigned({
      filename: value,
      module: "employee-pii",
    })
    const { url } = item
    if (url) return { value: { ...file, value: url } }
    else return { value: { ...file } }
  }

  return { value: { ...file } }
}

export const validateCPF = (strCPF) => {
  if (!strCPF) return false
  strCPF = strCPF.replace(/[^\d]+/g, "")
  let sum
  let remainder
  sum = 0
  if (
    strCPF.length !== 11 ||
    strCPF === "00000000000" ||
    strCPF === "11111111111" ||
    strCPF === "22222222222" ||
    strCPF === "33333333333" ||
    strCPF === "44444444444" ||
    strCPF === "55555555555" ||
    strCPF === "66666666666" ||
    strCPF === "77777777777" ||
    strCPF === "88888888888" ||
    strCPF === "99999999999"
  ) {
    return false
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }
  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(strCPF.substring(9, 10))) return false
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }
  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(strCPF.substring(10, 11))) return false
  return true
}

export function validatePassword(value) {
  var passw = /(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9!@#\$%\^\&*\)\(+=._-]{8,32}/
  return !!value.match(passw)
}

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "")
  if (cnpj.length != 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(0)) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != digitos.charAt(1)) return false

  return true
}

export const validateEmail = (value: string) => {
  var email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!value.match(email)
}

export const validatePhone = (value, countryCode = "BR") => {
  const parsedNumber =
    value.length > 2 ? phoneUtil.parseAndKeepRawInput(value, countryCode) : ""

  const isValid = parsedNumber ? phoneUtil.isValidNumber(parsedNumber) : false

  return isValid
}

export const lightenDarkenColor = (col, amt) => {
  var usePound = false
  if (col[0] == "#") {
    col = col.slice(1)
    usePound = true
  }
  var num = parseInt(col, 16)
  var r = (num >> 16) + amt
  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  }
  var b = ((num >> 8) & 0x00ff) + amt
  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  }
  var g = (num & 0x0000ff) + amt
  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  }
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
}

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export const formatCurrency = function (amount) {
  const parts = amount.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")

  return "R$" + parts.join(",")
}

export const filterConfirmationGroup = (groups) => {
  return (
    groups?.map((group) => {
      if (group.type === "candidateData") {
        return {
          ...group,
          fields: group.fields.filter((section) => section.enabled),
        }
      }

      if (group.type === "dependentsData") {
        return {
          ...group,
          fields: group.fields.map((dependent) =>
            dependent.filter((section) => section.enabled),
          ),
        }
      }

      return group
    }) || []
  )
}

export const createSegmentTrack = ({
  track,
  params,
  identify,
}: {
  track: string
  params?: ObjectLiteral
  identify?: {
    id: string
    params?: ObjectLiteral
  }
}) => {
  segmentTracking({
    track,
    module: "hiring",
    businessUnit: "people",
    params,
    identify,
  })
}

export const getNameInitials = (fullName) => {
  if (!fullName) return ""

  return fullName
    .trim()
    .split(" ")
    .filter((item) => item)
    .map((n) => n[0]?.toUpperCase())
    .join("")
    .substring(0, 2)
}

export const getArchiveNameFromKey = (key: string) => {
  const lastIndexName = key ? key.lastIndexOf("/") : ""
  return key && lastIndexName
    ? removeDropzoneTimestamp(key.substring(lastIndexName + 1))
    : "Nome não identificado"
}

export const downloadArchive = async (value: any, nameOfArchive: string) => {
  const readFile = await fetch(value, {
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
  })

  const fileToArrayBuffer = await readFile.arrayBuffer()
  const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
  const a = document.createElement("a")
  a.href = res
  a.download = nameOfArchive
  a.click()

  URL.revokeObjectURL(res)
}

export const renderDate = (date?: Date) => {
  if (!date) return undefined
  return dayjs(date).format("DD/MM/YYYY")
}

export const countDocumentWithValue = (
  documents: ConfirmationDocumentsWithValue[],
) => {
  const filteredDocuments = documents.filter((document) => document.enabled)
  const totalDocuments = filteredDocuments.length

  const hasValueBooleanList = filteredDocuments.map((document) => {
    if (!document.fields) return false
    return document.fields.some((f) => !!f.value)
  })
  const filteredDocsWithValue = hasValueBooleanList.filter(
    (hasValue) => hasValue,
  )

  return `${filteredDocsWithValue.length} / ${totalDocuments}`
}

export const countDependentDocuments = (
  dependents: ConfirmationDependent[],
) => {
  const allDependentsDocument: ConfirmationDocumentsWithValue[] =
    dependents.reduce((prev, curr) => prev.concat(curr.documents as any), [])
  return countDocumentWithValue(allDependentsDocument)
}

export const parseHiringType = (hiringType: string) => {
  const uppercasedHiringType = hiringType.toUpperCase()
  if (uppercasedHiringType === "INTERNSHIP") return "Estágio"

  return uppercasedHiringType
}

export function firstCapitalLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function removeEmptyString(str: any) {
  if (typeof str === "string") {
    if (str.length === 0) return null
  }

  return str
}

export function removeNullValues(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key]
    }
  })
  return obj
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1]
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(""),
  )

  return JSON.parse(jsonPayload)
}

export function countExpirationDatesBefore(
  allDates: Date[],
  dateToCheck: Date,
): number {
  const dateToCheckParsed = dayjs(dateToCheck)

  const numberOfDatesValidated = allDates.reduce((prev, curr) => {
    const dateParsed = dayjs(curr)
    const isBefore = dateParsed.isBefore(dateToCheckParsed, "day")

    if (isBefore) return prev + 1
    return prev
  }, 0)

  return numberOfDatesValidated
}

export function countExpirationDatesEqual(
  allDates: Date[],
  dateToCheck: Date,
): number {
  const dateToCheckParsed = dayjs(dateToCheck)

  const numberOfDatesValidated = allDates.reduce((prev, curr) => {
    const dateParsed = dayjs(curr)
    const isSame = dateParsed.isSame(dateToCheckParsed, "day")

    if (isSame) return prev + 1
    return prev
  }, 0)

  return numberOfDatesValidated
}

export function getFromKey(key: string, obj: any) {
  if (key.includes(".")) {
    const splitedKeys = key.split(".")
    let value = obj
    for (const key of splitedKeys) {
      if (value?.hasOwnProperty(key)) {
        value = value[key]
      } else {
        value = null
        break
      }
    }
    return value
  }

  return obj[key]
}

export function getFileExtension(fileName: string) {
  const splitted = fileName.split(".")
  return splitted[splitted.length - 1]
}

export function removeDropzoneTimestamp(fileName: string) {
  const splitted = fileName.split("-")
  if (splitted.length === 1) return fileName

  const lengthMaxExtensionCanHave = 4
  const hasOnlyTimeStampOnString =
    splitted.length === 2 && splitted[1]?.length <= lengthMaxExtensionCanHave
  if (hasOnlyTimeStampOnString) return splitted.join("")

  return splitted.slice(1).join("-")
}

export const parseDayjsToDateString = (value?: dayjs.Dayjs): string => {
  const date = value && dayjs(value).isValid() ? dayjs(value).toISOString() : ""
  return date
}
