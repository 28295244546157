import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const PopoverText = styled.div`
  color: 53464F;
`

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export const PopoverButton = styled(Typography)`
  display: flex;
  flex-direction: row;
  font-weight: 600;

  ${(props: { colorType: string }) =>
    props.colorType === "normal"
      ? css`
          color: #53464f;
        `
      : css`
          color: #c96c01;
        `}
`
