import { useCallback } from "react"

import {
  StyledAccordion,
  SubTitle,
  Title,
  StyledContainerIcon,
  AccordionTitle,
  AccordionDescription,
  FieldContainer,
  Text,
  Divider,
} from "./styles"

import { Employee } from "../../../types"
import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2"

import { AccordionDetails, AccordionSummary } from "@mui/material"

export const OnHoldContent = ({ employee }: { employee: Employee }) => {
  const renderPersonalInfo = useCallback(() => {
    if (!employee) return <></>

    const phone =
      (employee?.contacts?.find((c) => c.type === "phone") as any) || {}
    const email =
      (employee?.contacts?.find((c) => c.type === "email") as any) || {}

    const personalFields = [
      {
        label: "Nome completo",
        value: employee?.name || "",
      },
      {
        label: "CPF",
        value:
          employee?.documentNumber?.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4",
          ) || "",
      },
      {
        label: "Email",
        value: email?.value || "",
      },
      {
        label: "Celular",
        value:
          phone?.value?.replace(
            /(\d{2})(\d{2})(\d{5})(\d{4})/,
            "$1 $2 $3-$4",
          ) || "",
      },
    ]

    return personalFields.map((item, index) => {
      return (
        <FieldContainer key={index}>
          <SubTitle variant={"body4"} style={{ fontWeight: 700 }}>
            {item.label}
          </SubTitle>
          <Text variant={"body3"} hasValue={!!item?.value}>
            {item?.value || "Não preenchido"}
          </Text>
        </FieldContainer>
      )
    })
  }, [employee])

  const renderComplementarInfo = useCallback(() => {
    if (!employee) return <></>

    const fullAddress = employee?.address
      ? `${employee?.address?.street || ""}, ${
          employee?.address?.number || ""
        } - ${employee?.address?.district || ""}, ${
          employee?.address?.city || ""
        } -  ${employee?.address?.state || ""}, ${
          employee?.address?.zipCode || ""
        }`
      : ""

    const complementarFields = [
      {
        label: "Nome da mãe",
        value: employee?.mothersName || "",
      },
      {
        label: "Data de Nascimento",
        value: employee?.birthDate
          ? dayjs(employee.birthDate).format("DD/MM/YYYY")
          : "",
      },
      {
        label: "Endereço",
        value: fullAddress,
      },
    ]

    return complementarFields.map((item, index) => {
      return (
        <FieldContainer key={index}>
          <SubTitle variant={"body4"} style={{ fontWeight: 700 }}>
            {item.label}
          </SubTitle>
          <Text variant={"body3"} hasValue={!!item?.value}>
            {item?.value || "Não preenchido"}
          </Text>
        </FieldContainer>
      )
    })
  }, [employee])

  return (
    <StyledAccordion
      style={{
        marginBottom: "24px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            marginRight: "20px",
          }}
        >
          <AccordionTitle variant="headline7">Em espera</AccordionTitle>
          <AccordionDescription variant="body4">
            Gerencie as pessoas inclusas no fluxo de desligamento.
          </AccordionDescription>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px 44px 40px 44px" }}>
        <Title variant={"headline8"}>Dados pessoais</Title>
        <div style={{ marginTop: "24px" }}>{renderPersonalInfo()}</div>
        <Divider />
        <Title variant={"headline8"}>Dados complementares</Title>
        <div style={{ marginTop: "24px" }}>{renderComplementarInfo()}</div>
      </AccordionDetails>
    </StyledAccordion>
  )
}
