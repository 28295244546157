import {
  IconTypes,
  Icons,
  LinkButton,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { ContentContainer, IconContainer, StyledButton } from "./styles"

export type ModalConfirmationProps = {
  isOpen: boolean
  isLoading?: boolean
  title: string
  description: string
  cancelLabel: string
  confirmLabel: string
  confirmIcon?: IconTypes
  variant?: "default" | "error"
  onClose: () => void
  onConfirm: () => Promise<void>
}

export const ModalConfirmation = ({
  isOpen,
  isLoading,
  title,
  description,
  cancelLabel,
  confirmLabel,
  confirmIcon,
  variant = "default",
  onClose,
  onConfirm,
}: ModalConfirmationProps) => {
  const variantColors = {
    default: "#F20D7A",
    error: "#C96C01",
  }
  return (
    <Modal.Root open={isOpen} onClose={onClose} size={"xs"}>
      <>
        <Modal.Content>
          <ContentContainer>
            <IconContainer variantType={variant}>
              <Icons
                name="IconAlertCircle"
                fill="none"
                size={40}
                style={{ color: variantColors[variant] }}
              />
            </IconContainer>

            <Typography
              variant="body3"
              children="Atenção!"
              style={{
                textAlign: "center",
                color: variantColors[variant],
                fontWeight: 700,
                marginBottom: 4,
              }}
            />
            <Typography
              variant="headline6"
              children={title}
              style={{
                maxWidth: "421px",
                textAlign: "center",
                marginBottom: "16px",
              }}
            />
            <Typography
              variant="body3"
              children={description}
              style={{
                textAlign: "center",
                marginBottom: "14px",
                color: "#83727D",
              }}
            />
          </ContentContainer>
        </Modal.Content>
        <Modal.Footer>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <LinkButton
              variant="secondary"
              children={cancelLabel}
              style={{ alignSelf: "center" }}
              onClick={onClose}
            />
            <StyledButton
              size="large"
              variant="primary"
              variantType={variant}
              style={{ alignSelf: "center" }}
              loading={isLoading}
              disabled={isLoading}
              onClick={onConfirm}
            >
              {confirmLabel}
              {confirmIcon ? (
                <Icons name={confirmIcon} fill="none" size={40} />
              ) : (
                <></>
              )}
            </StyledButton>
          </div>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}
