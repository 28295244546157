import gql from "graphql-tag"
import { request } from "../client"
import { ResignationCard } from "../../types"

const GET_ALL_RESIGNATION_CARD = gql`
  query GetAllResignationCard {
    getAllResignationCard {
      _id
      setupId
      name
      description
      status
      code
      color
      active
      position
      columnId
      companyId
      companyName
      flowId
      version
      statusHistory {
        status
        columnId
      }
      metadata
      resignationInterviewObservation
      asoDate
      asoResult
      lastWorkingDate
      resignationRequestDate
      serveNoticePeriod
      resignation
      resignationReason
      resignationType
      resignationInterviewFile {
        key
        path
        value
        type
      }
      asoFile {
        key
        path
        value
        type
      }
      resignationLetter {
        key
        path
        value
        type
      }
      emailLink {
        name
        columnId
        link
        expired
      }
      employeeId
    }
  }
`

export const getAllResignationCard = async () => {
  const { getAllResignationCard } = await request(GET_ALL_RESIGNATION_CARD)
  return getAllResignationCard as ResignationCard[]
}
