import { RoundedButton as Button } from "./styles"
import { Icons, IconTypes } from "@flash-tecnologia/hros-web-ui-v2"

export type IRoundedButton = {
  label: string
  icon?: IconTypes
  onClick: () => void
  style?: React.CSSProperties
}
export const RoundedButton = ({
  label,
  icon,
  onClick,
  style,
}: IRoundedButton) => {
  return (
    <Button onClick={onClick} style={style}>
      {label}
      {icon && (
        <Icons style={label ? { marginLeft: 5 } : {}} name={icon} fill="none" />
      )}
    </Button>
  )
}
