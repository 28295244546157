import { IconTypes, Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { StyledGroupButton, StyledSwitchButton } from "./styles"

type Option = {
  icon: IconTypes
  handleClick: () => void
  value: string
  ariaLabel?: string
}

type SwitchButtonProps = {
  selectedValue: string
  option1: Option
  option2: Option
}

export const SwitchButton = ({
  selectedValue,
  option1,
  option2,
}: SwitchButtonProps) => {
  return (
    <StyledGroupButton>
      <StyledSwitchButton
        aria-label={option1.ariaLabel}
        selected={selectedValue === option1.value}
        onClick={option1.handleClick}
      >
        <Icons name={option1.icon} fill="transparent" />
      </StyledSwitchButton>
      <StyledSwitchButton
        aria-label={option2.ariaLabel}
        selected={selectedValue === option2.value}
        onClick={option2.handleClick}
      >
        <Icons name={option2.icon} fill="transparent" />
      </StyledSwitchButton>
    </StyledGroupButton>
  )
}
