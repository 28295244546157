import { gql } from "graphql-request"
import { request } from "../client"
import { IFormConfig } from "../../types"

const GET_FORMCONFIG_BY_EXTENSION_OR_BASE = gql`
  query GetFormConfigByExtensionOrBase($formConfigId: ID!) {
    getFormConfigByExtensionOrBase(formConfigId: $formConfigId) {
      _id
      baseId
      companyId
      extensionId
      formGroups {
        _id
        category
        description
        enabled
        fields {
          component
          enabled
          fieldId
          fieldRule {
            fieldId
            required
          }
          fieldType
          options
          order
          placeholder
          sectionId
          type
        }
        order
        title
        fillInstruction
        type
      }
      name
      type
    }
  }
`

export const getFormConfigByExtensionOrBase = async (formConfigId: string) => {
  const { getFormConfigByExtensionOrBase } = await request(
    GET_FORMCONFIG_BY_EXTENSION_OR_BASE,
    { formConfigId },
  )
  return getFormConfigByExtensionOrBase as IFormConfig
}
