import {
  Checkbox,
  TextField,
  TextFieldProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { CheckboxContainer } from "./styles"

type InputCheckbox = {
  id?: string
  name?: string
  value: boolean
  onClick: (checked: boolean) => void
  onBlur?: (e: any) => void
  disabled?: boolean
  text: string
}

type TextFieldCheckboxProps = {
  checkbox: InputCheckbox
} & TextFieldProps

export const TextFieldCheckbox = ({
  checkbox,
  children,
  value,
  ...rest
}: TextFieldCheckboxProps) => {
  const renderCheckbox = () => {
    return (
      <CheckboxContainer>
        <Checkbox
          id={checkbox.id}
          name={checkbox.name}
          onChange={(_, checked) => checkbox.onClick(checked)}
          onBlur={checkbox.onBlur}
          value={checkbox.value}
          checked={checkbox.value}
          disabled={checkbox.disabled || false}
        />
        <Typography variant="body3">{checkbox.text}</Typography>
      </CheckboxContainer>
    )
  }

  return (
    <TextField
      {...rest}
      value={checkbox.value ? "" : value}
      InputProps={{
        endAdornment: renderCheckbox(),
      }}
      disabled={checkbox.value}
    >
      {children}
    </TextField>
  )
}
