import { LinkButton, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  StyledModal,
  ContentContainer,
  Footer,
  IconContainer,
  StyledButton,
} from "./styled"

interface DraftSaveModalProps {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onSubmit: () => void
}

export const DraftSaveModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: DraftSaveModalProps) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer>
          <LinkButton
            variant="secondary"
            children="Voltar"
            style={{ alignSelf: "center" }}
            onClick={onClose}
          />
          <StyledButton
            size="large"
            variant="primary"
            variantType="error"
            style={{ alignSelf: "center" }}
            onClick={onSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            Sair e salvar rascunho
            <Icons name="IconArrowRight" fill="none" size={40} />
          </StyledButton>
        </Footer>
      }
    >
      <ContentContainer>
        <IconContainer>
          <Icons
            name="IconAlertCircle"
            fill="none"
            size={40}
            style={{ color: "#FE2B8F" }}
          />
        </IconContainer>

        <Typography
          variant="body3"
          children="Atenção!"
          style={{ textAlign: "center", color: "#FE2B8F", fontWeight: 700 }}
        />
        <Typography
          variant="headline6"
          children="Deseja sair e salvar o progresso da criação de contrato?"
          style={{
            maxWidth: "421px",
            textAlign: "center",
            marginBottom: "16px",
          }}
        />
        <Typography
          variant="body3"
          children="O conteúdo será salvo como rascunho e você poderá editar mais tarde."
          style={{
            textAlign: "center",
            marginBottom: "14px",
            color: "#83727D",
          }}
        />
      </ContentContainer>
    </StyledModal>
  )
}
