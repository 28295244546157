import { HiringTemplateField } from "../types"

export const convertTagsToText = (input: string) => {
  const domParser = new DOMParser()

  const content = domParser.parseFromString(input, "text/html")

  const tags: any = content.getElementsByClassName("ql-tag")

  let result = input

  for (const tag of tags) {
    const id = tag.getAttribute("data-id")

    if (!id) continue

    result = result.replace(tag.outerHTML, `{{${id}}}`)
  }

  return result
}

export const convertTextToTags = (
  input: string,
  fields: HiringTemplateField["fields"],
) => {
  let output = input
  let match

  const tagsRegex =
    /(?<tagChar>[{]{2})(?<id>[A-Za-zÀ-ÿ_\s]+)(?<endChar>[}]{2})/g

  while ((match = tagsRegex.exec(output))) {
    const { id } = match.groups

    const field = fields.find((field) => field.id === id)

    if (field) output = output.replace(match[0], renderTag(field))
  }

  return output
}

const renderTag = (item: HiringTemplateField["fields"][0]) => {
  const { value, id } = item
  // eslint-disable-next-line max-len
  return `<span class="ql-tag" contenteditable="false" data-denotation-char="{{" data-id="${id}" data-value="${value}">\uFEFF<span contenteditable="false"><span class="ql-mention-denotation-char" contenteditable="false">{{</span>${id}<span class="ql-mention-denotation-char" contenteditable="false">}}</span><span class="ql-tag-close" contenteditable="false"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="12" height="12" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" fill="currentColor" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M18 6l-12 12"></path><path d="M6 6l12 12"></path></svg></span></span>\uFEFF</span>`
}
