import {
  IconTypes,
  LinkButton,
  Icons,
  Loader,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  StyledModal,
  ContentContainer,
  Footer,
  IconContainer,
  StyledIcon,
  StyledTitle,
  StyledText,
  StyledSubtitle,
  StyledButton,
} from "./styles"

type Action = {
  text: string
  icon?: IconTypes
  onClick: () => Promise<void> | void
  isLoading?: boolean
}

type SecondaryAction = Omit<Action, "icon" | "isLoading">

interface ModalProps {
  variant?: "primary" | "warning"
  isOpen: boolean
  onClose: () => void
  confirmButton: Action
  cancelButton?: SecondaryAction
  title: string
  description: string | React.ReactNode
}

export const ConfirmationModal = ({
  variant = "warning",
  isOpen,
  onClose,
  confirmButton,
  cancelButton = {
    text: "Cancelar",
    onClick: () => onClose(),
  },
  title,
  description,
}: ModalProps) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer>
          <LinkButton
            variant={variant === "warning" ? "secondary" : "primary"}
            children={cancelButton.text}
            style={{ alignSelf: "center" }}
            onClick={cancelButton.onClick}
          />
          <StyledButton
            size="large"
            variant="primary"
            customVariant={variant}
            style={{ alignSelf: "center" }}
            onClick={async () => {
              await confirmButton.onClick()
              onClose()
            }}
            disabled={confirmButton.isLoading}
          >
            {confirmButton.isLoading ? (
              <Loader size="small" variant="secondary" />
            ) : (
              <>
                {confirmButton.text}
                {confirmButton.icon && (
                  <Icons name={confirmButton.icon} fill="none" size={40} />
                )}
              </>
            )}
          </StyledButton>
        </Footer>
      }
    >
      <ContentContainer>
        <IconContainer customVariant={variant}>
          <StyledIcon
            name="IconAlertCircle"
            fill="none"
            size={40}
            customVariant={variant}
          />
        </IconContainer>

        <StyledSubtitle
          variant="body3"
          children="Atenção!"
          style={{ textAlign: "center", marginBottom: "10px" }}
          customVariant={variant}
        />

        <StyledTitle
          variant="headline6"
          children={title}
          style={{
            maxWidth: "421px",
            textAlign: "center",
            marginBottom: "16px",
          }}
        />
        <StyledText
          variant="body3"
          children={description}
          style={{ textAlign: "center", marginBottom: "14px" }}
        />
      </ContentContainer>
    </StyledModal>
  )
}
