import styled from "styled-components"

import { Accordion } from "@flash-tecnologia/hros-web-ui-v2"

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
    border-radius: 12px;
    box-shadow: none;

    &:before {
      background: none;
    }
  }

  & .MuiAccordionSummary-root {
    padding: 16px;

    .MuiAccordionSummary-expandIconWrapper button {
      width: 32px;
      height: 32px;
    }
  }

  & div.MuiAccordionDetails-root {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

export { FieldsContainer, TextsContainer, StyledAccordion }
