import gql from "graphql-tag"
import { Contract, Signature } from "../../types"
import { request } from "../client"

export const UPDATE_CONTRACT = gql`
  mutation UpdateContract($params: UpdateContract) {
    updateContract(params: $params) {
      _id
      companyId
      contractDuration
      flowCardId
      status
      template {
        key
        path
        value
        type
      }
      signature {
        employeeId
        name
        type
        email
        token
        phone
        signedAt
        signatureFile
        status
        ip
        confirmationInfo
      }
      contractOutput {
        key
        path
        value
        type
        version
      }
      lastContractOutput {
        key
        path
        value
        type
        version
      }
    }
  }
`
type Variables = {
  params: {
    contractId: string
    status?: string
    contractDuration?: string
    template?: any
    signature?: Signature[]
    contractOutput?: any
  }
}

export async function updateContract(variables: Variables): Promise<Contract> {
  const { updateContract } = await request(UPDATE_CONTRACT, variables)
  return updateContract
}
