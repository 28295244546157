import styled from "styled-components"

const StyledFooterBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral.light.pure};

  padding: 16px 24px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export { StyledFooterBar, StyledButtonContainer }
