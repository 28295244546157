import {
  PageContainer,
  PageHeader,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2"

export const HeaderSkeleton = () => {
  return (
    <PageHeader>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Skeleton variant="rectangular" width="135px" height="38px" />
        <Skeleton
          variant="rectangular"
          width="276px"
          height="55px"
          style={{ marginLeft: "20px" }}
        />
      </div>
    </PageHeader>
  )
}

export const ContentSkeleton = () => {
  return (
    <PageContainer>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        style={{
          paddingTop: "10px",
          borderRadius: "5px",
        }}
      />
    </PageContainer>
  )
}
