import gql from "graphql-tag"
import { request } from "../client"
import { ChecklistCard } from "../../types"

const HIRING_TOGGLE_CHECKLIST_ITEM = gql`
  mutation HiringToggleChecklistItem($params: ToggleChecklistInput) {
    hiringToggleChecklistItem(params: $params) {
      checklists {
        items {
          label
          checked
        }
        columnId
      }
    }
  }
`

export const checklistToggleHiring = async (params: {
  columnId: string
  flowCardId: string
  item: string
  version: number
}): Promise<ChecklistCard[]> => {
  const { hiringToggleChecklistItem } = await request(
    HIRING_TOGGLE_CHECKLIST_ITEM,
    { params },
  )
  return hiringToggleChecklistItem.checklists as ChecklistCard[]
}
