import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import {
  StyledModal,
  ContentContainer,
  Footer,
  IconContainer,
  StyledIcon,
  StyledTitle,
  StyledText,
} from "./styled"

interface ModalProps {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmationSignatureModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading = false,
}: ModalProps) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer>
          <LinkButton
            variant="secondary"
            children="Voltar"
            style={{ alignSelf: "center" }}
            onClick={onClose}
          />
          <Button
            size="large"
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={onSubmit}
            loading={isLoading}
          >
            Prosseguir e enviar
            <StyledIcon name="IconArrowRight" fill="none" size={40} />
          </Button>
        </Footer>
      }
    >
      <ContentContainer>
        <IconContainer>
          <StyledIcon
            setColor="primary"
            name="IconAlertCircle"
            fill="none"
            size={40}
          />
        </IconContainer>

        <StyledTitle
          variant="body3"
          children="Atenção!"
          setColor="primary"
          style={{ textAlign: "center" }}
        />
        <StyledTitle
          variant="headline6"
          children="Tem certeza que deseja enviar o contrato?"
          setColor="neutral20"
          style={{
            maxWidth: "421px",
            textAlign: "center",
            marginBottom: "16px",
          }}
        />
        <StyledText
          variant="body3"
          children="Verifique se todas as informações foram 
          preenchidas corretamente."
          setColor="neutral50"
          style={{ textAlign: "center", marginBottom: "14px" }}
        />
      </ContentContainer>
    </StyledModal>
  )
}
