import React from "react"
import benefitsAdvertisingImage from "../../../../assets/benefits_advertising.png"
import workshiftAdvertisingImage from "../../../../assets/workshift_advertising.png"
import Advertising from "./Advertising"
import { HiringContent } from "../../../../components/screen/CardPage/types"

const BenefitsAdvertising: React.FC = () => (
  <Advertising
    name="workshift-advertising"
    title="Beneficios"
    description="Aproveite o cartão benefícios com + autonomia pro RH e + vantagens pro colaborador."
    imageSrc={benefitsAdvertisingImage}
    listItems={[
      "Aceito em mais de 4 milhões de estabelecimentos.",
      "Adequação ao PAT (Programa de Alimentação do Trabalhador).",
      "Cobertura nacional e internacional.",
      "Entrega rápida:  98% dos cartões recebidos em até 5 dias úteis.",
      "Integração com a Carteira do Google para usuários Android.",
    ]}
    link={{
      href: "https://flashapp.com.br/gestao-de-beneficios",
    }}
  />
)

const WorkshiftAdvertising: React.FC = () => (
  <Advertising
    name="workshift-advertising"
    title="Controle de jornada"
    description="Controlar, organizar e gerenciar a jornada de trabalho dos seus colaboradores nunca foi tão Flash!"
    imageSrc={workshiftAdvertisingImage}
    listItems={[
      "Registro de ponto via App, Web e Tablet com acompanhamento em tempo real da jornada de trabalho de todos os colaboradores.",
      "Redução em até 75% o tempo gasto com as atividades de ponto.",
      "Até 25% de redução em horas extras dos colaboradores.",
      "Cálculos automáticos e sistema regularizado conforme LGPD e Portaria 671 de 2021",
    ]}
    link={{
      href: "https://flashapp.com.br/gestao-de-pessoas/controle-de-jornada",
    }}
  />
)

const BenefitsAdvertisingByType: HiringContent = {
  clt: BenefitsAdvertising,
  pj: BenefitsAdvertising,
  internship: BenefitsAdvertising,
}

const WorkshiftAdvertisingByType: HiringContent = {
  clt: WorkshiftAdvertising,
  pj: WorkshiftAdvertising,
  internship: WorkshiftAdvertising,
}

export {
  BenefitsAdvertisingByType,
  WorkshiftAdvertisingByType,
  WorkshiftAdvertising,
}
