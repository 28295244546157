import React from "react"
import ConfigAccordion, {
  AccordionButton,
} from "../ConfigAccordion/ConfigAccordion"
import { useParams } from "react-router-dom"
import InfoList from "../../../../components/common/CandidateMenu/Information/InfoList"
import { Divider, Typography, dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import { AccordionChildrenContainer } from "./styles"
import { BodyContent } from "../PageContent/styles"
import { useTheme } from "styled-components"
import EmptySection from "../EmptySection"
import { FlowSubcategory, Workshift } from "../../../../types"
import { api } from "../../../../api"
import { useQuery } from "@tanstack/react-query"
import { HiringContentProps } from "../../../../components/screen/CardPage/types"
import { globalContext } from "../../../../context"
import { minutesToHours } from "../../../../utils"

const WorkshiftInformation: React.FC<{
  workshift?: Workshift
}> = ({ workshift }) => {
  const { colors } = useTheme()

  return (
    <AccordionChildrenContainer>
      <BodyContent>
        <Typography variant="headline8" style={{ marginBottom: 20 }}>
          Informações básicas
        </Typography>
        <InfoList
          data={[
            {
              text: "Nome",
              value: workshift?.name ?? "Não cadastrado",
              hideCopyIcon: true,
            },
            {
              text: "Descrição",
              value: workshift?.description ?? "Não cadastrado",
              hideCopyIcon: true,
            },
            {
              text: "Escala padrão",
              value: workshift?.isDefault ? "Sim" : "Não",
              hideCopyIcon: true,
            },
          ]}
        />
      </BodyContent>
      <BodyContent>
        <Typography variant="headline8" style={{ marginBottom: 20 }}>
          Dados da Escala
        </Typography>
        <Typography
          variant="headline9"
          variantColor={colors.secondary50}
          style={{
            marginBottom: 20,
          }}
        >
          Jornada de trabalho
        </Typography>
        <InfoList
          data={[
            {
              text: "Tipo de escala",
              value: workshift?.type ?? "Não cadastrado",
              hideCopyIcon: true,
            },
            {
              text: "Horário de entrada",
              value: workshift?.initialHour
                ? dayjs(workshift.initialHour).format("HH:mm")
                : "Não cadastrado",
              hideCopyIcon: true,
            },
            {
              text: "Horário de saída",
              value: workshift?.finalHour
                ? dayjs(workshift.finalHour).format("HH:mm")
                : "Não cadastrado",
              hideCopyIcon: true,
            },
            {
              text: "Intervalo para refeição",
              value: workshift?.interval ? "Sim" : "Não",
              hideCopyIcon: true,
            },
            ...(workshift?.intervalStart
              ? [
                {
                  text: "Horário de início do intervalo",
                  value: workshift.intervalStart,
                  hideCopyIcon: true,
                },
              ]
              : []),
            ...(workshift?.intervalDuration
              ? [
                {
                  text: "Duração do intervalo",
                  value: minutesToHours(parseInt(workshift.intervalDuration)),
                  hideCopyIcon: true,
                },
              ]
              : []),
            {
              text: "Habilitar intervalo pré-assinado",
              value: workshift?.preSignedInterval ? "Sim" : "Não",
              hideCopyIcon: true,
            },
          ]}
        />
        <Divider orientation="horizontal" />
        <Typography
          variant="headline9"
          variantColor={colors.secondary50}
          style={{ margin: "20px 0" }}
        >
          Feriados
        </Typography>
        <InfoList
          data={[
            {
              text: "Alterar escala em feriados",
              value: workshift?.hollydays ?? "Não cadastrado",
              hideCopyIcon: true,
            },
          ]}
        />
      </BodyContent>
    </AccordionChildrenContainer>
  )
}

const WorkshiftAccordion: React.FC<{
  status: "completed" | "pending"
  title: string
  description: string
  children: React.ReactNode
  loading?: boolean
}> = ({ status, title, description, children, loading }) => {
  const { id: cardId = "" } = useParams()
  const { companyPermissions } = React.useContext(globalContext)

  const [hasWorkshiftPermission, hasWorkshiftPagePermission] =
    React.useMemo(() => {
      return (companyPermissions || []).reduce(
        (acc, permission) => {
          const [workshift, workshiftPage] = acc
          if (permission === "*") {
            return [true, true]
          }
          const newWorkshift =
            workshift ||
            permission === "people_time_and_attendance_view_preferences"
          const newWorkshiftPage =
            workshiftPage ||
            permission === "people_time_and_attendance_manage_timesheets"
          return [newWorkshift, newWorkshiftPage]
        },
        [false, false],
      )
    }, [companyPermissions])

  return (
    <ConfigAccordion
      status={status}
      title={title}
      description={description}
      loading={loading}
      disabled={!hasWorkshiftPermission}
      button={
        status === "completed" ? (
          <AccordionButton
            href="/time-and-attendance/preferences"
            label="Ir para configurações de escala"
            variant={"secondary"}
            disabled={!hasWorkshiftPagePermission}
          />
        ) : (
          <AccordionButton
            label="Ir para configuração de escala de trabalho"
            href={`/flows/hiring/initial-config/workshift/${cardId}`}
            variant={"primary"}
            disabled={!hasWorkshiftPermission}
          />
        )
      }
    >
      {children}
    </ConfigAccordion>
  )
}

interface WorkshiftConfigProps extends HiringContentProps { }

const WorkshiftConfig: React.FC<WorkshiftConfigProps> = ({ card }) => {
  const { data, isInitialLoading: isLoading } = useQuery(
    ["candidate.workshift", card?.candidate.workshiftId],
    () => api.query.workshift.getWorkshiftById(card?.candidate.workshiftId),
    {
      enabled: !!card?.candidate.workshiftId,
      refetchOnWindowFocus: false,
    },
  )

  return (
    <WorkshiftAccordion
      status={data?.id ? "completed" : "pending"}
      title="Escala de trabalho"
      description="Configure a escala da nova pessoa"
      loading={isLoading}
    >
      {data ? (
        <WorkshiftInformation workshift={data} />
      ) : (
        <EmptySection
          title={"Configure a escala da nova pessoa"}
          description="As informações das configurações selecionadas aparecerão aqui."
        />
      )}
    </WorkshiftAccordion>
  )
}

export const WorkshiftConfigByType: Record<
  FlowSubcategory,
  typeof WorkshiftConfig
> = {
  clt: WorkshiftConfig,
  pj: WorkshiftConfig,
  internship: WorkshiftConfig,
}
