import gql from "graphql-tag"
import { request } from "../client"
import { Flow } from "../../types"

const GET_ONLY_FLOW_BY_ID = gql`
  query GetOnlyFlowById($flowId: ID!) {
    getOnlyFlowById(flowId: $flowId) {
      _id
      name
      description
      type
      category
      subcategory
      active
      companyId
      setupId
      columns {
        _id
        name
        description
        locked
        editable
        color
        position
        hidden
        tags
      }
    }
  }
`
export const getOnlyFlowById = async ({ flowId }: { flowId: string }) => {
  const { getOnlyFlowById } = await request(GET_ONLY_FLOW_BY_ID, { flowId })
  return getOnlyFlowById as Flow
}
