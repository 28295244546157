import { InputAdornment, Popper } from "@mui/material"
import styled from "styled-components"
import { TextField } from "@flash-tecnologia/hros-web-ui-v2"

export const SelectFieldOption = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`

export const SelectFieldOptionIcon = styled.div`
  margin-right: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: min-content;
  fill: ${({ theme }) => theme.colors.secondary[40]};
`

export const SelectFieldOptionCaption = styled.div`
  color: ${({ theme }) => theme.colors.neutral[60]};

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`

export const SearchFieldIcon = styled(InputAdornment)`
  position: relative;
  top: 30px;
  left: 20px;
  fill: transparent;
  z-index: 1;
  .iconSearch {
    stroke: ${({ theme }) => theme.colors.neutral[50]};
  }
`

export const StyledPopper = styled(Popper)`
  z-index: 9999;
  .MuiAutocomplete-listbox {
    margin: 6px 0;
    overflow: hidden;

    .MuiAutocomplete-option {
      border-radius: 8px;
      margin: 6px 12px;
      padding: 16px;
      text-align: left;

      &[aria-selected="true"] {
        color: ${({ theme }) => theme.colors.secondary[40]};
        font-weight: 700;

        .select-field-option {
          z-index: 9999;
          color: ${({ theme }) => theme.colors.secondary[40]};

          .select-field-option-icon {
            fill: ${({ theme }) => theme.colors.secondary[40]};
          }

          .select-field-option-caption {
            color: ${({ theme }) => theme.colors.neutral[60]};
          }
        }
      }
    }
  }
`

export const StyledTextField = styled(TextField)``

export const Wrapper = styled.div`
  .MuiPaper-root {
    max-height: 150px;
  }

  .Mui-focused {
    .iconSearch {
      stroke: var(--color-secondary-light5);
      stroke: ${({ theme }) => theme.colors.secondary[70]};
    }
  }

  .Mui-disabled {
    .iconSearch {
      stroke: ${({ theme }) => theme.colors.neutral[80]};
    }
  }

  .Mui-error {
    .iconSearch {
      stroke: ${({ theme }) => theme.colors.feedback.error[50]};
    }
  }
`
