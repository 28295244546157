import { TextField } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const FormContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs2};
`

export const FormLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.s};
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  &.full-width {
    grid-template-columns: 1fr;
  }
  &.custom-width {
    grid-template-columns: 1fr 3fr;
  }
`

export const StyledTextField = styled(TextField)`
  width: 100%;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`
