import {
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from "react"

import { useFormik } from "formik"
import * as yup from "yup"

import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import _ from "lodash"

import { HiringTemplateField, Flow } from "../../../../../../../types"

import {
  convertTagsToText,
  convertTextToTags,
} from "../../../../../../../utils/editorTextConverter"

import { Container, StyledEditor, HelperText } from "./styles"

const validationSchema = yup.object({
  text: yup
    .string()
    .required("Favor preencher o contrato!")
    .test("empty text editor", "Favor preencher contrato!", (val) =>
      Boolean(val?.replace(/<(.|\n)*?>/g, "").trim().length),
    ),
})

interface FormTextEditorProps {
  hiringFields: HiringTemplateField[]
  hiringType: Flow["subcategory"]
  readOnly?: boolean
  data?: any
  loading?: boolean
  onTagRemoved?: (tag: string) => void
  onSubmit: (text: string) => void
}

export type FormTextEditorHandle = {
  getValue: () => { text: string }
  parseToText: (text: string) => string
  parseToTags: (text: string) => string
  handleSubmit: () => void
  resetForm: () => void
  validateForm: any
  setFieldErrors: any
}

export const FormTextEditor = forwardRef<
  FormTextEditorHandle,
  FormTextEditorProps
>(
  (
    {
      hiringFields,
      hiringType,
      readOnly,
      data,
      loading,
      onTagRemoved,
      onSubmit,
    },
    ref,
  ) => {
    const editorRef: any = useRef(null)

    const formik = useFormik({
      initialValues: {
        text: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => onSubmit(values.text),
    })

    const searchFields = useMemo(() => {
      return _.flatMap(hiringFields, "fields") as HiringTemplateField["fields"]
    }, [hiringType])

    useImperativeHandle(ref, () => ({
      getValue: () => formik.values,
      parseToText: handleParseToText,
      parseToTags: handleParseToTags,
      handleSubmit: () => formik.handleSubmit(),
      resetForm: () => formik.resetForm(),
      validateForm: () => formik.validateForm(),
      setFieldErrors: (errors) => {
        Object.keys(errors).map((key) => {
          formik.setFieldTouched(key, true)
        })

        formik.setErrors(errors)
      },
    }))

    const handleParseToText = useCallback(
      (text: string) => convertTagsToText(text),
      [],
    )

    const handleParseToTags = useCallback(
      (text: string) => convertTextToTags(text, searchFields),
      [searchFields],
    )

    const helperText = formik.touched.text && formik.errors.text

    useEffect(() => {
      if (data) {
        const parsed = handleParseToTags(data?.html || "")
        formik.setValues({
          text: parsed,
        })
      }
    }, [data])

    useEffect(() => {
      const handleListener = (event: any) => {
        const elementClicked = event?.value

        if (!elementClicked?.id) return

        onTagRemoved?.(elementClicked.id)
      }

      window.addEventListener("mention-clicked", handleListener, false)

      return () => window.removeEventListener("mention-clicked", handleListener)
    }, [onTagRemoved])

    return (
      <Container onSubmit={formik.handleSubmit}>
        {loading ? (
          <Skeleton variant="rounded" height={"300px"} width={"100%"} />
        ) : (
          <div>
            <StyledEditor
              key={hiringType}
              ref={editorRef}
              value={formik.values.text}
              onChange={(value) =>
                formik.handleChange({ target: { id: "text", value } })
              }
              error={formik.touched.text && Boolean(formik.errors.text)}
              placeholder="Comece a criar seu contrato aqui...."
              readOnly={readOnly}
              modules={{
                mention: {
                  allowedChars: /^[A-Za-zÀ-ÿ\s]*$/,
                  mentionDenotationChars: ["{{"],
                  blotName: "tag",
                  source: function (searchTerm: string, renderList: any) {
                    if (searchTerm.length === 0)
                      return renderList(searchFields, searchTerm)

                    const removeAccents = (inputString: string) => {
                      const accentRegex = /[\u0300-\u036f]/g
                      const normalizeString = inputString
                        .normalize("NFD")
                        .replace(accentRegex, "")
                      return normalizeString.normalize("NFC")
                    }

                    const matches = searchFields.filter(
                      (item) =>
                        removeAccents(item?.value || "")
                          ?.toLowerCase()
                          .search(removeAccents(searchTerm).toLowerCase()) !==
                        -1,
                    )

                    renderList(matches, searchTerm)
                  },
                },
              }}
            />
            {helperText && (
              <HelperText variant="caption" tag="p">
                <Icons
                  color="#FEA034"
                  name="IconInfoCircle"
                  size={18}
                  fill="transparent"
                  style={{ marginRight: "4px" }}
                />
                {helperText}
              </HelperText>
            )}
          </div>
        )}
      </Container>
    )
  },
)
