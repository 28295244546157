import { BigNumber } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StatusCardContainer = styled.div`
  padding: 4px 8px;
  display: flex;
  width: fit-content;
  align-self: flex-start;
  background-color: #f6f8f9;
  border-radius: 24px;
`

export const RowCell = styled.div<{
  noLimitWidth?: boolean
  maxWidth?: number
}>`
  width: max-content;
  word-wrap: break-word;
  min-width: 150px;
  max-width: ${({ noLimitWidth, maxWidth = 200 }) =>
    noLimitWidth ? "none" : `${maxWidth}px`};

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const GridBigNumberContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 20px;
`

export const StyledBigNumber = styled(BigNumber)`
  max-width: none;
  flex: none;

  & > :last-child {
    flex: 1;
    gap: 0;
  }
`
