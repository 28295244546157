import gql from "graphql-tag"
import { request } from "../client"
import { HiringCard } from "../../types"

export const HIRING_UPDATE_CARD_STATUS = gql`
  mutation HiringUpdateCardStatus($params: UpdateCardStatus) {
    hiringUpdateCardStatus(params: $params) {
      _id
      setupId
      name
      description
      status
      code
      color
      active
      position
      columnId
      employeeId
      candidateId
      companyId
      companyName
      flowId
      version
      statusHistory {
        status
        columnId
      }
      metadata
      followers
      checklists {
        columnId
        items {
          checked
          label
        }
      }
      hiringFlowId
      approvalStatus
      approvalCommentary
      updatedAt
      createdAt
      formConfigId
      formExtensionId
      emailLink {
        name
        columnId
        link
        expired
      }
      admissionalExam
      attachments
      roles
      comments {
        message
        authorEmployeeId
        authorName
        sentDate
        timeSince
      }
      candidate {
        _id
        active
        nationality
        jobId
        companyId
        employeeId
        registrationDate
        name
        socialName
        legalName
        documentNumber
        cnpj
        gender
        race
        maritalStatus
        schooling
        email
        phone
        isPCD
        disabilityType
        leader
        linkedinProfileUrl
        indicator
        jobUrl
        curriculum
        academicCareer
        professionalCareer
        extracurricularActivities
        documents
        proposalLetterInfo
        internship
        validations
      }
    }
  }
`

export const hiringUpdateCardStatus = async (params: {
  newStatus: string
  flowCardId: string
  version: number
}): Promise<HiringCard> => {
  const { hiringUpdateCardStatus } = await request(HIRING_UPDATE_CARD_STATUS, {
    params,
  })
  return hiringUpdateCardStatus
}
