import { useEffect, forwardRef, useImperativeHandle } from "react"
import { FormikErrors, useFormik } from "formik"
import * as yup from "yup"

import { TextField, Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

import { StyledFormTitle, FormFieldsContainer } from "./styled"

const validationSchema = yup.object({
  name: yup.string().required("Favor preencher o nome"),
  description: yup.string(),
})

export type FormBasicHandle = {
  handleSubmit: () => void
  resetForm: () => void
  getValue: () => { name: string; description: string }
  validateForm: () => Promise<
    FormikErrors<{ name: string; description: string }>
  >
  setFieldErrors: (
    fields: FormikErrors<{ name: string; description: string }>,
  ) => any
}

interface FormBasicProps {
  isEdit: boolean
  data?: {
    name?: string
    description?: string
  }
  loading?: boolean
  readOnly?: boolean
  onSubmit: ({
    name,
    description,
  }: {
    name: string
    description: string
  }) => void
}

export const FormBasicInfo = forwardRef<FormBasicHandle, FormBasicProps>(
  ({ isEdit, data, onSubmit, loading, readOnly }, ref) => {
    const formik = useFormik({
      initialValues: {
        name: "",
        description: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => onSubmit(values),
    })

    useImperativeHandle(ref, () => ({
      getValue: () => formik.values,
      handleSubmit: () => formik.handleSubmit(),
      validateForm: () => formik.validateForm(),
      setFieldErrors: (errors) => {
        Object.keys(errors).map((key) => {
          formik.setFieldTouched(key, true)
        })

        formik.setErrors(errors)
      },
      resetForm: () => formik.resetForm(),
    }))

    useEffect(() => {
      if (isEdit && data && Object.keys(data).length) {
        formik.setValues({
          name: data?.name || "",
          description: data?.description || "",
        })
      }
    }, [data])

    return (
      <form onSubmit={formik.handleSubmit}>
        <StyledFormTitle variant="headline8">Nome e descrição</StyledFormTitle>
        {loading ? (
          <FormFieldsContainer>
            <Skeleton variant="rounded" height={"56px"} />
            <Skeleton variant="rounded" height={"129px"} />
          </FormFieldsContainer>
        ) : (
          <FormFieldsContainer>
            <div>
              <TextField
                id={"name"}
                name={"name"}
                label="Nome do contrato"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                disabled={readOnly}
              />
            </div>
            <div>
              <TextField
                id={"description"}
                name={"description"}
                label="Descrição"
                multiline={true}
                rows={4}
                fullWidth
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                disabled={readOnly}
              />
            </div>
          </FormFieldsContainer>
        )}
      </form>
    )
  },
)
