import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

import {
  Container,
  HeaderContent,
  CompanyName,
  LogoContainer,
  AvatarCircle,
  CenterContainer,
  RightContainer,
} from "./styles"

import Logo from "../../../../../assets/logo_flash.svg"

const Desktop = ({ company }) => {
  return (
    <Container>
      <HeaderContent>
        <LogoContainer>
          <AvatarCircle hasLogo={false}>
            {company?.name?.charAt(0) ?? ""}
          </AvatarCircle>
          <CompanyName>{company?.name || company?.legalName || ""}</CompanyName>
        </LogoContainer>
        <CenterContainer></CenterContainer>
        <RightContainer>
          <Typography
            variant="caption"
            style={{ fontWeight: 400, color: "#9F939B" }}
          >
            Realizado por
          </Typography>
          <Logo style={{ marginLeft: "8px" }} />
        </RightContainer>
      </HeaderContent>
    </Container>
  )
}

export default Desktop
