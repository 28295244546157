import styled from "styled-components"

import FileIcon from "../../../assets/file_upload_icon.svg"

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 4px;
  border-bottom: 2px solid ${({ theme }) => theme?.colors?.primary};

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    margin-top: 20px;
  }
`

export const StyledFileIcon = styled(FileIcon)`
  color: ${({ theme }) => theme?.colors?.primary};
  margin-right: 7px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 10px;
  }
`

export const DropzoneArea = styled.div`
  div.dropzone-text-container {
    align-items: center !important;
  }
`
