import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"

type RowValueProps = {
  title: string
  value: string | string[]
  style?: React.CSSProperties
}

export const RowValue = ({ title, value, style }: RowValueProps) => {
  const theme = useTheme()

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 5, ...style }}>
      <Typography
        variant="caption"
        variantColor={theme.colors.neutral[50]}
        weight={700}
      >
        {title}
      </Typography>

      {Array.isArray(value) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          {value.map((value) => (
            <Tag
              variant="pink"
              style={{ whiteSpace: "nowrap", fontWeight: 600, fontSize: 14 }}
            >
              {value}
            </Tag>
          ))}
        </div>
      ) : (
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[40]}
          weight={600}
        >
          {value}
        </Typography>
      )}
    </div>
  )
}
