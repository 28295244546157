import { useParams } from "react-router-dom"
import { ResignationCardPage } from "../../../components"
import { DoneContent } from "./DoneContent"

const ResignationDone = () => {
  const { id: cardId = "" } = useParams()

  return (
    <ResignationCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Concluído",
          href: `/flows/resignation/done/${cardId}`,
        },
      ]}
      contents={{ default: DoneContent }}
    />
  )
}

export default ResignationDone
