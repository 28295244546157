import {
  IconButton,
  SelectField,
  Stepper,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  Details,
  Section,
  SectionSubTitleAccordion,
  SectionTitle,
  StyledAccordion,
  StyledRadio,
  Summary,
} from "../styles"
import { RadioGroup } from "@mui/material"

export type FormInstitution = {
  type?: "digital" | "physical"
  institutionMail: string
  institutionName: string
  institutionPhone: string
  institutionEmployeeId: string
}

type FormErro = {
  hasError: boolean
  errorText: string
}

export type FormInstitutionError = {
  institutionName: FormErro
  institutionMail: FormErro
}

export const InstitutionForm = ({
  formInstitution,
  setFormInstitution,
  error,
  setError,
}: {
  formInstitution: FormInstitution
  setFormInstitution: (form: FormInstitution) => void
  error: FormInstitutionError
  setError: (error: FormInstitutionError) => void
}) => {
  return (
    <StyledAccordion>
      <Summary
        expandIcon={
          <IconButton size="medium" variant="filled" icon="IconChevronDown" />
        }
      >
        <Section>
          <SectionTitle variant="headline8">Instituição de Ensino</SectionTitle>
          <SectionSubTitleAccordion variant="body4">
            Preencha as informações abaixo conforme os dados do destinatário.
          </SectionSubTitleAccordion>
        </Section>
      </Summary>
      <Details>
        <div
          style={{
            marginBottom: "16px",
            marginTop: "32px",
          }}
        >
          <SectionTitle
            variant="headline8"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
            }}
          >
            Solicitar assinatura
          </SectionTitle>
          <SectionSubTitleAccordion variant="body4">
            Solicite a assinatura digitalmente ou através do preenchimento do
            documento físico.
          </SectionSubTitleAccordion>
        </div>
        <div
          style={{
            marginBottom: "24px",
          }}
        >
          <RadioGroup
            value={formInstitution.type}
            name="radio-buttons-group"
            onChange={async (e: any) => {
              setFormInstitution({ ...formInstitution, type: e.target.value })
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StyledRadio value={"digital"} name={"radio"} />
                <Typography
                  variant="body3"
                  style={{
                    color: "#53464F",
                    fontWeight: 600,
                  }}
                >
                  Solicitar assinatura digitalmente
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StyledRadio value={"physical"} name={"radio"} />
                <Typography
                  variant="body3"
                  style={{
                    color: "#53464F",
                    fontWeight: 600,
                  }}
                >
                  Solicitar assinatura fisicamente
                </Typography>
              </div>
            </div>
          </RadioGroup>
        </div>

        {formInstitution.type === "physical" ? (
          <div style={{ marginBottom: "24px" }}>
            <Stepper
              className="Stepper"
              type={"dotted"}
              direction={"vertical"}
              steps={[
                "Envie os documentos a serem assinados",
                "Colete a assinatura da pessoa e da empresa",
                "Encaminhe o documento atualizado e assinado pelos outros destinatários à Instituição de Ensino",
              ]}
              activeStep={1}
              disableClick
            />
          </div>
        ) : (
          <></>
        )}

        {formInstitution.type === "digital" ? (
          <div>
            <div style={{ marginBottom: "24px" }}>
              <TextField
                id="institutionName"
                name="institutionName"
                label="Nome"
                fullWidth
                value={formInstitution?.institutionName}
                defaultValue={"Não cadastrado"}
                error={!!error?.institutionName?.hasError}
                helperText={error?.institutionName?.errorText}
                onChange={({ target: { value } }) => {
                  if (value && error.institutionName.hasError) {
                    setError({
                      ...error,
                      institutionName: {
                        hasError: false,
                        errorText: "",
                      },
                    })
                  }
                  setFormInstitution({
                    ...formInstitution,
                    institutionName: value,
                  })
                }}
              />
            </div>
            <div style={{ marginBottom: "24px" }}>
              <TextField
                id="institutionMail"
                name="institutionMail"
                label="E-mail"
                fullWidth
                value={formInstitution?.institutionMail}
                defaultValue={"Não cadastrado"}
                error={!!error?.institutionMail?.hasError}
                helperText={error?.institutionMail?.errorText}
                onChange={({ target: { value } }) => {
                  if (value && error.institutionMail.hasError) {
                    setError({
                      ...error,
                      institutionMail: {
                        hasError: false,
                        errorText: "",
                      },
                    })
                  }
                  setFormInstitution({
                    ...formInstitution,
                    institutionMail: value,
                  })
                }}
              />
            </div>
            <div style={{ marginBottom: "24px" }}>
              <TextField
                id="institutionPhone"
                name="institutionPhone"
                label="Celular"
                fullWidth
                value={formInstitution?.institutionPhone}
                defaultValue={"Não cadastrado"}
                onChange={({ target: { value } }) => {
                  setFormInstitution({
                    ...formInstitution,
                    institutionPhone: value,
                  })
                }}
              />
            </div>
            <div style={{ marginBottom: "24px" }}>
              <SelectField
                name="institutionToSign"
                label="Assinar como"
                fullWidth
                disabled
                value="institution"
                options={[
                  {
                    label: "Instituição de Ensino",
                    value: "institution",
                  },
                ]}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Details>
    </StyledAccordion>
  )
}
