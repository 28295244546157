import { useMemo, useRef, useCallback, useState } from "react"
import { useNavigate, useParams, Navigate } from "react-router-dom"
import { useMutation } from "@apollo/client"
import * as reactQuery from "@tanstack/react-query"

import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { Grid, Box } from "@mui/material"

import { SettingPageTemplate } from "../../../../components/common/SettingPageTemplate"
import { WithoutSaveModal } from "../../WithoutSaveModal"
import { DraftSaveModal } from "../../DraftSaveModal"

import {
  Header,
  Content,
  FormContract,
  FormContractHandle,
  Fields,
} from "./components"

import { hiringFields } from "../../../../mock/templateFields"
import { Flow } from "../../../../types"

import dispatchToast from "../../../../utils/dispatchToast"

import { MainContainer } from "./styles"

import { FINALIZE_MODEL_CONTRACT } from "../../../../api/mutations/finalize_model_contract"
import { EDIT_MODEL_CONTRACT } from "../../../../api/mutations/edit_model_contract"
import { GET_MODEL_CONTRACT_BY_COMPANY } from "../../../../api/queries/get_model_contract_by_company"
import { createSegmentTrack } from "../../../../utils"
import { api } from "../../../../api"

interface ContractContentProps {
  flow: Flow
  data: any
  isFetching: boolean
}

export const ContractContent = ({
  flow,
  data,
  isFetching,
}: ContractContentProps) => {
  const [withoutSaveModelOpen, setWithoutSaveModelOpen] = useState(false)
  const [draftSaveModelOpen, setDraftSaveModelOpen] = useState(false)

  const formRef = useRef<FormContractHandle>(null)

  const navigate = useNavigate()

  const { modelId, flowId = '' } = useParams<{
    modelId?: string
    flowId?: string
  }>()

  const isEdit = useMemo(() => !!modelId, [modelId])

  const hiringType = useMemo(() => flow?.subcategory || "pj", [flow])

  const hiringTypeFields = useMemo(() => hiringFields[hiringType], [hiringType])

  const [finalizeModelContract, { loading: finalizeModelLoading }] =
    useMutation(FINALIZE_MODEL_CONTRACT, {
      onCompleted() {
        dispatchToast({
          type: "success",
          content: "Modelo de contrato concluído com sucesso!",
        })

        navigate(`/flows/settings/${flowId}/contracts`)
      },
      onError: () => {
        const errorMessage =
          "Ocorreu um erro ao concluir o modelo de contrato, favor tentar novamente!"

        dispatchToast({
          type: "error",
          content: errorMessage,
        })
      },
      refetchQueries: [
        { query: GET_MODEL_CONTRACT_BY_COMPANY, variables: { flowId } },
      ],
    })

  const { mutateAsync: getFlowSubcategoryByFlowId } = reactQuery.useMutation(
    api.mutation.hiring.flow.getFlowSubcategoryByFlowId
  )

  const [editModelContract, { loading: editModelLoading }] = useMutation(
    EDIT_MODEL_CONTRACT,
    {
      onCompleted() {
        navigate(`/flows/settings/${flowId}/contracts`)
      },
      onError: (error) => {
        const graphqlErrors: any = error.graphQLErrors[0]
        const errorCode = graphqlErrors?.errorCode as string

        let errorMessage =
          "Ocorreu um erro ao editar o modelo de contrato, favor tentar novamente!"

        if (errorCode === "MODEL_CONTRACT_ALREADY_EXISTS_ERROR") {
          errorMessage = `Você já criou um modelo de contrato com esse nome!`
        }

        dispatchToast({
          type: "error",
          content: errorMessage,
        })
      },
      refetchQueries: [
        { query: GET_MODEL_CONTRACT_BY_COMPANY, variables: { flowId } },
      ],
    },
  )

  const handleSubmit = useCallback(
    (text: string) => {
      const textContract = formRef?.current?.parseToText(text)

      finalizeModelContract({
        variables: {
          params: {
            html: textContract,
            modelContractId: modelId,
          },
        },
      })
    },
    [isEdit, flowId],
  )

  if (!isEdit)
    return (
      <Navigate to={`/flows/settings/${flowId}/contracts/create/basic-info`} />
    )

  return (
    <SettingPageTemplate
      stepper={{
        steps: ["Informações básicas", "Contrato"],
        activeStep: 1,
      }}
      routes={[
        {
          label: "Configurações",
          route: `/flows/settings/${flowId}/contracts`,
        },
        {
          label: `${isEdit ? "Editar" : "Criar novo"} modelo de contrato`,
        },
      ]}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => setWithoutSaveModelOpen(true),
        },
        draftProps: {
          title: "Sair e salvar rascunho",
          disabled:
            (data?.status && data?.status !== "draft") ||
            editModelLoading ||
            finalizeModelLoading,
          callback: async () => {
            try {
              const flowInfo = await getFlowSubcategoryByFlowId({ flowId })
              createSegmentTrack({
                track: `company_hiring_${flowInfo.subcategory || "clt"
                  }_contractmodel_draft_clicked`,
              })
            } catch (err: any) {
              console.error(err)
            }

            const validatePromise = await formRef?.current?.validateForm()

            if (Object.keys(validatePromise).length) {
              const firstErrorElement = Object.keys(validatePromise)[0]

              formRef?.current?.setFieldErrors({
                [firstErrorElement]: validatePromise[firstErrorElement],
              })

              return
            }

            const value = formRef?.current?.getValue()
            const text = value?.text || ''

            const textContract = formRef?.current?.parseToText(text)

            editModelContract({
              variables: {
                params: {
                  html: textContract,
                  modelContractId: modelId,
                },
              },
            })
            setDraftSaveModelOpen(true)
            return
          },
        },
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" /> Voltar
            </>
          ),
          callback: () => {
            navigate(
              `/flows/settings/${flowId}/contracts/edit/basic-info/${modelId}`,
            )
          },
          loading: false,
          disabled: false,
        },
        confirmProps: {
          title: (
            <>
              Concluir
              <Icons name="IconCheck" fill="transparent" />
            </>
          ),
          callback: async () => {
            try {
              const flowInfo = await getFlowSubcategoryByFlowId({ flowId })
              createSegmentTrack({
                track: `company_hiring_${flowInfo.subcategory || "clt"
                  }_contractmodel_complete_clicked`,
              })
            } catch (err: any) {
              console.error(err)
            }

            formRef?.current?.handleSubmit()
          },

          loading: finalizeModelLoading,
          disabled: finalizeModelLoading,
        },
      }}
    >
      <MainContainer>
        <Header isEdit={isEdit} />
        <Grid container spacing={4}>
          <Grid item sm={12} md={12} lg={3}>
            <Content
              html={() => {
                const value = formRef?.current?.getValue()
                const text = value?.text || ''

                const textContract = formRef?.current?.parseToText(text)
                return textContract
              }}
              modelId={modelId}
            />
          </Grid>
          <Grid item sm={12} md={8} lg={6}>
            <FormContract
              ref={formRef}
              hiringFields={hiringTypeFields}
              hiringType={hiringType}
              onSubmit={handleSubmit}
              readOnly={false}
              loading={isFetching}
              data={data}
            />
          </Grid>
          <Box
            component={Grid}
            item
            sm={0}
            md={4}
            lg={3}
            display={{ sm: "none", md: "unset" }}
          >
            <Fields
              hiringFields={hiringTypeFields}
              onFieldClicked={({ field }) => formRef?.current?.insertTag(field)}
              disabled={false}
            />
          </Box>
        </Grid>
      </MainContainer>
      <WithoutSaveModal
        isOpen={withoutSaveModelOpen}
        onClose={() => setWithoutSaveModelOpen(false)}
        onSubmit={() => navigate(`/flows/settings/${flowId}/contracts`)}
      />
      <DraftSaveModal
        isOpen={draftSaveModelOpen}
        onClose={() => setDraftSaveModelOpen(false)}
        isLoading={editModelLoading}
        onSubmit={async () => {
          const value = formRef?.current?.getValue()
          const text = value?.text || ''

          const textContract = formRef?.current?.parseToText(text)

          if (data?.html !== textContract) {
            editModelContract({
              variables: {
                params: {
                  html: textContract,
                  modelContractId: modelId,
                },
              },
            })
            return
          }

          return navigate(`/flows/settings/${flowId}/contracts`)
        }}
      />
    </SettingPageTemplate>
  )
}
