import { useState } from "react"
import {
  RenderField,
  ActionButton,
  GenericModal,
  ModalPreviewInstruction,
} from "../../"
import { IFormConfig, IFormGroup } from "../../../types"
import { Icons, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"

export type ModalPreviewFieldsProps = {
  isOpen: boolean
  handleClose: () => void
  flowId: string
  formConfig: IFormConfig
  section: IFormGroup
}

export const ModalPreviewFields = ({
  isOpen,
  handleClose,
  flowId,
  formConfig,
  section,
}: ModalPreviewFieldsProps) => {
  const [isInstructionPreviewOpen, setIsInstructionPreviewOpen] =
    useState<boolean>(false)

  const { title, description, fields } = section

  const actionButtons: ActionButton[] = [
    { variant: "secondary", description: "Fechar", handleAction: handleClose },
  ]

  return (
    <GenericModal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Pré-visualização"
      description="É assim que os candidatos verão essa seção."
      actionButtons={actionButtons}
    >
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
          }}
        >
          <div>
            <Typography variant="headline8" variantColor="#53464F">
              {title}
            </Typography>
            <Typography variant="body4" variantColor="#6B5B66">
              {description}
            </Typography>
          </div>

          {section?.fillInstruction?.html && (
            <LinkButton
              variant="primary"
              style={{ fontSize: 14 }}
              onClick={() => setIsInstructionPreviewOpen(true)}
            >
              Como encontrar ou preencher esse documento?
              <Icons fill="transparent" name="IconZoomQuestion" size={14} />
            </LinkButton>
          )}

          {fields?.map((field, idx) => (
            <RenderField key={idx} field={field} style={{ marginBottom: 16 }} />
          ))}
        </div>

        {isInstructionPreviewOpen && (
          <ModalPreviewInstruction
            isOpen={isInstructionPreviewOpen}
            handleClose={() => setIsInstructionPreviewOpen(false)}
            flowId={flowId}
            formConfig={formConfig}
            section={section}
          />
        )}
      </>
    </GenericModal>
  )
}
