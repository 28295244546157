import { useMutation } from "@tanstack/react-query"
import styled from "styled-components"
import { api } from "../../../api"
import { useState } from "react"
import { MultiSelect, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"

type InputData = {
  value?: any
  handleChange: (value) => void
  error?: boolean
  helperText?: string
}

interface LeaderAndFollowersProps {
  leader: InputData
  followers: InputData
}

type Option = {
  label: string
  value: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  gap: 24px;
`

export const LeaderAndFollowers = ({
  leader,
  followers,
}: LeaderAndFollowersProps) => {
  const [leaderOptions, setLeaderOptions] = useState<Option[]>([])
  const [followersOptions, setFollowersOptions] = useState<Option[]>([])

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { mutateAsync: getPartialEmployee, isLoading: isSearching } =
    useMutation(api.query.employee.getEmployeeByPartialName)

  return (
    <Container>
      <div>
        <Typography variant="headline8" variantColor="#53464F">
          {t("addCandidateForm.leaderAndFollowers.title")}
        </Typography>
        <Typography variant="body4" variantColor="#83727D">
          {t("addCandidateForm.leaderAndFollowers.description")}
        </Typography>
      </div>

      <MultiSelect
        renderInput={() => null}
        value={leader.value}
        onChange={async (e) => {
          if (e.target.value?.length === 0) {
            setLeaderOptions([])
            return
          }

          if (e.target.value?.length < 3) return
          const foundEmployees = await getPartialEmployee(e.target.value)
          setLeaderOptions(
            foundEmployees.map((employee) => ({
              label: employee.name,
              value: employee.id,
            })),
          )
        }}
        onSelectChange={(_, values) => {
          if (values.length > 1) return

          const value = values[0]
            ? {
              _id: values[0]?.value,
              name: values[0]?.label,
            }
            : null

          leader?.handleChange(value)
        }}
        loading={isSearching}
        error={leader?.error}
        helperText={leader?.helperText}
        loadingText={t("addCandidateForm.leaderAndFollowers.loadingText")}
        label={t("addCandidateForm.leaderAndFollowers.leaderPlaceholder")}
        noOptionsText={t("addCandidateForm.leaderAndFollowers.noOptionText")}
        fullWidth={true}
        limitTags={1}
        options={leaderOptions}
      />
      <MultiSelect
        renderInput={() => null}
        value={followers.value}
        onChange={async (e) => {
          if (e.target.value?.length === 0) {
            setFollowersOptions([])
            return
          }

          if (e.target.value?.length < 3) return
          const foundEmployees = await getPartialEmployee(e.target.value)
          setFollowersOptions(
            foundEmployees.map((employee) => ({
              label: employee.name,
              value: employee.id,
            })),
          )
        }}
        onSelectChange={(_, values) => followers?.handleChange(values)}
        loading={isSearching}
        error={followers?.error}
        helperText={followers?.helperText}
        loadingText={t("addCandidateForm.leaderAndFollowers.loadingText")}
        label={t("addCandidateForm.leaderAndFollowers.followersPlaceholder")}
        noOptionsText={t("addCandidateForm.leaderAndFollowers.noOptionText")}
        fullWidth={true}
        options={followersOptions}
      />
    </Container>
  )
}
