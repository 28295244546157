import { Button, ButtonProps, Loader } from "@flash-tecnologia/hros-web-ui-v2"

type LoadingButtonProps = { isLoading: boolean } & ButtonProps

export const LoadingButton = ({
  children,
  isLoading,
  disabled,
  loading,
  ...props
}: LoadingButtonProps) => {
  return (
    <Button {...props} disabled={isLoading ? true : disabled}>
      {isLoading ? (
        <Loader size="extraSmall" variant="secondary" />
      ) : (
        <>{children}</>
      )}
    </Button>
  )
}
