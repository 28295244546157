import {
  Button,
  IconButton,
  Icons,
  TextArea,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { ReactNode, useState } from "react"
import {
  AccordionBody,
  AccordionContainer,
  AccordionHeader,
  ActionContainer,
  ActionWrapper,
} from "./styles"
import { StatusCard } from "../"

interface ValidationAccordionProps {
  title: string
  description?: string
  status?: "waiting" | "declined" | "approved" | "notTouched"
  children: ReactNode
  disabled?: boolean
  required?: boolean
  onAccept?: () => Promise<void>
  onRefuse?: (refuseReason: string) => Promise<void>
}

export const ValidationAccordion = ({
  title,
  description,
  status,
  children,
  disabled = false,
  required = true,
  onAccept,
  onRefuse,
}: ValidationAccordionProps) => {
  const [isDeclined, setIsDeclined] = useState<boolean>(false)
  const [refuseReason, setRefuseReason] = useState<string>("")
  const [refusedCommentaryError, setRefusedCommentaryError] = useState("")

  const handleRefuse = async () => {
    const parsedRefuseReason = refuseReason.trim()
    if (!isDeclined) {
      setIsDeclined((prev) => !prev)
      return
    }
    if (!parsedRefuseReason) {
      setRefusedCommentaryError("Campo obrigatório")
      return
    }
    await onRefuse?.(parsedRefuseReason)
  }

  const statusMock = {
    ["notTouched"]: {
      text: "Aguardando envio",
      backgroundColor: "#D4E8FF",
      textColor: "#00244C",
      iconColor: "#4DA0FE",
    },
    ["waiting"]: {
      text: "Aguardando validação",
      backgroundColor: "#FFECD6",
      textColor: "#4C2900",
      iconColor: "#FEA034",
    },
    ["declined"]: {
      text: "Reprovado",
      backgroundColor: "#FFECD6",
      textColor: "#4C2900",
      iconColor: "#FEA034",
    },
    ["approved"]: {
      text: "Aprovado",
      backgroundColor: "#D7F9F3",
      textColor: "#094338",
      iconColor: "#2ED9B8",
    },
  }

  const canValidate = status === "waiting" && onAccept && onRefuse

  return (
    <AccordionContainer>
      <AccordionHeader
        expandIcon={
          <IconButton
            size="medium"
            variant="filled"
            icon={"IconChevronDown"}
            disabled={disabled}
          />
        }
      >
        {status && (
          <StatusCard
            backgroundColor={statusMock[status]?.backgroundColor}
            textColor={statusMock[status]?.textColor}
            borderColor={statusMock[status]?.backgroundColor}
            description={statusMock[status]?.text}
            iconPosition="left"
            icon="IconCircle"
            iconColor={statusMock[status]?.iconColor}
            iconFilled={true}
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <Typography
            variant="headline8"
            style={{
              color: "#53464F",
              marginTop: 16,
            }}
          >
            {title}

            {required && (
              <span
                style={{
                  color: "#FEA034",
                }}
              >
                *
              </span>
            )}
          </Typography>
        </div>

        <Typography
          variant="body4"
          style={{
            color: "#83727D",
            marginTop: 4,
          }}
        >
          {description}
        </Typography>
      </AccordionHeader>

      {canValidate && (
        <ActionContainer>
          <ActionWrapper style={{ marginBottom: "20px" }}>
            <Button
              size="small"
              buttonType="secondary"
              variantType="error"
              onClick={handleRefuse}
            >
              <Icons name="IconX" fill="transparent" />
              Reprovar documento
            </Button>

            <Button
              size="small"
              buttonType="secondary"
              variantType="success"
              onClick={onAccept}
            >
              <Icons name="IconCheck" fill="transparent" />
              Aprovar documento
            </Button>
          </ActionWrapper>

          {isDeclined && (
            <TextArea
              placeholder={"Descreva o motivo da reprovação*"}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (refusedCommentaryError && e.target.value.length > 0) {
                  setRefusedCommentaryError("")
                }
                setRefuseReason(e.target.value)
              }}
              errorMessage={refusedCommentaryError}
            />
          )}
        </ActionContainer>
      )}

      <AccordionBody>{children}</AccordionBody>
    </AccordionContainer>
  )
}
