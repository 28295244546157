import gql from "graphql-tag"
import { request } from "../client"

const SEND_DOCUMENTS_REQUEST = gql`
  mutation Mutation($params: SendDocumentsRequestInput) {
    sendDocumentsRequest(params: $params)
  }
`

export const sendDocumentsRequest = async (params: {
  flowCardId: string
  columnId: string
  subcategory: "clt" | "internship" | "pj"
  candidateId: string
  formConfigId: string
  formExtensionId: string
  subject: string
  version: number
}) => {
  await request(SEND_DOCUMENTS_REQUEST, { params })
}
