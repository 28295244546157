import { AccordionDetails, AccordionSummary } from "@mui/material"
import {
  AccordionDescription,
  AccordionTitle,
  StyledAccordion,
  StyledContainerIcon,
} from "./styles"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

interface GenericAccordionProps {
  title: string
  description: string
  children: React.ReactElement
}
export const GenericAccordion = ({
  title,
  description,
  children,
}: GenericAccordionProps) => {
  return (
    <StyledAccordion
      style={{
        marginBottom: "24px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            marginRight: "20px",
          }}
        >
          <AccordionTitle variant="headline8">{title}</AccordionTitle>
          <AccordionDescription variant="body4">
            {description}
          </AccordionDescription>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px 44px 40px 44px" }}>
        {children}
      </AccordionDetails>
    </StyledAccordion>
  )
}
