import { gql } from "graphql-request"
import { request } from "../client"

const CHECKLIST_TEMPLATE_REMOVE = gql`
  mutation TemplateRemoveChecklist($params: RemoveTemplateChecklistInput) {
    templateRemoveChecklist(params: $params)
  }
`

export const checklistTemplateRemove = async (params: {
  checklistId: string
  item: string
}) => {
  await request(CHECKLIST_TEMPLATE_REMOVE, { params })
}
