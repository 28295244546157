import gql from "graphql-tag"
import { request } from "../client"
import {
  Contract,
  ContractOutput,
  DumbSignInfoParam,
  FlowSubcategory,
  S3File,
  SignInfoParam,
} from "../../types"

export const SEND_SIGN_REQUEST = gql`
  mutation SendSignRequest($params: SendSignRequestInput) {
    sendSignRequest(params: $params) {
      _id
      companyId
      contractDuration
      flowCardId
      template {
        key
        path
        value
        type
      }
      signature {
        employeeId
        candidateId
        name
        type
        email
        token
        phone
        signedAt
        signatureFile
        status
        ip
        confirmationInfo
      }
      status
      contractOutput {
        key
        path
        value
        type
        version
      }
      lastContractOutput {
        key
        path
        value
        type
        version
      }
    }
  }
`

export const sendSignRequest = async (params: {
  cardId: string
  companyId: string
  columnId: string
  subcategory?: FlowSubcategory
  category: "hiring" | "resignation"
  template: S3File[]
  contractOutput: ContractOutput[]
  modelContract?: { origin: string; custom: string }[]
  candidate: SignInfoParam
  companyEmployee: SignInfoParam
  witness?: DumbSignInfoParam[]
  institution?: DumbSignInfoParam
}) => {
  const { sendSignRequest } = await request(SEND_SIGN_REQUEST, { params })
  return sendSignRequest as Contract
}
