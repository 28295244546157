import styled from "styled-components"
import { Icons, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { DeprecatedModal } from "../../../../../../components"

const StyledSectionContainer = styled.div`
  padding: 40px 112px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      border-radius: 16px;
      width: 870px;
      overflow: hidden;
    }
    .modal-content-area {
      padding: 0px;
      overflow: auto;
    }
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 112px;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`

const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const StyledFieldDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[70]};
`

const SectionSelect = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
`

const StyledIconsHoverable = styled(Icons)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const StyledFlex = styled.div`
  display: flex;
`

const CloseContainer = styled.div`
  position: absolute;
  right: 24px;
  top: 12px;
`

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`

export {
  StyledSectionContainer,
  CloseContainer,
  StyledFlex,
  StyledFlexCenter,
  StyledModal,
  StyledButtonContainer,
  StyledTitle,
  StyledSubTitle,
  StyledDescription,
  StyledFieldDescription,
  SectionSelect,
  StyledTextField,
  StyledIconsHoverable,
  StyledSubtitleContainer,
}
