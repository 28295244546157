import { gql } from "graphql-request"
import { request } from "../client"
import { IFormConfigList } from "../../types"

const GET_FORMCONFIG_BY_COMPANY = gql`
  query GetFormConfigByCompany($companyId: String) {
    getFormConfigByCompany(companyId: $companyId) {
      extensionId
      name
      numberOfGroups
      _id
      default
    }
  }
`

export const getFormConfigByCompany = async (companyId: string) => {
  const { getFormConfigByCompany } = await request(GET_FORMCONFIG_BY_COMPANY, {
    companyId,
  })
  return getFormConfigByCompany as IFormConfigList[]
}
