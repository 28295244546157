import { FormContainer } from "./styles"
import { PhoneMask } from "../../../../../utils/masks"
import { useFormik } from "formik"
import { Recipient } from "../../../../../types"
import TextFieldInput from "./TextFieldInput"
import * as yup from "yup"

export const recipientValidationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  phone: yup.string().required("Campo obrigatório"),
  email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
})

type Parser = (value: string) => string
type handleChangeCallback = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  parser?: Parser,
  maxLenght?: number,
) => void
const phoneParser = (value: string) => value.replace(/\D+/g, "")

const RecipientContactForm: React.FC<{
  formik: ReturnType<typeof useFormik<Recipient>>
}> = ({ formik }) => {
  const handleChange: handleChangeCallback = (e, parser, maxLenght) => {
    const value = parser ? parser(e.target.value) : e.target.value
    if (parser) {
      e.target.value = value
    }
    if (maxLenght && value.length > maxLenght) return

    formik.handleChange(e)
  }

  return (
    <FormContainer>
      <TextFieldInput
        label="Nome *"
        placeholder="Nome *"
        name="name"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name?.toString()}
        autoComplete="name"
      />
      <TextFieldInput
        label="Telefone *"
        placeholder="Telefone *"
        name="phone"
        onChange={(e) => handleChange(e, phoneParser, 11)}
        onBlur={formik.handleBlur}
        value={PhoneMask(formik.values.phone)}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone?.toString()}
        autoComplete="tel"
      />
      <TextFieldInput
        label="E-mail *"
        placeholder="E-mail *"
        name="email"
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email?.toString()}
        autoComplete="email"
      />
    </FormContainer>
  )
}

export default RecipientContactForm
