import styled from "styled-components"
import { DownloadVariants } from "../DownloadPanel"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 32px 40px;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
`

export const IconContainer = styled.div<{
  variant: DownloadVariants
}>`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, variant }) =>
    variant === "default"
      ? theme.colors.secondary[95]
      : theme.colors.feedback.error[90]};
`
