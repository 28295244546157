import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`

export { Wrapper }
