import {
  Button,
  Icons,
  LinkButton,
  Loader,
} from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { StyledFooterBar } from "../../../../components/common/FooterBar/style"
import { ActionButton } from "../../../../components"

interface FooterBarProps {
  actionButtons: ActionButton[]
}

const FooterBar: React.FC<FooterBarProps> = ({ actionButtons }) => {
  const renderButton = (button: ActionButton, idx = 0) => {
    if (button.variant === "link") {
      return (
        <LinkButton
          key={idx}
          variant="primary"
          onClick={button.handleAction}
          disabled={button.disabled}
          style={{ marginTop: 15 }}
        >
          {button.description}
          {button.icon && <Icons name={button.icon} fill="transparent" />}
        </LinkButton>
      )
    }

    return (
      <Button
        key={idx}
        size="large"
        variant={button.variant as "primary" | "secondary"}
        disabled={button.disabled}
        onClick={button.handleAction}
      >
        {button.isLoading ? (
          <Loader size="extraSmall" variant="secondary" />
        ) : (
          <>
            {button.description}
            {button.icon && <Icons name={button.icon} fill="transparent" />}
          </>
        )}
      </Button>
    )
  }

  const renderFooter = React.useMemo(() => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent:
            actionButtons?.length === 1 ? "flex-end" : "space-between",
        }}
      >
        {actionButtons.map((button, idx) => renderButton(button, idx))}
      </div>
    )
  }, [actionButtons])

  return <StyledFooterBar>{renderFooter}</StyledFooterBar>
}

export default FooterBar
