import { Typography, TextField } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { Container, Wrapper } from "./styles"
import * as yup from "yup"
import { useFormik } from "formik"
import React from "react"
import { StepsEnum, massSignatureContext } from "../../../MassSignature"
import { useNavigate } from "react-router-dom"

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  documentNumber: yup
    .string()
    .length(14, "Número de CPF precisa conter 12 numeros"),
  email: yup
    .string()
    .email("Por favor preencha um email valido")
    .required("Favor digitar o seu email"),
})

export const MassSignatureConfirmation = () => {
  const [canProceed, setCanProceed] = React.useState<boolean>(false)
  const theme = useTheme()
  const navigate = useNavigate()

  const { setFooter, setCurrentStep, confirmationData, setConfirmationData } =
    React.useContext(massSignatureContext)

  const formik = useFormik({
    initialValues: {
      name: confirmationData?.name ?? "",
      documentNumber: confirmationData?.documentNumber ?? "",
      email: confirmationData?.email ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setConfirmationData(values)
      setCurrentStep(StepsEnum.signing)
    },
    validateOnBlur: true,
    validateOnChange: true,
  })

  React.useEffect(() => {
    setFooter({
      cancelButton: {
        text: "Sair sem salvar",
        onClick: () => navigate("/flows/hiring"),
      },
      secondaryButton: {
        text: "Voltar",
        icon: "IconArrowLeft",
        iconPosition: "left",
        onClick: () => setCurrentStep(StepsEnum.contract),
      },
      primaryButton: {
        text: "Continuar",
        icon: "IconArrowRight",
        iconPosition: "right",
        onClick: () => formik.handleSubmit(),
        disabled: !canProceed,
      },
    })
  }, [canProceed])

  React.useEffect(() => {
    if (
      formik.values?.name &&
      formik.values?.email &&
      formik.values?.documentNumber
    ) {
      const errorsCount = Object.keys(formik.errors)?.length
      if (errorsCount > 0) {
        if (canProceed) setCanProceed(false)
        return
      }

      setCanProceed(true)
    }
  }, [formik])

  React.useEffect(() => {
    async function validateIfDataIsValid() {
      const errors = await formik.validateForm(confirmationData)
      const errorsCount = Object.keys(errors)?.length
      if (errorsCount !== 0) return

      setCanProceed(true)
    }

    if (!canProceed) {
      validateIfDataIsValid()
    }
  }, [confirmationData])

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
          Dados pessoais
        </Typography>
        <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
          <span style={{ color: theme.colors.feedback.error[40] }}>*</span>{" "}
          campo obrigatório
        </Typography>
      </div>

      <Wrapper>
        <TextField
          type={"text"}
          label={"Nome Completo"}
          name={"name"}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name?.toString()}
          required={true}
          fullWidth={true}
        />

        <TextField
          type={"text"}
          label={"CPF"}
          name={"documentNumber"}
          imaskProps={{ mask: "000.000.000-00" }}
          value={formik.values.documentNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.documentNumber &&
            Boolean(formik.errors.documentNumber)
          }
          helperText={
            formik.touched.documentNumber &&
            formik.errors.documentNumber?.toString()
          }
          required={true}
          fullWidth={true}
        />

        <TextField
          type={"text"}
          label={"E-mail"}
          name={"email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email?.toString()}
          required={true}
          fullWidth={true}
        />
      </Wrapper>
    </Container>
  )
}
