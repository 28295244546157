import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

export const LeftColumnContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-right: 35px;
  gap: 10px;
`

export const RightColumnContainer = styled.div`
  width: 70%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`
