import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import styled, { css } from "styled-components"

export const AccordionContainer = styled(Accordion)`
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[90]};
  border-radius: ${(props) => props.theme.borders.radius.s};
  box-shadow: unset;
  margin: unset;
  width: 100%;

  &.Mui-disabled {
    background-color: unset;
  }
`

export const AccordionHeader = styled(AccordionSummary)`
  padding: ${(props) => props.theme.spacings.s};

  &.Mui-disabled {
    opacity: unset;
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: column;
    margin: 0;

    .iconButton-custom-theme-filled {
      align-self: unset;
    }
  }

  .Mui-expanded {
    margin: 0;
  }
`

export const AccordionBody = styled(AccordionDetails)`
  border-top: 1px solid ${(props) => props.theme.colors.neutral[90]};
  padding: ${(props) => props.theme.spacings.s};
`

export const ActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0px 20px;
`

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  button {
    width: 50%;
    overflow: overlay;
  }
`

export const StyledButton = styled.button<{ variant: "success" | "error" }>`
  font-size: 16px;
  font-weight: 700;
  color: #6b5b66;
  border-radius: 12px;
  padding: 8px 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  ${({ variant }) => {
    const variants = {
      ["success"]: {
        borderColor: "#21A38A",
        backgroundColor: "#D7F9F3",
        iconColor: "#21A38A",
      },
      ["error"]: {
        borderColor: "#C96C01",
        backgroundColor: "#FFECD6",
        iconColor: "#C96C01",
      },
    }

    return css`
      border: 1px solid ${variants[variant]?.borderColor};

      .icon-container {
        color: ${variants[variant]?.iconColor};
        width: 16px;
        margin-right: 3px;
      }

      &:hover,
      &:focus {
        background-color: ${variants[variant]?.backgroundColor};
      }

      &:active {
        background-color: ${variants[variant]?.borderColor};
        color: #4c2900;
      }
    `
  }}
`
