import { TextField } from "@flash-tecnologia/hros-web-ui-v2"
import { StyledTextField } from "./styles"

const TextFieldInput: React.FC<React.ComponentProps<typeof TextField>> = (
  props,
) => {
  return (
    <div>
      <StyledTextField {...props} />
    </div>
  )
}

export default TextFieldInput
