import { useParams } from "react-router-dom"

import { OnHoldContent } from "./OnHoldContent"
import { ResignationCardPage } from "../../../components"

const OnHoldPage = () => {
  const { id: cardId = "" } = useParams()

  return (
    <ResignationCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Desligamento", href: "/flows/resignation" },
        { name: "Em espera", href: `/flows/resignation/onHold/${cardId}` },
      ]}
      contents={{ default: OnHoldContent }}
    />
  )
}

export default OnHoldPage
