import { useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"

import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { StyledDescription, StyledSubtitle, PreviewButton } from "./styles"
import { PREVIEW_MODEL_CONTRACT } from "../../../../../../api/mutations/preview_model_contract"
import dispatchToast from "../../../../../../utils/dispatchToast"
import { request } from "../../../../../../api/client"

export const Content = ({ html, modelId }) => {
  const navigate = useNavigate()

  const { mutate: previewModelContract, isLoading } = useMutation(
    async ({ params }: { params: any }) => {
      await request(PREVIEW_MODEL_CONTRACT, { params })
    },
    {
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro na pre-visualização.",
        })
      },
    },
  )

  return (
    <>
      <StyledSubtitle variant="headline8">Conteúdo do contrato</StyledSubtitle>
      <StyledDescription variant="body3">
        Preencha o campo ao lado com o texto do contrato e insira campos
        personalizáveis. Considere campos personalizáveis informações pessoais
        que variam de pessoa para pessoa.
      </StyledDescription>
      <PreviewButton
        variant="secondary"
        size="large"
        onClick={() => {
          const value = html()

          previewModelContract(
            {
              params: {
                html: value,
                modelContractId: modelId,
              },
            },
            {
              onSuccess: () => {
                window.open(
                  `/flows/settings/previewContract?modelId=${modelId}`,
                  "_blank",
                )
              },
            },
          )
        }}
        loading={isLoading}
      >
        Pré-visualizar contrato
        <Icons name="IconFileSearch" fill="transparent" />
      </PreviewButton>
    </>
  )
}
