import styled from "styled-components"

import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
`

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
`

export const FieldContainer = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`

export const ToggleContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  gap: 20px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 8px;
`

export const ToggleFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const Divider = styled.div`
  border: 1px solid #ebe6e9;
  height: 1px;
  width: 100%;
  margin: 40px 0;
`
