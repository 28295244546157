import { useMemo } from "react"
import Mobile from "./Mobile"
import Desktop from "./Desktop"

import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"

const Header = () => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  const { selectedCompany } = useSelectedCompany()

  const HeaderRender = useMemo(
    () =>
      isMobile ? (
        <Mobile company={selectedCompany} />
      ) : (
        <Desktop company={selectedCompany} />
      ),
    [isMobile, selectedCompany],
  )

  return HeaderRender
}

export default Header
