import gql from "graphql-tag"
import { request } from "../client"
import { MassCandidateProcess } from "../../types"

const INTERRUPT_MASS_CANDIDATE_PROCESS = gql`
  mutation InterruptMassCandidateProccess(
    $params: InputInterruptMassCandidateProccess
  ) {
    interruptMassCandidateProccess(params: $params) {
      _id
      notificationEmployeeId
      companyId
      flowId
      completionPercentage
      validationPercentage
      lastReadPosition
      lastValidatedPosition
      fileKey
      active
      dismiss
      errorReport {
        line
        reason
      }
      fileType
      initialPosition
      validationProgress
    }
  }
`

export type InterruptMassCandidateProccessParams = {
  flowId: string
}

export const interruptMassCandidateProcess = async (
  params: InterruptMassCandidateProccessParams,
): Promise<MassCandidateProcess> => {
  const response = await request<{
    interruptMassCandidateProccess: MassCandidateProcess
  }>(INTERRUPT_MASS_CANDIDATE_PROCESS, {
    params,
  })
  return response.interruptMassCandidateProccess
}
