import {
  Typography,
  IconButton,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { useTheme } from "styled-components"
import {
  CheckboxWrapper,
  Container,
  ContentContainer,
  HeaderContainer,
} from "./styles"

type Action = {
  checked: boolean
  handleClick: (param?: any) => Promise<void> | void
}

export type AccordionActiveProps = {
  title: string
  description: string
  actions: {
    active: Action
    edit: Omit<Action, "checked">
  }
  children: React.ReactNode
}

export const AccordionActive = ({
  title,
  description,
  actions,
  children,
}: AccordionActiveProps) => {
  const theme = useTheme()

  return (
    <Container>
      <HeaderContainer>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
            weight={700}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body4"
            variantColor={theme.colors.neutral[50]}
            weight={400}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {description}
          </Typography>
        </div>

        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
          <CheckboxWrapper>
            <Toggle
              checked={actions.active.checked}
              onChange={(_e, checked) => actions.active.handleClick(checked)}
            />

            <Typography
              variant="body3"
              variantColor={theme.colors.neutral[30]}
              weight={600}
            >
              {actions.active.checked ? "Ativo" : "Inativo"}
            </Typography>
          </CheckboxWrapper>

          {actions.active.checked ? (
            <IconButton
              variant="line"
              size="medium"
              icon="IconPencil"
              onClick={actions.edit.handleClick}
            />
          ) : (
            <></>
          )}
        </div>
      </HeaderContainer>

      {actions.active.checked ? (
        <ContentContainer>{children}</ContentContainer>
      ) : (
        <></>
      )}
    </Container>
  )
}
