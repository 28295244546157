import gql from "graphql-tag"
import { request } from "../client"
import { ConfirmationData } from "../../types"

export const GET_CONFIRMATION_DATA = gql`
  query GetConfirmationData($params: ConfirmationDataInput) {
    getConfirmationData(params: $params) {
      formConfigId
      extensionId
      candidateFields
      documentFields
      dependents
      candidateId
    }
  }
`

export const getConfirmationData = async (params: {
  candidateId: string
  formConfigId: string
  extensionId: string
}): Promise<ConfirmationData> => {
  const { getConfirmationData } = await request(GET_CONFIRMATION_DATA, {
    params,
  })
  return getConfirmationData as ConfirmationData
}
