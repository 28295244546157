import { useCallback } from "react"
import { getColumnById } from "../../../mock/board"
import { CardContainer, CardSubContainer, StatusCardContainer } from "./styles"
import {
  Icons,
  Tooltip,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate } from "react-router-dom"
import { Card, FlowCategory } from "../../../types"
import { getColumnStatusOptions } from "../../../mock/status"
import { HiringLimitDateEnum, ILimitDate } from "bff/src/types/hiring"

const limitDateEnumMap = {
  [HiringLimitDateEnum.aso_Ctl]: "Exame admissional",
  [HiringLimitDateEnum.aso_Estag]: "Exame admissional",
  [HiringLimitDateEnum.proposalLetter_Clt]: "Carta proposta",
  [HiringLimitDateEnum.proposalLetter_Estag]: "Carta proposta",
  [HiringLimitDateEnum.proposalLetter_Pj]: "Carta proposta",
  [HiringLimitDateEnum.sendDocuments_Clt]: "Envio de documentos",
  [HiringLimitDateEnum.sendDocuments_Estag]: "Envio de documentos",
  [HiringLimitDateEnum.sendDocuments_Pj]: "Envio de documentos",
  [HiringLimitDateEnum.signContract_Clt]: "Assinatura de contrato",
  [HiringLimitDateEnum.signContract_Estag]: "Assinatura de contrato",
  [HiringLimitDateEnum.signContract_Pj]: "Assinatura de contrato",
}

export enum LimitDateStatusEnum {
  expired = "expired",
  toExpired = "toExpired",
  valid = "valid",
}

type StatusTypes =
  | LimitDateStatusEnum.valid
  | LimitDateStatusEnum.expired
  | LimitDateStatusEnum.toExpired
type LimitDateInfo = {
  status: StatusTypes
  text: string
}
function getLimitDateInfos(limitDates: ILimitDate<any>[]): LimitDateInfo[] {
  if (!limitDates?.length) return []
  const infos = limitDates.reduce<LimitDateInfo[]>((prev, curr) => {
    let status = LimitDateStatusEnum.valid
    if (dayjs(curr.date).isBefore(dayjs(), "day"))
      status = LimitDateStatusEnum.expired
    if (dayjs(curr.date).isSame(dayjs(), "day"))
      status = LimitDateStatusEnum.toExpired

    if (status === LimitDateStatusEnum.valid) return prev
    return [
      ...prev,
      {
        status,
        text: `O prazo para retorno de ${limitDateEnumMap[curr.step]} ${
          status === LimitDateStatusEnum.expired
            ? " está expirado."
            : " vai expirar no final do dia."
        }`,
      },
    ]
  }, [])
  return infos
}

const ExpirationStatus = ({
  type,
}: {
  type: Omit<StatusTypes, LimitDateStatusEnum.valid>
}) => {
  return (
    <span
      style={{
        width: 26,
        height: 26,
        borderRadius: "50%",
        backgroundColor:
          type === LimitDateStatusEnum.expired ? "#FFECD6" : "#EBE6E9",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icons
        fill="transparent"
        name={
          type === LimitDateStatusEnum.expired ? "IconFileOff" : "IconFileAlert"
        }
        style={{
          color: type === LimitDateStatusEnum.expired ? "#C96C01" : "#6B5B66",
          width: 18,
          height: 18,
        }}
      />
    </span>
  )
}

export const CardComponent = <CardType extends Card>({
  card,
  column,
  category,
  provided,
}: {
  card: CardType
  column: { [key: string]: any }
  category: FlowCategory
  provided: { [key: string]: any }
}) => {
  const navigate = useNavigate()

  const { name, description, limitDates } = card

  const findStatusOptions = getColumnStatusOptions(card.columnId)
  const status = findStatusOptions.status.find((s) => s.name === card.status)

  const isFinishedColumn = [
    "Admissão concluída",
    "Concluído",
    "Arquivado",
  ].includes(column.name)

  const limitDatesInfos = !isFinishedColumn ? getLimitDateInfos(limitDates) : []

  const handleCardClick = useCallback(() => {
    const columnInfo = getColumnById(column._id, category)
    if (!columnInfo) {
      console.error("Column not found")
      return
    }
    const path = columnInfo?.route + card._id
    navigate(path)
  }, [])

  return (
    <CardContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={() => handleCardClick()}
    >
      <CardSubContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {status ? (
            <>
              <StatusCardContainer
                style={{ backgroundColor: status.colors?.background }}
              >
                <Typography
                  variant="caption"
                  style={{
                    fontWeight: 600,
                    color: status.colors?.text,
                  }}
                >
                  {status.name}
                </Typography>
              </StatusCardContainer>
              {limitDatesInfos?.length > 0 ? (
                <Tooltip
                  title={
                    <>
                      {limitDatesInfos.map((limitDate, idx) => (
                        <p key={idx} aria-label={limitDate.text}>
                          {limitDate.text}
                        </p>
                      ))}
                    </>
                  }
                >
                  <span>
                    <ExpirationStatus
                      type={
                        limitDatesInfos.some(
                          (limitDate) => limitDate.status === "expired",
                        )
                          ? "expired"
                          : "toExpire"
                      }
                    />
                  </span>
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "19px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body3"
              style={{
                fontWeight: 600,
              }}
            >
              {name}
            </Typography>

            {!status ? (
              limitDatesInfos?.length > 0 ? (
                <Tooltip
                  title={
                    <>
                      {limitDatesInfos.map((limitDate, idx) => (
                        <p key={idx} aria-label={limitDate.text}>
                          {limitDate.text}
                        </p>
                      ))}
                    </>
                  }
                >
                  <span>
                    <ExpirationStatus
                      type={
                        limitDatesInfos.some(
                          (limitDate) => limitDate.status === "expired",
                        )
                          ? "expired"
                          : "toExpire"
                      }
                    />
                  </span>
                </Tooltip>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
          <Typography variant="body4" style={{ fontWeight: 400 }}>
            {description}
          </Typography>
        </div>
      </CardSubContainer>
    </CardContainer>
  )
}
