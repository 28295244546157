import { useMutation } from "@tanstack/react-query"
import { api } from "../../../api"
import { Flow, HiringCard } from "../../../types"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import EmptySvg from "../../../assets/empty_validation.svg"

import {
  Button,
  Icons,
  LinkButton,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const EmptyState = ({
  card,
  flow,
}: {
  card: HiringCard
  flow: Flow
}) => {
  const navigate = useNavigate()
  const { mutateAsync: moveColumn, isLoading } = useMutation(
    api.mutation.hiring.card.moveColumn,
  )

  const handleNextColumn = async () => {
    if (!flow.columns) throw new Error("Unable to find next column")
    const actualColumnIndex = flow.columns.findIndex(
      (column) => column._id === card.columnId,
    )
    if (actualColumnIndex === -1) throw new Error("Unable to find next column")

    const nextColumn = flow.columns[actualColumnIndex + 1]
    await moveColumn({
      flowCardId: card._id,
      newColumnId: nextColumn._id,
      newPosition: 0,
      version: card.version,
    })
    navigate("/flows/hiring")
  }

  const handlePreviousColumn = async () => {
    if (!flow.columns) throw new Error("Unable to find next column")
    const actualColumnIndex = flow.columns.findIndex(
      (column) => column._id === card.columnId,
    )
    if (actualColumnIndex === -1)
      throw new Error("Unable to find previous column")

    const previousColumn = flow.columns[actualColumnIndex - 1]
    await moveColumn({
      flowCardId: card._id,
      newColumnId: previousColumn._id,
      newPosition: 0,
      version: card.version,
    })
    navigate("/flows/hiring")
  }

  return (
    <Wrapper
      style={{
        gap: 24,
        padding: "0 80px",
      }}
    >
      <EmptySvg />
      <Typography variant="body3" variantColor="#53464F">
        Não há itens para validar pois nenhuma lista de documentos foi enviada
        ao candidato.
      </Typography>

      <Wrapper
        style={{
          gap: 8,
        }}
      >
        <Button
          variant="primary"
          size="large"
          onClick={handlePreviousColumn}
          disabled={isLoading}
          style={{ width: "100%" }}
        >
          {isLoading ? (
            <Loader size="small" variant="secondary" />
          ) : (
            <>
              Enviar documentos ao candidato
              <Icons name="IconMail" fill="transparent" />
            </>
          )}
        </Button>

        <Typography variant="body4" variantColor="#83727D">
          ou
        </Typography>

        <LinkButton
          variant="primary"
          onClick={handleNextColumn}
          disabled={isLoading}
          style={{
            alignSelf: "center",
          }}
        >
          Mover para a próxima etapa
          <Icons name="IconArrowRight" fill="transparent" />
        </LinkButton>
      </Wrapper>
    </Wrapper>
  )
}
