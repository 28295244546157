import { useParams } from "react-router-dom"
import { PageTemplate } from "../../../components"
import { ReplaceLeaderContent } from "../../../components/screen/ReplaceLeader"

const ReplaceLeader = () => {
  const { flowId = '' } = useParams()

  return (
    <PageTemplate
      history={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Selecionar pessoa",
          href: `/flows/resignation/selectPeople/${flowId}`,
        },
        {
          name: "Substituir líder",
          href: `/flows/resignation/selectPeople/${flowId}/replaceLeader`,
        },
      ]}
    >
      <ReplaceLeaderContent />
    </PageTemplate>
  )
}

export default ReplaceLeader
