import React from "react"
import ConfigAccordion, {
  AccordionButton,
} from "../ConfigAccordion/ConfigAccordion"
import { useParams } from "react-router-dom"
import InfoList from "../../../../components/common/CandidateMenu/Information/InfoList"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { AccordionChildrenContainer } from "./styles"
import { BodyContent } from "../PageContent/styles"
import { useTheme } from "styled-components"
import EmptySection from "../EmptySection"
import { CompanyAddress, Recipient } from "../../../../types"
import { api } from "../../../../api"
import { HiringContentProps } from "../../../../components/screen/CardPage/types"
import { useQuery } from "@tanstack/react-query"
import { globalContext } from "../../../../context"

const CardDeliveryInformation: React.FC<{
  address?: CompanyAddress
  recipient?: Recipient
}> = ({ address, recipient }) => {
  const theme = useTheme()

  return (
    <AccordionChildrenContainer>
      <BodyContent>
        <Typography
          variant="headline8"
          style={{ marginBottom: theme.spacings.xs1 }}
        >
          Dados da entrega
        </Typography>
        <InfoList
          data={[
            {
              text: "CEP",
              value: address?.zipCode ?? "Não cadastrado",
              hideCopyIcon: !address?.zipCode,
            },
            {
              text: "Endereço",
              value: address?.street ?? "Não cadastrado",
              hideCopyIcon: !address?.street,
            },
            {
              text: "Número",
              value: address?.number ?? "Não cadastrado",
              hideCopyIcon: !address?.number,
            },
            {
              text: "Complemento",
              value: address?.complement || "Não cadastrado",
              hideCopyIcon: !address?.complement,
            },
            {
              text: "Bairro",
              value: address?.neighborhood ?? "Não cadastrado",
              hideCopyIcon: !address?.neighborhood,
            },
            {
              text: "Cidade",
              value: address?.city ?? "Não cadastrado",
              hideCopyIcon: !address?.city,
            },
            {
              text: "Estado",
              value: address?.state?.toUpperCase() ?? "Não cadastrado",
              hideCopyIcon: !address?.state,
            },
            {
              text: "Referência",
              value: address?.reference || "Não cadastrado",
              hideCopyIcon: !address?.reference,
            },
          ]}
        />
      </BodyContent>
      <BodyContent>
        <Typography
          variant="headline8"
          style={{ marginBottom: theme.spacings.xs1 }}
        >
          Dados do destinatário
        </Typography>
        <InfoList
          data={[
            {
              text: "Nome",
              value: recipient?.name ?? "Não cadastrado",
              hideCopyIcon: !recipient?.name,
            },
            {
              text: "Telefone",
              value: recipient?.phone ?? "Não cadastrado",
              hideCopyIcon: !recipient?.phone,
            },
            {
              text: "E-mail",
              value: recipient?.email ?? "Não cadastrado",
              hideCopyIcon: !recipient?.email,
            },
          ]}
        />
      </BodyContent>
    </AccordionChildrenContainer>
  )
}

interface CardDeliveryConfigAccordionProps {
  status: React.ComponentProps<typeof ConfigAccordion>["status"]
  address?: CompanyAddress
  recipient?: Recipient
  loading?: boolean
}

const CardDeliveryConfigAccordion: React.FC<
  CardDeliveryConfigAccordionProps
> = ({ status, address, recipient, loading }) => {
  const { id: cardId = "" } = useParams()
  const { companyPermissions } = React.useContext(globalContext)

  const [hasCardDeliveryOrderPermission, hasViewCardPagePermission] =
    React.useMemo(() => {
      if (!companyPermissions) {
        return [false, false];
      }

      return companyPermissions?.reduce(
        (acc, permission) => {
          const [cardDelivery, cardPage] = acc
          if (permission === "*") {
            return [true, true]
          }
          const newCardDelivery =
            cardDelivery || permission === "operations_create_card_order"
          const newCardPage =
            cardPage || permission === "operations_view_card_orders"
          return [newCardDelivery, newCardPage]
        },
        [false, false],
      )
    }, [companyPermissions])

  return (
    <ConfigAccordion
      status={status}
      title="Cartão flash"
      description="Solicite o nosso cartão para a nova pessoa"
      loading={loading}
      disabled={!hasCardDeliveryOrderPermission}
      button={
        status === "completed" ? (
          <AccordionButton
            label="Ir para acompanhamento da solicitação"
            href="/cards"
            variant={"secondary"}
            disabled={!hasViewCardPagePermission}
          />
        ) : (
          <AccordionButton
            label="Ir para solicitação de cartão"
            href={`/flows/hiring/initial-config/card-delivery/${cardId}`}
            variant={"primary"}
            disabled={!hasCardDeliveryOrderPermission}
          />
        )
      }
    >
      {status === "completed" ? (
        <CardDeliveryInformation address={address} recipient={recipient} />
      ) : (
        <EmptySection
          title={"Solicite o cartão flash da pessoa"}
          description="As informações das configurações selecionadas aparecerão aqui."
        />
      )}
    </ConfigAccordion>
  )
}

export default CardDeliveryConfigAccordion

interface CardDeliveryConfigProps extends HiringContentProps { }

const CardDeliveryConfig: React.FC<CardDeliveryConfigProps> = ({ card }) => {
  const { data, isInitialLoading: isLoading } = useQuery(
    ["candidate.cardDeliveryOrder", card?.candidate?.externalCardDeliveryId],
    () =>
      api.query.cardDelivery.getDeliveryCardOrder(
        card?.candidate?.externalCardDeliveryId,
      ),
    {
      enabled: !!card?.candidate?.externalCardDeliveryId,
      refetchOnWindowFocus: false,
      onSuccess: () => { },
    },
  )

  React.useEffect(() => {
    if (!card.candidate.employeeId) {
      console.error("Employee ID not found")
    }
  }, [card.candidate.employeeId])

  return (
    <CardDeliveryConfigAccordion
      status={data?.id ? "completed" : "pending"}
      address={data?.address}
      recipient={data?.recipient}
      loading={isLoading}
    />
  )
}

export const CardDeliveryConfigByType = {
  clt: CardDeliveryConfig,
  pj: CardDeliveryConfig,
  internship: CardDeliveryConfig,
}
