const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const reorderColumn = (list: any, source: any, destination: any) => {
  const startIndex = source.index
  const endIndex = destination.index

  const array = Array.from(list)
  const [removed] = array.splice(startIndex, 1)
  array.splice(endIndex, 0, removed)

  const ordered = array.map((r: any, index: any) => {
    return { ...r, position: index }
  })

  const currentItem = list[startIndex]
  const destinationItem = list[endIndex]

  const hasCardInColumn =
    currentItem?.cards?.length || destinationItem?.cards?.length

  const notAllowedLockedOrder = list.filter((l: any) => l.locked)
  const notAllowedHiddenOrder = list.filter((l: any) => l.hidden)

  const hasChangedLockedBlocked = notAllowedLockedOrder.length
    ? notAllowedLockedOrder.some(
      (n) =>
        !!ordered.find((o) => {
          return n._id === o._id && n.position !== o.position
        }),
    )
    : false

  const hasChangedHiddenBlocked = notAllowedHiddenOrder.length
    ? notAllowedHiddenOrder.some(
      (n) =>
        !!ordered.find((o) => n._id === o._id && n.position !== o.position),
    )
    : false

  return {
    col: ordered,
    hasChangedLockedBlocked: hasChangedLockedBlocked,
    hasChangedHiddenBlocked: hasChangedHiddenBlocked,
    hasCardInColumn: hasCardInColumn,
  }
}

const reorderItemsSameColumn = (list: any, index: any, item: any) => {
  const result = Array.from(list) as Array<any>
  result.splice(index, 1)
  result.splice(index, 0, item)

  return result
}

const removeItem = (list: any, index: any) => {
  const result = Array.from(list)
  const [removed] = result.splice(index, 1)

  return { cards: result, removed: removed }
}

const reorderCard = (list: any, index: any, item: any) => {
  const result = Array.from(list)
  result.splice(index, 0, item)

  return result
}

const reorderItemsOtherColumn = (
  item: any,
  findSourceIndex: any,
  removedItem: any,
  destinationCard: any,
  destination: any,
  removed: any,
  findDestination: any,
  findDestinationIndex: any,
) => {
  const result = Array.from(item) as Array<any>

  result.splice(findSourceIndex, 1)
  result.splice(findSourceIndex, 0, removedItem)

  const itemRemoved = { ...removed, columnId: destination.droppableId }
  const reordered = reorderCard(destinationCard, destination.index, itemRemoved)
  const updatedItem = { ...findDestination, cards: reordered }

  result.splice(findDestinationIndex, 1)
  result.splice(findDestinationIndex, 0, updatedItem)
  return result
}

const reorderMap = ({
  item,
  source,
  destination,
}: {
  item: any
  source: any
  destination: any
}) => {
  const currentItem = item.find((f: any) => f._id === source.droppableId)
  const currentCard = currentItem?.cards || []

  const destinationItem = item.find(
    (f: any) => f._id === destination.droppableId,
  )
  const destinationCard = destinationItem?.cards || []

  const findSource = item.find((i: any) => i._id === source.droppableId)
  const findSourceIndex = item.findIndex((f: any) => f._id === findSource._id)

  const findDestination = item.find(
    (i: any) => i._id === destination.droppableId,
  )

  const findDestinationIndex = item.findIndex(
    (i: any) => findDestination._id === i._id,
  )

  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(currentCard, source.index, destination.index)
    const findResult = { ...findSource, cards: reordered }

    const orderedColumn = reorderItemsSameColumn(
      item,
      findSourceIndex,
      findResult,
    )

    return orderedColumn.map((o: { [key: string]: any }) => {
      return {
        ...o,
        cards:
          o?.cards?.map((c: { [key: string]: any }, index) => {
            let hasChangedVersion = false

            if (
              destination.droppableId === o._id &&
              source.index < destination.index &&
              destination.index >= index
            )
              hasChangedVersion = true

            if (
              destination.droppableId === o._id &&
              source.index > destination.index &&
              source.index >= index &&
              destination.index <= index
            )
              hasChangedVersion = true
            return {
              ...c,
              position: index,
              version: hasChangedVersion ? c.version + 1 : c.version,
            }
          }) || [],
      }
    })
  }

  const { cards, removed } = removeItem(currentCard, source.index)
  const removedItem = { ...findSource, cards: cards }

  const orderedColumn = reorderItemsOtherColumn(
    item,
    findSourceIndex,
    removedItem,
    destinationCard,
    destination,
    removed,
    findDestination,
    findDestinationIndex,
  )

  return (
    orderedColumn.map((o: { [key: string]: any }) => {
      return {
        ...o,
        cards:
          o?.cards?.map((c: { [key: string]: any }, index) => {
            let hasChangedVersion = false

            if (source.droppableId === o._id && source.index <= index)
              hasChangedVersion = true

            if (destination.droppableId === o._id && destination.index <= index)
              hasChangedVersion = true
            return {
              ...c,
              position: index,
              version: hasChangedVersion ? c.version + 1 : c.version,
            }
          }) || [],
      }
    }) || []
  )
}

export { reorderColumn, reorderMap }
