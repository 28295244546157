import { gql } from "@apollo/client"
import { request } from "../client"

const VALIDATE_ASO_DOCUMENT = gql`
  mutation ValidateAsoDocument($params: ValidateAsoDocumentInput) {
    validateAsoDocument(params: $params)
  }
`

export const validateAsoDocument = async (params: {
  flowCardId: string
  status: "approved" | "declined" | "waiting"
  statusComment?: string
}) => {
  return await request(VALIDATE_ASO_DOCUMENT, { params })
}
