import gql from "graphql-tag"
import { request } from "../client"
import { CompanyAddress } from "../../types"
const FIND_ADDRESS_BY_ZIP_CODE = gql`
  query GetAddressByZipCode($zipCode: String!) {
    getAddressByZipCode(zipCode: $zipCode) {
      street
      neighborhood
      city
      state
    }
  }
`

export type GetAddressByZipCodeResponse = Pick<
  CompanyAddress,
  "street" | "neighborhood" | "city" | "state"
>

export const getAddressByZipCode = async (
  zipCode: string,
): Promise<GetAddressByZipCodeResponse> => {
  const { getAddressByZipCode } = await request(FIND_ADDRESS_BY_ZIP_CODE, {
    zipCode,
  })
  return getAddressByZipCode
}
