import { useCallback } from "react"

import {
  Container,
  Divider,
  FieldContainer,
  SubTitle,
  Text,
  Title,
} from "./styles"

import { dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import { Flow, ResignationCard } from "../../../types"

export const OnHoldContent = ({
  card,
  flow,
}: {
  card: ResignationCard
  flow: Flow
}) => {
  const renderPersonalInfo = useCallback(() => {
    if (!card.employee) return <></>

    const phone =
      (card.employee?.contacts?.find((c) => c.type === "phone") as any) || {}
    const email =
      (card.employee?.contacts?.find((c) => c.type === "email") as any) || {}

    const personalFields = [
      {
        label: "Nome completo",
        value: card.employee?.name || "Não cadastrado",
      },
      {
        label: "CPF",
        value:
          card.employee?.documentNumber?.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4",
          ) || "Não cadastrado",
      },
      {
        label: "Email",
        value: email?.value || "Não cadastrado",
      },
      {
        label: "Celular",
        value:
          phone?.value?.replace(
            /(\d{2})(\d{2})(\d{5})(\d{4})/,
            "$1 $2 $3-$4",
          ) || "Não cadastrado",
      },
    ]

    return personalFields.map((item, index) => {
      return (
        <FieldContainer key={index}>
          <SubTitle variant={"caption"}>{item.label}</SubTitle>
          <Text variant={"body3"}>{item.value}</Text>
        </FieldContainer>
      )
    })
  }, [card.employee])

  const renderComplementarInfo = useCallback(() => {
    if (!card.employee) return <></>

    const fullAddress = card.employee?.address
      ? `${card.employee?.address?.street || ""}, ${
          card.employee?.address?.number || ""
        } - ${card.employee?.address?.district || ""}, ${
          card.employee?.address?.city || ""
        } -  ${card.employee?.address?.state || ""}, ${
          card.employee?.address?.zipCode || ""
        }`
      : "Não cadastrado"

    const complementarFields = [
      {
        label: "Nome da mãe",
        value: card.employee?.mothersName || "Não cadastrado",
      },
      {
        label: "Data de Nascimento",
        value: card.employee?.birthDate
          ? dayjs(card.employee.birthDate).format("DD/MM/YYYY")
          : "Não cadastrado",
      },
      {
        label: "Endereço",
        value: fullAddress,
      },
    ]

    return complementarFields.map((item, index) => {
      return (
        <FieldContainer key={index}>
          <SubTitle variant={"caption"}>{item.label}</SubTitle>
          <Text variant={"body3"}>{item.value}</Text>
        </FieldContainer>
      )
    })
  }, [card.employee])

  return (
    <Container>
      <Title variant={"headline8"}>Dados pessoais</Title>
      <div style={{ marginTop: "24px" }}>{renderPersonalInfo()}</div>

      <Divider />
      <Title variant={"headline8"}>Dados complementares</Title>
      <div style={{ marginTop: "24px" }}>{renderComplementarInfo()}</div>
    </Container>
  )
}
