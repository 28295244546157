import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import GenericModalContent from "./GenericModalContent"

interface IModalDoneAdmission {
  title: React.ComponentProps<typeof Typography>["children"]
  subtitle?: React.ComponentProps<typeof Typography>["children"]
  desciption?: React.ComponentProps<typeof Typography>["children"]
}

export const GenericModalConfirmationContent = ({
  subtitle,
  title,
  desciption,
}: IModalDoneAdmission) => {
  return (
    <GenericModalContent
      title={title}
      subtitle={subtitle}
      desciption={desciption}
      icon={
        <ShapeIcon
          name="IconAlertCircle"
          size={64}
          variant="default"
          stroke="default"
          style={{ margin: "0 auto" }}
        />
      }
    />
  )
}
