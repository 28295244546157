import gql from "graphql-tag"
import { request } from "../client"

const REPLACE_CHILDREN_IN_ORGCHART = gql`
  mutation replaceChildrenInOrgchart($params: replaceChildrenInput) {
    replaceChildrenInOrgchart(params: $params)
  }
`

export const replaceChildrenInOrgchart = async (params: {
  employeeId: string
}) => {
  const { replaceChildrenInOrgchart } = await request(
    REPLACE_CHILDREN_IN_ORGCHART,
    { params },
  )
  return replaceChildrenInOrgchart
}
