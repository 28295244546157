import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const WarningLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      color: ${({ theme }) => theme.colors.feedback.error[40]} !important;

      &::before {
        border-bottom: 2px solid
          ${({ theme }) => theme.colors.feedback.error[40]} !important;
      }
    }
  }
`
