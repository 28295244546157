import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

export const RenderSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        flex: "1",
      }}
    >
      <Skeleton width={"40%"} height={40} />
      <Skeleton width={"80%"} height={40} />
      <Skeleton width={"100%"} height={100} />
      <Skeleton width={"100%"} height={100} />
    </div>
  )
}
