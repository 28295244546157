import {
  LinkButton,
  PageContainer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  overflow: hidden hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-top: 8px;
`

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`

export const StyledFooterBar = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral.light.pure};

  padding: 16px 24px;
  z-index: 99;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
`

export const FooterButtonContainer = styled.div<{
  justifyContent: "space-between" | "flex-end"
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      color: ${({ theme }) => theme.colors.neutral[50]} !important;

      &::before {
        border-bottom: 2px solid ${({ theme }) => theme.colors.neutral[50]} !important;
      }
    }
  }
`

export const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.xl}px`}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`
