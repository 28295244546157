import { gql } from "@apollo/client"
import { request } from "../client"

const DELETE_MODEL_PROPOSED_LETTER = gql`
  mutation DeleteModelProposedLetter($modelId: ID) {
    deleteModelProposedLetter(modelId: $modelId)
  }
`

export const deleteModelProposedLetter = async (modelId: string) => {
  const { deleteModelProposedLetter } = await request(
    DELETE_MODEL_PROPOSED_LETTER,
    {
      modelId,
    },
  )
  return deleteModelProposedLetter as boolean
}
