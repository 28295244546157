import { FlowBigNumbers, FlowWithCards, HiringCard } from "../../../types"
import { ColumnWrapper, Container } from "./styles"
import { useMemo, useRef, useState } from "react"
import {
  SearchField,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2"
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination"
import { useQueryClient } from "@tanstack/react-query"
import { hiringFlowColumns } from "../../../mock/board"
import { useNavigate } from "react-router-dom"
import { ModalMoveStep } from "../../../pages/Hiring/Home/components/ModalMoveStep"
import { ModalConfirmArchive } from "../../../pages/Hiring/Home/components/ModalConfirmArchive"
import dispatchToast from "../../../utils/dispatchToast"
import { getColumnBigNumber, getColumnsByKey } from "./options"
import { useTranslation } from "react-i18next"
import useLocalStorage from "../../../hooks/useLocalStorage"
import { ColumnBadge, FilterRef, HiringFilter } from "../../common"

export const List = ({
  flow: flowOg,
  bigNumbers,
}: {
  flow: FlowWithCards<HiringCard>
  bigNumbers: FlowBigNumbers
}) => {
  const [flow, setFlow] = useState<FlowWithCards<HiringCard>>(flowOg)
  const [selectedColumn, setSelectedColumn] = useLocalStorage<string>(
    "hiring-column",
    "all",
  )
  const [modalMoveStepControl, setModalMoveStepControl] = useState<{
    isOpen: boolean
    card: HiringCard | undefined
  }>({
    isOpen: false,
    card: undefined,
  })
  const [modalConfirmArchive, setModalConfirmArchive] = useState({
    isOpen: false,
    cardId: "",
    version: 0,
  })
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  })
  const [searchField, setSearchField] = useState<string>("")

  const filterRef = useRef<FilterRef>(null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const allCardsCount = useMemo(() => {
    return flowOg?.columns?.reduce<HiringCard[]>((prev, curr) => {
      return curr.cards?.length > 0 ? [...prev, ...curr.cards] : prev
    }, []).length
  }, [flowOg])

  const cards =
    selectedColumn === "all"
      ? flow.columns?.reduce<HiringCard[]>((prev, curr) => {
          return curr.cards?.length > 0 ? [...prev, ...curr.cards] : prev
        }, [])
      : flow.columns?.find((column) => column._id === selectedColumn)?.cards ||
        []

  const columnName = flow.columns?.find(
    (column) => column._id === selectedColumn,
  )?.name

  const columns = useMemo(() => {
    return getColumnsByKey(selectedColumn, flow)
  }, [flow, selectedColumn])

  const BigNumber = useMemo(() => {
    return getColumnBigNumber(selectedColumn, bigNumbers, navigate)
  }, [bigNumbers])

  const paginatedCards =
    cards?.slice(
      (pagination.pageNumber - 1) * pagination.pageSize,
      pagination.pageNumber * pagination.pageSize,
    ) || []

  const table = tableControllers.useTableColumns<HiringCard>({
    options: {
      actions: () => [
        {
          label: "Ver detalhes da etapa",
          icon: "IconDeviceAnalytics",
          key: "open",
          onClick: (data) => {
            const columnInfo = hiringFlowColumns.find((column) =>
              column.columnsId.includes(data.columnId),
            )
            navigate(columnInfo?.route + data._id)
          },
        },
        {
          label: "Mover para outra etapa",
          icon: "IconArrowsMoveHorizontal",
          key: "move",
          onClick: (data) => {
            setModalMoveStepControl({
              isOpen: true,
              card: data,
            })
          },
        },
        {
          label: "Compartilhar",
          icon: "IconShare",
          key: "share",
          onClick: (data) => {
            const columnInfo = hiringFlowColumns.find((column) =>
              column.columnsId.includes(data.columnId),
            )
            const link = columnInfo?.route + data._id
            navigator.clipboard.writeText(window.location.hostname + link)
            dispatchToast({
              type: "success",
              content: "Link do card copiado",
            })
          },
        },
        {
          label: "Arquivar",
          icon: "IconArchive",
          key: "archive",
          onClick: (data) => {
            setModalConfirmArchive({
              isOpen: true,
              cardId: data._id,
              version: data.version,
            })
          },
        },
      ],
    },
    columns: (columns as any) || [],
    data: paginatedCards,
    pagination: pagination,
    onPaginationChange: setPagination,
  })

  const handleChangeColumn = (columnId: string) => {
    setSelectedColumn(columnId)
  }

  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchField(value || "")

    filterRef.current?.applyExternalFilter({
      identifier: "searchField",
      key: ["name", "email", "documentNumber"],
      value: value,
    })
  }

  return (
    <>
      <SearchField
        value={searchField}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleSearchField(e)
        }
        label={t("home.options.searchField")}
        style={{ marginBottom: 32 }}
      />

      <Container>
        <ColumnWrapper style={{ width: "20%" }}>
          <ColumnBadge
            active={selectedColumn === "all"}
            onClick={() => handleChangeColumn("all")}
            info={allCardsCount}
            text="Todos"
            key={"all"}
          />

          {flowOg.columns?.map((column, idx) => (
            <ColumnBadge
              key={column.name + idx}
              active={selectedColumn === column._id}
              onClick={() => handleChangeColumn(column._id)}
              info={column.cards?.length || 0}
              text={column.name}
            />
          ))}
        </ColumnWrapper>

        <ColumnWrapper style={{ width: "calc(80% - 30px)" }}>
          <Table.Root>
            <Table.Header
              title={`${columnName || "Todos"} (${cards?.length || 0})`}
            />

            {BigNumber}

            <HiringFilter
              ref={filterRef}
              originalFlow={flowOg}
              flow={flow}
              handleUpdateFlow={(filteredFlow) => setFlow(filteredFlow)}
              hideFilter={{
                step: selectedColumn !== "all" ? true : false,
              }}
              style={{ marginBottom: 32 }}
            />

            <Table.Grid.Root>
              <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
              {table?.rows?.map((row, index) => (
                <Table.Grid.Row key={`${index}${row.id}`} row={row} />
              ))}
            </Table.Grid.Root>

            <Table.Pagination
              count={cards?.length || 0}
              onPaginationChange={({ pageSize, pageNumber }) =>
                setPagination({ ...pagination, pageSize, pageNumber })
              }
              pagination={pagination}
              pageSizeOptions={[
                {
                  label: "10 itens",
                  value: 10,
                },
                {
                  label: "25 itens",
                  value: 25,
                },
              ]}
            />
          </Table.Root>
        </ColumnWrapper>

        {modalMoveStepControl?.isOpen && modalMoveStepControl.card ? (
          <ModalMoveStep
            flow={flow}
            isOpen={modalMoveStepControl.isOpen}
            card={modalMoveStepControl.card}
            handleClose={() => {
              setModalMoveStepControl({
                isOpen: false,
                card: undefined,
              })

              queryClient.invalidateQueries(["flows", flow._id])
            }}
          />
        ) : (
          <></>
        )}

        {modalConfirmArchive?.isOpen ? (
          <ModalConfirmArchive
            flow={flow}
            isOpen={modalConfirmArchive.isOpen}
            cardId={modalConfirmArchive.cardId}
            cardVersion={modalConfirmArchive.version}
            handleClose={() => {
              setModalConfirmArchive({
                cardId: "",
                isOpen: false,
                version: 0,
              })

              queryClient.invalidateQueries(["flows", flow._id])
            }}
          />
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}
