import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  PageHeader,
  PageContainer,
  Icons,
  Button,
  useMediaQuery,
  Typography,
  IconButton,
  Menu,
  MenuOption,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  FlexColumn,
  PageHeaderOptionsContainer,
  PageHeaderSubcontainer,
  MenuText,
} from "./styles"
import { globalContext } from "../../../context"
import { useNavigate } from "react-router"
import {
  RoundedSelect,
  CreateCandidateModal,
  ModalExportData,
  List,
  Kanban,
  SwitchButton,
} from "../../../components"
import { useQuery } from "@tanstack/react-query"
import { HiringCard } from "../../../types"
import useLocalStorage from "../../../hooks/useLocalStorage"
import dispatchToast from "../../../utils/dispatchToast"
import { ContentSkeleton, HeaderSkeleton } from "./components/Skeleton"
import { api } from "../../../api"
import { useSearchParams } from "react-router-dom"

type FlowView = "list" | "kanban"

const HiringPage = () => {
  const [selectedHiringFlow, setSelectedHiringFlow] = useLocalStorage<string>(
    "hiring-flow",
    "",
  )
  const [selectedFlowView, setSelectedFlowView] = useLocalStorage<FlowView>(
    "flow-view",
    "kanban",
  )
  const [createCandidateModal, setCreateCandidateModal] =
    useState<boolean>(false)
  const [exportDataModal, setExportDataModal] = useState<boolean>(false)

  const { companyId } = useContext(globalContext)
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const [searchParams] = useSearchParams()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  React.useEffect(() => {
    const view = searchParams.get("view") as FlowView
    if (view) {
      setSelectedFlowView(view)
    }
  }, [searchParams])

  const { data: flowList, isInitialLoading: isFlowListLoading } = useQuery(
    ["flows", "company", companyId],
    () =>
      api.query.hiring.flow?.getFlowByCompany({
        companyId: companyId || "",
        category: "hiring",
      }),
    {
      enabled: !!companyId,
      onSuccess: (data) => {
        if (!selectedHiringFlow) {
          setSelectedHiringFlow(data[0]._id)
          return
        }

        const hasHiringFlowOnList =
          data.filter((flow) => flow?._id === selectedHiringFlow).length > 0
        if (!hasHiringFlowOnList) {
          setSelectedHiringFlow(data[0]._id)
        }
      },
      refetchOnWindowFocus: false,
    },
  )

  const { data: bigNumbers, isInitialLoading: isBigNumberLoading } = useQuery(
    ["flows", "big-numbers", selectedHiringFlow],
    () =>
      api.query.hiring.flow?.getFlowBigNumbers({
        flowId: selectedHiringFlow,
      }),
    {
      enabled: !!selectedHiringFlow,
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: flow,
    refetch: refetchFlow,
    isInitialLoading: isFlowLoading,
  } = useQuery(
    ["flows", selectedHiringFlow],
    () =>
      api.query.hiring.flow?.getFlowById<HiringCard>({
        flowId: selectedHiringFlow,
      }),
    {
      enabled: !!selectedHiringFlow,
      refetchOnWindowFocus: false,
    },
  )

  const menuOptions = [
    {
      onClick: async () => setCreateCandidateModal(true),
      children: (
        <div
          style={{
            display: "flex",
          }}
        >
          <Icons
            name="IconUser"
            style={{ marginRight: "8px", fill: "transparent" }}
          />
          <MenuText variant="body3">Adicionar um candidato</MenuText>
        </div>
      ),
    },
    {
      onClick: async () =>
        navigate(`/flows/hiring/addMassCandidates/${flow?._id}`),
      children: (
        <div
          style={{
            display: "flex",
          }}
        >
          <Icons
            name="IconUsers"
            style={{ marginRight: "8px", fill: "transparent" }}
          />
          <MenuText variant="body3">Adicionar vários candidatos</MenuText>
        </div>
      ),
    },
  ]

  const refreshBoard = () =>
    refetchFlow().finally(() =>
      dispatchToast({
        type: "success",
        content: t("board.refreshBoardSuccess"),
      }),
    )

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!flowList || isFlowListLoading ? (
        <HeaderSkeleton />
      ) : (
        <PageHeader>
          <FlexColumn style={{ gap: 24 }}>
            <PageHeaderSubcontainer>
              <Typography variant="headline6">{t("tab.titleLabel")}</Typography>
              <Menu
                anchorOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
                options={menuOptions}
                type="default"
              >
                <Button
                  size="medium"
                  variant="primary"
                  // onClick={() => setCreateCandidateModal(true)}
                  style={{
                    marginLeft: isMobile ? "0px" : "40px",
                    alignSelf: isMobile ? "center" : "flex-start",
                  }}
                >
                  <Typography variant="body3" style={{ fontWeight: 700 }}>
                    {t("tab.buttonLabel")}
                  </Typography>
                  <Icons name="IconPlus" style={{ fill: "#fff" }} />
                </Button>
              </Menu>
            </PageHeaderSubcontainer>

            <PageHeaderOptionsContainer>
              <RoundedSelect
                onSelectChange={(value) => setSelectedHiringFlow(value)}
                value={selectedHiringFlow}
                options={flowList?.map((flow) => ({
                  label: flow?.name,
                  value: flow?._id,
                }))}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 12,
                }}
              >
                <SwitchButton
                  selectedValue={selectedFlowView}
                  option1={{
                    icon: "IconLayoutKanban",
                    handleClick: () => setSelectedFlowView("kanban"),
                    value: "kanban",
                    ariaLabel: t("home.options.ariaLabelKanban"),
                  }}
                  option2={{
                    icon: "IconMenu2",
                    handleClick: () => setSelectedFlowView("list"),
                    value: "list",
                    ariaLabel: t("home.options.ariaLabelList"),
                  }}
                ></SwitchButton>
                <Menu
                  type="default"
                  options={[
                    {
                      children: (
                        <MenuOption
                          label={t("home.options.updateFlow")}
                          icon="IconRefresh"
                          onClick={refreshBoard}
                        />
                      ),
                    },
                    {
                      children: (
                        <MenuOption
                          label={t("home.options.exportData")}
                          icon="IconFileDownload"
                          onClick={() => setExportDataModal(true)}
                        />
                      ),
                    },
                  ]}
                >
                  <IconButton
                    size="small"
                    variant="line"
                    icon="IconDotsVertical"
                  />
                </Menu>

                <IconButton
                  size="small"
                  variant="line"
                  icon="IconSettings"
                  onClick={() => {
                    navigate(
                      `/flows/settings/${selectedHiringFlow}/proposedLetter`,
                    )
                  }}
                />
              </div>
            </PageHeaderOptionsContainer>
          </FlexColumn>
        </PageHeader>
      )}

      {isFlowLoading || !flow || !bigNumbers ? (
        <ContentSkeleton />
      ) : (
        <PageContainer style={{ paddingBottom: "20px" }}>
          {selectedFlowView === "kanban" ? (
            <Kanban flow={flow} bigNumbers={bigNumbers} />
          ) : (
            <List flow={flow} bigNumbers={bigNumbers} />
          )}

          {createCandidateModal ? (
            <CreateCandidateModal
              flow={flow}
              isOpen={createCandidateModal}
              handleClose={() => {
                setCreateCandidateModal(false)
              }}
            />
          ) : (
            <></>
          )}

          {exportDataModal && (
            <ModalExportData
              isOpen={exportDataModal}
              handleClose={() => setExportDataModal(false)}
              columns={flow?.columns || []}
            />
          )}
        </PageContainer>
      )}
    </div>
  )
}

export default HiringPage
