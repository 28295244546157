import gql from "graphql-tag"
import { request } from "../client"
import { CompanyGroups } from "../../types"

const GET_GROUPS_BY_COMPANY_ID = gql`
  query Query($companyId: ID!) {
    getGroupsByCompanyId(companyId: $companyId) {
      id
      name
      companyId
      active
    }
  }
`

export const getGroupsByCompanyId = async ({
  companyId,
}: {
  companyId: string
}) => {
  const { getGroupsByCompanyId } = await request(GET_GROUPS_BY_COMPANY_ID, {
    companyId,
  })
  return getGroupsByCompanyId as CompanyGroups[]
}
