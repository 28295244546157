import { Dispatch, useContext, useEffect, useState } from "react"
import { EnumGlobalActions, globalContext, IActions } from "../../../../context"
import { useNavigate } from "react-router"

import dispatchToast from "../../../../utils/dispatchToast"
import { FooterBar } from "../../../common/Components/FooterBar"

import { useFormik } from "formik"
import * as yup from "yup"

import EmptyDocConfig from "../../../../assets/emptyDocConfig.svg"

import {
  Title,
  Description,
  StyledPageContainer,
  StyledGrid,
  StyledTitle,
  StyledDescription,
  FlexBetween,
  StyledFlexCenter,
  StyledTextButton,
  StyledSectionContainer,
  StyledSectionTitle,
  OptionText,
  StyledMenu,
  StyledIconsHoverable,
  MandatoryText,
  StyledFieldContainer,
} from "./styles"

import {
  Button,
  Checkbox,
  DatePicker,
  Icons,
  SelectField,
  Stepper,
  TextField,
  TimePicker,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2"

import { ConfigFieldModal } from "../Modal"
import { UploadFieldModal } from "../Modal/ConfigFieldModal"
import { useParams } from "react-router-dom"
import { DeprecatedUpload } from "../../../common/DeprecatedUpload"
import { modalAction } from "../../../../utils"

const SecondStep = ({ steps, currentStep }) => {
  const navigate = useNavigate()
  const { dispatch } = useContext(globalContext)

  const urlId = useParams().id
  // HjV1nATef7EdXBPtwpshU
  const [handleSectionData, setHandleSectionData] = useState<{
    fields: any[]
  }>({ fields: [] })
  useEffect(() => {
    if (urlId === undefined) {
      navigate(`/flows/hiring/documents/sections/configure/`, {
        state: { currentStep: 0 },
        replace: true,
      })
    }
    (async () => {
      // const result = await getSection({ _id: urlId, callback: () => {} });
      // setHandleSectionData(result.data.value);
    })()
  }, [])

  const validationSchema = yup.object({
    name: yup.string().max(50).required("Favor digitar o nome do documento"),
    description: yup
      .string()
      .max(600)
      .required("Favor digitar a descrição do documento"),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {}
      return errors
    },
    onSubmit: async (values) => {
      // navigate(`/flows/hiring/documents/list`, {
      //   replace: true,
      // });
    },
  })

  const renderConfigs = () => {
    let configsPage = <></>

    handleSectionData.fields.length !== 0
      ? (configsPage = (
        <StyledFieldContainer>
          <StyledFlexCenter style={{ width: "100%" }}>
            {handleSectionData.fields?.map((f, fIndex) => {
              return (
                <div key={fIndex} style={{ marginBottom: "24px" }}>
                  {f.component === "shortText" ? (
                    <TextField
                      label={f.placeholder}
                      disabled={true}
                      fullWidth
                    />
                  ) : f.component === "select" ? (
                    <SelectField
                      options={f?.options}
                      disabled={false}
                      label={f?.placeholder || ""}
                      fullWidth
                    />
                  ) : f.component === "datePicker" ? (
                    <DatePicker
                      disabled={true}
                      label={f.placeholder || ""}
                      onDateChange={() => { }}
                      fullWidth
                    />
                  ) : f.component === "timePicker" ? (
                    <TimePicker
                      label={f.placeholder || ""}
                      value={null}
                      onChange={() => { }}
                      disabled={true}
                    />
                  ) : f.component === "attachment" ? (
                    <DeprecatedUpload
                      label={f.placeholder}
                      maxSize={5242880}
                      onFileSizeError={() => {
                        dispatchToast({
                          content:
                            "Arquivo maior que 5mb. Por favor, faça upload de arquivo menor que 5mb",
                          type: "warning",
                        })
                      }}
                    />
                  ) : f.component === "checkBox" ? (
                    <StyledFlexCenter>
                      <Checkbox />
                      <StyledDescription variant="body3">
                        {f.placeholder}
                      </StyledDescription>
                    </StyledFlexCenter>
                  ) : (
                    <></>
                  )}
                </div>
              )
            })}
            <StyledMenu
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              options={[
                {
                  onClick: async () => { },
                  children: (
                    <StyledFlexCenter>
                      <Icons
                        name="IconPencil"
                        fill="transparent"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                      <OptionText variant="body3" style={{ fontWeight: 600 }}>
                        Editar campo
                      </OptionText>
                    </StyledFlexCenter>
                  ),
                },
                {
                  onClick: () => { },
                  children: (
                    <StyledFlexCenter>
                      <Icons
                        name="IconEyeOff"
                        fill="transparent"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                      <OptionText variant="body3" style={{ fontWeight: 600 }}>
                        Ocultar campo
                      </OptionText>
                    </StyledFlexCenter>
                  ),
                },
                {
                  onClick: () => { },
                  children: (
                    <StyledFlexCenter>
                      <Icons
                        name="IconTrash"
                        fill="transparent"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                      <OptionText variant="body3" style={{ fontWeight: 600 }}>
                        Excluir campo
                      </OptionText>
                    </StyledFlexCenter>
                  ),
                },
              ]}
              type="default"
            >
              <StyledIconsHoverable name="IconDots" />
            </StyledMenu>
          </StyledFlexCenter>
          <StyledFlexCenter style={{ marginTop: "12px" }}>
            <Toggle />
            <MandatoryText variant="body3">Obrigatório</MandatoryText>
          </StyledFlexCenter>
        </StyledFieldContainer>
      ))
      : (configsPage = (
        <StyledFlexCenter
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          <EmptyDocConfig />
          <StyledDescription variant="body3" style={{ marginTop: "16px" }}>
            Você ainda não adicionou nenhum campo ou anexo
          </StyledDescription>
        </StyledFlexCenter>
      ))

    return configsPage
  }

  const renderConfig = () => {
    return (
      <StyledSectionContainer>
        <StyledSectionTitle
          variant="headline8"
          style={{ marginBottom: "32px" }}
        >
          Campos e anexos
        </StyledSectionTitle>
        {renderConfigs()}
      </StyledSectionContainer>
    )
  }

  return (
    <>
      <div
        style={{
          overflow: "hidden hidden",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledPageContainer>
          <div style={{ marginTop: "40px" }}>
            <Title variant="headline6">Campos e anexos</Title>
            <Description variant="body3">
              Inclua os campos e/ou anexos que pertencem a essa seção.
            </Description>
          </div>
          <div style={{ marginBottom: "40px", marginTop: "40px" }}>
            <Stepper
              className="Stepper"
              steps={steps}
              activeStep={currentStep}
              disableClick
            />
          </div>
          <StyledGrid container>
            <StyledGrid item sm={12} md={5} lg={4}>
              <div style={{ marginRight: "24px" }}>
                <StyledTitle variant="headline7">
                  Informações básicas
                </StyledTitle>
                <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                  <StyledDescription variant="body3">
                    Adicione as informações básicas do seu conteúdo, como nome e
                    descrição.
                  </StyledDescription>
                </div>
              </div>
            </StyledGrid>
            <StyledGrid item sm={12} md={7} lg={8}>
              <FlexBetween style={{ marginBottom: "40px" }}>
                <Button
                  variant="secondary"
                  size="medium"
                  style={{ width: "100%", marginRight: "24px" }}
                  onClick={() => {
                    modalAction({
                      param: {
                        name: "configFieldModal",
                        open: true,
                      },
                      dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
                    })
                  }}
                >
                  <Icons name="IconPlus" />
                  <StyledTextButton variant="body3">
                    Adicionar novo campo
                  </StyledTextButton>
                </Button>
              </FlexBetween>
              {renderConfig()}
            </StyledGrid>
          </StyledGrid>
        </StyledPageContainer>
        <FooterBar
          loadingUrl={[""]}
          previousHandleClick={() => {
            navigate(`/flows/hiring/documents/sections/configure/${urlId}`, {
              state: { currentStep: 0 },
              replace: true,
            })
          }}
          cancelHandleClick={() => {
            navigate(`/flows/hiring`, {
              replace: true,
            })
          }}
          nextHandleClick={async () => {
            const isValidToSubmit = await formik.validateForm()

            if (Object.keys(isValidToSubmit).length)
              dispatchToast({
                content: "Alguns campos estão incorretos. Favor verificar",
                type: "error",
              })

            formik.handleSubmit()
          }}
          steps={steps}
          currentStep={currentStep}
        />
      </div>
      <ConfigFieldModal />
      <UploadFieldModal />
    </>
  )
}

export { SecondStep }
