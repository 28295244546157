import { Button, Icons, SearchField } from "@flash-tecnologia/hros-web-ui-v2"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { IFieldConfig, IFormConfig, IFormGroup } from "../../../../types"
import dispatchToast from "../../../../utils/dispatchToast"
import {
  ActionWrapper,
  CancelButton,
  CardAddSectionContainer,
  Description,
  DividerLine,
  DividerWrapper,
} from "./styles"
import { useMutation, useQuery } from "@tanstack/react-query"
import { api } from "../../../../api"
import { createSegmentTrack } from "../../../../utils"

type Option = {
  label: string
  value: string
} | null

export const BindSection = ({
  companyId,
  formConfig,
  flowId,
  category,
  handleBindSection,
}: {
  companyId: string
  flowId: string
  formConfig: IFormConfig
  category: "employee" | "dependent"
  handleBindSection: (extensionId: string) => void
}) => {
  const [stage, setStage] = useState<"initial" | "bind">("initial")
  const [sectionSelected, setSectionSelected] = useState<Option>()

  const navigate = useNavigate()

  const { mutateAsync: sendFormConfigExtension } = useMutation(
    api.mutation.hiring.modelDocuments.sendFormConfigExtension,
  )
  const { mutateAsync: getFlowSubcategoryByFlowId } = useMutation(
    api.mutation.hiring.flow.getFlowSubcategoryByFlowId
  )

  const { data: backboneSections, isInitialLoading: isLoading } = useQuery(
    ["backbone-sections"],
    () => api.query.employee.getBackboneSections(),
  )

  const sectionOptions = backboneSections?.map((section) => ({
    label: section.name,
    value: section._id,
  }))

  const handleCreateNewSection = () => navigate("/settings/sections-fields")

  const handleSubmit = async () => {
    try {
      const flowInfo = await getFlowSubcategoryByFlowId({ flowId })
      createSegmentTrack({
        track: `company_hiring_${flowInfo.subcategory || "clt"
          }_documentlisttemplates_linksections_clicked`,
      })
    } catch (err: any) {
      console.error(err)
    }

    if (!sectionSelected || sectionSelected.value === "") {
      dispatchToast({
        content: "Você deve selecionar uma seção antes de vincular.",
        type: "warning",
      })
      return
    }

    const backboneSelectedSection = backboneSections?.find(
      ({ _id }) => _id === sectionSelected.value,
    )
    if (!backboneSelectedSection) {
      dispatchToast({
        content: "Seção não encontrada, por favor tente novamente mais tarde.",
        type: "error",
      })
      return
    }

    const newFormGroup: IFormGroup = {
      title: backboneSelectedSection.name,
      description: "",
      category: category,
      fields: backboneSelectedSection.fields.map(
        (field, idx) =>
          ({
            fieldId: field._id,
            sectionId: backboneSelectedSection._id,
            fieldType: field.type,
            placeholder: field.name,
            order: idx,
            component: field.component,
            options: field.options?.length > 0 ? field.options : undefined,
            enabled: true,
            type: "extension",
            fieldRule: {
              fieldId: field._id,
              required: true,
            },
          }) as IFieldConfig,
      ),
      type: "extension",
      enabled: true,
    }

    sendFormConfigExtension(
      {
        companyId: companyId,
        baseId: formConfig._id as string,
        name: formConfig.name,
        formGroups: [...formConfig.formGroups, newFormGroup],
      },
      {
        onSuccess: ({ _id }) => {
          dispatchToast({
            type: "success",
            content: "Seção vinculada com sucesso!",
          })

          handleBindSection(_id)
        },
        onError: (err: any) => {
          console.log(err)
          dispatchToast({
            type: "error",
            content: "Não foi possível vincular esta seção",
          })
        },
      },
    )
  }

  return (
    <CardAddSectionContainer>
      <Description variant="headline8">
        Adicionar seções ao template de documentos
      </Description>

      <Description variant="body3" style={{ margin: "24px 0" }}>
        Adicione uma seção existente ao seu template
      </Description>

      {stage === "initial" && (
        <Button
          style={{ width: "100%", marginBottom: 24 }}
          variant="primary"
          size="large"
          onClick={() => setStage("bind")}
        >
          <Icons name="IconPlus" />
          Vincular seção existente
        </Button>
      )}

      {stage === "bind" && (
        <React.Fragment>
          <SearchField
            label="Selecione uma seção existente"
            onSearchChange={(_, option) => setSectionSelected(option)}
            value={sectionSelected}
            loading={isLoading}
            options={sectionOptions}
          />

          <ActionWrapper>
            <CancelButton onClick={() => setStage("initial")}>
              Cancelar
            </CancelButton>

            <Button variant="primary" size="large" onClick={handleSubmit}>
              Vincular
            </Button>
          </ActionWrapper>
        </React.Fragment>
      )}

      <DividerWrapper>
        <DividerLine />

        <Description
          variant="body3"
          style={{ padding: "0px 12px", whiteSpace: "nowrap" }}
        >
          Ou crie novas seções personalizadas
        </Description>

        <DividerLine />
      </DividerWrapper>

      <Button
        style={{ width: "100%" }}
        variant="secondary"
        size="large"
        onClick={handleCreateNewSection}
      >
        <Icons name="IconPlus" />
        Criar nova seção
      </Button>
    </CardAddSectionContainer>
  )
}
