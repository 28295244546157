import { useParams, useSearchParams, Navigate } from "react-router-dom"
import { BasicInfoContent } from "./Steps/BasicInfoContent"
import { ProposedInfoContent } from "./Steps/ProposedInfoContent"

export const ManageModelProposedLetter = () => {
  const {
    flowId = "",
    mode = "",
    step = "",
  } = useParams<{
    flowId: string
    mode: "create" | "edit"
    step: "basic-info" | "proposed-info"
  }>()
  const [searchParams] = useSearchParams()
  const modelId = searchParams.get("modelId")

  if (!flowId) {
    return <Navigate to={"/flows/hiring"} />
  }

  if (!mode || !step || (mode === "edit" && !modelId)) {
    return <Navigate to={`/flows/settings/${flowId}/proposedLetter`} />
  }

  switch (step) {
    case "basic-info":
      return (
        <BasicInfoContent
          flowId={flowId}
          modelId={modelId as string}
          mode={mode}
        />
      )
    case "proposed-info":
      return (
        <ProposedInfoContent
          flowId={flowId}
          modelId={modelId as string}
          mode={mode}
        />
      )
    default:
      return <Navigate to={`/flows/settings/${flowId}/proposedLetter`} />
  }
}
