import styled from "styled-components"
import { motion } from "framer-motion"

export const Container = styled.div`
  background: #f6f3f6;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  position: relative;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 24px;
  align-items: center;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const AvatarCircle = styled.div<{
  logoPath?: string
  hasLogo?: boolean
}>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme, hasLogo }) =>
    !hasLogo ? theme.colors.primary : "transparent"};
  background-image: url(${({ logoPath }) => logoPath});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  font-size: 22px;
  text-transform: uppercase;
`

export const CompanyName = styled.p`
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #3c2d37;
  margin: 0 12px;
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`

export const MenuCircle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ebe5e9;
  padding: 10px;
`

export const ProgressBarContainer = styled.div`
  display: flex;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(74, 78, 87, 0.1);
`

export const ProgressBar = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100%;
  border-radius: 10px;
`

export const StepArea = styled.ul`
  margin-top: 10px;
  position: relative;
  padding-left: 45px;
  list-style: none;
  -webkit-tap-highlight-color: transparent;
  &:before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
  div:last-child {
    &:before {
      height: 0;
    }
  }
`

export const MenuContainer = styled.div<{
  active: boolean
  menuHeight: number
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ menuHeight }) => menuHeight}px;
  right: ${({ active }) => (active ? 0 : "-100%")};
  height: ${({ menuHeight }) => `calc(100vh - ${menuHeight}px)`};
  background-color: #ffffff;
  width: 100%;
  z-index: 998;
  transition: right 0.3s ease-in;
`
