import gql from "graphql-tag"

export const CREATE_UPDATE_CUSTOM_MODEL_CONTRACT = gql`
  mutation CreateUpdateCustomModelContract(
    $params: ICreateUpdateCustomModelContract
  ) {
    createUpdateCustomModelContract(params: $params) {
      _id
      name
      description
      tags
      status
      companyId
      candidateId
      modelContractId
      html
      active
      preview {
        key
        path
        value
        type
      }
      file {
        key
        path
        value
        type
      }
      verifiedTags {
        label
        tag
        value
      }
      candidateName
    }
  }
`
