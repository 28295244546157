import styled, { css } from "styled-components"

const ActiveMiniCard = css`
  border: 1px solid #fe2b8f;
  background: #ffe0ef;
  color: #f20d7a;
  cursor: pointer;
`

export const MiniCardContainer = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #53464f;
  font-weight: 600;

  padding: 13px 16px;
  margin: 6px 0;
  background: #FFFFF;
  border: 1px solid rgba(74, 78, 87, 0.1);
  border-radius: 6px;

  &:hover,
  &:focus {
    ${ActiveMiniCard}
  }

  ${(props) => (props.active ? ActiveMiniCard : "")}
`
