import styled from "styled-components"
import ArrowRight from "../../../assets/arrow_right.svg"
import {
  TextField,
  DatePicker,
  SelectField,
  Typography,
  LinkButton,
  TextArea,
} from "@flash-tecnologia/hros-web-ui-v2"
import { DeprecatedModal } from "../../../components"

const CloseRightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    display: none;
  }
`

const StyledArrow = styled(ArrowRight)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    flex-direction: column;
    overflow: auto;
  }
`

const RightContainer = styled.div`
  width: 100%;

  overflow: hidden auto;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    overflow: visible;
  }
`

const Title = styled.span`
  font-family: Cabinet Grotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 40px;
  color: #3b2e37;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const IconContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding: 16px;
  border: 1px solid #e8e3e6;
  border-radius: 50%;

  &:hover {
    opacity: 0.7;
  }
`

const CardContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;

  border: 1px solid #d1c7cc;
  border-radius: 8px 32px 8px 8px;
  margin-right: 24px;

  ${({ selected, theme }) =>
    selected
      ? `background-color: rgba(251, 42, 129, 0.1);border: 1px solid ${theme.colors.primary};filter: brightness(80%);`
      : ""}

  &:last-child {
    margin-right: 0px;
  }
`

const CardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`

const CardContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0px;
  }
`

const CardSubContentContainer = styled.div`
  display: flex;

  flex-direction: column;
`

const PreviewButton = styled.div`
  margin-top: 40px;
  margin-bottom: 45px;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 12px 44px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px 16px 4px 4px;
  color: #fb2a81;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
  }

  &:hover {
    color: #fff;
    background-color: #fb2a81;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`

const TagContainer = styled.div`
  display: flex;

  padding: 4px 12px;

  background: rgba(254, 43, 143, 0.18);
  border-radius: 24px;
  margin-top: 4px;
  margin: 10px;
`

const PerformanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px 4px 10px;

  border: 1px solid rgba(254, 43, 143, 0.18);
  border-radius: 4px;
`

const ConfirmButton = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 16px 24px;
  background: #f20d7a;
  border-radius: 12px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }
  &:hover {
    opacity: 0.5;
  }
`

const FormContentContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 48px 48px 0px 48px;
  text-align: left;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;

  color: #83727d;

  :last-child {
    margin-bottom: 0px;
  }
`

const ProposalFieldContainer = styled.div`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0px;
  }
`

const ToggleContainer = styled.div`
  border: 1px solid #e8e3e6;
  border-radius: 6px;
  padding: 12px 15px;
  margin-top: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`

const ProposalAcceptedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 48px auto;
  flex-direction: column;
  max-width: 426px;
  text-align: center;
`

const ProposalRefusedContainer = styled.div``

const RefusedReasonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  padding: 40px 44px;
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const StyledContainedButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;

  border: 1px solid #fe2b8f;
  border-radius: 12px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #fe2b8f;
  }
  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    margin-top: 20px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
  }
`

const StyledTextArea = styled.textarea`
  resize: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 120px;
  border: 1px solid #b9acb4;

  padding: 16px;
  margin-top: 24px;

  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    background: transparent;

    width: 6px;
    height: 48px;
  }

  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

const StyledTextArea2 = styled(TextArea)`
  z-index: 1;
`

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      border-radius: 16px;
      max-width: 95vw;
      max-height: 95vh;
      overflow: hidden;
    }
    .modal-content-area {
      padding: 0px;
      width: 95vw;
      height: 95vh;
    }
  }
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`

const StyledSelectField = styled(SelectField)``

const ResendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 24px;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    gap: 25px;
  }
`

export const RadioLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600;
`

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const RadioArea = styled.div`
  display: flex;
  margin-top: 24px;
  div:first-child {
    margin-right: 24px;
  }
`

export const StyledTextError = styled.p`
  margin: 6px 0 0 16px;
  font-size: 12px;
  color: var(--color-neutral-dark5);
  font-family: var(--font-family-secondary);
  font-weight: 600;
  display: flex;
  align-items: center;
  fill: transparent;

  svg {
    stroke: var(--color-feedback-error-pure);
    margin-right: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-feedback-error-pure) !important;
  }
`

export const StyledPreviewProgress = styled.div`
  width: 100%;
  height: 4px;
  background: #fe67af;
  border-radius: 12px;
  margin-top: 10px;

  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: wave;
  animation-timing-function: linear;
  background: linear-gradient(to right, #fe67af 50%, #da0b6d 80%);
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

export const UploadPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const UploadPreviewArea = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const UploadIconContainer = styled.div`
  margin-right: 7px;
  border-radius: 4px;
  display: flex;
`

export const UploadFileName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3b2e37;
  margin-right: 10px;
`

export const LineText = styled(Typography)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #83727d;

    :before,
    :after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid #ebe6e9;
    }

    :before {
      margin-right: 12px;
    }

    :after {
      margin-left: 12px;
    }
  }
`

export const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiPaper-root.MuiButtonBase-root {
      font-size: 14px;
      line-height: 16px;
  }
`

export {
  StyledTextArea,
  StyledTextArea2,
  ButtonContainer,
  StyledContainedButton,
  ToggleContainer,
  Container,
  RightContainer,
  Title,
  IconContainer,
  HeaderContainer,
  TagContainer,
  ContentContainer,
  FlexSpaceBetween,
  CloseRightContainer,
  CardContainer,
  CardHeaderContainer,
  CardContentContainer,
  CardSubContentContainer,
  PreviewButton,
  PerformanceContainer,
  ProposalFieldContainer,
  ConfirmButton,
  FormContentContainer,
  StyledArrow,
  ErrorContainer,
  ProposalAcceptedContainer,
  ProposalRefusedContainer,
  RefusedReasonContainer,
  SubContainer,
  StyledModal,
  StyledTextField,
  StyledDatePicker,
  StyledSelectField,
  ResendContainer,
}
