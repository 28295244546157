import React from "react"
import { useFormik } from "formik"
import { FormRef } from "../../Forms"
import * as yup from "yup"
import { CheckboxHugeField } from "../../components/CheckboxHugeField"
import dispatchToast from "../../../../utils/dispatchToast"
import { trpc } from "src/api/client"
import { NotificationsConfig } from "bff/src/types/hiring"
import { Flow } from "src/types"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

type FormData = {
  notifyLeaders: boolean
  notifyFollowers: boolean
  notifyMentions: boolean
}

const validationSchema = yup.object({
  notifyLeaders: yup.boolean(),
  notifyFollowers: yup.boolean(),
  notifyMentioned: yup.boolean(),
})

type CommentaryProps = {
  notificationConfig: NotificationsConfig
  flow: Flow
}

export const Commentary = React.forwardRef(
  (
    { notificationConfig, flow }: CommentaryProps,
    ref: React.Ref<FormRef<FormData>>,
  ) => {
    const navigate = useNavigate()
    const { mutateAsync: updateConfig } =
      trpc.notification.updateNotificationConfig.useMutation()

    const [t] = useTranslation("translations", {
      keyPrefix: "page.settings.editNotification.commentary",
    })

    const formik = useFormik<FormData>({
      initialValues: {
        notifyFollowers: notificationConfig.newComment?.notifyFollowers ?? true,
        notifyLeaders: notificationConfig.newComment?.notifyLeaders ?? true,
        notifyMentions: notificationConfig.newComment?.notifyMentions ?? true,
      },
      validationSchema,
      validate: (values) => {
        if (
          !values.notifyFollowers &&
          !values.notifyLeaders &&
          !values.notifyMentions
        ) {
          dispatchToast({
            content: t("errorAtLeastOne"),
            type: "error",
          })
          return { hasError: true }
        }
      },
      onSubmit: async (values) => {
        try {
          await updateConfig({
            notificationConfigId: notificationConfig._id,
            body: {
              newComment: {
                active: true,
                notifyFollowers: values.notifyFollowers,
                notifyLeaders: values.notifyLeaders,
                notifyMentions: values.notifyMentions,
              },
            },
          })
          dispatchToast({
            content: t("successUpdate"),
            type: "success",
          })
          navigate(`/flows/settings/${flow._id}/notification`)
        } catch (err: any) {
          dispatchToast({
            content: err.message,
            type: "error",
          })
        }
      },
    })

    React.useImperativeHandle(ref, () => formik)

    return (
      <>
        <CheckboxHugeField
          checked={formik.values.notifyLeaders}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyLeaders", value: checked },
            })
          }}
          title={t("notifyLeader")}
          description={t("notifyLeaderDescription")}
        />

        <CheckboxHugeField
          checked={formik.values.notifyFollowers}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyFollowers", value: checked },
            })
          }}
          title={t("notifyFollowers")}
          description={t("notifyFollowersDescription")}
        />

        <CheckboxHugeField
          checked={formik.values.notifyMentions}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyMentions", value: checked },
            })
          }}
          title={t("notifyMentions")}
          description={t("notifyMentionsDescription")}
        />
      </>
    )
  },
)
