import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 32px 40px;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
  margin-top: 40px;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`
export const ProgressWraper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0px;
`

const ContainerBar = styled.div<{ widthBar: number }>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ widthBar }) => widthBar}px;
  padding: 6px 0px;
  height: 8px;
  margin-right: 12px;
`

export const IconContainer = styled.div<{
  variantType: "default" | "error" | "success"
}>`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  background-color: ${({ theme, variantType }) =>
    variantType === "default"
      ? theme.colors.secondary[95]
      : variantType === "error"
        ? theme.colors.feedback.error[90]
        : theme.colors.feedback.success[90]};
`

export const IconAnimated = styled(Icons)<{
  variantType: "default" | "error" | "success"
}>`
  color: ${({ theme, variantType }) =>
    variantType === "default"
      ? theme.colors.secondary[40]
      : variantType === "error"
        ? theme.colors.feedback.error[40]
        : theme.colors.feedback.success[40]};
  animation: fullLoop linear 3s infinite;

  @keyframes fullLoop {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

const FilledProgressBar = styled.div<{
  variantType: "default" | "error" | "success"
  value: number
}>`
  background-color: ${({ variantType, theme }) =>
    variantType === "default"
      ? theme.colors.secondary[50]
      : variantType === "error"
        ? theme.colors.feedback.error[40]
        : theme.colors.feedback.success[40]};
  position: absolute;
  border-radius: 100px;
  width: calc(393px * ${({ value }) => value / 100});
  height: 8px;
  transition: width 0.6s;
`

export const Icon = styled(Icons)<{
  variantType: "validation" | "importing" | "error" | "success"
}>`
  color: ${({ theme, variantType }) =>
    ["validation", "importing"].includes(variantType)
      ? theme.colors.secondary[40]
      : variantType === "error"
        ? theme.colors.feedback.error[40]
        : theme.colors.feedback.success[40]};
`

const BackgroundProgressBar = styled.div<{
  variantType: "default" | "error" | "success"
}>`
  background-color: ${({ variantType, theme }) =>
    variantType === "default"
      ? theme.colors.secondary[90]
      : variantType === "error"
        ? theme.colors.feedback.error[90]
        : theme.colors.feedback.success[90]};
  position: absolute;
  border-radius: 100px;
  width: 393px;
  height: 8px;
`

const TextProgressBar = styled(Typography)<{
  variantType: "default" | "error" | "success"
}>`
  color: ${({ variantType, theme }) =>
    variantType === "default"
      ? theme.colors.secondary[50]
      : variantType === "error"
        ? theme.colors.feedback.error[40]
        : theme.colors.feedback.success[40]};
`

export {
  FilledProgressBar,
  BackgroundProgressBar,
  TextProgressBar,
  Container,
  ContainerBar,
}
