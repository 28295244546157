import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@apollo/client"

import {
  IconButton,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

import { Option } from "./styled"

import { DeleteModel } from "./DeleteModel"

import dispatchToast from "../../../../../../utils/dispatchToast"

import { DELETE_MODEL_CONTRACT } from "../../../../../../api/mutations/delete-model-contract"
import { DUPLICATE_MODEL_CONTRACT } from "../../../../../../api/mutations/duplicate-model-contract"

export const ModelOptions = ({ modelId, flowId }) => {
  const navigate = useNavigate()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [deleteModel, { loading: deleteLoading }] = useMutation(
    DELETE_MODEL_CONTRACT,
    {
      onCompleted: () => {
        setDeleteModalOpen(false)

        dispatchToast({
          type: "success",
          content: "Modelo deletado com sucesso!",
        })
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao excluir modelo.",
        })
      },
      refetchQueries: ["GetModelContractByCompany"],
    },
  )

  const [duplicateModel] = useMutation(DUPLICATE_MODEL_CONTRACT, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Modelo duplicado com sucesso!",
      })
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: "Erro ao duplicar modelo.",
      })
    },
    refetchQueries: ["GetModelContractByCompany"],
  })

  const options = useMemo(() => {
    const items = [
      {
        key: "preview",
        onClick: () => {
          window.open(
            `/flows/settings/previewContract?modelId=${modelId}`,
            "_blank",
          )
        },
        children: (
          <Option>
            <Icons name="IconFileSearch" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Pré-visualizar modelo
            </Typography>
          </Option>
        ),
      },
      {
        key: "edit",
        onClick: () => {
          navigate(
            `/flows/settings/${flowId}/contracts/edit/basic-info/${modelId}`,
          )
        },
        children: (
          <Option>
            <Icons name="IconPencil" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Editar modelo
            </Typography>
          </Option>
        ),
      },
      {
        key: "duplicate",
        onClick: () => {
          duplicateModel({
            variables: { params: { modelContractId: modelId } },
          })
        },
        children: (
          <Option>
            <Icons name="IconCopy" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Duplicar modelo
            </Typography>
          </Option>
        ),
      },
      // {
      //   key: "export",
      //   onClick: () => {},
      //   children: (
      //     <Option>
      //       <Icons name="IconDownload" fill="transparent" />
      //       <Typography variant="body3" style={{ fontWeight: 600 }}>
      //         Exportar modelo como PDF
      //       </Typography>
      //     </Option>
      //   ),
      // },
      {
        key: "delete",
        onClick: () => setDeleteModalOpen(true),
        children: (
          <Option>
            <Icons name="IconTrash" fill="transparent" color="#C96C01" />
            <Typography
              variant="body3"
              style={{ fontWeight: 600, color: "#C96C01" }}
            >
              Excluir modelo
            </Typography>
          </Option>
        ),
      },
    ]

    return items
  }, [modelId])

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </Menu>
      <DeleteModel
        isOpen={deleteModalOpen}
        loading={deleteLoading}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={() =>
          deleteModel({ variables: { params: { modelContractId: modelId } } })
        }
      />
    </>
  )
}
