import { gql } from "@apollo/client"
import { request } from "../client"
import { ListModelProposedLetter } from "../../types"

const GET_ALL_MODEL_PROPOSED_LETTER_BY_FLOW_ID = gql`
  query GetAllModelProposedLetterByFlowId($flowId: ID) {
    getAllModelProposedLetterByFlowId(flowId: $flowId) {
      _id
      name
      cardsAssociated
      status
      createdAt
    }
  }
`

export const getAllModelProposedLetterByFlowId = async (flowId: string) => {
  const { getAllModelProposedLetterByFlowId } = await request(
    GET_ALL_MODEL_PROPOSED_LETTER_BY_FLOW_ID,
    {
      flowId,
    },
  )
  return getAllModelProposedLetterByFlowId as ListModelProposedLetter[]
}
