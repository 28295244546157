import gql from "graphql-tag"
import { request } from "../client"
import { Contract, FlowSubcategory, Signature } from "../../types"

export const RESEND_SIGN_REQUEST = gql`
  mutation ResendSignRequest($params: ResendSignRequestInput) {
    resendSignRequest(params: $params) {
      _id
      companyId
      contractDuration
      flowCardId
      template {
        key
        path
        value
        type
      }
      signature {
        employeeId
        candidateId
        name
        type
        email
        token
        phone
        signedAt
        signatureFile
        status
        ip
        confirmationInfo
      }
      status
      contractOutput {
        key
        path
        value
        type
        version
      }
      lastContractOutput {
        key
        path
        value
        type
        version
      }
    }
  }
`

export const resendSignRequest = async (params: {
  cardId: string
  companyId: string
  contractId: string
  columnId: string
  subcategory?: FlowSubcategory
  signatureToReset: {
    type: string
    name: string
    email: string
  }
  signature: Signature[]
  category: "hiring" | "resignation"
}) => {
  const { resendSignRequest } = await request(RESEND_SIGN_REQUEST, { params })
  return resendSignRequest as Contract
}
