import gql from "graphql-tag"
import { request } from "../client"
import { CompanyAddress, Recipient } from "../../types"

export const GET_EMPLOYEE_CARD_ORDER = gql`
  query getDeliveryCardOrder($externalCardDeliveryId: ID!) {
    getDeliveryCardOrder(externalCardDeliveryId: $externalCardDeliveryId) {
      id
      address {
        street
        city
        state
        zipCode
        number
        complement
        reference
        neighborhood
      }
      recipient {
        name
        email
        phone
      }
    }
  }
`

export interface EmployeeCardOrder {
  id: string
  address: CompanyAddress | undefined
  recipient: Recipient | undefined
}

export const getDeliveryCardOrder = async (
  externalCardDeliveryId: string,
): Promise<EmployeeCardOrder> => {
  const { getDeliveryCardOrder } = await request(GET_EMPLOYEE_CARD_ORDER, {
    externalCardDeliveryId,
  })
  return getDeliveryCardOrder
}
