import { LinkButton, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  StyledModal,
  ContentContainer,
  Footer,
  IconContainer,
  StyledButton,
} from "./styled"

interface ModalProps {
  isOpen: boolean
  loading?: boolean
  onClose: () => void
  onConfirm: () => void
}

export const DeleteModel = ({
  isOpen,
  onClose,
  onConfirm,
  loading,
}: ModalProps) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer>
          <LinkButton
            variant="secondary"
            children="Cancelar"
            style={{ alignSelf: "center" }}
            onClick={onClose}
          />
          <StyledButton
            size="large"
            variant="primary"
            variantType="error"
            style={{ alignSelf: "center" }}
            loading={loading}
            disabled={loading}
            onClick={onConfirm}
          >
            Confirmar exclusão
            <Icons name="IconTrash" fill="none" size={40} />
          </StyledButton>
        </Footer>
      }
    >
      <ContentContainer>
        <IconContainer>
          <Icons
            name="IconAlertCircle"
            fill="none"
            size={40}
            style={{ color: "#C96C01" }}
          />
        </IconContainer>

        <Typography
          variant="body3"
          children="Atenção!"
          style={{ textAlign: "center", color: "#C96C01", fontWeight: 700 }}
        />
        <Typography
          variant="headline6"
          children="Tem certeza que deseja excluir o modelo de contrato?"
          style={{
            maxWidth: "421px",
            textAlign: "center",
            marginBottom: "16px",
          }}
        />
        <Typography
          variant="body3"
          children="Todas as informações relacionadas serão perdidas e essa ação não poderá ser desfeita."
          style={{ textAlign: "center", marginBottom: "14px" }}
        />
      </ContentContainer>
    </StyledModal>
  )
}
