import styled from "styled-components"
import ArrowRight from "../../../assets/arrow_right.svg"
import {
  Button,
  Typography,
  Radio,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { DeprecatedModal } from "../../../components"

export const CloseRightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    display: none;
  }
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

export const StyledArrow = styled(ArrowRight)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    flex-direction: column;
    overflow: auto;
  }
`

export const RightContainer = styled.div`
  width: 100%;

  overflow: hidden auto;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    overflow: visible;
  }
`

export const Title = styled.span`
  font-family: Cabinet Grotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #50494c;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const IconContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding: 16px;
  border: 1px solid #e8e3e6;
  border-radius: 50%;

  &:hover {
    opacity: 0.7;
  }
`

export const CardContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;

  border: 1px solid #d1c7cc;
  border-radius: 8px 32px 8px 8px;
  margin-right: 24px;

  ${({ selected, theme }) =>
    selected
      ? `background-color: rgba(251, 42, 129, 0.1);border: 1px solid ${theme.colors.primary};filter: brightness(80%);`
      : ""}

  &:last-child {
    margin-right: 0px;
  }
`

export const CardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`

export const CardContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0px;
  }
`

export const CardSubContentContainer = styled.div`
  display: flex;

  flex-direction: column;
`

export const PreviewButton = styled.div`
  margin-top: 40px;
  margin-bottom: 45px;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 12px 44px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px 16px 4px 4px;
  color: #fb2a81;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
  }

  &:hover {
    color: #fff;
    background-color: #fb2a81;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`

export const TagContainer = styled.div`
  display: flex;

  padding: 4px 12px;

  background: rgba(254, 43, 143, 0.18);
  border-radius: 24px;
  margin-top: 4px;
  margin: 10px;
`

export const PerformanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px 4px 10px;

  border: 1px solid rgba(254, 43, 143, 0.18);
  border-radius: 4px;
`

export const ConfirmButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  background-color: #fb2a81;

  border-radius: 4px 16px 4px 4px;
  margin-top: 80px;
  margin-bottom: 50px;

  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    pointer-events: none;
  }

  p {
    color: #fff;
    font-weight: 700;
  }

  &:hover {
    opacity: 0.5;
  }
`

export const FormContentContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 48px 48px 0px 48px;
  text-align: left;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;

  color: #83727d;

  :last-child {
    margin-bottom: 0px;
  }
`

export const ProposalFieldContainer = styled.div`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0px;
  }
`

export const ToggleContainer = styled.div`
  border: 1px solid #e8e3e6;
  border-radius: 6px;
  padding: 12px 15px;
  margin-top: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`

export const ProposalAcceptedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 48px auto;
  flex-direction: column;
  max-width: 426px;
  text-align: center;
`

export const ProposalRefusedContainer = styled.div``

export const RefusedReasonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  padding: 40px 44px;
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
`

export const ButtonToArchived = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  cursor: pointer;
  border: 1px solid #fe2b8f;
  border-radius: 12px;
  margin-top: 24px;
  margin-bottom: 24px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #fe2b8f;
  }
  &:hover {
    opacity: 0.5;
  }
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #f8f6f8;
  border-radius: 50%;
  margin-left: 7px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

export const CollapseContainer = styled.div<{ expanded: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid #e8e3e6;
  border-radius: 8px;
  margin-top: 16px;

  ${({ expanded }) =>
    expanded
      ? "border-bottom: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
      : ""}

  &:hover {
    opacity: 0.7;
  }
`

export const StyledButton = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-top: 40px;

  padding: 16px 24px;
  background: #f20d7a;
  border-radius: 12px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }
  &:hover {
    opacity: 0.5;
  }
`

export const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      border-radius: 16px;
      max-width: 95vw;
      max-height: 95vh;
      overflow: hidden;
    }
    .modal-content-area {
      padding: 0px;
      width: 95vw;
      height: 95vh;
    }
  }
`

export const StyledPreviewProgress = styled.div`
  width: 100%;
  height: 4px;
  background: #fe67af;
  border-radius: 12px;
  margin-top: 10px;

  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: wave;
  animation-timing-function: linear;
  background: linear-gradient(to right, #fe67af 50%, #da0b6d 80%);
`

export const Divider = styled.div`
  border: 1px solid #ebe6e9;
  height: 1px;
  width: 100%;
  margin: 40px 0;
`

export const Section = styled.div``

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiPaper-root.MuiButtonBase-root {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

export const SectionTitle = styled(Typography)`
  font-weight: bold !important;
  font-size: 20px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.neutral[40]};
`

export const SectionSubTitle = styled(Typography)`
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const SectionSubTitleAccordion = styled(Typography)`
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const RadioLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600;
`

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const RadioArea = styled.div`
  display: flex;
  margin-bottom: 31px;
  div:first-child {
    margin-right: 24px;
  }
`

export const StyledAccordion = styled(Accordion)`
  padding: 16px 40px;
  margin-bottom: 24px;
  .MuiAccordionSummary-root {
    padding: 0;
  }

  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
    border-radius: 12px;
    box-shadow: none;
    &:before {
      background: none;
    }
  }
`

export const Summary = styled(AccordionSummary)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Details = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    cursor: pointer;
    padding: 0;
  }
`

export const FormButton = styled(Button)`
  width: 100%;
`

export const UploadPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const UploadPreviewArea = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const UploadIconContainer = styled.div`
  margin-right: 7px;
  border-radius: 4px;
  display: flex;
`

export const UploadFileName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3b2e37;
  margin-right: 10px;
`

export const StyledRadio = styled(Radio)``
