import gql from "graphql-tag"
import { request } from "../client"

export const GET_FLOW_SUBCATEGORY_BY_FLOW_ID = gql`
  query GetFlowById($flowId: ID!) {
    getFlowById(flowId: $flowId) {
      _id
      category
      subcategory
    }
  }
`
export const getFlowSubcategoryByFlowId = async ({
  flowId,
}: {
  flowId: string
}) => {
  const { getFlowById } = await request(GET_FLOW_SUBCATEGORY_BY_FLOW_ID, {
    flowId,
  })
  return getFlowById as { _id: string; category: string; subcategory: string }
}
