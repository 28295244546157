import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"
import { DeprecatedModal } from "../../../components"

export const StyledModal = styled(DeprecatedModal)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 645px;
      max-height: 95vh;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 40px 112px;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  width: 64px;
  height: 64px;

  background-color: ${({ theme }) => theme.colors.feedback.error[90]};
  border-radius: 200px;
  margin-bottom: 16px;
`

export const StyledButton = styled(Button)``
