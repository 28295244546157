import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

import styled from "styled-components"

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-top: 8px;
`
const ErrorContainer = styled.div`
  border-radius: 12px;
  border: 1px solid #fec380;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background-color: #ffecd6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`

export { Title, Description, ErrorContainer, IconContainer }
