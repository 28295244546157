import styled from "styled-components"

import { TextEditor, Typography } from "@flash-tecnologia/hros-web-ui-v2"

const Container = styled.form`
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledEditor = styled(TextEditor)<{ error?: boolean }>`
  & .quill .ql-editor {
    min-height: 300px;
    max-height: 300px;
    overflow: auto;
  }

  ${({ error }) =>
    error &&
    `
     & #custom-ql-toolbar {
      border: 1px solid var(--color-feedback-error-pure);
     }

     & .quill .ql-container {
      border: 1px solid var(--color-feedback-error-pure) !important;
      border-top: none !important;
      background: var(--color-feedback-error-light1);
    }
  `}
`

const HelperText = styled(Typography)`
  && {
    margin: 6px 0px 0px 16px;
    color: var(--color-neutral-dark5);
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`

export {
  Container,
  TitleContainer,
  DescriptionContainer,
  StyledEditor,
  HelperText,
}
