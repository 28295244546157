import gql from "graphql-tag"
import { request } from "../client"
import {
  Contract,
  ContractOutput,
  DumbSignInfoParam,
  S3File,
  SignInfoParam,
} from "../../types"

type Params = {
  cardId: string
  companyId: string
  columnId: string
  template: S3File[]
  contractOutput: ContractOutput[]
  employee: SignInfoParam
  companyEmployee: SignInfoParam
  witness?: DumbSignInfoParam[]
}

export const RESIGNATION_SIGN_REQUEST = gql`
  mutation SendResignationSignRequest(
    $params: SendResignationSignRequestInput
  ) {
    sendResignationSignRequest(params: $params) {
      _id
      companyId
      contractDuration
      flowCardId
      template {
        key
        path
        value
        type
      }
      signature {
        employeeId
        name
        type
        email
        token
        phone
        signedAt
        signatureFile
        status
        ip
        confirmationInfo
      }
      status
      contractOutput {
        key
        path
        value
        type
        version
      }
      lastContractOutput {
        key
        path
        value
        type
        version
      }
    }
  }
`

export const sendResignationSignRequest = async (params: Params) => {
  const { sendResignationSignRequest } = await request(
    RESIGNATION_SIGN_REQUEST,
    {
      params,
    },
  )
  return sendResignationSignRequest as Contract
}
