import {
  ComponentEnum,
  ConfirmationData,
  ConfirmationDependent,
  FieldType,
  FieldValue,
  Flow,
  HiringCard,
} from "../../../types"
import { AccordionWrapper, Container, FieldWrapper } from "./styles"
import {
  ConfirmationModal,
  ValidationAccordion,
  WizardCardLink,
} from "../../../components"
import { useMemo, useState } from "react"
import { Button, Loader, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { RenderField } from "./RenderField"
import { api } from "../../../api"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import dispatchToast from "../../../utils/dispatchToast"
import { RenderSkeleton } from "./Skeleton"
import { EmptyState } from "./EmptyState"
import { createSegmentTrack } from "../../../utils"

const isAllDocumentsValidated = (data: ConfirmationData) => {
  const isAllCandidateDocValidated = data.documentFields.every(
    (doc) => doc.validation?.status !== "waiting",
  )

  let isAllDependentsDocValidated = true
  if (data.dependents && data.dependents.length > 0) {
    isAllDependentsDocValidated = data.dependents.every((dep) =>
      dep.documents.every((doc) => doc.validation?.status !== "waiting"),
    )
  }

  return isAllCandidateDocValidated && isAllDependentsDocValidated
}

const isAllDocumentsApproved = (data: ConfirmationData) => {
  const hasAnyDeclinedDocument = data.documentFields.some(
    (doc) => doc.validation?.status === "declined",
  )

  const filteredRequiredDocumentFields = data.documentFields.filter((doc) =>
    doc.fields?.every((f) => f.fieldRule?.required),
  )

  const isAllCandidateDocApproved = filteredRequiredDocumentFields.every(
    (doc) => doc.validation?.status === "approved",
  )

  const hasAnyDependentDeclinedDocument = data.dependents?.some((dep) => {
    return dep.documents.some((doc) => doc.validation?.status === "declined")
  })

  const isAllDependentsDocApproved = data.dependents?.every((dep) => {
    const filteredRequiredDocumentFields = dep.documents.filter((doc) =>
      doc.fields?.every((f) => f.fieldRule?.required),
    )
    return filteredRequiredDocumentFields.every(
      (doc) => doc.validation?.status === "approved",
    )
  })

  return (
    isAllCandidateDocApproved &&
    isAllDependentsDocApproved &&
    !hasAnyDeclinedDocument &&
    !hasAnyDependentDeclinedDocument
  )
}

const GenericContent = ({ card, flow }: { card: HiringCard; flow: Flow }) => {
  const [confirmationData, setConfirmationData] = useState<ConfirmationData>()
  const [confirmationSendModal, setConfirmationSendModal] =
    useState<boolean>(false)

  const queryClient = useQueryClient()

  const { isInitialLoading: isLoading, isRefetching } = useQuery(
    ["hiring-card-confirmation", card._id],
    () =>
      api.query.hiring.card.getConfirmationData({
        candidateId: card.candidateId,
        formConfigId: card.formConfigId,
        extensionId: card.formExtensionId,
      }),
    {
      enabled: !!(card.formConfigId && card.candidateId),
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => setConfirmationData(data),
    },
  )

  const { mutateAsync: candidateValidateDocument } = useMutation(
    api.mutation.hiring.candidateValidateDocument,
    {
      onMutate: async ({ sectionId, status, statusComment }) => {
        const oldConfirmationData = { ...confirmationData }

        setConfirmationData((oldData: any) => {
          return {
            ...oldData,
            documentFields: oldData.documentFields.map((doc) => {
              if (doc._id !== sectionId) return doc

              return {
                ...doc,
                validation: {
                  sectionId: sectionId || doc.validation?.sectionId,
                  status: status || doc.validation?.status,
                  statusComment: statusComment || undefined,
                },
              }
            }),
          }
        })

        return { oldConfirmationData }
      },
      onError: (_error, _var, context) => {
        setConfirmationData(context?.oldConfirmationData as any)
      },
    },
  )

  const { mutateAsync: dependentValidateDocument } = useMutation(
    api.mutation.hiring.dependentValidateDocument,
    {
      onMutate: async ({ dependentId, sectionId, status, statusComment }) => {
        const oldConfirmationData = { ...confirmationData }

        setConfirmationData((oldData: any) => {
          return {
            ...oldData,
            dependents: oldData.dependents.map((dependent) => {
              if (dependent._id !== dependentId) return dependent

              return {
                ...dependent,
                documents: dependent.documents.map((doc) => {
                  if (doc._id !== sectionId) return doc

                  return {
                    ...doc,
                    validation: {
                      sectionId,
                      status,
                      statusComment,
                    },
                  }
                }),
              }
            }),
          }
        })

        return { oldConfirmationData }
      },
      onError: (_error, _var, context) => {
        setConfirmationData(context?.oldConfirmationData as any)
      },
    },
  )

  const { mutateAsync: resendDocumentsRequest, isLoading: isSubmitLoading } =
    useMutation(api.mutation.hiring.resendDocumentsRequest)

  const findEmailLink = useMemo(
    () =>
      card.emailLink?.find(
        (e) =>
          e.columnId === card.columnId && e.name === "resendDocumentsRequest",
      ),
    [card.emailLink],
  )

  const renderCandidateBasicData = () => {
    return (
      <ValidationAccordion
        title={"Dados básicos"}
        description={"Preencha as informações abaixo com seus dados pessoais."}
        required={true}
      >
        <FieldWrapper>
          {confirmationData?.candidateFields.map((field, idx) => (
            <RenderField key={idx} field={field} />
          ))}
        </FieldWrapper>
      </ValidationAccordion>
    )
  }

  const renderCandidateDocuments = () => {
    return confirmationData?.documentFields?.map((doc, idx) => {
      const status = doc.validation ? doc.validation.status : "notTouched"
      const isRequired = doc.fields.every(
        (field) => field.fieldRule?.required || false,
      )

      const handleValidate = async (
        status: "approved" | "declined",
        statusComment: string,
      ) => {
        candidateValidateDocument({
          candidateId: card.candidateId,
          sectionId: doc._id,
          status,
          statusComment,
        }).catch(() => {
          dispatchToast({
            type: "error",
            content: "Houve um problema ao atualizar o documento",
          })
        })
      }

      return (
        <ValidationAccordion
          key={idx}
          title={doc.title}
          description={doc.description}
          required={isRequired}
          status={status}
          onAccept={() => handleValidate("approved", "")}
          onRefuse={(refusedReason: string) =>
            handleValidate("declined", refusedReason)
          }
        >
          <FieldWrapper>
            {doc.fields.map((field, idx) => (
              <RenderField key={idx} field={field} />
            ))}
          </FieldWrapper>
        </ValidationAccordion>
      )
    })
  }

  const renderDependentMandatoryField = (dependent: ConfirmationDependent) => {
    const mandatoryFields: FieldValue[] = [
      {
        candidateId: dependent.candidateId,
        component: ComponentEnum.ShortText,
        enabled: true,
        fieldId: "kinship",
        fieldType: FieldType.Text,
        value: dependent.kinship,
        placeholder: "Grau de parentesco",
        sectionId: "basic-data",
        type: "base",
      },
      {
        candidateId: dependent.candidateId,
        component: ComponentEnum.ShortText,
        enabled: true,
        fieldId: "name",
        fieldType: FieldType.Text,
        value: dependent.name,
        placeholder: "Nome do dependente",
        sectionId: "basic-data",
        type: "base",
      },
      {
        candidateId: dependent.candidateId,
        component: ComponentEnum.DatePicker,
        enabled: true,
        fieldId: "birthDate",
        fieldType: FieldType.Text,
        value: dependent.birthDate,
        placeholder: "Data de nascimento",
        sectionId: "basic-data",
        type: "base",
      },
    ]

    return (
      <ValidationAccordion title={"Dados básicos"} required={true}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 24,
          }}
        >
          {mandatoryFields.map((field, idx) => (
            <RenderField key={idx} field={field} />
          ))}
        </div>
      </ValidationAccordion>
    )
  }

  const renderDependents = () => {
    return confirmationData?.dependents?.map((dependent, idx) => {
      return (
        <ValidationAccordion
          key={idx}
          title={dependent.name}
          description={
            "Preencha as informações abaixo de acordo com os dados do dependente."
          }
          required={false}
        >
          <FieldWrapper>
            {renderDependentMandatoryField(dependent)}

            {dependent.documents?.map((doc, idx) => {
              const status = doc.validation
                ? doc.validation.status
                : "notTouched"
              const isRequired = doc.fields.every(
                (field) => field.fieldRule?.required || false,
              )

              const handleValidate = async (
                status: "approved" | "declined",
                statusComment: string,
              ) => {
                dependentValidateDocument({
                  dependentId: dependent._id,
                  sectionId: doc._id,
                  status,
                  statusComment,
                }).catch(() => {
                  dispatchToast({
                    type: "error",
                    content: "Houve um problema ao atualizar o documento",
                  })
                })
              }

              return (
                <ValidationAccordion
                  key={idx}
                  title={doc.title}
                  description={doc.description}
                  required={isRequired}
                  status={status}
                  onAccept={() => handleValidate("approved", "")}
                  onRefuse={(refusedReason: string) =>
                    handleValidate("declined", refusedReason)
                  }
                >
                  <FieldWrapper>
                    {doc.fields.map((field, idx) => (
                      <RenderField key={idx} field={field} />
                    ))}
                  </FieldWrapper>
                </ValidationAccordion>
              )
            })}
          </FieldWrapper>
        </ValidationAccordion>
      )
    })
  }

  const handleSubmit = async () => {
    const isAllApproved = isAllDocumentsApproved(confirmationData as any)
    const track = isAllApproved
      ? `company_hiring_${flow.subcategory}_approveddocuments_next_clicked`
      : `company_hiring_${flow.subcategory}_resenddocumentsrequest_clicked`

    createSegmentTrack({
      track,
    })

    await resendDocumentsRequest(
      {
        flowCardId: card._id,
        columnId: card.columnId,
        category: "hiring",
        subcategory: flow.subcategory,
        metadata: card.metadata,
        documentStatus: isAllApproved ? "approved" : "disapproved",
        disapprovedReason: "",
        candidateId: card.candidateId,
        version: card.version,
      },
      {
        onSuccess: () => {
          const message = isAllApproved
            ? "Documentos validado com sucesso!"
            : "Solicitação reenviada com sucesso!"

          dispatchToast({
            content: message,
            type: "success",
          })
          queryClient.invalidateQueries(["hiring-card", card._id])
        },
        onError: () => {
          dispatchToast({
            content: "Houve um erro ao finalizar a analise de documentos",
            type: "error",
          })
        },
      },
    )
  }

  if (!card.formConfigId) return <EmptyState card={card} flow={flow} />

  return (
    <Container>
      {findEmailLink && (
        <WizardCardLink multiplesEmailLinks={[{ emailLink: findEmailLink }]} />
      )}

      {!confirmationData || isRefetching || isLoading ? (
        <RenderSkeleton />
      ) : (
        <>
          <div style={{ marginBottom: 40 }}>
            <Typography
              variant="headline8"
              style={{
                color: "#53464F",
              }}
            >
              Documentos da pessoa
            </Typography>

            <Typography
              variant="body4"
              style={{
                color: "#83727D",
              }}
            >
              Revise e valide os documentos solicitados à pessoa.
            </Typography>
          </div>

          <AccordionWrapper>
            {renderCandidateBasicData()}
            {renderCandidateDocuments()}
          </AccordionWrapper>

          {confirmationData?.dependents || []?.length > 0 ? (
            <>
              <div style={{ marginBottom: 40 }}>
                <Typography
                  variant="headline8"
                  style={{
                    color: "#53464F",
                  }}
                >
                  Documentos de dependentes
                </Typography>

                <Typography
                  variant="body4"
                  style={{
                    color: "#83727D",
                  }}
                >
                  Podem ser considerados dependentes cônjuges ou
                  companheiros(as), filhos não emancipados de qualquer condição,
                  menores de vinte e um anos ou pessoas com deficiência.
                </Typography>
              </div>

              <AccordionWrapper>{renderDependents()}</AccordionWrapper>
            </>
          ) : (
            <></>
          )}

          <div
            style={{
              display: "flex",
              flex: "1",
            }}
          >
            <Button
              size="large"
              variant="primary"
              disabled={
                isSubmitLoading || !isAllDocumentsValidated(confirmationData)
              }
              onClick={() => setConfirmationSendModal(true)}
              style={{ width: "100%" }}
            >
              {isSubmitLoading ? (
                <Loader size="extraSmall" variant="secondary" />
              ) : (
                <span>Finalizar validação</span>
              )}
            </Button>

            <ConfirmationModal
              variant="primary"
              isOpen={confirmationSendModal}
              onClose={() => setConfirmationSendModal(false)}
              title="Tem certeza que deseja finalizar a validação?"
              description="Verifique se todas as informações foram preenchidas corretamente."
              confirmButton={{
                onClick: () => handleSubmit(),
                isLoading: isSubmitLoading,
                text: "Prosseguir e finalizar",
                icon: "IconArrowRight",
              }}
            />
          </div>
        </>
      )}
    </Container>
  )
}

export default GenericContent
