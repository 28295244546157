import { PageTemplate } from "../../../components"
import { SelectPeopleContent } from "../../../components/screen/SelectPeople"

const SelectPeople = () => {
  const steps = ["Desligamento", "Selecionar pessoa"]

  return (
    <PageTemplate
      history={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Selecionar pessoa",
          href: `/flows/selectPeople`,
        },
      ]}
    >
      <SelectPeopleContent steps={steps} currentStep={1} />
    </PageTemplate>
  )
}

export default SelectPeople
