import { Dialog } from "@mui/material"
import styled from "styled-components"

interface FocusedFlowProps {
  children: React.ReactNode
}

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const FocusedFlow = ({ children }: FocusedFlowProps) => {
  return (
    <Dialog open={true} keepMounted fullScreen transitionDuration={0}>
      <Container>{children}</Container>
    </Dialog>
  )
}
