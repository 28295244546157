import React from "react"
import { NotificationFeatureModal } from "../../common"
import useLocalStorage from "../../../hooks/useLocalStorage"
import { FeatureController, FeatureNotification, features } from "./features"
import { Outlet } from "react-router-dom"

export const FeaturesNotification = () => {
  const [featuresController, setFeaturesController] = useLocalStorage<
    FeatureController[]
  >("features-notification", [])

  React.useEffect(() => {
    const featuresToShow = features.filter((feature) => feature.isLive)
    if (featuresToShow?.length === 0) return

    let featuresToBeAdd: FeatureNotification[] = featuresToShow
    if (featuresController?.length > 0) {
      featuresToBeAdd = featuresToShow.reduce<FeatureNotification[]>((prev, curr) => {
        const foundIndex = featuresController?.findIndex(
          (controller) => controller.identifier === curr.identifier,
        )
        if (foundIndex === -1) return prev ? [...prev, curr] : [curr]
        else {
          return prev
        }
      }, [])
    }

    if (!featuresToBeAdd) return
    setFeaturesController((prev) => {
      const featuresController: FeatureController[] = featuresToBeAdd?.map(
        (feature) => ({
          identifier: feature.identifier,
          shouldShow: true,
        }),
      )
      return [...prev, ...featuresController]
    })
  }, [])

  const handleCloseFeature = React.useCallback(() => {
    setFeaturesController((prev) => {
      const copyPrev = [...prev]
      const showingFeatures = featuresController.filter(
        (feature) => feature.shouldShow,
      )
      if (showingFeatures?.length === 0) return []
      for (const showingFeature of showingFeatures) {
        const featureIndex = featuresController.findIndex(
          (feature) => feature.identifier === showingFeature.identifier,
        )
        if (featureIndex === -1) return []
        copyPrev.splice(featureIndex, 1, {
          ...prev[featureIndex],
          shouldShow: false,
        })
      }

      return copyPrev
    })
  }, [featuresController])

  const data = React.useMemo(() => {
    const featureControllers = featuresController.filter(
      (feature) => feature.shouldShow,
    )
    if (featureControllers?.length === 0) return null

    const featuresIdentifiers = featureControllers?.map(
      (feature) => feature.identifier,
    )
    const filteredFilters = features.filter((feature) =>
      featuresIdentifiers.includes(feature.identifier),
    )
    if (filteredFilters?.length === 0) return null

    return filteredFilters
  }, [featuresController])

  if (!data) return <Outlet />

  return (
    <>
      <Outlet />

      <NotificationFeatureModal
        features={data}
        handleClose={handleCloseFeature}
      />
    </>
  )
}
