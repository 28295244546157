import { gql } from "graphql-request"
import { request } from "../client"

const CREATE_FORMCONFIG = gql`
  mutation CreateFormConfig($params: CreateFormConfigInput) {
    createFormConfig(params: $params) {
      _id
    }
  }
`

export const createFormConfig = async (params: {
  name: string
  companyId: string
}) => {
  const { createFormConfig } = await request(CREATE_FORMCONFIG, { params })
  return createFormConfig
}
