import gql from "graphql-tag"
import { request } from "../client"
import { CompanyFlowList, FlowCategory } from "../../types"

const GET_FLOW_BY_COMPANY = gql`
  query GetFlowByCompany($params: IFlowByCompany) {
    getFlowByCompany(params: $params) {
      _id
      name
      category
      subcategory
      setupId
    }
  }
`

export const getFlowByCompany = async ({
  companyId,
  category,
}: {
  companyId: string
  category: FlowCategory
}) => {
  const { getFlowByCompany: data } = await request(GET_FLOW_BY_COMPANY, {
    params: { companyId, category },
  })
  return data as CompanyFlowList[]
}
