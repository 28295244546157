import styled, { css } from "styled-components"
import { IconButton, Icons } from "@flash-tecnologia/hros-web-ui-v2"

export const Container = styled.div<{ disabled: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 12px;

  padding: 24px 40px;
  margin-bottom: 24px;

  ${({ disabled }) =>
    disabled
      ? css`
          background: #f8f6f8 !important;

          ${Badge} {
            color: #b9acb4 !important;
          }
          ${Icon} {
            color: #b9acb4 !important;
          }
          ${ToggleText} {
            color: #b9acb4 !important;
          }
        `
      : null}
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }
`

export const Icon = styled(Icons)`
  color: #6b5b66;
`

export const Badge = styled.div`
  color: #1d161b;
  font-weight: 600;
  font-size: 12px;
  margin: 0 10px;
  padding: 4px 12px;
  background: #ebe6e9;
  border-radius: 24px;
`

export const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const ActionWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px;
`

export const ToggleText = styled.div`
  color: #6b5b66;
  weight: 600;
  font-size: 16px;
  margin: 0 10px;
`

export const ToggleIconButton = styled(IconButton)<{ active: boolean }>`
  &.MuiButtonBase-root {
    ${({ active }) =>
      !active
        ? css`
            background-color: #ffffff !important;
          `
        : null}
  }
`
