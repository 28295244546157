import { useNavigate, useParams } from "react-router-dom"
import dispatchToast from "../../../../../utils/dispatchToast"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../../../api"
import { Dialog, Grid } from "@mui/material"
import {
  InnerContent,
  ItemArea,
  ItemIcon,
  ItemText,
  ItemTitle,
  MainContainer,
  NavbarContainer,
  OuterContent,
  StyledPOneLine,
  UploadFileName,
  UploadIconContainer,
  UploadPreview,
  UploadPreviewArea,
  Wrapper,
} from "./styles"
import { Disclaimer, Logo } from "../../../../../components"
import {
  IconButton,
  IconTypes,
  Icons,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { ReactNode } from "react"
import { formatCurrency } from "../../../../../utils"
import { S3File } from "../../../../../types"

const workloadOptions = {
  ["twenty-hours-week"]: "20 horas semanais",
  ["thiry-hours-week"]: "30 horas semanais",
  ["forty-hours-week"]: "40 horas semanais",
}

const workshipOptions = {
  ["morning"]: "Manhã",
  ["afternoon"]: "Tarde",
}

const DeprecatedUploadPreview = ({ file }: { file: S3File }) => {
  const lastIndexName = file.key ? file.key.lastIndexOf("/") : ""
  const nameOfArchive =
    file.key && lastIndexName
      ? file.key.substring(lastIndexName + 1)
      : "Nome não identificado"

  return (
    <UploadPreview>
      <UploadPreviewArea>
        <UploadIconContainer>
          <Icons name={"IconFile"} style={{ fill: "transparent" }} />
        </UploadIconContainer>
        <UploadFileName>
          <StyledPOneLine numberOfLines={1}>{nameOfArchive}</StyledPOneLine>
        </UploadFileName>
      </UploadPreviewArea>

      <div style={{ display: "flex" }}>
        <IconButton
          size="medium"
          variant="filled"
          icon="IconDownload"
          onClick={async () => {
            try {
              if (!file.value) throw new Error("Arquivo não encontrado")
              const readFile = await fetch(file.value)
              const fileToArrayBuffer = await readFile.arrayBuffer()
              const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
              const a = document.createElement("a")
              a.href = res
              a.download = nameOfArchive
              a.click()
            } catch (error) {
              console.error(error)
              dispatchToast({
                content: "Houve um erro ao baixar o arquivo",
                type: "error",
              })
            }
          }}
          style={{ marginLeft: "10px" }}
        />
      </div>
    </UploadPreview>
  )
}

const Item = ({
  icon,
  title,
  children,
}: {
  icon: IconTypes
  title: string
  children?: ReactNode
}) => {
  return (
    <ItemArea>
      <ItemIcon>
        <Icons
          name={icon}
          fill={"transparent"}
          style={{ width: "24px", height: "24px" }}
        />
      </ItemIcon>
      <div>
        <ItemTitle>{title}</ItemTitle>
        {children}
      </div>
    </ItemArea>
  )
}

export const PreviewModelContract = () => {
  const { flowId = '', modelId = '' } = useParams<{
    flowId: string
    modelId: string
  }>()

  const navigate = useNavigate()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.settings.proposedLetterModel.preview",
  })

  const { data: flow, isInitialLoading: isFlowLoading } = useQuery(
    ["flows", "no-cards", flowId],
    () => api.query.hiring.flow.getOnlyFlowById({ flowId }),
    {
      enabled: !!flowId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.log(err)
        dispatchToast({
          content: "Houve um erro ao buscar dados do fluxo",
          type: "error",
        })
        navigate(`/flows/hiring`)
      },
    },
  )

  const { data: modelProposedLetter, isInitialLoading: isModelProposedLetterLoading } =
    useQuery(
      ["model-proposed-letter", modelId],
      () => api.query.hiring.modelProposedLetter.getById(modelId),
      {
        enabled: !!modelId,
        onError: (err) => {
          console.log(err)
          dispatchToast({
            content: "Houve um erro ao buscar o modelo de carta proposta",
            type: "error",
          })
          navigate(`/flows/settings/${flowId}/proposedLetter`)
        },
      },
    )

  const isLoading =
    !flow ||
    !modelProposedLetter ||
    isFlowLoading ||
    isModelProposedLetterLoading

  return (
    <Dialog open={true} keepMounted fullScreen transitionDuration={0}>
      <MainContainer>
        <NavbarContainer>
          <Logo />
          <Disclaimer />
        </NavbarContainer>

        <OuterContent>
          <InnerContent>
            <Grid container spacing={2}>
              <Grid item sm={12} md={5} lg={4}>
                <Grid item sm={12} md={10}>
                  <Typography variant="headline7" variantColor="#F20D7A">
                    {t("title")}
                  </Typography>
                  <Typography
                    variant="body3"
                    variantColor="#83727D"
                    style={{ marginTop: 22 }}
                  >
                    {t("description")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                md={7}
                lg={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 40,
                }}
              >
                {isLoading ? (
                  <>
                    <Skeleton variant="rectangular" height={300} />
                    <Skeleton variant="rectangular" height={300} />
                    <Skeleton variant="rectangular" height={300} />
                  </>
                ) : (
                  <>
                    {modelProposedLetter.proposedLetter?.aboutCompany?.length ? (
                      <Wrapper>
                        <Item
                          icon="IconPresentation"
                          title={t("aboutCompanyLabel")}
                        />

                        {modelProposedLetter.proposedLetter?.aboutCompany.map(
                          (file, idx) => (
                            <DeprecatedUploadPreview key={idx} file={file} />
                          ),
                        )}
                      </Wrapper>
                    ) : (
                      <></>
                    )}

                    <Wrapper>
                      {modelProposedLetter.proposedLetter?.role?.name ? (
                        <Item icon="IconIdBadge2" title={t("roleLabel")}>
                          <ItemText>
                            {modelProposedLetter.proposedLetter?.role.name}
                          </ItemText>
                        </Item>
                      ) : (
                        <></>
                      )}

                      {modelProposedLetter.proposedLetter?.department?.name ? (
                        <Item icon="IconBriefcase" title={t("departmentLabel")}>
                          <ItemText>
                            {modelProposedLetter.proposedLetter?.department.name}
                          </ItemText>
                        </Item>
                      ) : (
                        <></>
                      )}

                      {modelProposedLetter.proposedLetter?.assignments ? (
                        <Item
                          icon="IconFileDescription"
                          title={t("assignmentsLabel")}
                        >
                          <ItemText>
                            {modelProposedLetter.proposedLetter?.assignments}
                          </ItemText>
                        </Item>
                      ) : (
                        <></>
                      )}

                      {flow?.subcategory ? (
                        <Item icon="IconLink" title={t("flowTypeLabel")}>
                          <ItemText>
                            {flow.subcategory === "internship"
                              ? "Estágio"
                              : flow.subcategory.toUpperCase()}
                          </ItemText>
                        </Item>
                      ) : (
                        <></>
                      )}

                      {modelProposedLetter.proposedLetter?.salary ? (
                        <Item icon="IconCoin" title={t("salaryLabel")}>
                          <ItemText>
                            {formatCurrency(
                              modelProposedLetter.proposedLetter?.salary,
                            )}
                          </ItemText>
                        </Item>
                      ) : (
                        <></>
                      )}

                      {modelProposedLetter.proposedLetter?.variableSalary ? (
                        <Item icon="IconCoin" title={t("variableSalaryLabel")}>
                          <ItemText>
                            {modelProposedLetter.proposedLetter?.variableSalary}
                          </ItemText>
                        </Item>
                      ) : (
                        <></>
                      )}

                      {flow?.subcategory === "internship" ? (
                        <>
                          {modelProposedLetter.proposedLetter?.workload ? (
                            <Item
                              icon="IconClockHour4"
                              title={t("workloadLabel")}
                            >
                              <ItemText>
                                {
                                  workloadOptions[
                                  modelProposedLetter.proposedLetter?.workload
                                  ]
                                }
                              </ItemText>
                            </Item>
                          ) : (
                            <></>
                          )}

                          {modelProposedLetter.proposedLetter?.workship ? (
                            <Item icon="IconSun" title={t("workshipLabel")}>
                              <ItemText>
                                {
                                  workshipOptions[
                                  modelProposedLetter.proposedLetter?.workship
                                  ]
                                }
                              </ItemText>
                            </Item>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Wrapper>

                    {modelProposedLetter.proposedLetter?.benefits?.length ? (
                      <Wrapper>
                        <Item
                          icon="IconPresentation"
                          title={t("benefitsLabel")}
                        />

                        {modelProposedLetter.proposedLetter?.benefits.map(
                          (file, idx) => (
                            <DeprecatedUploadPreview key={idx} file={file} />
                          ),
                        )}
                      </Wrapper>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </InnerContent>
        </OuterContent>
      </MainContainer>
    </Dialog>
  )
}
