import gql from "graphql-tag"
import { request } from "../client"
import {
  Contract,
  ContractOutput,
  DumbSignInfoParam,
  FlowSubcategory,
  S3File,
  SignInfoParam,
} from "../../types"

type Params = {
  cardId: string
  companyId: string
  columnId: string
  subcategory?: FlowSubcategory
  category: "hiring" | "resignation"
  template: S3File[]
  contractOutput: ContractOutput[]
  modelContract?: { origin?: string; custom?: string }[]
  candidate: SignInfoParam
  companyEmployee?: SignInfoParam
  witness?: DumbSignInfoParam[]
  institution?: DumbSignInfoParam
}

export const SEND_SIGN_REQUEST_V2 = gql`
  mutation SendSignRequestV2($params: SendSignRequestInput) {
    sendSignRequestV2(params: $params) {
      _id
      companyId
      contractDuration
      flowCardId
      template {
        key
        path
        value
        type
      }
      signature {
        employeeId
        candidateId
        name
        type
        email
        token
        phone
        signedAt
        signatureFile
        status
        ip
        confirmationInfo
      }
      status
      contractOutput {
        key
        path
        value
        type
        version
      }
      lastContractOutput {
        key
        path
        value
        type
        version
      }
    }
  }
`

export const sendSignRequestV2 = async (params: Params) => {
  const { sendSignRequestV2 } = await request(SEND_SIGN_REQUEST_V2, { params })
  return sendSignRequestV2 as Contract
}
