import gql from "graphql-tag"

export const GET_CANDIDATE_MODEL_CONTRACT_BY_ID = gql`
  query GetCandidateModelContractById($params: IGetCandidateModelContractById) {
    getCandidateModelContractById(params: $params) {
      _id
      name
      description
      tags
      status
      companyId
      candidateId
      html
      active
      modelContractId
      preview {
        key
        path
        value
        type
      }
      file {
        key
        path
        value
        type
      }
      verifiedTags {
        label
        tag
        value
      }
      candidateName
    }
  }
`
