import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Checkbox } from "@mui/material"
import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const AccordionButton = styled.div`
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  color: ${({ theme }) => theme.colors.neutral[30]};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AccordionBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #f8f6f8;
  margin-top: 16px;
  padding-top: 16px;
`

export const ItemHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.neutral[30]};
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`

export const ItemTitle = styled.span`
  font-weight: 700 !important;
  font-family: Cabinet Grotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const FieldTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 700 !important;
  margin-top: 14px;
`
export const StyledTextContainer = styled.div<{ hasValue: boolean }>`
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.neutral[50] : "#C96C01"};
  margin-bottom: 16px;
`

export const FieldText = styled(Typography)<{ hasValue: boolean }>`
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.neutral[50] : "#C96C01"};
  font-weight: 400 !important;
`

export const ContainedInput = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.colors.neutral[50]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  border: 1px solid #f8f6f8;
  margin-bottom: 16px;
`

export const ContainedText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  margin-right: 6px;
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

export const StyledDownloadIcons = styled(Icons)`
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ChecklistItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #53464f;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
`

export const StyledCheckbox = styled(Checkbox)`
  &.MuiButtonBase-root {
    padding: 0px;
    margin-right: 10px;
  }
`

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Counter = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  padding: 4px 12px;
  border-radius: 24px;
  width: fit-content;

  color: ${({ theme }) => theme.colors.neutral[10]};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
`

export const RedirectContainer = styled.div`
  cursor: pointer;
  border-bottom: 1.5px solid #fe2b8f;
  width: fit-content;
  margin-top: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
`

export const RedirectText = styled.span`
  color: #fe2b8f;
  font-weight: 700;
  font-size: 14px;
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  color: ${({ theme }) => theme.colors.neutral[50]};
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 14px;
  width: 100%;
`

export const StatusSignature = styled.div<{ signed: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: ${({ signed, theme }) =>
    signed ? theme.colors.neutral[50] : "#C96C01"};
`

export const StatusDescription = styled.span`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 600 !important;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const HtmlDiv = styled.div`
  ul {
    margin-left: 15px;
  }
`
