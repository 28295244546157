import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2"

import { Wrapper, ItemContainer } from "./styles"

interface AccordionItemProps {
  title: string
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export const AccordionItem = ({ title, onClick }: AccordionItemProps) => {
  return (
    <Wrapper>
      <ItemContainer onClick={onClick}>
        <Typography
          variant="body4"
          style={{ color: "#6B5B66", fontWeight: 600 }}
        >
          {title}
        </Typography>
        <Icons name="IconPlus" fill="transparent" size={24} stroke="#9F939B" />
      </ItemContainer>
    </Wrapper>
  )
}
