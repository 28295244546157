import gql from "graphql-tag"
import { request } from "../client"

export const HIRING_NEXT_COLUMN = gql`
  mutation HiringNextColumn($flowCardId: String) {
    hiringNextColumn(flowCardId: $flowCardId)
  }
`

export const sendHiringNextColumn = async (params: { flowCardId: string }) => {
  await request(HIRING_NEXT_COLUMN, { params })
}
