import { useNavigate, useParams } from "react-router-dom"
import {
  GenericModal,
  GenericModalConfirmationContent,
  RouteHistory,
} from "../../../../components"
import {
  DataGrid,
  Icons,
  LinkButton,
  Loader,
  SelectField,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import { SpinnerContainer } from "../../../Configuration/styles"
import { HiringCard } from "../../../../types"
import { useMutation, useQuery } from "@tanstack/react-query"
import dispatchToast from "../../../../utils/dispatchToast"
import { api } from "../../../../api"
import { PageContainer } from "../../../../components/screen/CardPageTemplate/styles"
import PageContent from "../PageContent"
import Navigator, {
  NavigatorLink,
} from "../../../../components/common/Navigator"
import { BodyContent } from "../PageContent/styles"
import React, { useCallback } from "react"
import InfoList from "../../../../components/common/CandidateMenu/Information/InfoList"
import FooterBar from "../PageContent/FooterBar"
import { useTheme } from "styled-components"
import { minutesToHours } from "../../../../utils"

export const WorkshiftPage: React.FC = () => {
  const { id: cardId = "" } = useParams()
  const navigate = useNavigate()
  const theme = useTheme()

  const [workshiftId, setWorkshiftId] = React.useState<string>()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const {
    data: card,
    isLoading: isCardLoading,
    isRefetching,
  } = useQuery<HiringCard>(
    ["hiring-card", cardId],
    () => api.query.hiring.card.getHiringCardById(cardId || ""),
    {
      enabled: !!cardId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err: any) => {
        const defaultError = "Ocorreu um erro ao encontrar o candidato"
        const errorMessage =
          err.serviceError?.details?.[0]?.message ||
          err.serviceError?.message ||
          err.httpError?.message ||
          err?.message

        dispatchToast({
          content: errorMessage || defaultError,
          type: "error",
        })
        navigate("/flows/hiring")
      },
    },
  )

  const { data: flow, isInitialLoading: isFlowLoading } = useQuery(
    ["flows", "no-cards", card?.flowId],
    () =>
      api.query.hiring.flow.getOnlyFlowById({
        flowId: card?.flowId || "",
      }),
    {
      enabled: !!card,
      refetchOnWindowFocus: false,
    },
  )

  const { data: companyWorkshifts } = useQuery(
    ["companyWorkshifts"],
    () => api.query.company.getWorkshifts(),
    {
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: workshift,
    isInitialLoading: isWorkshiftLoading,
    refetch,
  } = useQuery(
    ["workshift", workshiftId],
    () => api.query.workshift.getWorkshiftById(workshiftId || ""),
    {
      enabled: !!workshiftId,
      refetchOnWindowFocus: false,
    },
  )

  React.useEffect(() => {
    if (workshiftId) {
      refetch()
    }
  }, [workshiftId])

  const { mutateAsync: saveWorkshift, isLoading: isSavingWorkshift } =
    useMutation(api.mutation.workshift.saveWorkshift, {
      onError: (err: any) => {
        const defaultError = "Ocorreu um erro ao salvar a escala de trabalho"
        const errorMessage =
          err.serviceError?.details?.[0]?.message ||
          err.serviceError?.message ||
          err.httpError?.message ||
          err?.message

        dispatchToast({
          content: errorMessage || defaultError,
          type: "error",
        })
      },
      onSuccess: () => {
        dispatchToast({
          content: "Escala de trabalho salva com sucesso",
          type: "success",
        })
        goBack()
      },
    })

  const closeModal = useCallback(() => {
    if (isSavingWorkshift) return
    setIsModalOpen(false)
  }, [isSavingWorkshift])

  const pageHistory: RouteHistory[] = [
    {
      name: "Admissão",
      href: "/flows/hiring",
    },
    {
      name: "Configuração inicial",
      href: `/flows/hiring/initial-config/${cardId}`,
    },
    {
      name: "Jornada de trabalho",
    },
  ]
  const breadcrumbs = pageHistory.map((history, index) => {
    return (
      <NavigatorLink
        key={`breadcrumb-link-${index}`}
        name={history.name}
        href={history.href}
      />
    )
  })

  const goBack = () => {
    navigate(-1)
  }

  const selectOptions = React.useMemo(() => {
    return companyWorkshifts?.map((workshift) => ({
      value: workshift.id,
      label: workshift.name,
    }))
  }, [companyWorkshifts])

  const totalWorkingHours = React.useMemo(
    () =>
      workshift?.schedule?.reduce((acc, { total }) => {
        return acc + total
      }, 0),
    [workshift],
  )

  if (!flow || !card || isFlowLoading || isCardLoading || isRefetching) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  return (
    <PageContainer>
      <Navigator breadcrumbs={breadcrumbs} />
      <PageContent
        title="Configurar escala de trabalho"
        subtitle="Selecionar escala"
        description="Selecione uma escala de trabalho para esta pessoa."
        card={card}
        flow={flow}
      >
        <BodyContent
          style={{
            gap: theme.spacings.xs2,
            padding: theme.spacings.s,
          }}
        >
          <Typography variant="headline8" variantColor={theme.colors.neutral30}>
            Escalas de trabalho
          </Typography>
          <SelectField
            placeholder="Selecionar escala"
            required
            label="Selecionar escala"
            value={workshiftId}
            options={selectOptions}
            onSelectChange={(_, { value }) => {
              setWorkshiftId(value)
            }}
          />
          <LinkButton
            to={`${window.location.origin}/time-and-attendance/preferences/workshift/create/basic-data`}
            variant="primary"
            style={{
              width: "fit-content",
            }}
            onClick={() => {
              window.open(
                `${window.location.origin}/time-and-attendance/preferences/workshift/create/basic-data`,
                "_blank",
              )
            }}
          >
            Criar uma nova escala de trabalho <Icons name="IconArrowRight" />
          </LinkButton>
          <Typography
            variant="caption"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            variantColor={theme.colors.neutral40}
          >
            <Icons
              name="IconInfoCircle"
              size={16}
              color={theme.colors.neutral40}
              style={{ marginRight: theme.spacings.xs5 }}
            />{" "}
            Certifique-se de ter permissão para criar uma nova escala.
          </Typography>
        </BodyContent>
        {workshiftId && (
          <BodyContent
            style={{
              gap: theme.spacings.xs1,
            }}
          >
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral30}
            >
              Informações básicas
            </Typography>
            <InfoList
              data={[
                {
                  text: "Nome",
                  value: workshift?.name ?? "Não cadastrado",
                  hideCopyIcon: true,
                  loading: isWorkshiftLoading,
                },
                ...(workshift?.description
                  ? [
                    {
                      text: "Descrição",
                      value: workshift?.description,
                      hideCopyIcon: true,
                      loading: isWorkshiftLoading,
                    },
                  ]
                  : []),
                {
                  text: "Escala padrão",
                  value: workshift?.isDefault ? "Sim" : "Não",
                  hideCopyIcon: true,
                  loading: isWorkshiftLoading,
                },
              ]}
            />
          </BodyContent>
        )}
        {workshiftId && (
          <BodyContent style={{ gap: theme.spacings.xs }}>
            <Typography
              variant="headline8"
              variantColor={theme.colors.neutral30}
              style={{ marginBottom: -theme.spacings.xs5 }}
            >
              Dados da Escala
            </Typography>
            <BodyContent style={{ gap: theme.spacings.xs }}>
              <Typography
                variant="headline9"
                variantColor={theme.colors.secondary50}
              >
                Jornada de trabalho
              </Typography>
              <InfoList
                data={[
                  {
                    text: "Tipo de escala",
                    value: workshift?.type || "",
                    hideCopyIcon: true,
                    loading: isWorkshiftLoading,
                  },
                  {
                    text: "Horário de entrada",
                    value: workshift?.initialHour
                      ? dayjs(workshift?.initialHour).format("HH:mm")
                      : "",
                    hideCopyIcon: true,
                    loading: isWorkshiftLoading,
                  },
                  {
                    text: "Horário de saída",
                    value: workshift?.finalHour
                      ? dayjs(workshift?.finalHour).format("HH:mm")
                      : "",
                    hideCopyIcon: true,
                    loading: isWorkshiftLoading,
                  },
                  {
                    text: "Intervalo para refeição",
                    value: workshift?.interval ? "Sim" : "Não",
                    hideCopyIcon: true,
                    loading: isWorkshiftLoading,
                  },
                  ...(workshift?.intervalStart
                    ? [
                      {
                        text: "Horário de início do intervalo",
                        value: dayjs(workshift?.intervalStart).format(
                          "HH:mm",
                        ),
                        hideCopyIcon: true,
                        loading: isWorkshiftLoading,
                      },
                    ]
                    : []),
                  ...(workshift?.intervalDuration
                    ? [
                      {
                        text: "Duração do intervalo",
                        value: minutesToHours(
                          parseInt(workshift?.intervalDuration),
                        ),
                        hideCopyIcon: true,
                        loading: isWorkshiftLoading,
                      },
                    ]
                    : []),
                  {
                    text: "Habilitar intervalo pré-assinalado",
                    value: workshift?.preSignedInterval ? "Sim" : "Não",
                    hideCopyIcon: true,
                    loading: isWorkshiftLoading,
                  },
                ]}
              />
              <Typography
                key={"workshift-schedule-title"}
                variant="caption"
                variantColor={theme.colors.neutral50}
              >
                Pré visualização da escala
              </Typography>
              <DataGrid
                key={"workshift-schedule-grid"}
                columns={[
                  {
                    Header: "Dia",
                    accessor: "weekDay",
                    disableSortBy: true,
                  },
                  {
                    Header: "Entrada",
                    accessor: "initialHour",
                    disableSortBy: true,
                  },
                  {
                    Header: "Intervalo",
                    accessor: "interval",
                    disableSortBy: true,
                  },
                  {
                    Header: "Saída",
                    accessor: "finalHour",
                    disableSortBy: true,
                  },
                  {
                    Header: "Tipo do dia",
                    accessor: "type",
                    disableSortBy: true,
                  },
                ]}
                data={
                  workshift?.schedule?.map(
                    ({
                      day,
                      initialHour,
                      breakEnds,
                      breakStarts,
                      finalHour,
                      type,
                    }) => ({
                      weekDay: day,
                      initialHour: initialHour
                        ? dayjs(initialHour).format("HH:mm")
                        : "",
                      interval:
                        breakStarts && breakEnds
                          ? `${dayjs(breakStarts).format("HH:mm")} - ${dayjs(breakEnds).format("HH:mm")}`
                          : "",
                      finalHour: finalHour
                        ? dayjs(finalHour).format("HH:mm")
                        : "",
                      type: type,
                    }),
                  ) ?? []
                }
                hasPagination={false}
              />
              <Typography
                key={"workshift-schedule-total"}
                variant="body3"
                variantColor={theme.colors.neutral50}
              >
                Jornada semanal: {totalWorkingHours} horas.
              </Typography>
            </BodyContent>
            <BodyContent
              style={{
                gap: theme.spacings.xs,
              }}
            >
              <Typography
                variant="headline9"
                variantColor={theme.colors.secondary50}
              >
                Feriados
              </Typography>
              <InfoList
                data={[
                  {
                    text: "Alterar escala em feriados",
                    value: workshift?.hollydays ?? "Não cadastrado",
                    hideCopyIcon: true,
                    loading: isWorkshiftLoading,
                  },
                ]}
              />
            </BodyContent>
          </BodyContent>
        )}
      </PageContent>
      <FooterBar
        actionButtons={[
          {
            variant: "link",
            description: "Cancelar",
            handleAction: goBack,
            disabled: isSavingWorkshift,
          },
          {
            variant: "primary",
            description: "Salvar",
            icon: "IconCheck",
            handleAction: () => {
              setIsModalOpen(true)
            },
            disabled: isWorkshiftLoading || isSavingWorkshift,
            isLoading: isWorkshiftLoading || isSavingWorkshift,
          },
        ]}
      />
      <GenericModal
        handleClose={closeModal}
        isOpen={isModalOpen}
        showCloseButton={false}
        actionButtons={[
          {
            variant: "link",
            description: "Configurar depois",
            handleAction: goBack,
            disabled: isSavingWorkshift,
          },
          {
            variant: "primary",
            description: "Finalizar configuração",
            icon: "IconCheck",
            disabled: isSavingWorkshift,
            isLoading: isSavingWorkshift,
            handleAction: async () => {
              await saveWorkshift({
                workshiftId: workshiftId || "",
                candidateId: card.candidate._id,
                flowCardId: card._id,
                version: card.version,
              })
            },
          },
        ]}
      >
        <GenericModalConfirmationContent
          subtitle={"Atenção!"}
          title={"Gostaria de finalizar a configuração de escala?"}
          desciption={
            "A escala de trabalho poderá ser editada na página de configurações de escala após finalização."
          }
        />
      </GenericModal>
    </PageContainer>
  )
}

export default WorkshiftPage
