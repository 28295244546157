import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"

import {
  Button,
  Loader,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useFormik } from "formik"
import * as yup from "yup"

import {
  createPreSignedUrl,
  uploadFileToS3,
  getS3Presigned,
} from "@flash-tecnologia/hros-web-utility"

import { Flow, ResignationCard, S3File } from "../../../types"

import { SectionLoading, DeprecatedUpload } from "../../../components"
import { UploadLoading } from "../../../components/common/UploadLoading"
import { UploadPreview } from "../../../components/common/DeprecatedUploadPreview"
import dispatchToast from "../../../utils/dispatchToast"

import {
  Container,
  FieldContainer,
  SubTitle,
  Title,
  StyledSubtitleContainer,
  StyledDescription,
  Divider,
} from "./styles"

import { RESIGNATION_NEXT_COLUMN } from "../../../api/mutations/resignation-card-next-column"
import { RESIGNATION_UPDATE_CARD } from "../../../api/mutations/resignation-update-card"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { request } from "../../../api/client"

export const InterviewContent = ({
  card: initialCard,
  flow,
}: {
  card: ResignationCard
  flow: Flow
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { id } = useParams()
  const [card, setCard] = useState<ResignationCard>(initialCard);

  const [loadingFile, setLoadingFile] = useState<any>(false)
  const [resignationFlowInfo, setResignationFlowInfo] = useState<any>("")

  const { mutate: updateCard } = useMutation(
    async ({ params }: { params: any }) => {
      await request(RESIGNATION_UPDATE_CARD, { params })
    },
  )

  const { mutate: sendToNextColumn } = useMutation(
    async () => {
      await request(RESIGNATION_NEXT_COLUMN, { flowCardId: id })
      return
    },
    {
      onSuccess: () => {
        dispatchToast({
          content: "Dados cadastrados com sucesso",
          type: "success",
        })
        navigate("/flows/resignation")
        queryClient.invalidateQueries(["flows"])
      },
      onError: () => {
        dispatchToast({ content: "Ocorreu um erro", type: "error" })
      },
    },
  )

  useEffect(() => {
    if (card) setResignationFlowInfo(card)
  }, [card])

  useEffect(() => {
    if (resignationFlowInfo) {
      if (resignationFlowInfo?.resignationInterviewObservation)
        formik.setFieldValue(
          "resignationInterviewObservation",
          resignationFlowInfo?.resignationInterviewObservation || "",
        )
    }
  }, [resignationFlowInfo])

  const validationSchema = yup.object({
    resignationInterviewObservation: yup
      .string()
      .max(600)
      .required("Favor digitar as observações"),
  })

  const formik = useFormik({
    initialValues: {
      resignationInterviewObservation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendToNextColumn()
    },
  })

  const handleFile = async ({ name, file }) => {
    setLoadingFile(true)

    const { employeeId, companyId, _id } = resignationFlowInfo

    const url = `${companyId}/${employeeId}/${_id}/resignation/interview/${name}`

    if (!companyId || !employeeId || !_id || !name) return

    const { key = "", signedUrl = "" } = await createPreSignedUrl({
      filename: url,
      module: "employee-pii",
      contentType: file.type,
    });

    await uploadFileToS3({ file, url: signedUrl });

    const params = { key, path: url, type: "internal" };

    await updateFieldGlobally({
      value: params,
      field: "resignationInterviewFile",
    });

    const { url: presignedUrl } = await getS3Presigned({
      filename: url,
      module: "employee-pii",
    });

    const resignationInterviewFile: S3File = {
      key,
      path: url,
      type: "internal",
      value: presignedUrl.toString(),
    };

    setCard((prevCard) => ({
      ...prevCard,
      resignationInterviewFile,
    }));

    setLoadingFile(false)    
  }

  const updateFieldGlobally = async ({
    value,
    field,
  }: {
    value: any
    field: string
  }) => {
    await updateCard({
      params: {
        flowCardId: card._id,
        [field]: value,
      },
    })
  }

  return (
    <Container>
      <div style={{ marginBottom: "24px" }}>
        <Title variant={"headline8"}>Entrevista de desligamento</Title>
        <SubTitle variant={"caption"}>
          Suba um arquivo ou escreva a ata da reunião ou qualquer registro da
          entrevista com a pessoa.
        </SubTitle>
      </div>

      <FieldContainer>
        {resignationFlowInfo?.resignationInterviewFile?.value ? (
          <UploadPreview
            uploadItem={resignationFlowInfo?.resignationInterviewFile}
            onRemove={async () => {
              await updateFieldGlobally({
                field: "resignationInterviewFile",
                value: null,
              })
              setCard((prevCard) => ({
                ...prevCard,
                resignationInterviewFile: undefined,
              }));
            }}
          />
        ) : (
          <DeprecatedUpload
            label={"Entrevista de desligamento"}
            maxSize={5242880}
            accept={["jpg", "png", "pdf", "txt", "doc"]}
            onFileSizeError={() => {
              dispatchToast({
                content:
                  "Arquivo maior que 5mb. Por favor, faça upload de arquivo menor que 5mb",
                type: "warning",
              })
            }}
            onChange={({ name, file }) => {
              handleFile({ name, file })
            }}
            customPreview={() => (loadingFile ? <UploadLoading /> : <></>)}
          />
        )}
      </FieldContainer>

      <TextField
        id={"resignationInterviewObservation"}
        name={"resignationInterviewObservation"}
        label={"Observações"}
        inputProps={{ maxLength: 600 }}
        value={formik.values.resignationInterviewObservation}
        onChange={formik.handleChange}
        onBlur={async (e) => {
          await updateFieldGlobally({
            value: e.target.value || null,
            field: "resignationInterviewObservation",
          })
        }}
        error={
          formik.touched.resignationInterviewObservation &&
          Boolean(formik.errors.resignationInterviewObservation)
        }
        helperText={
          formik.touched.resignationInterviewObservation &&
          formik.errors.resignationInterviewObservation
        }
        multiline
        rows={4}
        fullWidth
      />

      <StyledSubtitleContainer>
        <StyledDescription variant="caption">
          Máximo 600 caracteres
        </StyledDescription>
        <StyledDescription variant="caption">
          {formik.values.resignationInterviewObservation.length}/600
        </StyledDescription>
      </StyledSubtitleContainer>

      <Divider style={{ marginTop: "40px", marginBottom: "49px" }} />
      <SectionLoading url={"resignationNextColumn"}>
        {({ loading }) => (
          <Button
            type={"submit"}
            size={"medium"}
            variant="primary"
            style={{ alignSelf: "center", width: "100%" }}
            disabled={loading}
            onClick={() => formik.handleSubmit()}
          >
            {loading ? (
              <Loader size="extraSmall" variant="secondary" />
            ) : (
              <Typography variant="body3" style={{ fontWeight: 700 }}>
                Cadastrar
              </Typography>
            )}
          </Button>
        )}
      </SectionLoading>
    </Container>
  )
}
