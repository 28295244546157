import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconContainer = styled.div<{ variantType: "default" | "error" }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 20px;

  width: 64px;
  height: 64px;
  border-radius: 50%;

  background-color: ${({ variantType, theme }) =>
    variantType === "error"
      ? theme.colors.feedback.error[90]
      : theme.colors.secondary[95]};
`

export const StyledButton = styled(Button)<{
  variantType: "default" | "error"
}>`
  ${({ variantType, theme }) =>
    variantType === "error" &&
    css`
      && {
        &.button-custom-theme-primary.MuiPaper-root.MuiButtonBase-root {
          background: ${theme.colors.feedback.error[90]};
          color: ${theme.colors.feedback.error[40]};
        }
        &.button-custom-theme-primary.MuiPaper-root.MuiButtonBase-root:hover {
          box-shadow: 0 16px 40px ${theme.colors.feedback.error[90]};
        }
        &.button-custom-theme-primary.MuiPaper-root.MuiButtonBase-root.loading {
          animation-duration: 6s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: wave;
          animation-timing-function: linear;
          background: linear-gradient(
            to right,
            ${theme.colors.feedback.error[90]} 50%,
            ${theme.colors.feedback.error[70]} 80%
          );
          pointer-events: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        }
      }
    `}
`
