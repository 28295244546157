import React from "react"
import { IconContainer } from "./styles"
import { useTheme } from "styled-components"

const BenefitBadge: React.FC<{
  name: string
  src: string
  size: "s" | "m" | "l"
}> = (props) => {
  const theme = useTheme()
  return (
    <IconContainer _size={theme.icons.sizes[props.size]}>
      <img
        alt={props.name + " icon"}
        src={props.src}
        style={{ width: "55%" }}
      />
    </IconContainer>
  )
}
export default BenefitBadge
