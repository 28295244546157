import {
  Button,
  Icons,
  LinkButton,
  Loader,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  StyledModal,
  Container,
  Header,
  CloseWrapper,
  IconContainer,
  RoundIconContainer,
  RoundIconTitle,
  Title,
  Description,
  Footer,
} from "./styles"
import { useContext, useState } from "react"
import dispatchToast from "../../../../utils/dispatchToast"
import { globalContext } from "../../../../context"
import { api } from "../../../../api"
import { useMutation } from "@tanstack/react-query"

interface ModalExportCandidateDataProps {
  isOpen: boolean
  handleClose: () => void
  candidateId: string
}

export const ModalExportCandidateData = ({
  isOpen,
  handleClose,
  candidateId,
}: ModalExportCandidateDataProps) => {
  const [exportFinished, setExportFinished] = useState<boolean>(false)

  const { employee } = useContext(globalContext)
  const { mutateAsync: createReport, isLoading: isReportLoading } = useMutation(
    api.mutation.hiring.createBoardReport,
  )
  const employeeEmail =
    employee?.contacts?.find((c) => c.type === "email")?.value || ""

  const handleSubmit = async () => {
    await createReport({
      candidateIds: [candidateId],
      reportType: "both",
    })
      .then((_) => setExportFinished(true))
      .catch(() => {
        dispatchToast({
          content: "Houve um error ao exportar dados",
          type: "error",
        })
      })
  }

  if (exportFinished) {
    return (
      <StyledModal open={isOpen} onClose={handleClose} isSmall={true}>
        <Container>
          <Header
            style={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CloseWrapper>
              <IconContainer onClick={handleClose}>
                <Icons name="IconX" style={{ width: "16px", height: "16px" }} />
              </IconContainer>
            </CloseWrapper>

            <RoundIconContainer>
              <Icons name="IconCheck" fill="transparent" size={42} />
            </RoundIconContainer>

            <RoundIconTitle>Pronto!</RoundIconTitle>

            <Title variant="headline6">
              Seu relatório está sendo exportado!
            </Title>

            <Description variant="body3" style={{ marginTop: 15 }}>
              Para acessá-lo é só conferir o e-mail que será enviado em alguns
              minutos para o endereço:
            </Description>

            <Description variant="body3" style={{ marginTop: 5 }}>
              <b>{employeeEmail}</b>
            </Description>

            <Description variant="body3" style={{ marginTop: 20 }}>
              Para acessar os dados utilize os{" "}
              <b>4 primeiros dígitos do seu CPF</b> como senha.
            </Description>
          </Header>

          <Footer style={{ justifyContent: "center" }}>
            <Button size="large" variant={"primary"} onClick={handleClose}>
              Voltar para Admissão
            </Button>
          </Footer>
        </Container>
      </StyledModal>
    )
  }

  return (
    <StyledModal open={isOpen} onClose={handleClose} isSmall={true}>
      <Container>
        <Header
          style={{
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <CloseWrapper>
            <IconContainer onClick={handleClose}>
              <Icons name="IconX" style={{ width: "16px", height: "16px" }} />
            </IconContainer>
          </CloseWrapper>

          <RoundIconContainer>
            <Icons name="IconFileSpreadsheet" fill="transparent" size={42} />
          </RoundIconContainer>

          <Title variant="headline6" style={{ marginTop: 15 }}>
            Deseja exportar o relatório?
          </Title>

          <Description variant="body3" style={{ marginTop: 15 }}>
            O relatório apresentará somente os <b>dados desse candidato</b>{" "}
            convertidos em um arquivo excel. O arquivo será enviado para o
            email:
          </Description>

          <Description variant="body3" style={{ marginTop: 10 }}>
            <b>{employeeEmail}</b>
          </Description>
        </Header>

        <Footer>
          <LinkButton
            variant="primary"
            onClick={handleClose}
            style={{ marginTop: 15 }}
          >
            Cancelar
          </LinkButton>

          <Button size="large" variant={"primary"} onClick={handleSubmit}>
            {isReportLoading ? (
              <Loader size="extraSmall" variant="secondary" />
            ) : (
              <>
                Exportar relatório
                <Icons name={"IconDownload"} fill="transparent" />
              </>
            )}
          </Button>
        </Footer>
      </Container>
    </StyledModal>
  )
}
