import { GenericModal, GenericModalConfirmationContent } from "../GenericModal"

interface IAdmissionWarningModal {
  isOpen: boolean
  handleClose: () => void
}

export const AdmissionWarningModal: React.FC<IAdmissionWarningModal> = ({
  isOpen,
  handleClose,
}) => {
  return (
    <GenericModal
      handleClose={handleClose}
      isOpen={isOpen}
      showCloseButton={false}
      actionButtons={[
        {
          variant: "primary",
          description: "Entendi",
          handleAction: handleClose,
        },
      ]}
    >
      <GenericModalConfirmationContent
        subtitle={"Atenção!"}
        title={
          "Você precisa finalizar a admissão antes de ir para configurações iniciais."
        }
        desciption={
          "Todo o histórico do processo admissional deste candidato será mantido dentro da etapa de Admissão concluída."
        }
      />
    </GenericModal>
  )
}
