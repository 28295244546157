import { gql } from "@apollo/client"
import { request } from "../client"

const RESEND_ADMISSIONAL_EXAM_REQUEST = gql`
  mutation ResendAdmissionalExamRequest(
    $params: ResendAdmissionalExamRequestInput
  ) {
    resendAdmissionalExamRequest(params: $params) {
      _id
    }
  }
`

export const resendAdmissionalExamRequest = async (params: {
  flowCardId: string
  columnId: string
  candidateId: string
  reason: string
  version: number
}) => {
  const { resendAdmissionalExamRequest } = await request(
    RESEND_ADMISSIONAL_EXAM_REQUEST,
    { params },
  )
  return resendAdmissionalExamRequest
}
