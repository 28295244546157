import React, { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { SendData, Importing } from "./screen"
import dispatchToast from "../../../utils/dispatchToast"
import {
  Container,
  SpinnerContainer,
  FooterButtonContainer,
  StyledDescription,
  StyledFooterBar,
  StyledLinkButton,
  StyledPageContainer,
  StyledTitle,
  Title,
} from "./styles"
import {
  Button,
  IconTypes,
  Icons,
  LinkButton,
  Loader,
  Typography,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../api"
import { Flow, MassCandidateProcess } from "../../../types"
import { Grid } from "@mui/material"
import { Header } from "../../../components"

export enum Steps {
  SendData = 0,
  Importing = 1,
}
export interface IMassCandidateContext {
  flow: Flow
  process?: MassCandidateProcess
  refetchProcess: () => void
  setFooterButtons: (buttons: FooterButtonsType) => void
}

export const massCandidateContext =
  React.createContext<IMassCandidateContext>({
    flow: {} as Flow,
    refetchProcess: () => { },
    setFooterButtons: () => { },
  })

const steps = [
  {
    title: "Envie os dados",
    text: "Envie os dados básicos dos candidatos por planilha ou arquivo de texto para realizar o cadastro em lote.",
  },
  {
    title: "Status das ações",
    text: "Acompanhe aqui o status do cadastro. Esse processo pode demorar alguns minutos, mas você pode fechar essa página e continuar usando a plataforma normalmente!",
  },
]

const routes = [
  {
    label: "Admissão",
    route: `/flows/hiring`,
  },
  {
    label: "Cadastrar candidatos",
  },
]

type ButtonFooterProps<T> = {
  props: Partial<T>
  text: string
  icon: IconTypes
  iconSide?: "right" | "left"
}

export type FooterButtonsType = {
  primary: ButtonFooterProps<React.ComponentProps<typeof Button>>
  secondary?: ButtonFooterProps<React.ComponentProps<typeof Button>>
  tertiary?: Omit<
    ButtonFooterProps<React.ComponentProps<typeof LinkButton>>,
    "icon"
  >
}

const AddMassCandidates = () => {
  const [footerButtons, setFooterButtons] =
    React.useState<FooterButtonsType | undefined>(undefined)

  const { flowId = '' } = useParams()
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)")

  const { data: flow, isInitialLoading: isFlowLoading } = useQuery(
    ["flows", "no-cards", flowId],
    () =>
      api.query.hiring.flow.getOnlyFlowById({
        flowId: flowId as string,
      }),
    {
      enabled: !!flowId,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
        dispatchToast({
          content: "Houve um problema ao buscar informações do fluxo",
          type: "error",
        })
        navigate("/flows/hiring")
      },
    },
  )

  const {
    data: process,
    refetch: refetchProcess,
    isInitialLoading: isLoadingProcess,
  } = useQuery(
    ["mass-candidate-process", flow?._id],
    () => api.query.hiring.massCandidate.findProcessByFlow({ flowId: flowId as string }),
    {
      enabled: !!flow,
      refetchOnWindowFocus: false,
    },
  )

  const value: IMassCandidateContext = useMemo(
    () => ({
      flow: flow as Flow,
      process,
      refetchProcess,
      setFooterButtons: (buttons: FooterButtonsType) =>
        setFooterButtons(buttons),
    }),
    [flow, process],
  )

  const currentStep = !process ? Steps.SendData : Steps.Importing

  const step = React.useMemo(() => {
    switch (currentStep) {
      case Steps.Importing:
        return <Importing />
      case Steps.SendData:
        return <SendData />
      default:
        throw new Error("No step found")
    }
  }, [currentStep])

  if (!flow || isFlowLoading || isLoadingProcess) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  return (
    <massCandidateContext.Provider value={value}>
      <Container>
        <Header
          breadcrumbs={routes}
          steps={["Envie os Dados", "Status das ações"]}
          currentStep={currentStep}
        />

        <StyledPageContainer>
          <Title variant="headline6" style={{ marginTop: "40px" }}>
            Cadastrar candidatos
          </Title>

          <Grid container>
            <Grid item sm={12} md={5} lg={3}>
              <div style={{ marginRight: "24px", marginTop: "40px" }}>
                <StyledTitle variant="headline7">
                  {steps[currentStep].title}
                </StyledTitle>
                <div style={{ marginTop: "16px" }}>
                  <StyledDescription variant="body3">
                    {steps[currentStep].text}
                  </StyledDescription>
                </div>
              </div>
            </Grid>

            {step}
          </Grid>
        </StyledPageContainer>

        <StyledFooterBar>
          <FooterButtonContainer
            justifyContent={
              footerButtons?.tertiary ? "space-between" : "flex-end"
            }
          >
            {footerButtons?.tertiary ? (
              <StyledLinkButton
                variant="primary"
                style={{ alignSelf: "center" }}
                {...footerButtons.tertiary.props}
              >
                {footerButtons.tertiary.text}
              </StyledLinkButton>
            ) : (
              <></>
            )}

            <div style={{ display: "flex", gap: 20 }}>
              {footerButtons?.secondary ? (
                <Button
                  variant="secondary"
                  size={isMobile ? "small" : "large"}
                  style={{ alignSelf: "center" }}
                  {...footerButtons.secondary.props}
                >
                  {footerButtons?.primary.props.loading ? (
                    <Loader size="small" variant="secondary" />
                  ) : (
                    <>
                      {footerButtons.secondary?.iconSide === "left" &&
                        footerButtons.secondary.icon ? (
                        <Icons
                          name={footerButtons.secondary.icon}
                          fill="transparent"
                        />
                      ) : (
                        <></>
                      )}

                      <Typography variant="body3" style={{ fontWeight: 700 }}>
                        {footerButtons.secondary.text}
                      </Typography>

                      {!footerButtons.secondary.iconSide ||
                        (footerButtons.secondary.iconSide === "right" &&
                          footerButtons.secondary.icon) ? (
                        <Icons
                          name={footerButtons.secondary.icon}
                          fill="transparent"
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Button>
              ) : (
                <></>
              )}

              <Button
                size={isMobile ? "small" : "large"}
                variant="primary"
                style={{ marginLeft: "24px", alignSelf: "center" }}
                {...footerButtons?.primary.props}
              >
                {footerButtons?.primary.props.loading ? (
                  <Loader size="small" variant="secondary" />
                ) : (
                  <>
                    {footerButtons?.primary.iconSide === "left" &&
                      footerButtons?.primary.icon ? (
                      <Icons
                        name={footerButtons?.primary.icon}
                        fill="transparent"
                      />
                    ) : (
                      <></>
                    )}

                    <Typography variant="body3" style={{ fontWeight: 700 }}>
                      {footerButtons?.primary.text}
                    </Typography>

                    {footerButtons?.primary.icon && (!footerButtons?.primary.iconSide || footerButtons?.primary.iconSide === "right") ? (
                      <Icons
                        name={footerButtons?.primary.icon}
                        fill="transparent"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Button>
            </div>
          </FooterButtonContainer>
        </StyledFooterBar>
      </Container>
    </massCandidateContext.Provider>
  )
}

export default AddMassCandidates
