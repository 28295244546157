import gql from "graphql-tag"
import { request } from "../client"

const GET_MODEL_CONTRACT_BY_NAME = gql`
  query GetModelContractByName($params: IGetCandidateModelByName) {
    getModelContractByName(params: $params) {
      _id
      name
      customModelContractId
    }
  }
`
export const getModelContractById = async (params: {
  flowId: string
  candidateId: string
}) => {
  const { getModelContractByName } = await request(GET_MODEL_CONTRACT_BY_NAME, {
    params,
  })
  return getModelContractByName
}
