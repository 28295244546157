import { gql } from "@apollo/client"
import { request } from "../client"
import { PresignedS3 } from "../../types"

const GET_S3_FILE = gql`
  mutation GetS3File($params: GetS3FileInput) {
    getS3File(params: $params) {
      signedUrl
      key
      region
    }
  }
`

export const getS3File = async (params: { path: string; module: string }) => {
  const { getS3File } = await request(GET_S3_FILE, { params })
  return getS3File as PresignedS3
}
