import gql from "graphql-tag"
import { ChecklistCard } from "../../types"
import { request } from "../client"

const HIRING_CHANGE_CHECKLIST = gql`
  mutation HiringChangeChecklist($params: ChangeChecklistInput) {
    hiringChangeChecklist(params: $params) {
      checklists {
        items {
          label
          checked
        }
        columnId
      }
    }
  }
`

export const checklistUpdateHiring = async (params: {
  columnId: string
  flowCardId: string
  version: number
  checklist: string[]
}): Promise<ChecklistCard[]> => {
  const { hiringChangeChecklist } = await request(HIRING_CHANGE_CHECKLIST, {
    params,
  })
  return hiringChangeChecklist.checklists as ChecklistCard[]
}
