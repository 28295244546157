import gql from "graphql-tag"
import { request } from "../client"
import { ChecklistCard } from "../../types"

const RESIGNATION_CHANGE_CHECKLIST = gql`
  mutation ResignationChangeChecklist($params: ChangeChecklistInput) {
    resignationChangeChecklist(params: $params) {
      checklists {
        items {
          label
          checked
        }
        columnId
      }
    }
  }
`

export const checklistUpdateResignation = async (params: {
  columnId: string
  flowCardId: string
  version: number
  checklist: string[]
}): Promise<ChecklistCard[]> => {
  const { resignationChangeChecklist } = await request(
    RESIGNATION_CHANGE_CHECKLIST,
    { params },
  )
  return resignationChangeChecklist.checklists as ChecklistCard[]
}
