import styled from "styled-components"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

export const Container = styled.div`
  margin-top: 40px;
  padding: 40px 0px 0px;
  border-top: 1px solid #ebe6e9;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const OptionsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
