import styled, { css } from "styled-components"

const bannerStyles = {
  ["info"]: {
    banner: css`
      border: 1px solid ${({ theme }) => theme.colors.feedback.info[70]};
      color: ${({ theme }) => theme.colors.feedback.info[10]};
    `,
    circle: css`
      background-color: ${({ theme }) => theme.colors.feedback.info[90]};
      color: ${({ theme }) => theme.colors.feedback.info[50]};
    `,
    closeButton: css`
      color: ${({ theme }) => theme.colors.feedback.info[40]};
    `,
  },
  ["error"]: {
    banner: css`
      border: 1px solid ${({ theme }) => theme.colors.feedback.error[70]};
      color: ${({ theme }) => theme.colors.feedback.error[10]};
    `,
    circle: css`
      background-color: ${({ theme }) => theme.colors.feedback.error[90]};
      color: ${({ theme }) => theme.colors.feedback.error[50]};
    `,
    closeButton: css`
      color: ${({ theme }) => theme.colors.feedback.error[40]};
    `,
  },
}

export const BannerContainer = styled.div<{ type: "info" | "error" }>`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;

  ${({ type }) => bannerStyles[type].banner}
`

export const IconContainer = styled.div<{ type: "info" | "error" }>`
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  ${({ type }) => bannerStyles[type].circle}
`

export const ContentContainer = styled.div`
  max-width: 600px;
  word-break: break-word;
  text-align: start;
  width: 100%;
  p:first-child {
    margin-bottom: 4px;
  }
`

export const CloseContainer = styled.div<{ type: "info" | "error" }>`
  cursor: pointer;
  ${({ type }) => bannerStyles[type].closeButton}
`
