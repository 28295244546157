import styled, { css } from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacings.m} 8%; // should be 5% but it's 8% to match the design
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};
`

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.m};
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`

export const Content = styled.div`
  width: 100%;
  padding: 40px;
  border: 1px solid #d1c7ce;
  border-radius: 12px;
`

export const MenuWrapper = styled.div`
  width: 30%;
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const ResetPillButton = styled.div<{
  disabled?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 8px 15px;
  border-radius: 100px;
  cursor: pointer;
  margin-bottom: 20px;

  ${({ disabled = false, theme }) =>
    disabled
      ? css`
          cursor: initial;
          background-color: ${theme.colors.neutral[95]};
          border: 1px solid ${theme.colors.neutral[95]};
          color: ${theme.colors.neutral[70]};
        `
      : css`
          color: ${theme.colors.secondary[50]};
          background-color: white;
          border: 1px solid ${theme.colors.secondary[50]};
        `}
`

export const ChecklistPillButton = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 8px 15px;
  border-radius: 100px;
  cursor: pointer;
  margin-bottom: 20px;

  ${({ active }) =>
    active
      ? css`
          color: #f20d7a;
          background-color: #ffe0ef;
          border: 1px solid #ffe0ef;
        `
      : css`
          color: #6b5b66;
          background-color: white;
          border: 1px solid #d1c7ce;
        `}
`

export const CardStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 16px;
`

export const CardStatusColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CardStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
