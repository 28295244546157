import gql from "graphql-tag"
import { request } from "../client"

const CREATE_BOARD_REPORT = gql`
  mutation CreateBoardReport($params: CreateBoardReportInput) {
    createBoardReport(params: $params)
  }
`

export const createBoardReport = async (params: {
  candidateIds: string[]
  reportType: "worksheet" | "documents" | "both"
}) => {
  return await request(CREATE_BOARD_REPORT, { params })
}
