import gql from "graphql-tag"
import { request } from "../client"
import {
  MassCandidateProcess,
  MassCandidatesAcceptedFilesTypes,
} from "../../types"

const CREATE_MASS_CANDIDATE_PROCESS = gql`
  mutation CreateMassCandidateProcess(
    $params: InputCreateMassCandidateProcess
  ) {
    createMassCandidateProcess(params: $params) {
      _id
      notificationEmployeeId
      companyId
      flowId
      completionPercentage
      validationPercentage
      lastReadPosition
      lastValidatedPosition
      fileKey
      active
      dismiss
      errorReport {
        line
        reason
      }
      fileType
      initialPosition
      validationProgress
    }
  }
`

export type CreateMassCandidateProcessParams = {
  flowId: string
  companyId: string
  fileKey: string
  fileType: MassCandidatesAcceptedFilesTypes
  notificationEmployeeId: string
}

export const createMassCandidateProcess = async (
  params: CreateMassCandidateProcessParams,
): Promise<MassCandidateProcess> => {
  const response = await request<{
    createMassCandidateProcess: MassCandidateProcess
  }>(CREATE_MASS_CANDIDATE_PROCESS, {
    params,
  })
  return response.createMassCandidateProcess
}
