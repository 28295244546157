import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};

  span {
    color: ${({ theme }) => theme.colors.feedback.error[50]};
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const Wrapper = styled.div`
  padding: 48px 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`
