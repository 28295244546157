import { useNavigate } from "react-router-dom"
import { ConfirmationModal } from "../../../../../components"
import { CandidateSignInformation } from "../../../../../types"

interface ModalIlegibleCandidates {
  isOpen: boolean
  handleClose: () => void
  candidates: CandidateSignInformation[]
}
export const ModalIlegibleCandidates = ({
  isOpen,
  handleClose,
  candidates,
}: ModalIlegibleCandidates) => {
  const navigate = useNavigate()

  const handleCancel = () => {
    const countElegibleCandidates = candidates.filter((c) => c.isElegibleToSign)
    if (countElegibleCandidates?.length === 0) {
      handleClose()
      navigate(-1)
      return
    }
    handleClose()
  }

  return (
    <ConfirmationModal
      variant="primary"
      isOpen={isOpen}
      onClose={handleClose}
      confirmButton={{
        text: "Confirmar",
        onClick: () => handleCancel(),
      }}
      cancelButton={{
        text: "Cancelar",
        onClick: () => handleCancel(),
      }}
      title="Alguns dos candidatos selecionados não podem ter o contrato assinado!"
      description={
        <>
          <b>
            {candidates.filter((candidate) => !candidate.isElegibleToSign)
              ?.length ?? 0}
          </b>{" "}
          dos <b>candidatos</b> selecionados não estão{" "}
          <b>disponíveis para assinar o contrato</b>, pois outra pessoa foi
          designada como responsável pela assinatura. Portanto, eles não serão
          incluídos no fluxo a seguir.
        </>
      }
    />
  )
}
