import styled from "styled-components"

export const PageHeaderContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.s} 0;
`

export const PageDescriptionContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs1};
`

export const ContentContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  flex: 1;
  max-width: 86%;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  width: 30%;
`

export const RightContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.m};
  width: 70%;
`

export const BodyContent = styled.div`
  display: grid;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.m};
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral90}`};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  height: fit-content;
`
