import styled from "styled-components"

import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

export const Container = styled.div``

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
`

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 700;
`

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  margin: 40px 0px;
`

export const FieldContainer = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`
