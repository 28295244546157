import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import emptySection from "../../../../assets/emptySection.png"
import { useTheme } from "styled-components"
import { EmptySectionContainer, EmptySectionImage } from "./styles"
const EmptySection = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  const { colors } = useTheme()
  return (
    <EmptySectionContainer>
      <EmptySectionImage src={emptySection} alt={"seção vazia do layout"} />
      <Typography variant="headline8" variantColor={colors.neutral40}>
        {title}
      </Typography>
      <Typography variant="body4" variantColor={colors.neutral50}>
        {description}
      </Typography>
    </EmptySectionContainer>
  )
}

export default EmptySection
