import gql from "graphql-tag"
import { request } from "../client"
import { CompanyEmployee } from "../../types"

const GET_EMPLOYEE_BY_COMPANY_GROUPS = gql`
  query GetEmployeeByCompanyGroups($groups: String) {
    getEmployeeByCompanyGroups(groups: $groups) {
      id
      name
      documentNumber
      phoneNumber
      email
      corporateEmail
      status
      companyId
      groups
      roles
      invitationDate
    }
  }
`

export const getEmployeeByCompanyGroups = async (groups: string) => {
  const { getEmployeeByCompanyGroups } = await request(
    GET_EMPLOYEE_BY_COMPANY_GROUPS,
    { groups },
  )
  return getEmployeeByCompanyGroups as CompanyEmployee[]
}
