import React from "react"
import { Autocomplete, TextFieldProps, AutocompleteProps } from "@mui/material"

import * as styled from "./styled"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

export interface ObjectLiteral {
  [key: string]: any
}

export type SearchFieldProps = {
  options?: ObjectLiteral[]
  multiple?: boolean
  value?: any
  error?: boolean
  onSearchChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
  ) => void
  helperText?: string
  noOptionsText?: string
  onInputChange?: (
    event: React.SyntheticEvent,
    value?: string,
    reason?: string,
  ) => void
} & Omit<TextFieldProps, "variant"> &
  Omit<
    AutocompleteProps<ObjectLiteral, boolean, boolean, true>,
    "onBlur" | "onChange" | "renderInput" | "options"
  >

export const SearchField = ({
  options = [],
  disabled,
  multiple = false,
  value,
  onChange,
  onSearchChange,
  onInputChange,
  helperText,
  error,
  ref,
  label,
  onBlur,
  className,
  onKeyDown,
  inputRef,
  ...props
}: SearchFieldProps) => {
  return (
    <styled.Wrapper>
      <Autocomplete
        {...props}
        ref={ref}
        className={error ? `Mui-error ${className}` : `${className}`}
        disablePortal
        options={options}
        multiple={multiple}
        onChange={onSearchChange}
        value={value}
        readOnly={disabled}
        disabled={disabled}
        freeSolo
        onInputChange={onInputChange}
        popupIcon={<Icons name="IconChevronDown" fill="transparent" />}
        PopperComponent={(props: any) => <styled.StyledPopper {...props} />}
        renderInput={(params) => (
          <>
            <styled.StyledTextField
              {...params}
              ref={inputRef}
              onBlur={(e) => {
                onBlur && onBlur(e)
                onChange && onChange(e)
              }}
              onChange={onChange}
              error={error}
              onKeyDown={onKeyDown}
              disabled={disabled}
              label={label}
              helperText={helperText}
              variant="filled"
            />
          </>
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id}>
              <styled.SelectFieldOption className="select-field-option">
                {Boolean(option?.icon) && (
                  <styled.SelectFieldOptionIcon className="select-field-option-icon">
                    {option?.icon}
                  </styled.SelectFieldOptionIcon>
                )}
                <div>
                  <div>{option?.label}</div>
                  {Boolean(option?.caption) && (
                    <styled.SelectFieldOptionCaption className="select-field-option-caption">
                      {option?.caption}
                    </styled.SelectFieldOptionCaption>
                  )}
                </div>
              </styled.SelectFieldOption>
            </li>
          )
        }}
      />
    </styled.Wrapper>
  )
}
