import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  IconButton,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Option } from "./styled"
import { api } from "../../../../../../../../api"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ModalConfirmation } from "../../../../../../../../components"
import dispatchToast from "src/utils/dispatchToast"

export const ModelOptions = ({ modelId, flowId }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    api.mutation.hiring.modelProposedLetter.delete,
  )
  const { mutateAsync: handleDuplicate } = useMutation(
    api.mutation.hiring.modelProposedLetter.duplicate,
  )

  const options = useMemo(() => {
    const items = [
      {
        key: "preview",
        onClick: () =>
          window.open(
            `/flows/settings/${flowId}/proposedLetter/preview/${modelId}`,
            "_blank",
          ),
        children: (
          <Option>
            <Icons name="IconFileSearch" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Pré-visualizar modelo
            </Typography>
          </Option>
        ),
      },
      {
        key: "edit",
        onClick: () => {
          navigate(
            `/flows/settings/${flowId}/proposedLetter/edit/basic-info?modelId=${modelId}`,
          )
        },
        children: (
          <Option>
            <Icons name="IconPencil" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Editar modelo
            </Typography>
          </Option>
        ),
      },
      {
        key: "duplicate",
        onClick: async () => {
          await handleDuplicate(modelId)
          dispatchToast({
            content: "Modelo de carta proposta duplicado com sucesso!",
            type: "success",
          })
          queryClient.refetchQueries(["all-model-proposed-letter", flowId])
        },
        children: (
          <Option>
            <Icons name="IconCopy" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Duplicar modelo
            </Typography>
          </Option>
        ),
      },
      {
        key: "delete",
        onClick: () => setDeleteModalOpen(true),
        children: (
          <Option>
            <Icons name="IconTrash" fill="transparent" color="#C96C01" />
            <Typography
              variant="body3"
              style={{ fontWeight: 600, color: "#C96C01" }}
            >
              Excluir modelo
            </Typography>
          </Option>
        ),
      },
    ]

    return items
  }, [modelId])

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </Menu>
      <ModalConfirmation
        isOpen={deleteModalOpen}
        isLoading={isDeleting}
        variant="error"
        title="Tem certeza que deseja excluir o modelo de carta proposta?"
        description="Todas as informações relacionadas serão perdidas e essa ação não poderá ser desfeita."
        cancelLabel="Cancelar"
        confirmLabel="Confirmar exclusão"
        confirmIcon="IconTrash"
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={async () => {
          await handleDelete(modelId)
          queryClient.refetchQueries(["all-model-proposed-letter", flowId])
          setDeleteModalOpen(false)
        }}
      />
    </>
  )
}
