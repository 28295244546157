import { useState, useEffect } from "react"

import { Search } from "./styles"

interface FieldsSearchProps {
  onChange: (value: string) => void
  disabled: boolean
}

export const FieldsSearch = ({ onChange, disabled }: FieldsSearchProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (inputValue === undefined) return

    const timer = setTimeout(() => {
      onChange(inputValue)
    }, 700)

    return () => timer && clearTimeout(timer)
  }, [inputValue])

  const handleOnChange = (value) => {
    setInputValue(value)
  }

  const handleOnBlur = (value) => {
    const target = value?.target?.value
    handleOnChange(target || "")
  }
  const handleOnInput = (value) => {
    const target = value?.target?.value
    handleOnChange(target || "")
  }

  return (
    <Search
      onChange={(e) => handleOnChange(e.target.value)}
      onSearchChange={handleOnBlur}
      onInputChange={handleOnInput}
      label="Buscar"
      disabled={disabled}
    />
  )
}
