import { LinkButton, Typography, dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import {
  GridBigNumberContainer,
  RowCell,
  StatusCardContainer,
  StyledBigNumber,
} from "./styles"
import { Flow, HiringCard, FlowBigNumbers } from "../../../types"
import { Insights } from "../../common/Insights"
import {
  hiringColumnsId,
  hiringFlowColumns,
  hiringLimitDateMap,
} from "../../../mock/board"
import { getColumnStatusOptions } from "../../../mock/status"

const baseColumns = (flow: Flow) => [
  {
    header: "Candidato",
    accessorKey: "name",
    cell: (context) => (
      <RowCell noLimitWidth={true}>
        <Typography variant="body3">
          {context.cell.row.original.name}
        </Typography>
        <Typography variant="body4" variantColor="#9F939B">
          {context.cell.row.original.email}
        </Typography>
      </RowCell>
    ),
  },
  {
    header: "Cargo",
    accessorKey: "role",
    cell: (context) => (
      <RowCell>{context.cell.row.original.role?.name || ""}</RowCell>
    ),
  },
  {
    header: "Data prevista da admissão",
    accessorKey: "hiringDate",
    cell: (context) => (
      <RowCell>
        {context.cell.row.original.hiringDate
          ? dayjs(context.cell.row.original.hiringDate).format("DD/MM/YYYY")
          : ""}
      </RowCell>
    ),
  },
  {
    header: "Status",
    accessorKey: "status",
    cell: (context) => {
      const columnId = context.cell.row.original.columnId
      const cardStatus: string = context.cell.row.original.status

      const columnStatus = getColumnStatusOptions(columnId)
      const statusInfo = columnStatus.status.find(
        (status) => status.name === cardStatus,
      )

      return (
        <RowCell noLimitWidth={true}>
          {statusInfo ? (
            <StatusCardContainer
              style={{ backgroundColor: statusInfo?.colors?.background }}
            >
              <Typography
                variant="caption"
                style={{
                  fontWeight: 600,
                  color: statusInfo?.colors?.text,
                }}
              >
                {statusInfo.name}
              </Typography>
            </StatusCardContainer>
          ) : (
            <></>
          )}
        </RowCell>
      )
    },
  },
]

const limitDateColumn = {
  header: "Prazo para retorno do candidato",
  accessorKey: "columnId",
  cell: (context) => {
    const card = context.cell.row.original as HiringCard
    const columnInfo = hiringFlowColumns.find((column) =>
      column.columnsId.includes(card.columnId),
    )
    if (!columnInfo) return <RowCell></RowCell>
    const enumsToSearch = hiringLimitDateMap[columnInfo.name]

    let value = ""
    if (enumsToSearch?.length > 0) {
      const limitDateFound = card.limitDates.find((limitDate) => {
        return enumsToSearch.includes(limitDate.step)
      })

      if (limitDateFound && limitDateFound.date)
        value = dayjs(limitDateFound.date).format("DD/MM/YYYY")
    }

    return <RowCell>{value}</RowCell>
  },
}

export function getColumnsByKey(columnKey: string, flow: Flow) {
  const column = [
    {
      name: "all",
      ids: ["all"],
      column: [
        ...baseColumns(flow),
        {
          header: "Etapa",
          accessorKey: "columnId",
          cell: (context) => {
            const cardColumnId = context.cell.row.original.columnId
            const columnName = flow.columns?.find(
              (column) => column._id === cardColumnId,
            )
            return <RowCell>{columnName?.name || ""}</RowCell>
          },
        },
        limitDateColumn,
      ],
    },
    {
      name: "aso",
      ids: hiringColumnsId.admission,
      column: [...baseColumns(flow), limitDateColumn],
    },
    {
      name: "proposedLetter",
      ids: hiringColumnsId.proposedLetter,
      column: [...baseColumns(flow), limitDateColumn],
    },
    {
      name: "documentsSend",
      ids: hiringColumnsId.documentsSend,
      column: [...baseColumns(flow), limitDateColumn],
    },
    {
      name: "signing",
      ids: hiringColumnsId.signing,
      column: [...baseColumns(flow), limitDateColumn],
    },
  ]

  const foundColumn = column.find((info) => info.ids.includes(columnKey))
  if (!foundColumn) return baseColumns(flow)
  return foundColumn.column
}

export function getColumnBigNumber(
  columnKey: string,
  bigNumbersData: FlowBigNumbers,
  navigate: any,
) {
  const bigNumbers = [
    {
      name: "all",
      ids: ["all"],
      component: () => {
        return <Insights bigNumbers={bigNumbersData} hasHeader={false} />
      },
    },
    {
      name: "proposedLetter",
      ids: hiringColumnsId.proposedLetter,
      component: () => {
        return (
          <GridBigNumberContainer>
            <StyledBigNumber
              icon="IconMailForward"
              iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
              title="Envios de proposta pendentes"
              value={bigNumbersData.pendingProposalSubmission?.length ?? 0}
              button={<></>}
              orientation="horizontal"
            />
            <StyledBigNumber
              icon="IconCalendarTime"
              iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
              title="Propostas com prazo expirado"
              value={bigNumbersData.expiredProposals?.length ?? 0}
              button={<></>}
              orientation="horizontal"
            />
            <StyledBigNumber
              icon="IconX"
              iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
              title="Propostas recusadas"
              value={bigNumbersData.refusedProposals?.length ?? 0}
              button={<></>}
              orientation="horizontal"
            />
          </GridBigNumberContainer>
        )
      },
    },
    {
      name: "documentsSend",
      ids: hiringColumnsId.documentsSend,
      component: () => {
        return (
          <GridBigNumberContainer>
            <StyledBigNumber
              icon="IconUser"
              iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
              title="Solicitações de envio pendentes"
              value={bigNumbersData.pendingDocumentsRequest?.length ?? 0}
              button={<></>}
              orientation="horizontal"
            />
            <StyledBigNumber
              icon="IconCalendarTime"
              iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
              title="Solicitações com prazo expirado"
              value={bigNumbersData.expiredDocumentsRequest?.length ?? 0}
              button={<></>}
              orientation="horizontal"
            />
          </GridBigNumberContainer>
        )
      },
    },
    {
      name: "signing",
      ids: hiringColumnsId.signing,
      component: () => {
        return (
          <StyledBigNumber
            style={{ width: "50%" }}
            icon="IconPencilPlus"
            iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
            title="Assinaturas de contrato pendentes"
            value={bigNumbersData.pendingSigning?.length ?? 0}
            button={
              bigNumbersData.pendingSigning?.length > 0 ? (
                <LinkButton
                  variant="primary"
                  onClick={() => {
                    const parsedIds = bigNumbersData.pendingSigning.join(",")
                    navigate(`/flows/actions/signing?flowCardsId=${parsedIds}`)
                  }}
                >
                  Assinar contratos
                </LinkButton>
              ) : (
                <></>
              )
            }
            orientation="horizontal"
          />
        )
      },
    },
  ]

  const foundBignumber = bigNumbers.find((info) => info.ids.includes(columnKey))
  if (!foundBignumber) return <></>
  return foundBignumber.component()
}
