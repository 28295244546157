import {
  Button,
  Icons,
  LinkButton,
  Modal,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../api"
import styled, { useTheme } from "styled-components"
import { FeatureNotification } from "../../screen/FeatureNotification/features"
import React from "react"

export type NotificationFeatureModalProps = {
  features: FeatureNotification[]
  handleClose: () => void
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const IconCircleContainer = styled.div`
  border-radius: 50%;
  width: 80px;
  height: 80px;

  background-color: ${({ theme }) => theme.colors.secondary[95]};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  > svg {
    width: 48px;
    height: 48px;
    color: ${({ theme }) => theme.colors.secondary[50]};
  }
`

export const NotificationFeatureModal = ({
  features,
  handleClose,
}: NotificationFeatureModalProps) => {
  const [page, setPage] = React.useState<number>(0)
  const theme = useTheme()

  const { data: gifs, isInitialLoading: isLoadingGif } = useQuery(
    ["new-feature-gif", ...features?.map((feature) => feature.identifier)],
    () => {
      const files = features.map((feature) => ({
        identifier: feature.identifier,
        path: feature.modal.gifPath,
        module: "hiring",
      }))
      return api.mutation.helper.getMultipleS3File(files)
    },
    {
      enabled: !!features,
    },
  )

  const onlyOneFeature = features?.length === 1
  const isLastPage = features?.length === page + 1

  const handleSubmit = () => {
    if (onlyOneFeature || isLastPage) {
      handleClose()
      return
    }

    setPage((prev) => {
      return prev + 1
    })
  }

  const handleBack = () => {
    if (!onlyOneFeature && page > 0) {
      setPage((prev) => {
        return prev - 1
      })
      return
    }

    handleClose()
  }

  if (features?.length === 0) return <></>

  const feature = features[page]

  return (
    <Modal.Root open={features?.length > 0} onClose={handleClose} size="xs">
      <>
        <Modal.Content>
          <ContentContainer>
            <IconCircleContainer>
              <Icons name="IconSparkles" fill="transparent" />
            </IconCircleContainer>

            <Typography
              variant="body3"
              variantColor={theme.colors.secondary[50]}
              weight={700}
              style={{ marginTop: 20 }}
            >
              Novidade!
            </Typography>

            <Typography
              variant="headline6"
              variantColor={theme.colors.neutral[20]}
              style={{ marginTop: 8 }}
            >
              {feature.modal.title}
            </Typography>

            <Typography
              variant="body3"
              variantColor={theme.colors.neutral[50]}
              style={{ marginTop: 15 }}
            >
              {feature.modal.description}
            </Typography>

            {feature.modal.gifPath ? (
              <div style={{ marginTop: 10, width: "100%", minHeight: 215 }}>
                {!gifs || isLoadingGif ? (
                  <Skeleton variant="rectangular" width={"100%"} height={215} />
                ) : (
                  <img
                    src={
                      gifs.find(
                        (gifS3) => gifS3?.identifier === feature.identifier,
                      )?.signedUrl
                    }
                    alt="Feature tutorial"
                    style={{
                      width: "100%",
                    }}
                  />
                )}
              </div>
            ) : (
              <></>
            )}
          </ContentContainer>
        </Modal.Content>
        <Modal.Footer>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LinkButton
              variant="primary"
              onClick={handleBack}
              style={{ alignSelf: "center" }}
            >
              {onlyOneFeature || page === 0 ? "Ver mais tarde" : "Voltar"}
            </LinkButton>

            <Button size="large" variant="primary" onClick={handleSubmit}>
              {isLastPage ? "Começar a usar" : "Próximo"}
            </Button>
          </div>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}
