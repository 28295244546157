import { useParams } from "react-router-dom"
import { ResignationCardPage } from "../../../components"

import { Exam } from "./Content"

const ResignationExam = () => {
  const { id: cardId = "" } = useParams()

  return (
    <ResignationCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Exame demissional",
          href: `/flows/resignation/exam/${cardId}`,
        },
      ]}
      contents={{ default: Exam }}
    />
  )
}

export default ResignationExam
