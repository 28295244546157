import { gql } from "graphql-request"
import { request } from "../client"
import { ChecklistData } from "../../types"

const GET_CHECKLIST_TEMPLATE = gql`
  query TemplateGetChecklist($flowId: ID!) {
    templateGetChecklist(flowId: $flowId) {
      _id
      columnId
      flowId
      companyId
      items
    }
  }
`

export const getChecklistTemplate = async (flowId: string) => {
  const { templateGetChecklist } = await request(GET_CHECKLIST_TEMPLATE, {
    flowId,
  })
  return templateGetChecklist as ChecklistData[]
}
