import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

import styled, { css } from "styled-components"
import { DeprecatedModal } from "../DeprecatedModal"

export const StyledModal = styled(DeprecatedModal)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 645px;
      max-height: 95vh;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 40px 112px;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconContainer = styled.div<{
  customVariant: "primary" | "warning"
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  width: 64px;
  height: 64px;

  background-color: ${({ customVariant, theme }) =>
    customVariant === "warning"
      ? theme.colors.feedback.error[90]
      : theme.colors.secondary[95]};
  border-radius: 200px;
  margin-bottom: 16px;
`

export const StyledSubtitle = styled(Typography)<{
  customVariant: "primary" | "warning"
}>`
  && {
    font-weight: 700;
    color: ${({ customVariant, theme }) =>
      customVariant === "warning"
        ? theme.colors.feedback.error[40]
        : theme.colors.secondary[50]};
  }
`

export const StyledTitle = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral[20]}};
  }
`

export const StyledText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral[50]}};
  }
`

export const StyledButton = styled(Button)<{
  customVariant: "primary" | "warning"
}>`
  ${({ customVariant }) =>
    customVariant === "warning"
      ? css`
          color: ${({ theme }) => theme.colors.feedback.error[40]} !important;
          border: none !important;
          background-color: ${({ theme }) =>
            theme.colors.feedback.error[90]} !important;

          &:hover,
          &:focus {
            box-shadow: 0 8px 40px
              ${({ theme }) => theme.colors.feedback.error[90]} !important;
          }
        `
      : undefined}
`

export const StyledIcon = styled(Icons)<{
  customVariant: "primary" | "warning"
}>`
  && {
    color: ${({ customVariant, theme }) =>
      customVariant === "warning"
        ? theme.colors.feedback.error[40]
        : theme.colors.secondary[50]};
  }
`
