import { useParams } from "react-router-dom"
import { ResignationCardPage } from "../../../components"
import { StartProcessContent } from "./StartProcessContent"

const StartProcess = () => {
  const { id: cardId = "" } = useParams()

  return (
    <ResignationCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Processo iniciado",
          href: `/flows/resignation/startProcess/${cardId}`,
        },
      ]}
      contents={{ default: StartProcessContent }}
    />
  )
}

export default StartProcess
