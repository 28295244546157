import { CSSProperties } from "react"
import { getNameInitials } from "../../../utils"
import { ProfileImage, ProfileInitials } from "./styles"

interface ProfilePicture {
  src?: string
  alt?: string
  userName: string
  width?: string
  height?: string
  fontSize?: string
  style?: CSSProperties
}

const ProfilePicture = ({
  src,
  alt,
  userName,
  width = "120px",
  height = "120px",
  fontSize = "50px",
  style,
}: ProfilePicture) => {
  const nameInitials = getNameInitials(userName)

  if (src) {
    return (
      <ProfileImage
        src={src}
        alt={alt}
        width={width}
        height={height}
        style={style}
      />
    )
  }

  return (
    <ProfileInitials
      width={width}
      height={height}
      fontSize={fontSize}
      style={style}
    >
      {nameInitials}
    </ProfileInitials>
  )
}

export default ProfilePicture
