import { useState, useMemo } from "react"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { cloneDeep } from "lodash"

import { FieldsSearch } from "./FieldsSearch"
import { AccordionItem } from "./AccordionItem"

import { HiringTemplateField } from "../../../../../../types"

import { StyledAccordion, FieldsContainer, TextsContainer } from "./styles"

interface FieldsProps {
  hiringFields: HiringTemplateField[]
  disabled?: boolean
  onFieldClicked: ({
    field,
  }: {
    field: HiringTemplateField["fields"][0]
  }) => void
}

export const Fields = ({
  hiringFields = [],
  disabled,
  onFieldClicked,
}: FieldsProps) => {
  const [search, setSearch] = useState<string>("")

  const filteredFields = useMemo(() => {
    if (!search) return hiringFields

    let dataToFilter = cloneDeep(hiringFields)

    const removeAccents = (inputString: string) => {
      const accentRegex = /[\u0300-\u036f]/g
      const normalizeString = inputString
        .normalize("NFD")
        .replace(accentRegex, "")
      return normalizeString.normalize("NFC")
    }

    if (search) {
      dataToFilter = dataToFilter
        ?.map((item) => {
          return {
            ...item,
            fields: (item.fields || []).filter((field) =>
              removeAccents(field.label)
                .toLowerCase()
                .includes(removeAccents(search).toLowerCase()),
            ),
          }
        })
        .filter((item) => (item.fields || []).length)
    }

    return dataToFilter
  }, [search, hiringFields])

  return (
    <FieldsContainer>
      <TextsContainer>
        <Typography variant="headline8" style={{ color: "#53464F" }}>
          Campos dinâmicos
        </Typography>
        <Typography variant="body4" style={{ color: "#83727D" }}>
          Você pode adicionar um campo dinâmico nesta seção. Basta escolher a
          informação abaixo e clicar para inseri-la no texto.
        </Typography>
      </TextsContainer>
      <FieldsSearch onChange={setSearch} disabled={!!disabled} />
      {filteredFields.map((hiringField) => (
        <StyledAccordion
          key={hiringField.id + !!search}
          disabled={disabled}
          defaultExpanded={!!search}
          customHeader={
            <Typography
              variant="body4"
              style={{ color: "#53464F", fontWeight: "700" }}
            >
              {hiringField.title}
            </Typography>
          }
          variant="default"
        >
          {hiringField.fields.map((field) => (
            <AccordionItem
              key={field.id}
              title={field.label}
              onClick={() => onFieldClicked({ field })}
            />
          ))}
        </StyledAccordion>
      ))}
    </FieldsContainer>
  )
}
