import { useEffect, useState } from "react"
import { Dialog } from "@mui/material"
import { useSearchParams, useNavigate } from "react-router-dom"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

import { MainContainer, PageContents } from "./styles"

import Header from "./components/Header"
import ContractVisualizer from "./components/ContractVisualizer"

import { GET_MODEL_CONTRACT_BY_ID } from "../../api/queries/get_model_contract_by_id"
import { useLazyQuery } from "@apollo/client"

const PreviewContracts = () => {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const [modelId, setModelId] = useState("")

  useEffect(() => {
    const modelId = searchParams.get("modelId")
    if (modelId) setModelId(modelId)

    const customModelId = searchParams.get("customModelId")

    if (!modelId && !customModelId) navigate("/flows/hiring")
  }, [searchParams])

  const [fetchModelContractById, { loading: loadingModel, data: modelData }] =
    useLazyQuery<{
      getModelContractById: any
    }>(GET_MODEL_CONTRACT_BY_ID, {
      variables: { modelContractId: modelId },
      fetchPolicy: "network-only",
    })

  useEffect(() => {
    if (modelId) fetchModelContractById()
  }, [modelId])

  const { getModelContractById: modelContract } = modelData || {}

  return (
    <Dialog open={true} keepMounted fullScreen transitionDuration={0}>
      <MainContainer>
        <Header />
        <PageContents>
          {loadingModel ? (
            <Skeleton variant="rounded" height={"100%"} width={"100%"} />
          ) : (
            <ContractVisualizer path={modelContract?.preview?.value} />
          )}
        </PageContents>
      </MainContainer>
    </Dialog>
  )
}

export default PreviewContracts
