import { useEffect, useState, useMemo } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Loader, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { PageTemplate, RouteHistory } from "../../components/"
import dispatchToast from "../../utils/dispatchToast"
import { Flow, FlowCategory } from "../../types"
import { Container, SpinnerContainer, Tab, TabContainer } from "./styles"
import { GET_FLOW_BY_ID } from "../../api/queries/get_flow_by_id"

const hiringTypesAlias = {
  ["clt"]: "Contratação CLT",
  ["pj"]: "Contratação PJ",
  ["internship"]: "Contratação Estágio",
}

enum ConfigurationTabs {
  proposedLetter = "proposedLetter",
  documents = "documents",
  contract = "contract",
  checklist = "checklist",
  notification = "notification",
}

type PageTab = {
  name: ConfigurationTabs
  title: string
  route: string
  breadcrumbs: RouteHistory[]
}

function getTabByCategory(flowId: string, category: FlowCategory): PageTab[] {
  const tabs: { [key in FlowCategory]: PageTab[] } = {
    ["hiring"]: [
      {
        name: ConfigurationTabs.proposedLetter,
        title: "Modelos de carta proposta",
        route: `/flows/settings/${flowId}/proposedLetter`,
        breadcrumbs: [
          { name: "Admissão", href: "/flows/hiring" },
          {
            name: "Modelos de carta proposta",
            href: `/flows/settings//${flowId}/proposedLetter`,
          },
        ],
      },
      {
        name: ConfigurationTabs.documents,
        title: "Modelos de documentos",
        route: `/flows/settings/${flowId}/documents/list`,
        breadcrumbs: [
          { name: "Admissão", href: "/flows/hiring" },
          {
            name: "Modelos de documentos",
            href: `/flows/settings//${flowId}/documents/list`,
          },
        ],
      },
      {
        name: ConfigurationTabs.contract,
        title: "Modelos de contratos",
        route: `/flows/settings/${flowId}/contracts`,
        breadcrumbs: [
          { name: "Admissão", href: "/flows/hiring" },
          {
            name: "Modelos de contratos",
            href: `/flows/settings//${flowId}/contracts`,
          },
        ],
      },
      {
        name: ConfigurationTabs.checklist,
        title: "Modelos de checklist",
        route: `/flows/settings/${flowId}/checklist`,
        breadcrumbs: [
          { name: "Admissão", href: "/flows/hiring" },
          {
            name: "Modelo de checklist",
            href: `/flows/settings/${flowId}/checklist`,
          },
        ],
      },
      {
        name: ConfigurationTabs.notification,
        title: "Notificações",
        route: `/flows/settings/${flowId}/notification`,
        breadcrumbs: [
          { name: "Admissão", href: "/flows/hiring" },
          {
            name: "Notificações",
            href: `/flows/settings/${flowId}/notification`,
          },
        ],
      },
    ],
    ["resignation"]: [
      {
        name: ConfigurationTabs.checklist,
        title: "Modelos de checklist",
        route: `/flows/settings/${flowId}/checklist`,
        breadcrumbs: [
          { name: "Desligamento", href: "/flows/resignation" },
          {
            name: "Configurações do Kanban",
            href: `/flows/settings/${flowId}/checklist`,
          },
        ],
      },
    ],
  }

  return tabs[category]
}

const ConfigurationPage = () => {
  const [activeTab, setActiveTab] = useState<ConfigurationTabs>()

  const { flowId = "" } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const { data, loading } = useQuery<{ getFlowById: Flow }>(GET_FLOW_BY_ID, {
    variables: { flowId },
    fetchPolicy: "cache-first",
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors[0]
      const errorCode = graphqlErrors?.errorCode as string

      let errorMessage = "Erro ao buscar dados, tente novamente mais tarde."

      if (errorCode === "HIRING_FLOW_DOES_NOT_EXISTS_ERROR") {
        errorMessage = "Erro! não foi possível encontrar o tipo de contratação."

        navigate(`/flows/hiring`)
      }

      dispatchToast({
        type: "error",
        content: errorMessage,
      })
    },
  })

  const { getFlowById: flow } = data || {}

  const pages = useMemo(() => {
    if (!flow) return []
    return getTabByCategory(flow._id, flow.category)
  }, [flow])

  const currentPage = pages?.find((page) =>
    location.pathname.includes(page.route),
  ) as PageTab

  useEffect(() => {
    if (!flow || loading) return

    if (!currentPage) {
      const defaultRedirect = {
        hiring: "/flows/hiring",
        resignation: "/flows/resignation",
      }

      navigate(defaultRedirect[flow.category])
      return
    }

    setActiveTab(currentPage.name)
  }, [currentPage])

  const configurationHeader: { title: JSX.Element; description: JSX.Element } =
    useMemo(() => {
      if (activeTab == null)
        return {
          title: <>Configurações do Kanban</>,
          description: <>Gerencie as configurações do kanban</>,
        }

      switch (activeTab) {
        case ConfigurationTabs.notification:
          return {
            title: <>Configurações de Gestão de fluxos</>,
            description: (
              <>
                Personalize as configurações do{" "}
                <b>Módulo de Gestão de fluxos.</b>
              </>
            ),
          }
        default: {
          if (flow?.category === "hiring") {
            const hiringType = (
              <b>{hiringTypesAlias[flow.subcategory || "clt"]}</b>
            )
            return {
              title: <>Configurações do Kanban de {hiringType}</>,
              description: (
                <>Gerencie as configurações do kanban de {hiringType}</>
              ),
            }
          }

          return {
            title: <>Configurações do Kanban de Desligamento</>,
            description: (
              <>Gerencie as configurações do kanban de Desligamento</>
            ),
          }
        }
      }
    }, [activeTab])

  if (loading || !flow) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  return (
    <PageTemplate
      history={currentPage.breadcrumbs}
      headerStyle={{ border: "none" }}
      disableGoBack
    >
      <Container>
        <Typography variant="headline6" style={{ color: "#3B2E37" }}>
          {configurationHeader.title}
        </Typography>

        <Typography
          variant="body3"
          style={{ color: "#83727D", marginTop: 8, marginBottom: 35 }}
        >
          {configurationHeader.description}
        </Typography>

        <TabContainer>
          {pages.map((page, idx) => (
            <Tab
              key={`tab_config_${idx}`}
              active={activeTab === page.name}
              onClick={() => navigate(page.route)}
            >
              {page.title}
            </Tab>
          ))}
        </TabContainer>

        <Outlet context={[flow]} />
      </Container>
    </PageTemplate>
  )
}

export default ConfigurationPage
