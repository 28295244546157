import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
`

export const CandidateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.m};
`

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
`

export const ProfileTitle = styled.div`
  font-family: Cabinet Grotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.primary};
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`
