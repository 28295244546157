import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`
