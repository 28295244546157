import { useContext, useEffect, useMemo, useState } from "react"
import { IconButton, Icons, Toggle } from "@flash-tecnologia/hros-web-ui-v2"
import { Grid } from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../api"
import { resignationContext } from "../../../context"
import { OrgchartInfoBox } from "../../../pages/Resignation/Home/Modal/LeadershipHandler/OrgchartInfoBox"
import {
  Avatar,
  AvatarName,
  EmptyContainer,
  StyledDataGrid,
  StyledEmptyText,
  StyledGridContainer,
  StyledSearchField,
  StyledSecondaryTitle,
  StyledSelectField,
  StyledTableGrid,
  StyledTableText,
  StyledText,
  StyledTitle,
} from "./styles"
import { FooterBar } from "../../common/Components/FooterBar"
import { useNavigate, useParams } from "react-router-dom"
import dispatchToast from "../../../utils/dispatchToast"

export const ReplaceLeaderContent = () => {
  const { leaders } = useContext(resignationContext)
  const queryClient = useQueryClient()
  const { flowId = '' } = useParams()
  const navigate = useNavigate()

  const [totalEmployees, setTotalEmployees] = useState(0)
  const [totalLeaders, setTotalLeaders] = useState(0)
  const [showEmptyComponent, setShowEmptyComponent] = useState(false)
  const [rightList, setRightList] = useState<any[]>([])
  const [orgchartLeaders, setOrgchartLeaders] = useState<any[]>([])
  const [filteredLeaders, setFilteredLeaders] = useState<any[]>([])

  const [isChangeList, setIsChangeList] = useState(
    orgchartLeaders?.reduce((acc, leader) => {
      acc[leader._id] = false
      return acc
    }, {}),
  )

  const [hasSelectedLeader, setHasSelectedLeader] = useState(
    orgchartLeaders?.reduce((acc, leader) => {
      acc[leader._id] = null
      return acc
    }, {}),
  )

  const avatarName = (name = "") => {
    const nameSplited = name.split(" ")
    const firstName = nameSplited[0] || ""
    const lastName = nameSplited[1] || ""
    return `${firstName[0] || ""}${lastName[0] || ""}`
  }

  const onSearch = (value: string) => {
    const result = orgchartLeaders.filter((leader) =>
      leader?.name?.match(new RegExp(value, "ig")),
    )

    setFilteredLeaders([...result])
  }

  const handleToggleChange = (leaderId: string) => {
    setIsChangeList((prevIsChangeList) => {
      const updatedIsChangeList = {
        ...prevIsChangeList,
        [leaderId]: !prevIsChangeList[leaderId],
      }

      const updatedFilteredLeaders = orgchartLeaders.map((leader) => ({
        ...leader,
        isChange: updatedIsChangeList[leader._id],
      }))

      setFilteredLeaders(updatedFilteredLeaders)

      return updatedIsChangeList
    })
  }

  const handleSelectLeader = (leaderId: string, selectedLeaderValue?: any) => {
    setHasSelectedLeader((prevHasLeader) => {
      const updatedLeader = {
        ...prevHasLeader,
        [leaderId]: selectedLeaderValue?.value || null,
      }

      const updatedFilteredLeaders = orgchartLeaders.map((leader: any) => ({
        ...leader,
        employeeId:
          leader._id === leaderId
            ? selectedLeaderValue?.value
            : leader.employeeId,
      }))


      setFilteredLeaders(updatedFilteredLeaders)

      return updatedLeader
    })
  }

  const handleNextButtonClick = () => {
    setShowEmptyComponent(true)
    const isAnyEmpty = orgchartLeaders.map((leader) => {
      if (isChangeList[leader._id] && !hasSelectedLeader[leader._id]) {
        dispatchToast({
          content: `Preencha os campos pendentes na tabela. Certifique-se de conferir todas as páginas.`,
          type: "error",
        })
        return true
      } else {
        return false
      }
    })

    if (!isAnyEmpty.includes(true)) {
      const updatedLeaders = orgchartLeaders.map((leader) => ({
        personToBeReplaced: leader?._id,
        levelToSave: 0,
        keepChildren: true,
        isChange: isChangeList[leader._id] ? isChangeList[leader._id] : false,
        employeeId: hasSelectedLeader[leader._id]
          ? hasSelectedLeader[leader._id]
          : null,
      }))
      changeMultiple({ employees: updatedLeaders })
    }
    setShowEmptyComponent(false)
  }

  const { data: leadersFromOrgchart, isInitialLoading: isLeadersLoading } = useQuery(
    ["get-leaders"],
    () => api.query.orgchart.getLeadersFromOrgchart(),
    {
      retry: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  )

  const { mutate: createManyCard } = useMutation(
    api.mutation.resignation.card.createManyCard,
  )

  const { mutate: changeMultiple, isLoading: isChanging } = useMutation(
    api.mutation.orgchart.changeMultipleEmployeeInOrgchart,
    {
      onSuccess: async () => {
        dispatchToast({
          content: `${totalEmployees} pessoas adicionadas no fluxo de desligamento com sucesso.`,
          type: "success",
        })

        setShowEmptyComponent(false)
        await createManyCard(
          {
            employeeIds: rightList.map((r) => r._id),
            flowId,
          },
          {
            onSuccess: () => {
              dispatchToast({
                content: "Pessoas incluidas ao fluxo com sucesso!",
                type: "success",
              })

              queryClient.invalidateQueries(["flows", flowId])
              navigate(`/flows/resignation`)
            },
          },
        )
      },
      onError: () => {
        setShowEmptyComponent(false)
        dispatchToast({
          content: `Não foi possível completar esta ação. Tente novamente!`,
          type: "error",
        })
      },
    },
  )

  useEffect(() => {
    setOrgchartLeaders(leaders?.employees || [])
    setFilteredLeaders(leaders?.employees || [])
    setTotalEmployees(leaders?.totalEmployees || 0)
    setTotalLeaders(leaders?.totalLeaders || 0)
    setRightList(leaders?.rightListEmployees || [])
  }, [leaders])

  const EmptyLeaderComponent = () => {
    return (
      <EmptyContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <StyledEmptyText variant="body3" weight={600}>
            Selecionar
          </StyledEmptyText>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <StyledText variant="caption" weight={600}>
              Este campo deve ser preenchido.
            </StyledText>
            <Icons
              fill="transparent"
              name="IconInfoCircle"
              size={16}
              color="#83727D"
            />
          </div>
        </div>

        <IconButton
          size="medium"
          variant="line"
          icon="IconPencil"
          onClick={() => setShowEmptyComponent(false)}
        />
      </EmptyContainer>
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: "Líder em desligamento",
        disableSortBy: true,
        accessor: "name",
        Cell: ({
          row: {
            original: { name = null },
          },
        }) => {
          if (name) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Avatar>
                  <AvatarName variant="body1">{avatarName(name)}</AvatarName>
                </Avatar>
                <StyledTableText variant="body3" weight={600}>
                  {name}
                </StyledTableText>
              </div>
            )
          } else {
            return null
          }
        },
      },
      {
        Header: "Escolher substituto?",
        disableSortBy: true,
        accessor: "isChange",
        Cell: ({ row }) => {
          if (row?.original?._id) {
            return (
              <Toggle
                checked={isChangeList[row?.original?._id]}
                onChange={() => {
                  handleToggleChange(row?.original?._id)
                }}
              />
            )
          } else {
            return null
          }
        },
      },
      {
        Header: "Novo líder",
        disableSortBy: true,
        accessor: "employeeId",
        Cell: ({ row }) => {
          if (row?.original?._id && isChangeList[row?.original?._id]) {
            if (!showEmptyComponent) {
              return (
                <StyledSelectField
                  label="Selecionar"
                  onSelectChange={(_, value) =>
                    handleSelectLeader(row?.original?._id, value)
                  }
                  value={hasSelectedLeader[row?.original?._id]}
                  options={leadersFromOrgchart}
                  searchable={true}
                  noOptionsText={"Sem opções"}
                />
              )
            } else {
              return <EmptyLeaderComponent />
            }
          } else {
            return null
          }
        },
      },
    ],
    [
      showEmptyComponent,
      leadersFromOrgchart,
      isChangeList,
      hasSelectedLeader,
      filteredLeaders,
      leaders,
    ],
  )
  return (
    <>
      <StyledGridContainer container>
        <Grid item md={12}>
          <StyledTitle variant="headline6">Selecionar pessoas </StyledTitle>
        </Grid>
        <Grid item md={4} style={{ marginTop: "40px", paddingRight: "24px" }}>
          <StyledSecondaryTitle variant="headline7">
            Substituir líderes
          </StyledSecondaryTitle>
          <StyledText variant="body3">
            Algumas das pessoas selecionada são líderes de equipes atualmente.
            Deseja selecionar novos líderes para substituí-los?
          </StyledText>
        </Grid>
        <StyledTableGrid item md={8}>
          <StyledTableText variant="body3" weight={700}>
            {totalLeaders || 0} das {totalEmployees || 0} pessoas selecionadas
            para desligamento são líderes e precisarão ser substituídas para
            evitar que os membros da equipe sejam desvinculados do organograma
            empresarial.
          </StyledTableText>
          <OrgchartInfoBox plural={true} />
          <StyledSearchField
            label="Buscar por líder"
            onChange={(e) => {
              onSearch(e.target.value)
            }}
          />
          <StyledDataGrid
            columns={columns}
            data={filteredLeaders || []}
            loading={isLeadersLoading}
            hasPagination={true}
            pageSizeOptions={[
              { label: "5 itens", value: 5 },
              { label: "10 itens", value: 10 },
              { label: "20 itens", value: 20 },
            ]}
          />
        </StyledTableGrid>
      </StyledGridContainer>
      <FooterBar
        cancelHandleClick={() => {
          navigate(`/flows/resignation`)
        }}
        previousHandleClick={() => {
          navigate(`/flows/resignation/selectPeople/${flowId}`)
        }}
        isLoading={isChanging}
        nextHandleClick={() => {
          handleNextButtonClick()
        }}
        steps={[]}
        currentStep={0}
      />
    </>
  )
}
