import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const StyledIcon = styled(Icons)`
  margin-right: ${({ theme }) => theme.spacings.xs3};
  width: 16px;
  height: 16px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
