import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;
`

export const HeaderContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;
  gap: 10px;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  gap: 16px;
  border-radius: 40px;
  padding: 13px 24px 13px 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 35px;
`
