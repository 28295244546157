import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  :not(:last-child) {
    border-bottom: 1px solid #e8e3e6;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 24px;

  :not(:last-child) {
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & > button,
    & > div {
      width: 100% !important;
    }
  }
`
