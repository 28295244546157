export type ModelTypeFileAvailable = "XLSX" | "CSV" | "TXT"

export type ModelArchive = {
  path: string
  type: ModelTypeFileAvailable
  s3PresignedValue?: string
}

export enum MassCandidatesAcceptedFilesTypes {
  XLSX = "xlsx",
  XLS = "xls",
  CSV = "csv",
  TXT = "txt",
}

type MassCandidateError = {
  line: number
  reason: string
}

export interface MassCandidateProcess {
  _id: string
  notificationEmployeeId: string
  companyId: string
  flowId: string
  completionPercentage: number
  validationPercentage: number
  lastReadPosition: number
  lastValidatedPosition: number
  fileKey: string
  active: boolean
  dismissed: boolean
  errorReport: MassCandidateError[]
  fileType: MassCandidatesAcceptedFilesTypes
  initialIndex: number
  initialPosition: number
  validationProgress: number
}
