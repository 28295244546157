import gql from "graphql-tag"
import { request } from "../client"

const HIRING_POST_COMMENT = gql`
  mutation HiringPostComment($params: PostComment) {
    hiringPostComment(params: $params) {
      _id
      version
    }
  }
`

export const hiringPostComment = async (params: {
  message: string
  flowCardId: string
  version: number
}) => {
  const { hiringPostComment } = await request(HIRING_POST_COMMENT, { params })
  return hiringPostComment as { _id: string; version: number }
}
