import { useParams } from "react-router-dom"
import { HiringCardPage } from "../../../components/"
import CLTContent from "./CLTContent"
import PJContent from "./PJContent"
import InternshipContent from "./InternshipContent"

const CandidatePage = () => {
  const { id: cardId = "" } = useParams()

  const contents = {
    ["clt"]: CLTContent,
    ["pj"]: PJContent,
    ["internship"]: InternshipContent,
  }

  return (
    <HiringCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Admissão", href: "/flows/hiring" },
        { name: "Pessoa", href: `/flows/hiring/candidate/${cardId}` },
      ]}
      contents={contents}
    />
  )
}

export default CandidatePage
