import gql from "graphql-tag"
import { Benefit } from "../../types"
import { request } from "../client"

const GET_CANDIDATE_BENEFITS = gql`
  query GetCandidateBenefits($candidateId: ID!) {
    getCandidateBenefits(candidateId: $candidateId) {
      id
      name
      description
      icon
      type
      value
    }
  }
`

export const getCandidateBenefits = async (
  candidateId: string,
): Promise<Benefit[]> => {
  const { getCandidateBenefits } = await request(GET_CANDIDATE_BENEFITS, {
    candidateId,
  })
  return getCandidateBenefits
}
