import styled, { css } from "styled-components"

const bannerStyles = {
  ["info"]: {
    banner: css`
      border: 1px solid ${({ theme }) => theme.colors.feedback.info[70]};
      color: ${({ theme }) => theme.colors.feedback.info[10]};
    `,
    circle: css`
      background-color: ${({ theme }) => theme.colors.feedback.info[90]};
      color: ${({ theme }) => theme.colors.feedback.info[50]};
    `,
    closeButton: css`
      color: ${({ theme }) => theme.colors.feedback.info[40]};
    `,
  },
  ["error"]: {
    banner: css`
      border: 1px solid ${({ theme }) => theme.colors.feedback.error[70]};
      color: ${({ theme }) => theme.colors.feedback.error[10]};
    `,
    circle: css`
      background-color: ${({ theme }) => theme.colors.feedback.error[90]};
      color: ${({ theme }) => theme.colors.feedback.error[50]};
    `,
    closeButton: css`
      color: ${({ theme }) => theme.colors.feedback.error[40]};
    `,
  },
  ["success"]: {
    banner: css`
      border: 1px solid ${({ theme }) => theme.colors.feedback.success[70]};
      color: ${({ theme }) => theme.colors.feedback.success[10]};
    `,
    circle: css`
      background-color: ${({ theme }) => theme.colors.feedback.success[90]};
      color: ${({ theme }) => theme.colors.feedback.success[50]};
    `,
    closeButton: css`
      color: ${({ theme }) => theme.colors.feedback.success[40]};
    `,
  },
}

export const BannerContainer = styled.div<{
  type: "info" | "error" | "success"
  canHide: boolean
}>`
  ${({ type }) => bannerStyles[type].banner};

  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ canHide }) =>
    canHide ? "space-between" : "flex-start"}
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
`

export const IconContainer = styled.div<{ type: "info" | "error" | "success" }>`
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ type }) => bannerStyles[type].circle}
`

export const MesssageContainer = styled.div`
  padding: 0px 25px;
`

export const CloseContainer = styled.div<{
  type: "info" | "error" | "success"
}>`
  display: flex;
  cursor: pointer;
  ${({ type }) => bannerStyles[type].closeButton}
`
