import gql from "graphql-tag"
import { request } from "../client"
import { CompanyAddress, Recipient } from "../../types"

export const ORDER_CARD_DELIVERY = gql`
  mutation orderCardDelivery(
    $params: OrderCardInput!
    $address: CompanyAddressInput!
    $recipient: RecipientInput!
  ) {
    orderCardDelivery(params: $params, address: $address, recipient: $recipient)
  }
`

export interface orderCardDeliveryParams {
  params: {
    adminId: string
    employeeId: string
    candidateId: string
    saveAddress: boolean
    flowCardId: string
    version: number
  }
  address: CompanyAddress
  recipient: Recipient
}

export const orderCardDelivery = async (
  params: orderCardDeliveryParams,
): Promise<void> => {
  await request(ORDER_CARD_DELIVERY, params)
}
