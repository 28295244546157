import gql from "graphql-tag"
import { request } from "../client"

const CREATE_MANY_RESIGNATION_CARD = gql`
  mutation CreateManyResignationCard($params: CreateManyResignationCardInput) {
    createManyResignationCard(params: $params) {
      _id
      name
      columnId
    }
  }
`

export const createManyResignationCard = async (params: {
  employeeIds: string[]
  flowId: string
}) => {
  const { createManyResignationCard } = await request(
    CREATE_MANY_RESIGNATION_CARD,
    { params },
  )
  return createManyResignationCard as {
    _id: string
    name: string
    columnId: string
  }[]
}
