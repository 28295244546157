import styled from "styled-components"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { DeprecatedModal } from "../../../common"

export const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      width: 100%;
      max-width: 896px;
      max-height: 80vh;
      overflow: hidden;

      .modal-content-area {
        padding: 0px;
      }
    }
  }
`

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 12px;
  padding-right: 24px;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 24px;

  cursor: pointer;
  padding: 14px;
  border: 1px solid #e8e3e6;
  border-radius: 50%;

  &:hover {
    opacity: 0.7;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 150px;
  border-bottom: 1px solid #ebe6e9;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 150px;
  overflow: scroll;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 24px 150px;
  border-top: 1px solid #ebe6e9;
`

export const Title = styled(Typography)`
  color: #1d161b;
`

export const SubTitle = styled(Typography)`
  color: #53464f;
`

export const Description = styled(Typography)`
  color: #83727d;
`
