import gql from "graphql-tag"
import { request } from "../client"

const GET_GROUPS_BY_COMPANY_ID = gql`
  query Query {
    deprecatedGetGroupsByCompanyId
  }
`

export const deprecatedGetGroupsByCompanyId = async () => {
  const { deprecatedGetGroupsByCompanyId } = await request(
    GET_GROUPS_BY_COMPANY_ID,
  )
  return deprecatedGetGroupsByCompanyId
}
