import { useMemo, useEffect } from "react"
import { useParams, useNavigate, Navigate } from "react-router-dom"

import { useQuery, useLazyQuery } from "@apollo/client"
import { Loader } from "@flash-tecnologia/hros-web-ui-v2"

import { BasicInfo, ContractContent } from "./Steps"

import { SpinnerContainer } from "./styles"

import dispatchToast from "../../utils/dispatchToast"
import { Flow } from "../../types"
import { GET_MODEL_CONTRACT_BY_ID } from "../../api/queries/get_model_contract_by_id"
import { GET_FLOW_BY_ID } from "../../api/queries"

type StepType = typeof BasicInfo | typeof ContractContent | undefined

const CreateContractsTemplate = () => {
  const navigate = useNavigate()
  const { step, modelId, flowId } = useParams()

  const { data: flowData, loading: loadingFlow } = useQuery<{
    getFlowById: Flow
  }>(GET_FLOW_BY_ID, {
    variables: { flowId },
    fetchPolicy: "cache-first",
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors[0]
      const errorCode = graphqlErrors?.errorCode as string

      let errorMessage = "Erro ao buscar dados, tente novamente mais tarde."

      if (errorCode === "HIRING_FLOW_DOES_NOT_EXISTS_ERROR") {
        errorMessage = "Erro! não foi possível encontrar o tipo de contratação."

        navigate(`/flows/hiring`)
      }

      dispatchToast({
        type: "error",
        content: errorMessage,
      })
    },
  })

  const [fetchModelContractById, { loading: loadingModel, data: modelData }] =
    useLazyQuery<{
      getModelContractById: any
    }>(GET_MODEL_CONTRACT_BY_ID, {
      variables: { modelContractId: modelId },
      fetchPolicy: "network-only",
      onError: (error) => {
        const graphqlErrors: any = error.graphQLErrors[0]
        const errorCode = graphqlErrors?.errorCode as string

        let errorMessage = "Erro ao buscar dados, tente novamente mais tarde."

        if (errorCode === "MODEL_CONTRACT_ID_DOES_NOT_FOUND_ERROR") {
          errorMessage =
            "Erro! não foi possível encontrar o modelo de contrato."

          navigate(`/flows/settings/${flowId}/contracts`)
        }

        dispatchToast({
          type: "error",
          content: errorMessage,
        })
      },
    })

  useEffect(() => {
    if (modelId) fetchModelContractById()
  }, [modelId])

  const { getFlowById: flow } = flowData || {}
  const { getModelContractById: modelContract } = modelData || {}

  const Step: StepType = useMemo(() => {
    if (!step) return undefined
    const steps = {
      "basic-info": BasicInfo,
      "contract-content": ContractContent,
    }
    return steps[step]
  }, [step])

  if (!Step) return <Navigate to={`/flows/settings/${flowId}/contracts`} />

  if (loadingFlow || !flow) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  return <Step data={modelContract} isFetching={loadingModel} flow={flow} />
}

export default CreateContractsTemplate
