import gql from "graphql-tag"
import { request } from "../client"

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($employeeId: ID!, $params: UpdateEmployeeInput) {
    updateEmployee(employeeId: $employeeId, params: $params)
  }
`

type UpdateEmployeeInput = {
  employeeId: string
  params: {
    name: string
    legalName: string
    cnpj: string
    assignments: any
    hiringType: string
    gender: string
    genderOther: string
    mothersName: string
    birthDate: string
    isForeign: boolean
    isPWD: boolean
    termAccepted: boolean
    hasFirstLogin: boolean
    department: string
    salary: number
    proposalStatus: string
    refuseReason: string
    companyRole: string
    socialName: string
    internship: any
    address: any
    contacts: any
    profilePicture: any
    nationality: string
    skinColor: string
    maritalStatus: string
    schooling: string
    roles: any
    groups: any
    active: boolean
    serveNoticePeriod: boolean
    lastWorkingDate: string
  }
}

export const updateEmployeeApi = async (input: UpdateEmployeeInput) => {
  await request(UPDATE_EMPLOYEE, input)
}
