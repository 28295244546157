import gql from "graphql-tag"
import { request } from "../client"

export const HIRING_MOVE_COLUMN = gql`
  mutation HiringChangeColumn($params: ChangeColumn) {
    hiringChangeColumn(params: $params)
  }
`

export type ChangeCardColumn = {
  flowCardId: string
  newColumnId: string
  newPosition: number
  version: number
}

export const hiringChangeColumn = async (params: ChangeCardColumn) => {
  await request(HIRING_MOVE_COLUMN, { params })
}
