import gql from "graphql-tag"
import { request } from "../client"
import { CompanyEmployee } from "../../types"

const GET_EMPLOYEE_BY_COMPANY = gql`
  query GetEmployeesByCompany($query: GetEmployeeByCompanyOptions) {
    getEmployeesByCompany(query: $query) {
      id
      name
      documentNumber
      phoneNumber
      email
      corporateEmail
      status
      companyId
      groups
      roles
      invitationDate
    }
  }
`

export const getEmployeeByCompany = async (query: { query?: string }) => {
  const { getEmployeesByCompany } = await request(GET_EMPLOYEE_BY_COMPANY, {
    query,
  })
  return getEmployeesByCompany as CompanyEmployee[]
}
