import { gql } from "graphql-request"
import { request } from "../client"

const REMOVE_FORMCONFIG = gql`
  mutation RemoveFormConfig($formConfigId: ID!) {
    removeFormConfig(formConfigId: $formConfigId) {
      _id
    }
  }
`

export const removeFormConfig = async (formConfigId: string) => {
  await request(REMOVE_FORMCONFIG, { formConfigId })
}
