import React from "react"
import { CandidateSignInformation } from "../../../types"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../api"
import dispatchToast from "../../../utils/dispatchToast"
import {
  ConfirmationModal,
  FocusedFlow,
  Footer,
  FooterOptions,
  Header,
  HeaderBreadcrumb,
} from "../../../components"
import {
  MassSignatureContracts,
  MassSignatureConfirmation,
  MassSignatureSignature,
} from "./screen"
import { SpinnerContainer } from "./styles"
import { Loader, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { ModalIlegibleCandidates } from "./components/ModalIlegibleCandidates"

export enum StepsEnum {
  contract = 0,
  confirmation = 1,
  signing = 2,
}

type StepContent = {
  stepLabel: string
  title: string
  description: string
}

type ConfirmationData = {
  name: string
  documentNumber: string
  email: string
}

export interface IMassSignatureContext {
  setStep(step: StepsEnum): void
  validCandidates: CandidateSignInformation[]
  invalidCandidates: CandidateSignInformation[]
  confirmationData?: ConfirmationData
  removeCandidates(flowCardId: string): void
  setFooter(options: FooterOptions): void
  setCurrentStep(step: StepsEnum): void
  setConfirmationData(data: ConfirmationData): void
}

const stepContents: { [key in StepsEnum]: StepContent } = {
  [StepsEnum.contract]: {
    stepLabel: "Seleção de contratos",
    title: "Seleção de contratos",
    description: "Visualize ou exclua da seleção os contratos selecionados.",
  },
  [StepsEnum.confirmation]: {
    stepLabel: "Dados pessoais",
    title: "Dados pessoais",
    description: "Confirme seus dados para prosseguir com a assinatura.",
  },
  [StepsEnum.signing]: {
    stepLabel: "Assinatura",
    title: "Assinatura",
    description: "Insira sua assinatura abaixo",
  },
}

const breadcrumbs: HeaderBreadcrumb[] = [
  {
    label: "Admissão",
    route: `/flows/hiring`,
  },
  {
    label: "Assinar contratos",
  },
]

export const massSignatureContext =
  React.createContext<IMassSignatureContext>({
    setStep: () => { },
    validCandidates: [],
    invalidCandidates: [],
    removeCandidates: () => { },
    setFooter: () => { },
    setCurrentStep: () => { },
    setConfirmationData: () => { },
  })

export const MassSignaturePage = () => {
  const [confirmationData, setConfirmationData] =
    React.useState<ConfirmationData | undefined>(undefined)
  const [modalDeleteConfirmation, setModalDeleteConfirmation] =
    React.useState<boolean>(false)
  const [modalCandidatesIlegibles, setModalCandidatesIlegibles] =
    React.useState<boolean>(false)
  const [currentStep, setCurrentStep] = React.useState<StepsEnum>(
    StepsEnum.contract,
  )
  const [currentFooter, setCurrentFooter] =
    React.useState<FooterOptions | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const theme = useTheme()

  const flowCardsId = searchParams.get("flowCardsId")?.split(",") || []

  const { data: candidates, isInitialLoading: isCandidatesLoading } = useQuery(
    ["mass-signatures", flowCardsId],
    () =>
      api.query.hiring.massSignature.getAndValidateSigningCandidates(
        flowCardsId,
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!flowCardsId,
      onError: (err: any) => {
        dispatchToast({
          content: err.message,
          type: "error",
        })
        navigate(-1)
      },
    },
  )

  React.useEffect(() => {
    if (!candidates) return
    const hasSomeoneIlegible =
      candidates.filter((candidate) => !candidate.isElegibleToSign)?.length ?? 0
    if (hasSomeoneIlegible) setModalCandidatesIlegibles(true)
  }, [candidates])

  React.useEffect(() => {
    if (!flowCardsId || flowCardsId?.length === 0) navigate("/flows/hiring")
  }, [flowCardsId])

  const value: IMassSignatureContext = React.useMemo(
    () => ({
      setStep: (step: StepsEnum) => setCurrentStep(step),
      validCandidates: candidates?.filter(
        (candidate) => candidate.isElegibleToSign,
      ) || [],
      invalidCandidates: candidates?.filter(
        (candidate) => !candidate.isElegibleToSign,
      ) || [],
      confirmationData,
      removeCandidates,
      setFooter: (options: FooterOptions) => setCurrentFooter(options),
      setCurrentStep: (step: StepsEnum) => setCurrentStep(step),
      setConfirmationData: (data: ConfirmationData) =>
        setConfirmationData(data),
    }),
    [candidates, confirmationData],
  )

  const step = React.useMemo(() => {
    switch (currentStep) {
      case StepsEnum.contract:
        return <MassSignatureContracts />
      case StepsEnum.confirmation:
        return <MassSignatureConfirmation />
      case StepsEnum.signing:
        return <MassSignatureSignature />
    }
  }, [currentStep])

  function removeCandidates(toBeRemovedFlowCardId: string) {
    if (flowCardsId?.length === 1) {
      setModalDeleteConfirmation(true)
      return
    }
    const cleanedFlowCardIds = flowCardsId.reduce(
      (prev: string[], flowCardId) => {
        const findValidated = candidates?.find(
          (signInfo) => signInfo.flowCardId === flowCardId,
        )
        if (!findValidated) return prev
        if (!findValidated.isElegibleToSign) return prev

        return [...prev, flowCardId]
      },
      [],
    )
    const filteredFlowCards = cleanedFlowCardIds.filter(
      (id) => id !== toBeRemovedFlowCardId,
    )
    setSearchParams(
      new URLSearchParams(`flowCardsId=${filteredFlowCards.join(",")}`),
    )
  }

  if (!candidates || isCandidatesLoading) {
    return (
      <FocusedFlow>
        <SpinnerContainer>
          <Loader variant="primary" size="large" />
        </SpinnerContainer>
      </FocusedFlow>
    )
  }

  return (
    <massSignatureContext.Provider value={value}>
      <FocusedFlow>
        <Header
          breadcrumbs={breadcrumbs}
          steps={Object.keys(stepContents).map(
            (key) => stepContents[key]?.stepLabel || "",
          )}
          currentStep={currentStep}
          style={{
            position: "fixed",
            zIndex: 2,
            top: "0px",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "110px 30px",
          }}
        >
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[20]}
            style={{ marginBottom: 30 }}
          >
            Assinar contratos
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 15,
              marginBottom: 20,
            }}
          >
            <Typography
              variant="headline7"
              variantColor={theme.colors.secondary[50]}
            >
              {stepContents[currentStep].title}
            </Typography>
            <Typography variant="body3" variantColor={theme.colors.neutral[50]}>
              {stepContents[currentStep].description}
            </Typography>
          </div>

          {step}
        </div>

        {currentFooter ? (
          <Footer
            options={currentFooter}
            style={{
              position: "fixed",
              zIndex: 2,
              bottom: "0px",
            }}
          />
        ) : (
          <></>
        )}
      </FocusedFlow>

      {modalDeleteConfirmation ? (
        <ConfirmationModal
          variant="primary"
          isOpen={modalDeleteConfirmation}
          onClose={() => setModalDeleteConfirmation(false)}
          confirmButton={{
            text: "Confirmar",
            onClick: async () => {
              navigate("/flows/hiring")
            },
          }}
          title="Tem certeza que deseja excluir a seleção?"
          description="Você irá retornar para a visão de lista."
        />
      ) : (
        <></>
      )}

      {modalCandidatesIlegibles ? (
        <ModalIlegibleCandidates
          isOpen={modalCandidatesIlegibles}
          handleClose={() => setModalCandidatesIlegibles(false)}
          candidates={candidates}
        />
      ) : (
        <></>
      )}
    </massSignatureContext.Provider>
  )
}
