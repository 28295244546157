import styled, { css } from "styled-components"

export const Container = styled.div``

export const DropdownContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 12px;

  width: 305px;
  position: absolute;
  z-index: 9999;
  bottom: 100px;
`

export const DropdownTitle = styled.div`
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #53464f;
`

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 260px;
  overflow: auto;
`

const dropdownVariantStyle = {
  ["active"]: css`
    background-color: #ffe0ef;
    border-color: #fe67af;
    color: #f20d7a;
    font-weight: 700;
  `,
  ["default"]: css`
    background-color: #FFFFF;
    border-color: #ebe6e9;
    color: #83727d;
  `,
  ["secondary"]: css`
    background-color: #ffe0ef;
    border-color: #ffe0ef;
    color: #f20d7a;
  `,
}

export const DropdownItem = styled.div<{
  variant: "active" | "default" | "secondary"
}>`
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin: 4px 0;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-width: 1px;
  border-style: solid;
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  ${({ variant }) => dropdownVariantStyle[variant]}
`
