import { Breadcrumbs, Stepper } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid #ebe6e9;
  background-color: #fff;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 715px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-left: 15px;
  min-width: 220px;
`

export const StyledStepper = styled(Stepper)`
  margin-left: 20px;
  @media screen and (max-width: 715px) {
    margin-top: 15px;
    :first-child {
      margin-left: 55px;
    }
  }
`
