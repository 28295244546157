import gql from "graphql-tag"
import { request } from "../client"

const CHECK_LEADER_BY_EMPLOYEEID = gql`
  mutation checkLeaderByEmployeeId($params: checkLeaderInput) {
    checkLeaderByEmployeeId(params: $params)
  }
`

export const checkLeaderByEmployeeId = async (params: {
  employeeIds: string[]
}) => {
  const { checkLeaderByEmployeeId } = await request(
    CHECK_LEADER_BY_EMPLOYEEID,
    { params },
  )
  return checkLeaderByEmployeeId
}
