import { FlowCategory } from "src/types"
import { hiringColumnsId, resignationColumnsId } from "./board"
import { FlowStage } from "bff/src/types/hiring"

type ColumnStageInfo = {
  ids: string[]
  key: FlowStage
}

export const columnsStageInfo: Record<FlowCategory, ColumnStageInfo[]> = {
  ["hiring"]: [
    {
      ids: hiringColumnsId.candidates,
      key: FlowStage.ADD_PERSON,
    },
    {
      ids: hiringColumnsId.proposedLetter,
      key: FlowStage.PROPOSAL,
    },
    {
      ids: hiringColumnsId.admission,
      key: FlowStage.REQUEST_MEDICAL_EXAM,
    },
    {
      ids: hiringColumnsId.documentsSend,
      key: FlowStage.SEND_DOCUMENTS,
    },
    {
      ids: hiringColumnsId.documentsValidation,
      key: FlowStage.VALIDATE_DOCUMENTS,
    },
    {
      ids: hiringColumnsId.signing,
      key: FlowStage.SIGN_CONTRACT,
    },
    {
      ids: hiringColumnsId.archived,
      key: FlowStage.ARCHIVED,
    },
    {
      ids: hiringColumnsId.initialConfiguration,
      key: FlowStage.INITIAL_SETUP,
    },
    {
      ids: hiringColumnsId.done,
      key: FlowStage.DONE,
    },
  ],
  ["resignation"]: [
    {
      ids: resignationColumnsId.onHold,
      key: FlowStage.ON_HOLD,
    },
    {
      ids: resignationColumnsId.startProcess,
      key: FlowStage.START_PROCESS,
    },
    {
      ids: resignationColumnsId.interview,
      key: FlowStage.INTERVIEW,
    },
    {
      ids: resignationColumnsId.exam,
      key: FlowStage.EXAM,
    },
    {
      ids: resignationColumnsId.rescission,
      key: FlowStage.RESCISSION,
    },
    {
      ids: resignationColumnsId.signature,
      key: FlowStage.SIGN_CONTRACT,
    },
    {
      ids: resignationColumnsId.documentUpdate,
      key: FlowStage.DOCUMENT_UPDATE,
    },
    {
      ids: resignationColumnsId.archived,
      key: FlowStage.ARCHIVED,
    },
    {
      ids: resignationColumnsId.done,
      key: FlowStage.DONE,
    },
  ],
}

export const hiringFlowStageMap = {
  [FlowStage.ADD_PERSON]: "Pessoas",
  [FlowStage.PROPOSAL]: "Carta proposta",
  [FlowStage.SEND_DOCUMENTS]: "Envio de documentos",
  [FlowStage.VALIDATE_DOCUMENTS]: "Validação de documentos",
  [FlowStage.REQUEST_MEDICAL_EXAM]: "Exame admissional",
  [FlowStage.SIGN_CONTRACT]: "Assinatura de contrato",
  [FlowStage.DONE]: "Concluído",
  [FlowStage.INITIAL_SETUP]: "Configurações iniciais",
  [FlowStage.ARCHIVED]: "Arquivado",
}
