import styled from "styled-components"

import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 1095px;
  margin-bottom: 32px;
`

const ModelTitle = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral[20]};
  }
`

const ModelSubtitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral[50]};
  }
`

export { Container, TextsContainer, ModelTitle, ModelSubtitle }
