import styled from "styled-components"

import {
  Accordion,
  Typography,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0px;
`

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
    border-radius: 12px;
    box-shadow: none;

    &:before {
      background: none;
    }
  }
`

const TemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiPaper-root.MuiButtonBase-root {
      font-size: 14px;
      line-height: 16px;
  }
`

const TemplateCard = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  border: 1px solid
    ${({ theme, error }) => (error ? "#FEC380" : theme.colors.secondary[70])};
  border-radius: 12px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.6;
  }
`

const LineText = styled(Typography)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #83727d;

    :before,
    :after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid #ebe6e9;
    }

    :before {
      margin-right: 12px;
    }

    :after {
      margin-left: 12px;
    }
  }
`

const HelperText = styled(Typography)`
  && {
    margin: 6px 0px 0px 0px;
    color: var(--color-neutral-dark5);
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`

export {
  Container,
  StyledAccordion,
  TemplateWrapper,
  StyledLinkButton,
  MultiSelectContainer,
  TemplateCard,
  LineText,
  HelperText,
}
