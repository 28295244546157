import gql from "graphql-tag"
import { Benefit } from "../../types"
import { request } from "../client"

const GET_COMPANY_BENEFITS = gql`
  query GetBenefits {
    getCompanyBenefits {
      id
      name
      description
      icon
      type
    }
  }
`

export const getCompanyBenefits = async (): Promise<Benefit[]> => {
  const { getCompanyBenefits } = await request(GET_COMPANY_BENEFITS)
  return getCompanyBenefits
}
