import styled from "styled-components"

export const CheckboxContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 140px;
  color: var(--color-neutral-dark4);
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    p {
      display: none;
    }
  }
`
