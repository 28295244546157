import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom"

import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility"

import { ExternalRoute } from "../components/common/ExternalRoute"
import ErrorBoundary from "../utils/ErrorBoundary"
import { routesConfig, RoutesConfig } from "./routesConfig"
import { RoutesGuard } from "./routesGuard"

const externalRoutes: RouteObject[] = [
  {
    path: "*",
    ErrorBoundary: ErrorBoundary,
    element: <ExternalRoute />,
  },
]

function createRouteObjects(routes: RoutesConfig): RouteObject[] {
  return Object.values(routes).map((routeObject) => {
    const { path, element, children } = routeObject
    return {
      path,
      element,
      children: children ? createRouteObjects(children) : undefined,
    }
  })
}

const router = createBrowserRouter([
  {
    path: "/flows",
    element: <RoutesGuard />,
    // ErrorBoundary: ErrorBoundary,
    children: createRouteObjects(routesConfig),
  },
  ...externalRoutes,
])

const AppRouter = () => {
  useRegisterRoutes([
    {
      path: routesConfig.settingsCreateContracts.path,
      hideHeader: true,
    },
    {
      path: routesConfig.settingsUpdateContracts.path,
      hideHeader: true,
    },
    {
      path: routesConfig.settingsManageModelProposedLetter.path,
      hideHeader: true,
    },
  ])

  return <RouterProvider router={router} />
}

export default AppRouter
