import {
  Accordion,
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { IFormConfig, IFormGroup } from "../../../types"
import { useTranslation } from "react-i18next"
import { ModalPreviewInstruction, PreviewField } from "../../../components"
import { CSSProperties, useState } from "react"
import { Wrapper } from "./styles"

interface DocumentListProps {
  flowId: string
  formConfig: IFormConfig
}

const DocumentAccordion = ({
  flowId,
  formConfig,
  section,
}: {
  flowId: string
  formConfig: IFormConfig
  section: IFormGroup
}) => {
  const [isInstructionPreviewOpen, setIsInstructionPreviewOpen] =
    useState<boolean>(false)
  const isRequired = section.fields?.every((field) => field.fieldRule?.required)

  return (
    <>
      <Accordion
        variant="default"
        tagVariant="primary"
        tagName={!isRequired ? "Opcional" : undefined}
        customHeader={
          <>
            <Typography variant="headline8" variantColor="#53464F">
              {section.title}
              {isRequired && (
                <span
                  style={{
                    marginLeft: 2,
                    color: "#FEA034",
                  }}
                >
                  *
                </span>
              )}
            </Typography>
            <Typography variant="body4" variantColor="#83727D">
              {section.description}
            </Typography>
          </>
        }
      >
        <>
          {section.fillInstruction?.html && (
            <LinkButton
              variant="primary"
              style={{
                fontSize: 14,
                marginBottom: 24,
              }}
              onClick={() => setIsInstructionPreviewOpen(true)}
            >
              Como encontrar ou preencher esse documento?
              <Icons fill="transparent" name="IconZoomQuestion" size={14} />
            </LinkButton>
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 24,
            }}
          >
            {section.fields?.map((field, idx) => (
              <PreviewField key={idx} field={field} />
            ))}
          </div>
        </>
      </Accordion>

      {isInstructionPreviewOpen && (
        <ModalPreviewInstruction
          isOpen={isInstructionPreviewOpen}
          handleClose={() => setIsInstructionPreviewOpen(false)}
          flowId={flowId}
          formConfig={formConfig}
          section={section}
        />
      )}
    </>
  )
}

const RenderDocuments = ({
  title,
  description,
  flowId,
  formConfig,
  sections,
  style,
}: {
  title: string
  description: string
  flowId: string
  formConfig: IFormConfig
  sections: IFormGroup[]
  style?: CSSProperties
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
        ...style,
      }}
    >
      <div>
        <Typography variant="headline8" variantColor="#53464F">
          {title}
        </Typography>
        <Typography variant="body4" variantColor="#83727D">
          {description}
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 40,
        }}
      >
        {sections.map((section, idx) => (
          <DocumentAccordion
            key={idx}
            flowId={flowId}
            formConfig={formConfig}
            section={section}
          />
        ))}
      </div>
    </div>
  )
}

export const DocumentList = ({ flowId, formConfig }: DocumentListProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const filteredSections = formConfig.formGroups.filter(
    (section) => section.enabled,
  )

  const candidateDocuments = filteredSections?.filter(
    (section) => section.category === "employee",
  )
  const dependentDocuments = filteredSections?.filter(
    (section) => section.category === "dependent",
  )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {candidateDocuments?.length > 0 && (
        <Wrapper>
          <RenderDocuments
            title={t("documentsList.datesCandidateTitle")}
            description={t("documentsList.datesCandidateSubtitle")}
            flowId={flowId}
            formConfig={formConfig}
            sections={candidateDocuments}
          />
        </Wrapper>
      )}

      {dependentDocuments?.length > 0 && (
        <Wrapper>
          <RenderDocuments
            title={t("documentsList.datesDependentsTitle")}
            description={t("documentsList.datesDependentsSubtitle")}
            flowId={flowId}
            formConfig={formConfig}
            sections={dependentDocuments}
          />
        </Wrapper>
      )}
    </div>
  )
}
