import styled from "styled-components"
import { Typography, Button } from "@flash-tecnologia/hros-web-ui-v2"

const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-word;
`

const StyledDescription = styled(Typography)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const PreviewButton = styled(Button)`
  margin-bottom: 40px;
  @media screen and (min-width: 992px) {
    width: 100%;
  }
`

export { StyledSubtitle, StyledDescription, PreviewButton }
