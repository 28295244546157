import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > :first-child {
    padding-top: 0;
  }

  & > :last-child {
    border-bottom: none;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-bottom: 40px;
  gap: 24px;
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;

  overflow: scroll;
`

export const StyleStatusButton = styled(Button)<{
  variantType: "success" | "error"
  active: boolean
}>`
  ${({ active, variantType }) =>
    active &&
    css`
      background: ${variantType === "success"
        ? "#d7f9f3"
        : "#ffecd6"} !important;
      border: none !important;
    `}
`
