import { EmptyState, Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export const DefaultErrorPage = () => {
  const navigate = useNavigate()
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  return (
    <EmptyState
      type="error"
      title={t("errors.default.title")}
      description={t("errors.default.description")}
      buttonText={
        <>
          <span>{t("errors.default.buttonText")}</span>
          <Icons name="IconHome" />
        </>
      }
      buttonProps={{
        onClick: () => navigate("/flows/hiring"),
        size: "large",
      }}
    />
  )
}
