export default {
  translations: {
    page: {
      tab: {
        titleLabel: "Admission",
        buttonLabel: "Add person",
        admissionLabel: "Admissions",
        modelLabel: "Models",
        automationLabel: "Automations",
      },
      cpfForm: {
        title: "Please, fill the cpf to verification",
        backbuttonLabel: "BACK",
        sendbuttonLabel: "SEND",
      },
      proposedLetter: {
        currentState: "Current phase",
        titleSend: "Proposed Letter",
        subtitleSend:
          "Configure proposal conditions, submit and track candidate answer status",
        responsibleLabel: "Responsible",
        companionLabel: "Companion",
        sendProposalTitle: "Submission of the proposal",
        sendProposalSubtitle:
          "The candidate will receive the proposal link via email and accept it on a web page. See below how it will look!",
        emailProposalTitle: "Proposal email",
      },
      submissionContent: {
        previewButtonLabel: "Preview the email",
        modelEmailLabel: "Proposal email template",
        modelEmailPlaceholder: "Select email",
        subjectEmailLabel: "Email subject",
        subjectEmailPlaceholder: "Enter the subject",
        copyEmailLabel: "Insert people in copy",
        copyEmailPlaceholder: "Enter email",
        attachmentEmailTitle: "Send attachment with email",
        attachmentEmailColorSubtitle: "Select the file",
        attachmentEmailSubtitle: "drag and drop the here.",
        scheduleEmailTitle: "Schedule email sending",
        scheduleEmailSubtitle:
          "Choose the day and time for the e-mail to be sent.",
        proposalButtonLabel: "Send proposal",
      },
      personalInfo: {
        name: "Full name",
        email: "Email",
        phone: "Telephone",
        cpf: "CPF",
        dateLabel: "Expected date of admission",
      },
      documentsSend: {
        currentState: "Current phase",
        titleSend: "Sending documents",
        subtitleSend:
          "Configure the proposal conditions, send it and track the candidate's acceptance status",
        editButtonLabel: "Edit phase",
        responsibleLabel: "Responsible",
        companionLabel: "Companion",
        secondTitle: "Document request",
        toggleTitle: "Request documents from the candidate",
        thirdTitle: "Request email",
        buttonText: "Request documents from the candidate",
      },
      documentsList: {
        title: "Documents list",
        documentLabel: "Documentation template",
        documentPlaceholder: "Select model",
        datesCandidateTitle: "Candidate documents",
        datesCandidateSubtitle:
          "List of documents that will be requested from the candidate upon admission",
        datesDependentsTitle: "Documents of dependents",
        datesDependentsSubtitle:
          "Spouses or partners, non-emancipated children of any condition, minors under twenty-one years of age or people with disabilities may be considered dependents.",
        previewProposal: "Preview the proposal",
        previewButtonText: "Preview the document request page",
      },
      documentsValidation: {
        currentState: "Current phase",
        title: "Document validation",
        subtitle:
          "Configure proposal conditions, submit and track candidate acceptance status",
        responsibleLabel: "Responsible",
        companionLabel: "Companion",
        secondTitle: "Resubmission of documents",
        toggleTitle: "Request documents from the candidate",
        thirdTitle: "Request email",
      },
      proposalContent: {
        title: "Proposal content",
        buttonLabel: "Send Proposal",
        buttonManualLabel: "Proposal accepted",
      },
      board: {
        lockedBlock:
          "Some columns are locked. To move the column, unlock the locked columns",
        hiddenBlocked:
          "Some columns are hidden. To move the column, unlock the hidden columns",
        cardInColumn:
          "Some of the columns have cards. Please move the card before moving the column",
        cardInLastColumn: "The card is in the last column and cannot be moved",
        cardsInOtherColumn:
          "A connection error occured when updating the card!",
        refreshBoardSuccess: "Board successfully updated!",
      },
      upload: {
        fileSelect: "Select a file",
      },
      addCandidateForm: {
        fullNamePlaceHolder: "Enter the name",
        cpfPlaceHolder: "Enter the document number",
        phonePlaceHolder: "Enter the phone number",
        emailPlaceHolder: "Enter e-mail",
        errorValidate: "Invalid data",
        errorRequired: "Required field",
        errorMaxLength: "Incorrect maximum number of characters",
        errorMinLength: "Incorrect minimum number of characters",
        errorPattern: "Use only letters",
        welcomeMesage:
          "Welcome to the candidate area. Here you register every person applying for your jobs. After registering, remember to update each candidate's progress in your admissions flow.",
        basicInfo: "Basic Datas",
        infoFill:
          "Enter the candidate information according to the fields below.",
        newEmployeeError: "Some data is not correct!",
        backButton: "Back",
        addCandidateButton: "Add candidate",
        inProgress: "In progress",
        candidateAddedSucess: "Candidate successfully added",
        candidateAddedError:
          "We're sorry, there was an error on our end creating the new candidate.",
        leaderAndFollowers: {
          title: "Leader and Followers",
          description:
            "Include people who will monitor the person’s admission process",
          leaderPlaceholder: "Select leader",
          followersPlaceholder: "Select followers",
        },
      },
      candidate: {
        candidateTitle: "Candidate",
        candidateDescription:
          "Add recruitment information and manage your candidate's approval status",
        recruitmentDataTitle: "Application Information",
        recruitmentDataDescription:
          "Register and view job and candidate information",
        linkedinPlaceHolder: "Enter the LinkedIn link",
        vacantJobLabel: "Job Link",
        vacantJobPlaceholder: "Enter the job link",
      },
      documentValidation: {
        documentSend: "Document not send",
        information: "Informations",
        notIncluded: "Not included",
        documentStatus: "Document status",
        reasonField: "Please, enter a reason",
        maxCharacters: "Maximum 100 characters",
        resendDocNotification: "Please, resend some documents!",
        waitingCandidate: "Waiting for the candidate return",
        validatedSucess: "Documents successfully validated",
        resendRequest: "Resend Request",
        next: "Next",
      },
      genericContent: {
        addTask: "Add Task",
        taskPlaceholder: "Enter the task",
        checklistTitle:
          "Create a checklist to organize and track the evolution of each stage of this phase",
        taskDuplicated: "There is a task with the same name already created",
        incorrectData: "Some data isn't correct!",
        checklistContractSignTitle:
          "Organize and manage your list of activities below and gain agility to follow the contract signing flow",
        checklistHiringExamTitle:
          "Organize and manage your list of activities below and gain agility to follow the steps of the admission exam",
        genericTitle: "Organize and manage your to-do list below",
      },
      historicContent: {
        welcomeSubtitle:
          "Send welcome to your new employee. Use this space to consult all admission data. Use this space to consult all admission data",
        filedSubtitle:
          "Use this space to look up candidate information. You can also unarchive this profile and add it to a new admission.",
        documentPhoto: "Document picture",
        documentUnsent: "Document not send",
        notIncluded: "Not included",
        linkedinPlaceholder: "Enter the LinkedIn link",
        vacantJobLabel: "Job Link",
        vacantJobPlaceholder: "Enter the job link",
        recruitmentDataDescription:
          "Register and view job and candidate information",
        recruitmentDataTitle: "Candidate Information",
        candidateDocTitle: "Candidate Documents",
        candidateDocSubtitle:
          "List of documents requested from the candidate on admission",
        dependentDocTitle: "Dependent documents",
        dependentDocSubtitle:
          "Spouses or partners, non-emancipated children of any condition, under twenty-one years old or invalids may be considered dependents.",
      },
      proposalLetter: {
        rolePlaceholder: "Enter the role",
        roleLabel: "Role",
        departamentPlaceholder: "Enter the department",
        departamentLabel: "Department",
        hiringTypePlaceholder: "Select",
        hiringTypeLabel: "Link type",
        salaryPlaceholder: "$ 0,00",
        salaryLabel: "fixed remuneration",
        hiringDatePlaceholder: "Select",
        hiringDateLabel: "Expected date of admission",
        AcceptedProposal: "Proposal accepted successfully!",
        proposalLetterNotification: "Your proposed letter has arrived",
        candidateReturnAwaiting: "Waiting for the candidate to return",
        letterDatesError: "Some data is not correct!",
        bidField:
          "Fill in the fields below according to the offer that will be made to the candidate",
        bidDeclined: "Offer declined",
        bidAccepted: "Offer accepted",
      },
      errors: {
        default: {
          title: "Page not found",
          description: "The page you are looking for does not exist or was not found",
          buttonText: "Go back to the home page",
        }
      }
    },
  },
}
