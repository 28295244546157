import gql from "graphql-tag"
import { request } from "../client"
import { SignManyContractsResult } from "../../types"

const SIGN_MANY_CONTRACTS = gql`
  mutation SignManyContracts($params: signManyContractsInput) {
    signManyContracts(params: $params) {
      contractsSigned
      contractsWithPdf
    }
  }
`

export type SignManyContractsParams = {
  flowCardIds: string[]
  signatureFile: {
    key: string
    path: string
    origin: string
  }
  confirmationInfo?: {
    name: string
    documentNumber: string
    email: string
  }
}

export const signManyContracts = async (
  params: SignManyContractsParams,
): Promise<SignManyContractsResult> => {
  const response = await request<{
    signManyContracts: SignManyContractsResult
  }>(SIGN_MANY_CONTRACTS, {
    params,
  })
  return response.signManyContracts
}
