import gql from "graphql-tag"
import { request } from "../client"
import { FlowCategory, FlowSubcategory } from "../../types"

const SEND_DOCUMENTS_VALIDATION_CANDIDATE = gql`
  mutation SendDocumentsValidationCandidate(
    $params: SendDocumentsValidationInput
  ) {
    sendDocumentsValidationCandidate(params: $params)
  }
`

export const resendDocumentsRequest = async (params: {
  flowCardId: string
  candidateId: string
  metadata: any
  disapprovedReason: string
  version: number
  columnId: string
  documentStatus: string
  category: FlowCategory
  subcategory?: FlowSubcategory
}) => {
  await request(SEND_DOCUMENTS_VALIDATION_CANDIDATE, { params })
}
