import { CSSProperties } from "react"

import { IconTypes, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { StatusContainer } from "./styles"

interface StatusCard {
  backgroundColor?: string
  borderColor?: string
  textColor?: string
  description: string
  icon?: IconTypes
  iconPosition?: "right" | "left"
  iconFilled?: boolean
  iconColor?: string
  style?: CSSProperties
}

export const StatusCard = ({
  backgroundColor = "#FFE0EF",
  borderColor,
  textColor = "#F20D7A",
  icon,
  iconPosition = "left",
  iconFilled = false,
  iconColor,
  description,
  style,
}: StatusCard) => {
  return (
    <StatusContainer
      style={{
        backgroundColor: backgroundColor,
        borderColor: borderColor || backgroundColor,
        ...style,
      }}
    >
      {icon && iconPosition === "left" && (
        <Icons
          name={icon}
          color={iconColor || borderColor}
          fill={iconFilled ? iconColor : "transparent"}
          width={6}
          height={6}
          style={{ marginRight: 5 }}
        />
      )}

      <Typography
        variant="caption"
        style={{
          fontWeight: 600,
          color: textColor,
        }}
      >
        {description}
      </Typography>

      {icon && iconPosition === "right" && (
        <Icons
          name={icon}
          fill={iconFilled ? iconColor : "transparent"}
          color={iconColor || borderColor}
          width={6}
          height={6}
          style={{ marginLeft: 5 }}
        />
      )}
    </StatusContainer>
  )
}
