import { ContentContainer, DocumentSentContainer, SubContainer } from "./styles"
import SignedContract from "../../../assets/done.svg"
import { Divider, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { UploadPreview, WizardCardLink } from "../../../components"
import { useMemo } from "react"
import { Contract, EmailLink, ResignationCard, Signature } from "../../../types"
import { ContractSignature } from "./ContractSignature"
import styled from "styled-components"

const DocumentSentContainerTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`

export interface ContractSentProps {
  card: ResignationCard
  contract: Contract
}

function getMultipleEmailLinksOrdered(
  emailLinks: EmailLink[],
): { title: string; emailLink: EmailLink }[] {
  const signatureOrderMap = {
    candidateSign: {
      title: "Link da pessoa",
      order: 0,
    },
    witnessSign0: {
      title: "Link da testemunha 1",
      order: 1,
    },
    witnessSign1: {
      title: "Link da testemunha 2",
      order: 2,
    },
    companySign: {
      title: "Link da empresa",
      order: 3,
    },
  }

  const mappedEmailLinks =
    emailLinks?.map((emailLink) => ({
      emailLink: emailLink,
      ...signatureOrderMap[emailLink?.name],
    })) ?? []

  const sortedEmailLinks = mappedEmailLinks.sort((a, b) => a.order - b.order)
  return sortedEmailLinks.map(({ order, ...rest }) => rest)
}

export const ContractSent: React.FC<ContractSentProps> = ({
  card,
  contract,
}) => {
  const findEmailLink =
    useMemo(() => {
      const linksFromThisColumn = card.emailLink?.filter(
        (e) => e.columnId === card.columnId,
      )
      return getMultipleEmailLinksOrdered(linksFromThisColumn)
    }, [card?.emailLink]) || []

  return (
    <SubContainer>
      <DocumentSentContainer>
        <SignedContract />
        <DocumentSentContainerTextContainer>
          <Typography variant="headline6" variantColor="#6B5B66" weight={700}>
            Documentos de rescisão enviado!
          </Typography>
          <Typography variant="body2" weight={600} variantColor="#83727D">
            Agora é só aguardar o retorno do destinatário. Confira abaixo as
            configurações dos documentos de rescisão encaminhado.
          </Typography>
        </DocumentSentContainerTextContainer>
      </DocumentSentContainer>
      <Divider orientation="horizontal" />
      {findEmailLink?.length ? (
        <div>
          <WizardCardLink multiplesEmailLinks={findEmailLink} />
        </div>
      ) : null}
      <Divider orientation="horizontal" />
      <ContentContainer>
        <div>
          <Typography variant="headline8" weight={700} variantColor="#53464F">
            Status
          </Typography>

          <Typography variant="body4" weight={400} variantColor="#83727D">
            Acompanhe o status da assinatura dos documentos de rescisão
          </Typography>
        </div>

        {contract?.signature?.map((item: Signature, index) => {
          let nextSigning = contract.signature.find(
            (s) => s.status === "Pending",
          )
          return (
            <ContractSignature
              key={index}
              card={card}
              nextSignature={nextSigning}
              signature={item}
              contract={contract}
            />
          )
        })}

        <UploadPreview
          file={contract.lastContractOutput as any}
          options={{
            canDownload: true,
            canRemove: false,
            downloadText: "Baixar contrato",
          }}
          tag={{
            variant: contract.status === "Signed" ? "success" : "error",
            children:
              contract.status === "Signed" ? "Assinado" : "Não assinado",
          }}
          hasShareLink={true}
          key={contract.lastContractOutput?.value}
        />
      </ContentContainer>
    </SubContainer>
  )
}
