import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { useTheme } from "styled-components"

const Container = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding-top: ${({ theme }) => theme.spacings.xs1};
  padding-bottom: ${({ theme }) => theme.spacings.xs5};
`

interface IModalDoneAdmission {
  title: React.ComponentProps<typeof Typography>["children"]
  subtitle?: React.ComponentProps<typeof Typography>["children"]
  desciption?: React.ComponentProps<typeof Typography>["children"]
  icon?: React.ReactNode
}

export const GenericModalContent = ({
  subtitle,
  title,
  desciption,
  icon,
}: IModalDoneAdmission) => {
  const theme = useTheme()
  return (
    <Container>
      {icon}
      <>
        {subtitle && (
          <Typography
            variant="body3"
            variantColor={theme.colors.secondary50}
            style={{ textAlign: "center" }}
            weight={700}
          >
            {subtitle}
          </Typography>
        )}
        <Typography
          variant="headline6"
          variantColor={theme.colors.neutral20}
          style={{ textAlign: "center" }}
        >
          {title}
        </Typography>
      </>

      {desciption && (
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral50}
          style={{ textAlign: "center" }}
        >
          {desciption}
        </Typography>
      )}
    </Container>
  )
}

export default GenericModalContent
