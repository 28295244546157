import styled, { css } from "styled-components"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

export const MainContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`

export const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
`

export const StyledDescription = styled(Typography)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const FormWrapper = styled.div`
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  padding: 32px;
  width: 100%;
`
export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledTextArea = styled.div<{ error: boolean }>`
  ${({ error }) =>
    error
      ? css`
          > div > textarea {
            background-color: rgb(255, 239, 204);
            border: 2px solid rgb(254, 160, 52) !important;
          }

          > div > textarea:active,
          textarea:focus {
            z-index: 0;
          }

          > div > textarea:active + label,
          textarea:focus + label {
            background-color: rgb(255, 239, 204) !important;
          }

          > div > div {
            display: none;
          }
        `
      : undefined}
`

export const StyledTextAreaHelper = styled.div`
  display: flex;
  flex-direction: row;

  margin: 4px 0px 0px 16px;
  font-size: 12px;
  color: #83727d;
  font-weight: 600;

  > svg {
    color: #fea034;
    margin-right: 2px;
  }
`
