import React from "react"
import { ListContainer } from "../styles"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { StyledIcon, TextContainer } from "./styles"
import { useTheme } from "styled-components"

type Props = {
  title: string
  value: string | number | boolean
}

export const FieldData: React.FC<Props> = (props) => {
  const theme = useTheme()
  return (
    <ListContainer>
      <StyledIcon name="IconList" />
      <TextContainer>
        <Typography variant={"caption"} color={theme.colors.neutral[30]}>
          {props.title}
        </Typography>
        <Typography variant={"body4"} color={theme.colors.neutral[30]}>
          {props.value.toString()}
        </Typography>
      </TextContainer>
    </ListContainer>
  )
}
