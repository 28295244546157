import gql from "graphql-tag"
import { request } from "../client"
import { CompanyAddress } from "../../types"
const GET_COMPANY_ADDRESSES = gql`
  query GetDeliveryAddresses {
    getDeliveryAddresses {
      name
      street
      number
      complement
      neighborhood
      city
      state
      zipCode
      reference
    }
  }
`

export interface GetCompanyAddressesResponse extends CompanyAddress {
  name: string
}

export const getCompanyDeliveryAddresses = async (): Promise<
  GetCompanyAddressesResponse[]
> => {
  const { getDeliveryAddresses } = await request(GET_COMPANY_ADDRESSES)
  return getDeliveryAddresses
}
