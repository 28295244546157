import React from "react"
import { Tab } from "@flash-tecnologia/hros-web-ui-v2"
import { Texting } from "./Texting"
import { Drawing } from "./Drawing"
import html2canvas from "html2canvas"
import * as htmlToImage from "html-to-image"
import { dataURLtoFile } from "../../../utils"

enum SignatureTypes {
  texting = 0,
  drawing = 1,
}

export type SignatureRef = {
  getSignatureFile(): Promise<File | null>
}

export const Signature = React.forwardRef<SignatureRef>((_, ref) => {
  const [currentTab, setCurrentTab] = React.useState<SignatureTypes>(
    SignatureTypes.texting,
  )

  React.useImperativeHandle(
    ref,
    () => ({
      getSignatureFile: async (): Promise<File | null> => {
        if (currentTab === SignatureTypes.drawing) {
          return await getDrawingFile()
        }

        return await getTextingFile()
      },
    }),
    [currentTab],
  )

  async function getDrawingFile(): Promise<File | null> {
    const elementSVG = document.getElementById("signSvg")
    if (!elementSVG) return null
    const pathFromSVG = elementSVG.querySelector("path")
    if (!pathFromSVG || !pathFromSVG?.getAttribute("d")) return null

    const base64 = await htmlToImage.toPng(elementSVG)
    if (!base64) return null

    const res = await fetch(base64)
    const blob = await res.blob()
    const file = new File([blob], "signature.png", { type: "image/png" })
    return file
  }

  async function getTextingFile(): Promise<File | null> {
    const element = document.getElementById("textToUpload")
    if (!element?.innerHTML) return null

    const response = await html2canvas(element, {
      onclone: function (doc: any) {
        const spanToUnhidden = doc.getElementById("textToUpload")
        spanToUnhidden.style.display = "block"
      },
    })
    if (!response) return null

    const dataURL = response.toDataURL()
    const file = dataURLtoFile(dataURL, "signature.png")
    return file
  }

  return (
    <Tab
      tabItens={[
        {
          label: "Digitar",
          component: <Texting />,
        },
        {
          label: "Desenhar",
          component: <Drawing />,
        },
      ]}
      selected={currentTab}
      onTabChanged={(index: SignatureTypes) => setCurrentTab(index)}
    />
  )
})
