import { useState, createRef, useEffect } from "react"
import {
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2"
import { SignContainer } from "./styles"

export const Drawing = () => {
  const svgRef = createRef<SVGSVGElement>()
  const canvasRef = createRef<any>()

  const [paths, setPaths] = useState<Array<[string, number, number]>>([])
  const [pathsUntil, setPathsUntil] = useState<any>([])
  const [svgX, setSvgX] = useState(0)
  const [svgY, setSvgY] = useState(0)

  const [minX, setMinX] = useState(Infinity)
  const [minY, setMinY] = useState(Infinity)

  const [maxX, setMaxX] = useState(0)
  const [maxY, setMaxY] = useState(0)

  const [drawing, setDrawing] = useState(false)

  useEffect(() => {
    const svg = svgRef.current
    if (!svg) return
    const { x, y } = svg.getBoundingClientRect()
    setSvgX(x)
    setSvgY(y)
  }, [svgRef])

  const resetDrawingBoard = () => {
    setPaths([])
    setMinX(Infinity)
    setMaxX(0)
    setMinY(Infinity)
    setMaxY(0)
  }

  return (
    <SignContainer>
      <div
        style={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
      >
        <LinkButton
          variant="primary"
          style={{ alignSelf: "center" }}
          onClick={resetDrawingBoard}
        >
          Limpar
        </LinkButton>
      </div>

      <div
        role={"presentation"}
        ref={canvasRef}
        onMouseDown={(event: any) => {
          setDrawing(true)
          const x = event.clientX - svgX
          const y = event.clientY - svgY

          setMinX(Math.min(minX, x))
          setMaxX(Math.max(maxX, x))
          setMinY(Math.min(minY, y))
          setMaxY(Math.max(maxY, y))
          setPaths([...paths, ["M", x, y]])
        }}
        onMouseMove={(event: any) => {
          if (!drawing) return

          const x = event.clientX - svgX
          const y = event.clientY - svgY

          setMinX(Math.min(minX, x))
          setMaxX(Math.max(maxX, x))
          setMinY(Math.min(minY, y))
          setMaxY(Math.max(maxY, y))
          setPaths([...paths, ["L", x, y]])
        }}
        onMouseUp={() => {
          setDrawing(false)
          const array = pathsUntil
          const merge = array.flat(1)

          const filterArray = pathsUntil.length
            ? paths.filter((a: any) => !merge.some((p: any) => p === a))
            : paths

          if (!filterArray.length) {
            setPathsUntil([])
            return
          }

          array.push(filterArray)
          setPathsUntil(array)
        }}
      >
        <svg
          id="signSvg"
          ref={svgRef}
          style={{
            width: "100%",
            height: "250px",
            zIndex: 99,
          }}
        >
          <path
            strokeWidth={5}
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke={"black"}
            fill="none"
            d={paths.reduce(
              (fullPath, lineItem) =>
                `${fullPath}${lineItem[0]}${lineItem[1]}, ${lineItem[2]}`,
              "",
            )}
          />
        </svg>

        <div
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <div
            style={{
              position: "absolute",
              background: "#D1C7CE",
              height: "1px",
              left: "16px",
              right: "16px",
              bottom: "40px",
            }}
          />
        </div>
      </div>
    </SignContainer>
  )
}
