import gql from "graphql-tag"
import { request } from "../client"

export const SAVE_BENEFITS = gql`
  mutation SaveBenefits($params: SaveBenefitsInput!) {
    saveBenefits(params: $params)
  }
`

export interface SaveBenefitsParams {
  flowCardId: string
  version: number
  candidateId: string
  employeeId: string
  benefits: {
    id: string
    value: number
  }[]
}

export const saveBenefits = async (
  params: SaveBenefitsParams,
): Promise<void> => {
  await request(SAVE_BENEFITS, { params })
}
