import { IconButton, Icons, IconTypes } from "@flash-tecnologia/hros-web-ui-v2"
import { Popover as MuiPopover } from "@mui/material"
import { useState } from "react"
import { PopoverButton, PopoverContainer, PopoverText } from "./styles"

export type PopoverButton = {
  icon: IconTypes
  text: string
  variant: "normal" | "danger"
  handleClick: () => Promise<void>
}

export type PopoverType = {
  text?: string[]
  buttons?: PopoverButton[]
}

export type IconButtonPopoverProps = {
  variant: "line" | "filled"
  size: "small" | "medium" | "large"
  icon: IconTypes
  disabled?: boolean
  popover: PopoverType
}

export const IconButtonPopover = ({
  variant,
  icon,
  size,
  disabled = false,
  popover,
}: IconButtonPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const prevent = (e: any, callback: any) => {
    e.stopPropagation()
    callback()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const renderPopoverContent = () => {
    return (
      <div>
        {popover.text &&
          popover.text.map((text, idx) => (
            <PopoverText key={idx}>{text}</PopoverText>
          ))}

        {popover.buttons &&
          popover.buttons.map((button, idx) => (
            <PopoverContainer
              key={idx}
              onClick={(e) => prevent(e, button.handleClick)}
            >
              <PopoverButton variant="body3" colorType={button.variant}>
                <Icons
                  style={{ marginRight: 5 }}
                  name={button.icon}
                  fill="none"
                />
                {button.text}
              </PopoverButton>
            </PopoverContainer>
          ))}
      </div>
    )
  }

  return (
    <div>
      <IconButton
        variant={variant}
        icon={icon}
        size={size}
        onClick={handleClick}
        disabled={disabled}
      />

      <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => prevent(e, handleClose)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {renderPopoverContent()}
      </MuiPopover>
    </div>
  )
}
