import { Icons, IconTypes, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useState } from "react"
import {
  BannerContainer,
  CloseContainer,
  IconContainer,
  MesssageContainer,
} from "./styles"

interface Banner {
  type: "info" | "error" | "success"
  icon: IconTypes
  title?: string
  message: string
  hasHideBanner?: boolean
  style?: React.CSSProperties
}

export const Banner = ({
  type,
  icon,
  title,
  message,
  hasHideBanner = true,
  style,
}: Banner) => {
  const [hideBanner, setHideBanner] = useState<boolean>(false)

  return !hideBanner ? (
    <BannerContainer type={type} style={style} canHide={hasHideBanner}>
      <IconContainer type={type}>
        <Icons name={icon} fill="transparent" size={34} />
      </IconContainer>

      <MesssageContainer>
        {title ? (
          <div>
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              {title}
            </Typography>
            <Typography variant="body4">{message}</Typography>
          </div>
        ) : (
          <Typography variant="body3" style={{ fontWeight: 600 }}>
            {message}
          </Typography>
        )}
      </MesssageContainer>

      {hasHideBanner && (
        <CloseContainer type={type} onClick={() => setHideBanner(true)}>
          <Icons name="IconX" size={14} />
        </CloseContainer>
      )}
    </BannerContainer>
  ) : null
}
