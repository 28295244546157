import { gql } from "@apollo/client"
import { request } from "../client"
import { IModelProposedLetter } from "../../types"

const CREATE_MODEL_PROPOSED_LETTER = gql`
  mutation Mutation($params: InputCreateModelProposedLetter) {
    createModelProposedLetter(params: $params) {
      _id
      name
      flowId
      companyId
      description
      status
      createdAt
      proposedLetter {
        aboutCompany
        benefits
        role
        department
        group
        assignments
        salary
        variableSalary
        workload
        workship
      }
    }
  }
`

export const createModelProposedLetter = async (params: {
  name: string
  flowId: string
  companyId: string
  description?: string
  status: "published" | "draft"
  proposedLetter?: {
    aboutCompany?: {
      key: string
      path: string
      type: string
    }
    benefits?: {
      key: string
      path: string
      type: string
    }
    role?: {
      _id: string
      name: string
    }
    department?: {
      _id: string
      name: string
    }
    group?: {
      _id: string
      name: string
    }
    assignments?: string
    salary?: number
    variableSalary?: string
    workload?: string
    workship?: string
  }
}) => {
  const { createModelProposedLetter } = await request(
    CREATE_MODEL_PROPOSED_LETTER,
    {
      params,
    },
  )
  return createModelProposedLetter as IModelProposedLetter
}
