import gql from "graphql-tag"

export const PREVIEW_MODEL_CONTRACT = gql`
  mutation PreviewModelContract($params: IPreviewModelContract) {
    previewModelContract(params: $params) {
      _id
      name
      description
      tags
      status
      companyId
      html
      active
      preview {
        key
        path
        value
        type
      }
      file {
        key
        path
        value
        type
      }
    }
  }
`
