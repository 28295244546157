import gql from "graphql-tag"
import { request } from "../client"

const CHANGE_MULTIPLE_EMPLOYEE_IN_ORGCHART = gql`
  mutation changeMultipleEmployeeInOrgchart(
    $params: changeMultipleEmployeeInput
  ) {
    changeMultipleEmployeeInOrgchart(params: $params)
  }
`

export type EmployeesInOrgchart = {
  employeeId?: string | null | undefined
  personToBeReplace?: string
  levelToSave?: number
  keepChildren?: boolean
  isChange?: boolean | null | undefined
}

export const changeMultipleEmployeeInOrgchart = async (params: {
  employees: EmployeesInOrgchart[]
}) => {
  const { changeMultipleEmployeeInOrgchart } = await request(
    CHANGE_MULTIPLE_EMPLOYEE_IN_ORGCHART,
    { params },
  )
  return changeMultipleEmployeeInOrgchart
}
