import { Dispatch, ReactElement, createContext, useReducer } from "react"
import { EnumResignationActions, IActions, resignationReducer } from "./reducer"

export interface IResignationContext {
  dispatch?: Dispatch<IActions<EnumResignationActions>>
  leaders?: { [key: string]: any }
}

const resignationContext = createContext<IResignationContext>({
  dispatch: () => { },
})

const ResignationContext = (props: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(resignationReducer, {})

  const value: IResignationContext = {
    dispatch,
    ...state,
  }

  return (
    <resignationContext.Provider value={value}>
      {props.children}
    </resignationContext.Provider>
  )
}

export { ResignationContext, resignationContext }
