import React from "react"
import BenefitSetterAccordion from "./BenefitSetterAccordion"
import { Benefit } from "../../../../../../types"
import { Container } from "./styles"
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2"

type Props = {
  benefits: Benefit[]
  values: {
    [key: string]: number
  }
  setBenefitValue: (name: string, value: number) => void
}

const BenefitsSetters: React.FC<Props> = ({
  benefits,
  values,
  setBenefitValue,
}) => {
  const workDaysInMonth = dayjs().daysInMonth()
  return (
    <Container>
      {benefits.map(({ icon, name, type }) => (
        <BenefitSetterAccordion
          benefit={{
            icon,
            name,
            type,
            value: values[name],
          }}
          setBenefitValue={(value) => {
            let totalValue = value
            if (type === "daily") {
              totalValue = value * workDaysInMonth
            }

            setBenefitValue(name, totalValue)
          }}
        />
      ))}
    </Container>
  )
}

export default BenefitsSetters
