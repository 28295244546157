import {
  IconButton,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

const CardTitle = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 100%;
  height: 100%;
`

const CardDescription = styled(Typography)`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
`

const CardContainer = styled.div<{ newSection: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  ${({ newSection, theme }) =>
    newSection
      ? css`
          border: 2px dashed ${theme.colors.primary};
        `
      : ""}
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  }
`

const CardIconButton = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.secondary[95]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary[95]};
      }
      svg {
        stroke: ${({ theme }) => theme.colors.secondary[50]};
      }
    }
  }
`

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

const OptionText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const StyledMenu = styled(Menu)`
  && {
    &.menu-base-custom-theme {
      ul {
        &.MuiList-root {
          padding: 12px;

          li {
            padding: 16px;
            margin-bottom: 0px;

            &.MuiButtonBase-root {
              div {
                text-align: start;
              }
              &:hover {
                background-color: ${({ theme }) => theme.colors.primary};
                border-radius: 0px;
                svg {
                  color: ${({ theme }) => theme.colors.neutral.light.pure};
                }
                p {
                  color: ${({ theme }) => theme.colors.neutral.light.pure};
                }
              }
              &:last-child {
                margin-bottom: 0px;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
`

const StyledIconsHoverable = styled(Icons)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const StyledWarningOption = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colors.feedback.error[40]};
  }

  p {
    color: ${({ theme }) => theme.colors.feedback.error[40]};
  }
`

export {
  CardTitle,
  CardDescription,
  StyledFlexCenter,
  CardContainer,
  CardIconButton,
  FlexBetween,
  OptionText,
  StyledMenu,
  StyledIconsHoverable,
  StyledWarningOption,
}
