import { useParams } from "react-router-dom"
import { HiringCardPage } from "../../../components"
import {
  CLTContent,
  PJContent,
  InternshipContent,
} from "../../../components/screen/HistoricContent"

const DonePage = () => {
  const { id: cardId = "" } = useParams()

  const contents = {
    ["clt"]: CLTContent,
    ["pj"]: PJContent,
    ["internship"]: InternshipContent,
  }

  return (
    <HiringCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Admissão", href: "/flows/hiring" },
        {
          name: "Concluído",
          href: `/flows/hiring/done/${cardId}`,
        },
      ]}
      contents={contents}
    />
  )
}

export default DonePage
