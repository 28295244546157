import { Benefit } from "../../../../../../../types"
import {
  StyledAccordion,
  AccordionHeader,
  AccordionChildrenContainer,
} from "./styles"
import BenefitBadge from "../../BenefitBadge"
import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { currencyParser, onlyNumbers } from "../../../../../../../utils"

type Props = {
  benefit: Pick<Benefit, "name" | "icon" | "type"> & { value: number }
  setBenefitValue: (value: number) => void
}

const inputLabelMap: {
  [key in Benefit["type"]]: string
} = {
  daily: "Saldo por dia",
  monthly: "Saldo por mês",
}

const BenefitSetterAccordion: React.FC<Props> = ({
  benefit,
  setBenefitValue,
}) => {
  const [value, setValue] = React.useState<number>()
  return (
    <StyledAccordion
      variant="disabled"
      customHeader={
        <AccordionHeader>
          <BenefitBadge src={benefit.icon} name={benefit.name} size="l" />
          <div className="text-container">
            <Typography variant="headline8">{benefit.name}</Typography>
            <Typography variant="body4">
              {benefit.value
                ? currencyParser(benefit.value.toString())
                : "Valores a definir"}
            </Typography>
          </div>
        </AccordionHeader>
      }
    >
      <AccordionChildrenContainer>
        <TextField
          label={inputLabelMap[benefit.type]}
          placeholder={inputLabelMap[benefit.type]}
          value={value ? currencyParser(value.toString()) : ""}
          onChange={(e) => {
            const parsedValue = Number(onlyNumbers(e.target.value))
            setValue(parsedValue)
          }}
          onBlur={(e) => {
            const parsedValue = onlyNumbers(e.target.value)
            setBenefitValue(parseInt(parsedValue))
          }}
        />
      </AccordionChildrenContainer>
    </StyledAccordion>
  )
}

export default BenefitSetterAccordion
