import gql from "graphql-tag"

export const GET_MODEL_CONTRACT_BY_COMPANY = gql`
  query GetModelContractByCompany($flowId: String) {
    getModelContractByCompany(flowId: $flowId) {
      _id
      name
      status
      createdAt
      associatedCandidates
    }
  }
`
