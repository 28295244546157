import { useContext, useState } from "react"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import {
  DocumentCard,
  MiniCard,
  BindSection,
  PageTemplate,
  Banner,
} from "../../../../components"
import { globalContext } from "../../../../context"
import { IFormConfig, IFormGroup } from "../../../../types"
import dispatchToast from "../../../../utils/dispatchToast"

import {
  BodyContainer,
  CategoryContainer,
  Description,
  DocumentsContainer,
  HeaderContainer,
  Title,
} from "./styled"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../../api"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

const SkeletonLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
      }}
    >
      <Skeleton variant="rectangular" height={360} />
      {[0, 1, 2].map((_, idx) => (
        <Skeleton key={idx} variant="rectangular" height={132} />
      ))}
    </div>
  )
}

const ManageSections = () => {
  const [selectedSection, setSelectedSection] = useState<
    "employee" | "dependent"
  >("employee")

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { companyId } = useContext(globalContext)
  const { flowId, id: baseOrExtensionId } = useParams()

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutateAsync: sendFormConfigExtension } = useMutation(
    api.mutation.hiring.modelDocuments.sendFormConfigExtension,
  )

  const defaultFormConfig: IFormConfig = {
    name: "",
    formGroups: [],
    type: "base",
  };

  const {
    data: formConfig,
    refetch,
    isInitialLoading: isLoading,
    isRefetching,
  } = useQuery(
    ["form-config", baseOrExtensionId],
    () =>
      api.query.hiring.modelDocuments.getFormConfigByExtensionOrBase(
        baseOrExtensionId ?? "",
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        console.log(err)
        dispatchToast({
          type: "error",
          content: "Houve um problema ao buscar dados do documento",
        })
        navigate(`/flows/settings/${flowId}/documents/list`)
      },
    },
  )

  const handleUpdateSection = (
    updatedFormGroup: IFormGroup,
    successMessage?: string,
  ) => {
    if (!formConfig) return
    const groupIndex = formConfig.formGroups.findIndex(
      (group) => group._id === updatedFormGroup._id,
    )
    if (groupIndex === -1) return

    const updatedGroups = formConfig.formGroups.map((group) => ({
      ...group,
      description: group.description || "",
    }))
    updatedGroups.splice(groupIndex, 1, updatedFormGroup)

    sendFormConfigExtension(
      {
        companyId: companyId || "",
        baseId: formConfig?._id || "",
        name: formConfig?.name || "",
        formGroups: updatedGroups,
      },
      {
        onSuccess: (response) => {
          dispatchToast({
            type: "success",
            content: successMessage || "Campo atualizado com sucesso!",
          })
          const { _id: newExtension } = response
          if (baseOrExtensionId !== newExtension) {
            navigate(`/flows/settings/${flowId}/documents/${newExtension}`)
            return
          }
          refetch()
        },
        onError: (err: any) => {
          console.log(err)
          dispatchToast({
            type: "error",
            content: "Houve um erro ao atualizar campo",
          })
        },
      },
    )
  }

  const handleBindSection = (newExtension: string) => {
    if (baseOrExtensionId !== newExtension) {
      navigate(`/flows/settings/${flowId}/documents/${newExtension}`)
      return
    }

    queryClient.invalidateQueries(["form-config", baseOrExtensionId])
  }

  const filteredSection = formConfig?.formGroups.filter(
    (group) => group.category === selectedSection,
  )

  return (
    <PageTemplate
      history={[
        { name: "Admissão", href: "/flows/hiring" },
        {
          name: "Modelos de documentos",
          href: `/flows/settings/${flowId}/documents/list`,
        },
        { name: "Configurar lista de documentos" },
      ]}
    >
      <HeaderContainer>
        <Title variant="headline6">Configurar modelo</Title>
        <Description variant="body3" style={{ marginTop: 5 }}>
          Configure abaixo o que será solicitado na lista de documentos, como
          uniforme, foto para o crachá, entre outros.
        </Description>

        <Banner
          icon="IconInfoCircle"
          type="info"
          title={t("documentTemplate.section.instructionBannerTitle")}
          message={t("documentTemplate.section.instructionBannerDescription")}
          hasHideBanner={false}
          style={{ marginTop: 16 }}
        />
      </HeaderContainer>

      <BodyContainer>
        <CategoryContainer>
          <Title variant="headline7" style={{ marginBottom: 16 }}>
            Categorias
          </Title>

          <MiniCard
            active={selectedSection === "employee"}
            onClick={() => setSelectedSection("employee")}
          >
            Candidato
          </MiniCard>

          <MiniCard
            active={selectedSection === "dependent"}
            onClick={() => setSelectedSection("dependent")}
          >
            Dependente
          </MiniCard>
        </CategoryContainer>

        <DocumentsContainer>
          <Title variant="headline7" style={{ marginBottom: 16 }}>
            Seções
          </Title>

          {isLoading || isRefetching ? (
            <SkeletonLoading />
          ) : (
            <>
              {formConfig && (
                <BindSection
                  companyId={companyId || ""}
                  formConfig={formConfig}
                  flowId={flowId || ""}
                  category={selectedSection}
                  handleBindSection={handleBindSection}
                />
              )}

              {filteredSection?.map((section, idx) => (
                <DocumentCard
                  key={idx}
                  formConfig={formConfig ?? defaultFormConfig}
                  flowId={flowId || ""}
                  section={section}
                  handleUpdateSection={handleUpdateSection}
                />
              ))}
            </>
          )}
        </DocumentsContainer>
      </BodyContainer>
    </PageTemplate>
  )
}

export default ManageSections
