import { Wrapper } from "./styles"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

interface HeaderProps {
  isEdit: boolean
}

export const Header = ({ isEdit }: HeaderProps) => {
  return (
    <Wrapper>
      <Typography variant="headline6">
        {isEdit ? "Editar" : "Criar novo"} modelo de contrato
      </Typography>
    </Wrapper>
  )
}
