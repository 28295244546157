import { gql } from "graphql-request"
import { request } from "../client"

const CHECKLIST_TEMPLATE_UPDATE = gql`
  mutation TemplateUpdateChecklist($params: UpdateTemplateChecklistInput) {
    templateUpdateChecklist(params: $params)
  }
`

export const checklistTemplateUpdate = async (params: {
  checklistId: string
  oldValue: string
  newValue: string
}) => {
  await request(CHECKLIST_TEMPLATE_UPDATE, { params })
}
