import styled from "styled-components"

export const RoundedButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0 18px;
  height: 42px;
  cursor: pointer;

  color: #6b5b66;
  font-weight: 500;

  border: 1px solid var(--color-neutral-light2);
  border-radius: 100px;

  &:hover {
    background-color: var(--color-neutral-light2);
  }
`
