import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

import { StyledPOneLine, StyledPreviewProgress } from "./styles"

export const UploadLoading = () => {
  return (
    <div
      style={{
        pointerEvents: "none",
        userSelect: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "32px 40px",
          border: "1px solid #EBE6E9",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginRight: "7px",
          }}
        >
          <div
            style={{
              marginRight: "7px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icons name={"IconFile"} style={{ fill: "transparent" }} />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "22px",
                color: "#3B2E37",
              }}
            >
              <StyledPOneLine numberOfLines={1}>
                Carregando o arquivo, por favor, aguarde!
              </StyledPOneLine>
            </span>
            <StyledPreviewProgress />
          </div>
        </div>
      </div>
    </div>
  )
}
