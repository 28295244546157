import { IconTypes, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Container, IconCircleContainer, Wrapper } from "./styles"
import { ModelTypeFileAvailable } from "../../../../../types"
import { downloadArchive } from "../../../../../utils"

export type ModelDownloadProps = {
  fileType: ModelTypeFileAvailable
  presignedFileUrl: string
}

const IconModelMap: { [key in ModelTypeFileAvailable]: IconTypes } = {
  CSV: "CSVDownload",
  TXT: "TXTDownload",
  XLSX: "XLSXDownload",
}

export const ModelDownload = ({
  fileType,
  presignedFileUrl,
}: ModelDownloadProps) => {
  const fileName = `Candidatos.${fileType.toLowerCase()}`
  return (
    <Container>
      <Wrapper>
        <Icons
          name={IconModelMap[fileType]}
          fill="transparent"
          size={34}
          style={{ flexShrink: 0 }}
        />

        <div>
          <Typography variant={"headline10"}>
            {`Baixar modelo ${fileType}`}
          </Typography>
          <Typography variant={"body4"}>{fileName}</Typography>
        </div>
      </Wrapper>

      <IconCircleContainer
        onClick={() => downloadArchive(presignedFileUrl, fileName)}
      >
        <Icons name="IconDownload" fill="transparent" />
      </IconCircleContainer>
    </Container>
  )
}
