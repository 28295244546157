import React from "react"
import { FooterBar, FooterData } from "../../common/FooterBar"
import { PageBody, PageContainer } from "./styles"
import Navigator, { NavigatorLink } from "../../common/Navigator"

type RouteHistory = {
  name: string
  href?: string
}

interface PageTemplateProps {
  history: RouteHistory[]
  children: React.ReactNode
  footer?: FooterData
  headerStyle?: React.CSSProperties
  disableGoBack?: boolean
}

const PageTemplate = ({
  history,
  children,
  footer,
  headerStyle,
  disableGoBack,
}: PageTemplateProps) => {
  const breadcrumbs = history.map((route, idx) => {
    const key = `breadcrumb-link-${idx}`
    const isLast = idx === history.length - 1

    return (
      <NavigatorLink
        key={key}
        name={route.name}
        href={isLast ? undefined : route.href}
      />
    )
  })

  return (
    <PageContainer>
      <Navigator
        style={headerStyle}
        breadcrumbs={breadcrumbs}
        disableGoBack={disableGoBack}
      />

      <PageBody>{children}</PageBody>

      {footer && <FooterBar {...footer} />}
    </PageContainer>
  )
}

export { PageTemplate, PageTemplateProps, RouteHistory }
