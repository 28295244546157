import gql from "graphql-tag"

export const EDIT_MODEL_CONTRACT = gql`
  mutation EditModelContract($params: IEditModelContract) {
    editModelContract(params: $params) {
      _id
      name
      description
      tags
      status
      companyId
      html
      active
      preview {
        key
        path
        value
        type
      }
      file {
        key
        path
        value
        type
      }
    }
  }
`
