import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const AccordionChildrenContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const BenefitContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`

export const BenefitName = styled(Typography)`
  width: 100%;
`
