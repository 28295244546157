import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTextArea = styled.textarea<{
  width: string
  height: string
  resizable: boolean
}>`
  background-color: transparent;
  border: 1px solid #b9acb4;
  border-radius: 12px;
  padding: 16px;
  overflow: auto !important;
  resize: ${({ resizable }) => (resizable ? "vertical" : "none")};
  width: ${({ width }) => width};
  min-height: ${({ height }) => height} !important;

  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    background: transparent;
    width: 6px;
    height: 48px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9acb4;
    border-radius: 12px;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #b9acb4;
  }

  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #6b5b66;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 12px;
  color: #83727d;
  margin-top: 5px;
  padding: 0px 15px;
`
