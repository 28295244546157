export const onlyNumbers = (str: string): string =>
  str?.replace(/\D/g, "") ?? ""

export const cepParser = (value: string): string => onlyNumbers(value)

export const cpfParser = (value: string): string => onlyNumbers(value)

export const cnpjParser = (value: string): string => onlyNumbers(value)

export const currencyParser = (value: string) => {
  const n = String(value).replace(/\D/g, "").replace(/^0+/g, "")
  const t = n.padStart(3, "0")

  return (
    "R$" +
    t
      .replace(/(\D)/g, "")
      .replace(/(\d)(\d{2})$/, "$1,$2")
      .replace(/(?=(\d{3})+(\D))\B/g, ".")
  )
}
