import gql from "graphql-tag"
import { request } from "../client"
import { IDocumentValidation } from "../../types"

const DEPENDENT_VALIDATE_DOCUMENT = gql`
  mutation ValidateDependentDocument($params: ValidateDependentDocumentInput) {
    validateDependentDocument(params: $params)
  }
`

export const dependentValidateDocument = async (params: {
  dependentId: string
  sectionId: string
  status: "approved" | "declined" | "waiting"
  statusComment?: string
}) => {
  const { validateDependentDocument } = await request(
    DEPENDENT_VALIDATE_DOCUMENT,
    { params },
  )
  return validateDependentDocument as IDocumentValidation[]
}
