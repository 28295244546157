import gql from "graphql-tag"
import { request } from "../client"
import { MassCandidateProcess } from "../../types"

const FIND_MASS_CANDIDATE_PROCESS_BY_FLOW = gql`
  query FindMassCandidateProcessByFlow(
    $params: InputFindMassCandidateProcessByFlow
  ) {
    findMassCandidateProcessByFlow(params: $params) {
      _id
      notificationEmployeeId
      companyId
      flowId
      completionPercentage
      validationPercentage
      lastReadPosition
      lastValidatedPosition
      fileKey
      active
      dismiss
      errorReport {
        line
        reason
      }
      fileType
      initialPosition
      validationProgress
    }
  }
`

export type FindMassCandidateProcessByFlowParams = {
  flowId: string
}

export const findMassCandidateProcessByFlow = async (
  params: FindMassCandidateProcessByFlowParams,
): Promise<MassCandidateProcess> => {
  const response = await request<{
    findMassCandidateProcessByFlow: MassCandidateProcess
  }>(FIND_MASS_CANDIDATE_PROCESS_BY_FLOW, {
    params,
  })
  return response.findMassCandidateProcessByFlow
}
