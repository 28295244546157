import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"
import { DeprecatedModal } from "../../../common"

export const StyledModal = styled(DeprecatedModal)<{ isSmall?: boolean }>`
  && {
    .modal-container {
      width: 100%;
      max-width: ${({ isSmall = false }) => (isSmall ? "740px" : "896px")};
      max-height: 80vh;
      overflow: hidden;

      .modal-content-area {
        padding: 0px;
      }
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`

export const Header = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 150px;
  border-bottom: 1px solid #ebe6e9;
`

export const Body = styled.body`
  display: flex;
  flex-direction: column;
  padding: 40px 150px;
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 24px 150px;
  border-top: 1px solid #ebe6e9;
`

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 12px;
  padding-right: 24px;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 24px;

  cursor: pointer;
  padding: 14px;
  border: 1px solid #e8e3e6;
  border-radius: 50%;

  &:hover {
    opacity: 0.7;
  }
`

export const Title = styled(Typography)`
  color: #1d161b;
`

export const Description = styled(Typography)`
  color: #83727d;
`

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #d1c7ce;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
`

export const RoundIconContainer = styled.div`
  width: 64px;
  height: 64px;
  background-color: #ffe0ef;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    color: #f20d7a;
  }
`

export const RoundIconTitle = styled.div`
  color: #f20d7a;
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
`
