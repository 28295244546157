import gql from "graphql-tag"
import { request } from "../client"
import { FlowBigNumbers } from "../../types"

export const GET_FLOW_BIG_NUMBERS = gql`
  query GetFlowBigNumbers($flowId: ID!) {
    getFlowBigNumbers(flowId: $flowId) {
      pendingProposalSubmission
      expiredProposals
      refusedProposals
      pendingDocumentsRequest
      expiredDocumentsRequest
      expiredAsoRequest
      pendingSigning
      expiredSigningRequest
    }
  }
`

export const getFlowBigNumbers = async ({
  flowId,
}: {
  flowId: string
}): Promise<FlowBigNumbers> => {
  const { getFlowBigNumbers } = await request<{
    getFlowBigNumbers: FlowBigNumbers
  }>(GET_FLOW_BIG_NUMBERS, { flowId })
  return getFlowBigNumbers
}
