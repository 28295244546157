import { gql } from "graphql-request"
import { request } from "../client"

const RENAME_FORMCONFIG = gql`
  mutation RenameFormConfig($params: RenameFormConfigInput) {
    renameFormConfig(params: $params) {
      _id
      name
    }
  }
`

export const renameFormConfig = async (params: {
  formConfigId: string
  newName: string
}) => {
  await request(RENAME_FORMCONFIG, { params })
}
