import { useAuth } from "@flash-hros/auth-helper"
import { useEffect } from "react"
import { matchPath, useLocation, useNavigate } from "react-router-dom"
import { externalRoutes } from "src/routes/supportedExternalRoutes"

export const ExternalRoute = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const contractStatus = useAuth.getState().contractStatus || null

  useEffect(() => {
    const isExtenalRouteSupported = Object.values(externalRoutes).some(
      (externalRoute) =>
        matchPath({ path: externalRoute.path, end: true }, location.pathname),
    )

    if (isExtenalRouteSupported) {
      navigate(location.pathname)
    } else if (contractStatus?.url) {
      navigate(contractStatus.url, { state: { from: location } })
    } else {
      throw new Error("External route not supported")
    }
  }, [])

  return null
}
