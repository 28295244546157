import { useParams } from "react-router-dom"
import { HiringCardPage } from "../../../components"
import GenericContent from "./GenericContent"

const DocumentsSendPage = () => {
  const { id: cardId = "" } = useParams()

  const contents = {
    ["clt"]: GenericContent,
    ["pj"]: GenericContent,
    ["internship"]: GenericContent,
  }

  return (
    <HiringCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Admissão", href: "/flows/hiring" },
        {
          name: "Envio de documentos",
          href: `/flows/hiring/documents/send/${cardId}`,
        },
      ]}
      contents={contents}
    />
  )
}

export default DocumentsSendPage
