import * as yup from "yup"
import {
  CircleIconContainer,
  ConfirmationModal,
  HiringLimitDate,
  LimitDateRef,
  Upload,
  WizardCardLink,
} from "../../../components"
import { AdmissionalExamStatus, Flow, HiringCard } from "../../../types"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import {
  Button,
  DatePicker,
  Icons,
  Loader,
  Radio,
  Skeleton,
  TextArea,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import React, { useRef, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../api"
import dispatchToast from "../../../utils/dispatchToast"
import { Container, Row, StatusContainer, StyleStatusButton } from "./styles"
import { createSegmentTrack } from "../../../utils"
import { hiringCardContext } from "../../../components/screen/CardTemplate/Hiring"
import { HiringLimitDateEnum } from "bff/src/types/hiring"
import { useTheme } from "styled-components"

const getLimitDateStep = (subcategory?: "clt" | "internship" | "pj") => {
  if (!subcategory) return null
  switch (subcategory) {
    case "clt":
      return HiringLimitDateEnum.aso_Ctl
    case "internship":
      return HiringLimitDateEnum.aso_Estag
  }
}

const SendRequestContent = ({
  card,
  flow,
}: {
  card: HiringCard
  flow: Flow
}) => {
  const [isSending, setIsSending] = useState<boolean>(false)
  const [confirmationSendModal, setConfirmationSendModal] =
    useState<boolean>(false)
  const queryClient = useQueryClient()

  const { mutateAsync: mutateUpdateCard } = useMutation(
    api.mutation.hiring.card.updateCard,
  )
  const { mutateAsync: sendAdmissionalExamRequest } = useMutation(
    api.mutation.hiring.sendAdmissionalExamRequest,
  )

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })
  const limitDateRef = useRef<LimitDateRef>(null)

  const formik = useFormik({
    initialValues: {
      limitDate: card.admissionalExam?.limitDate,
      medicalReferral: card.admissionalExam?.medicalReferral,
    },
    validationSchema: yup.object({
      limitDate: yup.string().required("Campo obrigatório"),
    }),
    validate: async () => {
      const errors: any = await limitDateRef.current?.validate()
      return errors
    },
    onSubmit: async (values) => {
      createSegmentTrack({
        track: `company_hiring_${flow.subcategory}_admissionexam_send_clicked`,
      })

      try {
        setIsSending(true)

        await limitDateRef.current?.submit()

        const medicalReferral = values.medicalReferral
        if (medicalReferral?.value) delete medicalReferral.value

        const updatedCard = await mutateUpdateCard({
          admissionalExam: {
            limitDate: values.limitDate,
            medicalReferral: medicalReferral,
            status: AdmissionalExamStatus.sent,
          },
          flowCardId: card._id,
        })
        await sendAdmissionalExamRequest({
          candidateId: updatedCard.candidateId,
          columnId: updatedCard.columnId,
          flowCardId: updatedCard._id,
          version: updatedCard.version,
        })
        await dispatchToast({
          content: "Exame admissional enviado com sucesso",
          type: "success",
        })
        queryClient.invalidateQueries(["hiring-card", card._id])
        setIsSending(false)
      } catch (err: any) {
        setIsSending(false)
        const errorMessage =
          err.serviceError?.details?.[0]?.message ||
          err.serviceError?.message ||
          err.httpError?.message ||
          err?.message
        dispatchToast({
          content: errorMessage,
          type: "error",
        })
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
  })

  const limitDateStep = getLimitDateStep(flow?.subcategory)

  return (
    <>
      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("admissionExam.dateLimitTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("admissionExam.dateLimitDescription")}
          </Typography>
        </div>

        <DatePicker
          label={t("admissionExam.dateLimitLabel")}
          value={formik.values.limitDate}
          fromDate={dayjs()}
          onDateChange={(value) => {
            if (!dayjs(value).isValid()) return

            formik.handleChange({
              target: {
                id: "limitDate",
                value: dayjs(value).toISOString(),
              },
            })
          }}
          error={!!formik.errors.limitDate}
          helperText={
            !!formik.errors.limitDate &&
            "Por favor insira uma data limite valida"
          }
          fullWidth
        />

        {limitDateStep ? (
          <HiringLimitDate
            ref={limitDateRef}
            cardId={card._id}
            step={limitDateStep}
            limitDate={card.limitDates?.find(
              (limitDate) => limitDate.step === limitDateStep,
            )}
          />
        ) : (
          <></>
        )}
      </Row>

      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("admissionExam.guideTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("admissionExam.guideDescription")}
          </Typography>
        </div>

        <Upload
          value={formik.values.medicalReferral}
          label={t("admissionExam.guideLabel")}
          accept={["jpg", "png", "pdf"]}
          folder={`${card.companyId}/${card.candidateId}/${card._id}/aso`}
          module="employee-pii"
          customFilename={`Guia_${card.candidate.name}`}
          onUpload={async (file) => {
            createSegmentTrack({
              track: `company_hiring_${flow.subcategory}_fileupload_aso_clicked`,
            })

            formik.handleChange({
              target: {
                id: "medicalReferral",
                value: file,
              },
            })
          }}
        />
      </Row>

      <Button
        variant="primary"
        size="medium"
        onClick={() => setConfirmationSendModal(true)}
        style={{ width: "100%" }}
        disabled={isSending}
      >
        {isSending ? (
          <Loader size="extraSmall" variant="secondary" />
        ) : (
          <>
            {t("admissionExam.buttonSendRequest")}
            <Icons fill="transparent" name="IconMail" />
          </>
        )}
      </Button>

      <ConfirmationModal
        variant="primary"
        isOpen={confirmationSendModal}
        onClose={() => setConfirmationSendModal(false)}
        title="Tem certeza que deseja enviar a solicitação de ASO?"
        description="Verifique se todas as informações foram preenchidas corretamente."
        confirmButton={{
          onClick: () => formik.handleSubmit(),
          isLoading: isSending,
          text: "Prosseguir e enviar",
          icon: "IconArrowRight",
        }}
      />
    </>
  )
}

const ValidateContent = ({ card: ogCard }: { card: HiringCard }) => {
  const [card, setCard] = useState<HiringCard>(ogCard)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isRadioLoading, setIsRadioLoading] = useState<boolean>(false)
  const [documentStatus, setDocumentStatus] = useState<
    "toAccept" | "toResend" | null
  >(
    ["approved", "declined"].includes(card.admissionalExam?.status || "")
      ? "toAccept"
      : null,
  )
  const [refuseReason, setRefuseReason] = useState<string>("")

  const { mutateAsync: mutateUpdateCard } = useMutation(
    api.mutation.hiring.card.updateCard,
  )
  const { mutateAsync: validateAdmissionalExamDocument } = useMutation(
    api.mutation.hiring.validateAdmissionalExamDocument,
  )
  const { mutateAsync: mutateUpdateStatus } = useMutation(
    api.mutation.hiring.card.updateStatus,
  )
  const { mutateAsync: resendAdmissionalExamRequest } = useMutation(
    api.mutation.hiring.resendAdmissionalExamRequest,
  )
  const queryClient = useQueryClient()
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const handleRefuse = async () => {
    if (!refuseReason || refuseReason.length === 0) {
      dispatchToast({
        type: "error",
        content: "Descreva o motivo da recusa antes de reenviar.",
      })
      return
    }
    try {
      setIsSending(true)
      await validateAdmissionalExamDocument({
        flowCardId: card._id,
        status: "declined",
        statusComment: refuseReason,
      })
      const updatedCard = await mutateUpdateCard({
        flowCardId: card._id,
        admissionalExam: {
          status: AdmissionalExamStatus.resent,
        },
      })
      await resendAdmissionalExamRequest({
        flowCardId: updatedCard._id,
        candidateId: updatedCard.candidateId,
        columnId: updatedCard.columnId,
        reason: refuseReason,
        version: updatedCard.version,
      })
      await dispatchToast({
        content: "Exame admissional reenviado com sucesso",
        type: "success",
      })
      queryClient.invalidateQueries(["hiring-card", card._id])
      setIsSending(false)
    } catch (err: any) {
      setIsSending(false)
      const errorMessage =
        err.serviceError?.details?.[0].message ||
        err.serviceError?.message ||
        err.httpError?.message
      dispatchToast({
        content: errorMessage,
        type: "error",
      })
    }
  }

  return (
    <>
      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("admissionExam.dateLimitTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("admissionExam.dateLimitDescription")}
          </Typography>
        </div>

        <DatePicker
          label={t("admissionExam.dateLimitLabel")}
          value={card.admissionalExam.limitDate}
          onDateChange={() => {}}
          disabled={true}
          fullWidth
        />
      </Row>

      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("admissionExam.guideTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("admissionExam.guideDescription")}
          </Typography>
        </div>

        <Upload
          value={card.admissionalExam.medicalReferral}
          label={t("admissionExam.guideLabel")}
          accept={["jpg", "png", "pdf"]}
          folder={`${card.companyId}/${card.candidateId}/${card._id}/aso`}
          canDelete={false}
        />
      </Row>

      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("admissionExam.asoTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("admissionExam.asoDescription")}
          </Typography>
        </div>

        {!card.admissionalExam.file ? (
          <StatusContainer>
            <Typography
              variant="body3"
              variantColor="#53464F"
              style={{
                fontWeight: 700,
              }}
            >
              {card.candidate.name}
            </Typography>
            <Typography
              variant="body4"
              variantColor="#83727D"
              style={{
                textDecoration: "underline",
              }}
            >
              {card.candidate.email}
            </Typography>
            <Typography
              variant="body4"
              variantColor="#C96C01"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontWeight: 700,
              }}
            >
              <Icons
                name="IconClock"
                fill="transparent"
                size={16}
                style={{
                  marginRight: 5,
                }}
              />
              {t("admissionExam.asoStatus")}
            </Typography>
          </StatusContainer>
        ) : (
          <>
            <DatePicker
              label={t("admissionExam.asoExamDate")}
              value={card.admissionalExam.date}
              onDateChange={() => {}}
              disabled={true}
              fullWidth
            />

            <div>
              <Typography
                variant="body3"
                variantColor="#53464F"
                style={{ marginBottom: 4 }}
              >
                ASO
              </Typography>
              <Upload
                value={card.admissionalExam.file}
                label={t("admissionExam.guideLabel")}
                accept={["jpg", "png", "pdf"]}
                folder={`${card.companyId}/${card.candidateId}/${card._id}/aso`}
                canDelete={false}
              />
            </div>
          </>
        )}
      </Row>

      {card.admissionalExam?.status &&
        [
          AdmissionalExamStatus.filled,
          AdmissionalExamStatus.approved,
          AdmissionalExamStatus.declined,
        ].includes(card.admissionalExam.status) && (
          <Row>
            {isRadioLoading ? (
              <Skeleton width={"100%"} height={200} />
            ) : (
              <>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "calc(50% - 12px) calc(50% - 12px)",
                    gap: 24,
                  }}
                >
                  <StyleStatusButton
                    size="medium"
                    buttonType="secondary"
                    variantType="error"
                    onClick={() => setDocumentStatus("toResend")}
                    style={{ width: "100%" }}
                    disabled={[
                      AdmissionalExamStatus.approved,
                      AdmissionalExamStatus.declined,
                    ].includes(card.admissionalExam.status)}
                    active={documentStatus === "toResend"}
                  >
                    <Icons name="IconX" fill="transparent" />
                    {t("admissionExam.asoReproveButton")}
                  </StyleStatusButton>
                  <StyleStatusButton
                    size="medium"
                    buttonType="secondary"
                    variantType="success"
                    onClick={() => setDocumentStatus("toAccept")}
                    style={{ width: "100%" }}
                    active={documentStatus === "toAccept"}
                  >
                    <Icons name="IconCheck" fill="transparent" />
                    {t("admissionExam.asoApproveButton")}
                  </StyleStatusButton>
                </div>

                {documentStatus === "toResend" && (
                  <>
                    <TextArea
                      maxLength={100}
                      placeholder={t("admissionExam.textAreaRefuseLabel")}
                      onChange={(value: any) =>
                        setRefuseReason(value.target.value)
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="primary"
                        onClick={handleRefuse}
                        disabled={isSending}
                        size="medium"
                      >
                        {isSending ? (
                          <Loader size="extraSmall" variant="secondary" />
                        ) : (
                          <>
                            {t("admissionExam.buttonResendRequest")}
                            <Icons fill="transparent" name="IconMail" />
                          </>
                        )}
                      </Button>
                    </div>
                  </>
                )}

                {documentStatus === "toAccept" && (
                  <div>
                    <Typography
                      variant="body4"
                      variantColor="#83727D"
                      style={{ marginBottom: 8 }}
                    >
                      {t("admissionExam.asoCanWorkDescription")}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Radio
                        name="radio-buttons"
                        onChange={async () => {
                          setIsRadioLoading(true)
                          try {
                            const { version } = await mutateUpdateCard({
                              flowCardId: card._id,
                              admissionalExam: {
                                status: AdmissionalExamStatus.approved,
                              },
                            })
                            const updatedCard = await mutateUpdateStatus({
                              flowCardId: card._id,
                              newStatus: "Candidato apto",
                              version: version,
                            })
                            setCard(updatedCard)
                            setIsRadioLoading(false)
                          } catch (err: any) {
                            dispatchToast({
                              content: "Algo aconteceu ao aprovar o candidato",
                              type: "error",
                            })
                            setIsRadioLoading(false)
                          }
                        }}
                        checked={
                          card.admissionalExam.status ===
                          AdmissionalExamStatus.approved
                        }
                      />
                      <Typography variant="body3" variantColor="#53464F">
                        {t("admissionExam.asoCanWorkLabel")}
                      </Typography>

                      <Radio
                        name="radio-buttons"
                        onChange={async () => {
                          setIsRadioLoading(true)
                          try {
                            const { version } = await mutateUpdateCard({
                              flowCardId: card._id,
                              admissionalExam: {
                                status: AdmissionalExamStatus.declined,
                              },
                            })
                            const updatedCard = await mutateUpdateStatus({
                              flowCardId: card._id,
                              newStatus: "Candidato inapto",
                              version: version,
                            })
                            setCard(updatedCard)
                            setIsRadioLoading(false)
                          } catch (err: any) {
                            dispatchToast({
                              content: "Algo aconteceu ao reprovar o candidato",
                              type: "error",
                            })
                            setIsRadioLoading(false)
                          }
                        }}
                        checked={
                          card.admissionalExam.status ===
                          AdmissionalExamStatus.declined
                        }
                        style={{ marginLeft: 40 }}
                      />
                      <Typography variant="body3" variantColor="#53464F">
                        {t("admissionExam.asoCannotWorkLabel")}
                      </Typography>
                    </div>
                  </div>
                )}
              </>
            )}
          </Row>
        )}
    </>
  )
}

export const GenericContent = ({
  card,
  flow,
}: {
  card: HiringCard
  flow: Flow
}) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { setResetStep } = React.useContext(hiringCardContext)
  const { mutateAsync: resetAso, isLoading: isLoadingResetAso } = useMutation(
    api.mutation.hiring.card.resetAso,
  )

  React.useEffect(() => {
    setResetStep({
      canReset: !!card.admissionalExam?.status,
      confirmCallback: async () => {
        await resetAso(card._id)
        dispatchToast({
          type: "success",
          content: "Etapa reiniciada com sucesso!",
        })
        queryClient.resetQueries(["hiring-card", card._id])
      },
      isLoading: isLoadingResetAso,
    })
  }, [card])

  const findEmailLink =
    card.emailLink?.find(
      (e) => e.columnId === card.columnId && e.name === "resendAsoRequest",
    ) ||
    card.emailLink?.find(
      (e) => e.columnId === card.columnId && e.name === "asoRequest",
    )

  return (
    <Container>
      {["sent", "resent"].includes(card.admissionalExam?.status || "") && (
        <Row style={{ gap: 0, textAlign: "center", alignItems: "center" }}>
          <CircleIconContainer
            backgroundColor={theme.colors.secondary[95]}
            color={theme.colors.secondary[50]}
          >
            <Icons name="IconCheck" fill="transparent" />
          </CircleIconContainer>
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[30]}
          >
            Solicitação enviada!
          </Typography>
          <Typography
            variant="body2"
            variantColor={theme.colors.neutral[50]}
            style={{ maxWidth: 500, marginTop: 16 }}
          >
            Agora é só aguardar o anexo do ASO. Confira abaixo o agendamento
            encaminhado.
          </Typography>
        </Row>
      )}

      {findEmailLink && (
        <Row>
          <WizardCardLink
            multiplesEmailLinks={[{ emailLink: findEmailLink }]}
          />
        </Row>
      )}

      {!card.admissionalExam?.status ? (
        <SendRequestContent card={card} flow={flow} />
      ) : (
        <ValidateContent card={card} />
      )}
    </Container>
  )
}
