import {
  Breadcrumbs,
  IconButton,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { PageHeader } from "../../screen/CardPageTemplate/styles"
import { useNavigate } from "react-router-dom"
import { Link } from "@mui/material"
import { useTheme } from "styled-components"

export type NavigatorLinkProps = {
  name: string
  href?: string
}

export const NavigatorLink = ({ name, href }: NavigatorLinkProps) => {
  const { colors } = useTheme()
  return (
    <Link
      underline={href ? "hover" : "none"}
      color={colors.neutral50}
      fontWeight={700}
      href={href}
    >
      {/* <Typography variant="body4" variantColor={colors.neutral50} weight={700}> */}
      {name}
      {/* </Typography> */}
    </Link>
  )
}

export type NavigatorProps = {
  breadcrumbs: React.ReactComponentElement<
    typeof NavigatorLink | typeof Typography
  >[]
  style?: React.CSSProperties
  disableGoBack?: boolean
}

export const Navigator: React.FC<NavigatorProps> = ({
  style,
  disableGoBack,
  breadcrumbs,
}) => {
  const navigate = useNavigate()
  const handleReturn = () => navigate(-1)

  return (
    <PageHeader style={style}>
      {!disableGoBack && (
        <IconButton
          onClick={handleReturn}
          size="small"
          variant="line"
          icon="IconChevronLeft"
        />
      )}
      <Breadcrumbs
        style={{ marginLeft: 15 }}
        separator={<Icons name="IconChevronRight" fill={"transparent"} />}
        breadcrumbs={breadcrumbs}
      />
    </PageHeader>
  )
}

export default Navigator
