import { gql } from "@apollo/client"
import { request } from "../client"
import { IModelProposedLetter } from "../../types"

const DUPLICATE_MODEL_PROPOSED_LETTER = gql`
  mutation DuplicateModelProposedLetter($modelId: ID) {
    duplicateModelProposedLetter(modelId: $modelId) {
      _id
      name
      flowId
      companyId
      description
      status
      createdAt
      proposedLetter {
        aboutCompany
        benefits
        role
        department
        group
        assignments
        salary
        variableSalary
        workload
        workship
      }
    }
  }
`

export const duplicateModelProposedLetter = async (modelId: string) => {
  const { duplicateModelProposedLetter } = await request(
    DUPLICATE_MODEL_PROPOSED_LETTER,
    {
      modelId,
    },
  )
  return duplicateModelProposedLetter as IModelProposedLetter
}
