import React from "react"
import { useFormik } from "formik"
import { FormRef } from "../../Forms"
import * as yup from "yup"
import { MultiSelect } from "@flash-tecnologia/hros-web-ui-v2"
import { CheckboxHugeField } from "../../components/CheckboxHugeField"
import dispatchToast from "../../../../utils/dispatchToast"
import { Flow, FlowStage, NotificationsConfig } from "bff/src/types/hiring"
import { trpc } from "src/api/client"
import { useNavigate } from "react-router-dom"
import { columnsStageInfo, hiringFlowStageMap } from "src/mock/flowStages"
import { useTranslation } from "react-i18next"

type Option = { label: string; value: string }

type FormData = {
  stages: Option[]
  notifyLeaders: boolean
  notifyFollowers: boolean
}

const validationSchema = yup.object({
  stages: yup
    .array()
    .min(1, "Por favor selecione alguma etapa")
    .required("Campo obrigatorio"),
  notifyLeaders: yup.boolean(),
  notifyFollowers: yup.boolean(),
})

const getFlowColumnOptions = (): { label: string; value: string }[] => {
  return Object.keys(columnsStageInfo.hiring).map((key) => {
    const stage = columnsStageInfo.hiring[key].key
    return {
      label: hiringFlowStageMap[stage],
      value: stage,
    }
  })
}

const getOptionFromStage = (
  stage: FlowStage,
): { label: string; value: string } => {
  return {
    label: hiringFlowStageMap[stage],
    value: stage,
  }
}

type MovimentationProps = {
  notificationConfig: NotificationsConfig
  flow: Flow
}

export const Movimentation = React.forwardRef(
  (
    { notificationConfig, flow }: MovimentationProps,
    ref: React.Ref<FormRef<FormData>>,
  ) => {
    const navigate = useNavigate()
    const { mutateAsync: updateConfig } =
      trpc.notification.updateNotificationConfig.useMutation()

    const [t] = useTranslation("translations", {
      keyPrefix: "page.settings.editNotification.movimentation",
    })

    const formik = useFormik<FormData>({
      initialValues: {
        stages: notificationConfig.cardMovement?.stages
          ? notificationConfig.cardMovement?.stages.map((stage) =>
              getOptionFromStage(stage),
            )
          : [],
        notifyFollowers:
          notificationConfig.cardMovement?.notifyFollowers ?? true,
        notifyLeaders: notificationConfig.cardMovement?.notifyLeaders ?? true,
      },
      validationSchema,
      validate: (values) => {
        if (!values.notifyFollowers && !values.notifyLeaders) {
          dispatchToast({
            content: t("errorAtLeastOne"),
            type: "error",
          })
          return { hasError: true }
        }
      },
      onSubmit: async (values) => {
        try {
          await updateConfig({
            notificationConfigId: notificationConfig._id,
            body: {
              cardMovement: {
                active: true,
                notifyFollowers: values.notifyFollowers,
                notifyLeaders: values.notifyLeaders,
                stages: values.stages.map(
                  (stages) => stages.value as FlowStage,
                ),
              },
            },
          })
          dispatchToast({
            content: t("successUpdate"),
            type: "success",
          })
          navigate(`/flows/settings/${flow._id}/notification`)
        } catch (err: any) {
          dispatchToast({
            content: err.message,
            type: "error",
          })
        }
      },
    })

    React.useImperativeHandle(ref, () => formik)

    const options = React.useMemo(() => getFlowColumnOptions(), [])

    return (
      <>
        <MultiSelect
          renderInput={() => null}
          label={
            <>
              {t("selectStep")} <span style={{ color: "#C96C01" }}>*</span>
            </>
          }
          value={formik.values.stages}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          options={options}
          onSelectChange={(_, values) => {
            formik.handleChange({ target: { name: "stages", value: values } })
          }}
          error={formik.touched.stages && Boolean(formik.errors.stages)}
          helperText={
            (formik.touched.stages && formik.errors.stages?.toString()) || ""
          }
          fullWidth={true}
        />

        <CheckboxHugeField
          checked={formik.values.notifyLeaders}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyLeaders", value: checked },
            })
          }}
          title={t("notifyLeader")}
          description={t("notifyLeaderDescription")}
        />

        <CheckboxHugeField
          checked={formik.values.notifyFollowers}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyFollowers", value: checked },
            })
          }}
          title={t("notifyFollowers")}
          description={t("notifyFollowersDescription")}
        />
      </>
    )
  },
)
