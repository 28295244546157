import { useParams } from "react-router-dom"
import { ResignationCardPage } from "../../../components"
import { ArchivedContent } from "./ArchivedContent"

const ResignationDone = () => {
  const { id: cardId = "" } = useParams()

  return (
    <ResignationCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Arquivado",
          href: `/flows/resignation/archived/${cardId}`,
        },
      ]}
      contents={{ default: ArchivedContent }}
    />
  )
}

export default ResignationDone
