import { Accordion } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const AccordionHeader = styled.div`
  color: inherit;
  display: grid;
  gap: ${(props) => props.theme.spacings.xs2};
  width: 100%;

  .tag-custom-theme-disabled {
    color: ${(props) => props.theme.colors.neutral[10]};
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacings.xs5};
  }
`

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    border: ${({ theme }) =>
      `${theme.borders.width.xs2} solid ${theme.colors.neutral80}`};
    border-radius: ${({ theme }) => theme.borders.radius.m};
  }
`
