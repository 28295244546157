import styled from "styled-components"

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 15px 40px;
`

export const OuterContent = styled.div`
  background: #f8f6f8;
  padding: 40px;
  width: 100%;
  height: 100%;
`

export const InnerContent = styled.div`
  background-color: #fff;
  padding: 80px 112px;
  border-radius: 8px;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 12px;
`
export const ItemArea = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 24px;
`

export const ItemIcon = styled.div`
  margin-right: 25px;
  fill: #55444f;
`

export const ItemTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #856f7e;
`

export const ItemText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6d5a66;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    font-size: 11px;
  }
`

export const UploadPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-top: 32px;

  border: 1px solid #ebe6e9;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const UploadPreviewArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const UploadIconContainer = styled.div`
  margin-right: 7px;
  border-radius: 4px;
  display: flex;
`

export const UploadFileName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3b2e37;
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`
