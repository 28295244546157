import { Button, Icons, IconTypes } from "@flash-tecnologia/hros-web-ui-v2"
import { CSSProperties, useEffect, useRef, useState } from "react"
import {
  Container,
  DropdownContainer,
  DropdownItem,
  DropdownTitle,
  DropdownWrapper,
} from "./styles"

type ButtonProps = {
  size: "small" | "large"
  variant: "primary" | "secondary"
  style?: CSSProperties
  text: string
  icon?: IconTypes
  disabled?: boolean
}

type DropdownOptions = {
  variant: "active" | "default" | "secondary"
  onClick: () => void
  text: string
  icon?: IconTypes
}

type DropdownProps = {
  options: DropdownOptions[]
  title: string
  style?: CSSProperties
}

interface ButtonMenuProps {
  button: ButtonProps
  dropdown: DropdownProps
}

export const ButtonMenu = ({ button, dropdown }: ButtonMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const menuDropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutsideMenu(event: any) {
      if (
        menuDropdownRef.current &&
        !menuDropdownRef.current?.contains(event.target)
      ) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutsideMenu)

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu)
    }
  }, [])

  const handleButtonClick = () => setIsMenuOpen((prev) => !prev)

  const { icon: buttonIcon, text, ...buttonProps } = button

  return (
    <Container>
      {isMenuOpen && (
        <DropdownContainer ref={menuDropdownRef}>
          <DropdownTitle>{dropdown.title}</DropdownTitle>

          <DropdownWrapper>
            {dropdown.options.map((item, idx) => (
              <DropdownItem
                key={idx}
                variant={item.variant}
                onClick={() => {
                  setIsMenuOpen(false)
                  item.onClick()
                }}
              >
                {item.icon && <Icons name={item.icon} fill="transparent" />}
                <span style={{ marginLeft: 4 }}>{item.text}</span>
              </DropdownItem>
            ))}
          </DropdownWrapper>
        </DropdownContainer>
      )}
      <Button {...buttonProps} onClick={handleButtonClick}>
        {buttonIcon && <Icons name={buttonIcon} fill="transparent" />}
        {text}
      </Button>
    </Container>
  )
}
