import { useRef, useState, useEffect, useCallback } from "react"

import {
  Container,
  Header,
  LogoContainer,
  AvatarCircle,
  CompanyName,
  MenuContainer,
} from "./styles"

const Mobile = ({ company }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [menuContainerHeight, setMenuContainerHeight] = useState(0)
  const [active, setActive] = useState(false)

  useEffect(() => {
    setMenuContainerHeight(ref?.current?.clientHeight || 0)
  }, [])

  const setMenuActive = useCallback(() => {
    setActive(!active)
  }, [active])

  return (
    <Container ref={ref}>
      <Header>
        <LogoContainer>
          <AvatarCircle hasLogo={false}>
            {company?.name?.charAt(0) ?? ""}
          </AvatarCircle>
          <CompanyName>{company?.name || company?.legalName || ""}</CompanyName>
        </LogoContainer>
        {/* <MenuCircle onClick={setMenuActive}>
          <Icons
            name={active ? "IconX" : "IconMenu"}
            fill={"#6D5A66"}
            size={18}
            onClick={() => {}}
          />
        </MenuCircle> */}
      </Header>
      <MenuContainer
        menuHeight={menuContainerHeight}
        active={active}
      ></MenuContainer>
    </Container>
  )
}

export default Mobile
