import { NotificationCard } from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"

const InfoNotificationCard: React.FC<{
  onClose: () => void
}> = ({ onClose }) => (
  <NotificationCard.Root
    variant="contained"
    type="info"
    showCloseButton={true}
    onClose={onClose}
  >
    <>
      <NotificationCard.Icon iconName="IconInfoCircle" />
      <NotificationCard.WrapperTexts>
        <NotificationCard.Title>
          Configurar e-mail de boas vindas
        </NotificationCard.Title>
        <NotificationCard.Subtitle>
          É necessário definir o e-mail corporativo e realizar o envio do
          convite de boas-vindas à plataforma para concluir o processo de
          admissão de candidatos.
        </NotificationCard.Subtitle>
      </NotificationCard.WrapperTexts>
    </>
  </NotificationCard.Root>
)

export default InfoNotificationCard
