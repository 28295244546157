import {
  Button,
  Icons,
  Skeleton,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { AccordionHeader, StyledAccordion } from "./styles"

type TagStatus = "pending" | "completed"

const tagVariantMap: Record<
  TagStatus,
  React.ComponentProps<typeof Tag>["variant"]
> = {
  pending: "gray",
  completed: "success",
}
const tagNameMap: Record<TagStatus, string> = {
  pending: "Não realizado",
  completed: "Finalizado",
}

export const AccordionButton: React.FC<{
  label: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  variant: "primary" | "secondary"
  href: string
  disabled?: boolean
}> = ({ label, onClick, variant, href, disabled }) => {
  return (
    <a
      href={href}
      onClick={(e) => {
        if (onClick && !disabled) {
          e.preventDefault()
          onClick(e)
        }
        if (disabled) {
          e.preventDefault()
        }
      }}
    >
      <Button
        size="small"
        variant={variant}
        onClick={(e) => {
          e.stopPropagation()
        }}
        style={{
          width: "100%",
        }}
        disabled={disabled}
      >
        {label}
        <span style={{ marginLeft: 5 }}>
          <Icons name="IconArrowRight" />
        </span>
      </Button>
    </a>
  )
}

const ConfigAccordion: React.FC<{
  status: TagStatus
  title: string
  description: string
  children: React.ReactNode
  button: React.ReactComponentElement<typeof AccordionButton>
  loading?: boolean
  disabled?: boolean
}> = ({ status, title, description, children, button, loading, disabled }) => {
  if (loading) return <Skeleton height={200} variant="rectangular" />
  const styledButton = React.cloneElement(button, {
    disabled,
  })
  return (
    <StyledAccordion
      title={title}
      description={description}
      variant="disabled"
      disabled={disabled}
      customHeader={
        <AccordionHeader>
          <Tag
            style={{
              width: "fit-content",
            }}
            disabled
            variant={disabled ? "disabled" : tagVariantMap[status]}
          >
            <>{tagNameMap[status]}</>
          </Tag>
          <div className="text-container">
            <Typography variant="headline8">{title}</Typography>
            <Typography variant="body4">{description}</Typography>
          </div>
          {styledButton}
        </AccordionHeader>
      }
    >
      {children}
    </StyledAccordion>
  )
}

export default ConfigAccordion
