import { useNavigate, useParams } from "react-router-dom"
import {
  GenericModal,
  GenericModalConfirmationContent,
  RouteHistory,
} from "../../../../components"
import Navigator, {
  NavigatorLink,
} from "../../../../components/common/Navigator"
import { PageContainer } from "../../../../components/screen/CardPageTemplate/styles"
import PageContent from "../PageContent"
import FooterBar from "../PageContent/FooterBar"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Benefit, HiringCard } from "../../../../types"
import dispatchToast from "../../../../utils/dispatchToast"
import { api } from "../../../../api"
import React from "react"
import { SpinnerContainer } from "../../../Configuration/styles"
import { Loader } from "@flash-tecnologia/hros-web-ui-v2"
import BenefitSelector from "./components/BenefitSelector"
import BenefitsSetters from "./components/BenefitsSetters"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"

const BenefitsPage: React.FC = () => {
  const { id: cardId = "" } = useParams()
  const { selectedCompany } = useSelectedCompany()
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [content, setContent] = React.useState<"search" | "input">("search")
  const [selectedBenefits, setSelectedBenefits] = React.useState<Benefit[]>([])
  const [values, setValues] = React.useState<{
    [key: string]: number
  }>({})

  const setBenefitValue = (name: string, value: number) => {
    setValues((oldState) => ({
      ...oldState,
      [name]: value,
    }))
  }

  const { data: card, isInitialLoading: isCardLoading } = useQuery<HiringCard>(
    ["hiring-card", cardId],
    () => api.query.hiring.card.getHiringCardById(cardId),
    {
      enabled: !!cardId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err: any) => {
        const defaultError = "Ocorreu um erro ao encontrar o candidato"
        const errorMessage =
          err.serviceError?.details?.[0]?.message ||
          err.serviceError?.message ||
          err.httpError?.message ||
          err?.message

        dispatchToast({
          content: errorMessage || defaultError,
          type: "error",
        })
        navigate("/flows/hiring")
      },
    },
  )

  const { data: flow, isInitialLoading: isFlowLoading } = useQuery(
    ["flows", "no-cards", card?.flowId],
    () => {
      if (card?.flowId) {
        return api.query.hiring.flow.getOnlyFlowById({
          flowId: card?.flowId,
        });
      }
    },
    {
      enabled: !!card,
      refetchOnWindowFocus: false,
    },
  )

  const { data: benefits, isInitialLoading: isBenefitsLoading } = useQuery(
    ["benefits"],
    () => api.query.company.getBenefits(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  )

  const { mutateAsync: saveBenefits, isLoading: isSavingBenefits } =
    useMutation(api.mutation.benefits.saveBenefits, {
      onError: (err: any) => {
        const defaultError = "Ocorreu um erro ao salvar os benefícios"
        const errorMessage =
          err.serviceError?.details?.[0]?.message ||
          err.serviceError?.message ||
          err.httpError?.message ||
          err?.message

        dispatchToast({
          content: errorMessage || defaultError,
          type: "error",
        })
      },
      onSuccess: () => {
        dispatchToast({
          content: "Benefícios atribuídos com sucesso!",
          type: "success",
        })
        goBack()
      },
    })

  const pageHistory: RouteHistory[] = [
    {
      name: "Admissão",
      href: "/flows/hiring",
    },
    {
      name: "Configuração inicial",
      href: `/flows/hiring/initial-config/${cardId}`,
    },
    {
      name: "Solicitar cartão flash",
    },
  ]

  const breadcrumbs = pageHistory.map((history, index) => {
    return (
      <NavigatorLink
        key={`breadcrumb-link-${index}`}
        name={history.name}
        href={history.href}
      />
    )
  })

  const goBack = () => {
    navigate(`/flows/hiring/initial-config/${cardId}`)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleSaveButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleSave = () => {
    saveBenefits({
      benefits: selectedBenefits.map((benefit) => ({
        id: benefit.id,
        value: values[benefit.name],
      })),
      flowCardId: cardId,
      version: card?.version ?? 0,
      candidateId: card?.candidateId || "",
      employeeId: card?.candidate?.employeeId || "",
    })
  }

  const disableSaveInput = React.useMemo(() => {
    return Object.values(selectedBenefits).some(
      (benefit) => !values[benefit.name],
    )
  }, [values, selectedBenefits])

  const footerBarActionButtons: React.ComponentProps<
    typeof FooterBar
  >["actionButtons"] = React.useMemo(() => {
    if (content === "search") {
      return [
        {
          variant: "link",
          description: "Cancelar",
          handleAction: goBack,
        },
        {
          variant: "primary",
          description: "Continuar",
          icon: "IconArrowRight",
          handleAction: () => {
            setContent("input")
          },
          disabled: !selectedBenefits?.length,
        },
      ]
    }

    return [
      {
        variant: "link",
        description: "Voltar",
        handleAction: () => {
          setContent("search")
        },
      },
      {
        variant: "primary",
        description: "Finalizar configuração",
        icon: "IconCheck",
        isLoading: isSavingBenefits,
        disabled: isSavingBenefits || disableSaveInput,
        handleAction: handleSaveButtonClick,
      },
    ]
  }, [content, isSavingBenefits, disableSaveInput])

  const genericModalActionButtons: React.ComponentProps<
    typeof GenericModal
  >["actionButtons"] = React.useMemo(() => {
    return [
      {
        variant: "link",
        description: "Cancelar",
        handleAction: closeModal,
        isLoading: isSavingBenefits,
      },
      {
        variant: "primary",
        description: "Finalizar configuração",
        icon: "IconCheck",
        isLoading: isSavingBenefits,
        handleAction: handleSave,
      },
    ]
  }, [isSavingBenefits, handleSave])

  if (!flow || !card || isFlowLoading || isCardLoading) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  const contentMap = {
    search: (
      <BenefitSelector
        companyId={selectedCompany.id}
        loading={isBenefitsLoading}
        data={benefits
          ? benefits?.map((benefit) => ({
            benefit,
          }))
          : null
        }
        callback={(selectedBenefits) => {
          setSelectedBenefits(selectedBenefits.map(({ benefit }) => benefit))
        }}
      />
    ),
    input: (
      <BenefitsSetters
        benefits={selectedBenefits}
        values={values}
        setBenefitValue={setBenefitValue}
      />
    ),
  }

  return (
    <PageContainer>
      <Navigator breadcrumbs={breadcrumbs} />
      <PageContent
        title="Atribuir benefícios"
        subtitle="Dados da entrega"
        description="Defina o saldo que a pessoa receberá no(s) benefício(s)"
        card={card}
        flow={flow}
      >
        {contentMap[content]}
      </PageContent>
      <FooterBar actionButtons={footerBarActionButtons} />
      <GenericModal
        handleClose={closeModal}
        isOpen={isModalOpen}
        showCloseButton={false}
        actionButtons={genericModalActionButtons}
      >
        <GenericModalConfirmationContent
          subtitle={"Atenção!"}
          title={"Gostaria de finalizar a configuração de benefícios?"}
          desciption={
            "A atribuição de benefícios poderá ser editada na página de pedido de benefícios após finalização."
          }
        />
      </GenericModal>
    </PageContainer>
  )
}

export default BenefitsPage
