import { useCallback } from "react"

import {
  StyledAccordion,
  Title,
  StyledContainerIcon,
  AccordionTitle,
  AccordionDescription,
  Text,
  ChecklistWrapper,
  ChecklistItem,
  StyledPOneLine,
  StyledCheckbox,
} from "./styles"

import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

import { AccordionDetails, AccordionSummary } from "@mui/material"

export const UpdateContractContent = ({ resignationFlowInfo }) => {
  const renderChecklist = useCallback(() => {
    const checklist = resignationFlowInfo.checklists?.find(
      (checklist) => checklist.columnId === "uz6g7xOUBzoyRR35cgorn",
    )

    return checklist ? (
      <>
        <ChecklistWrapper>
          {checklist.items?.map((checklist, idx) => (
            <ChecklistItem key={idx}>
              <StyledCheckbox
                checked={checklist.checked}
                disabled
                checkedIcon={<Icons name="CheckboxCheckedIcon" size={18} />}
                icon={<Icons name="CheckboxIcon" size={18} />}
              />
              {checklist.label}
            </ChecklistItem>
          ))}
        </ChecklistWrapper>
      </>
    ) : (
      <>
        <StyledPOneLine numberOfLines={1}>
          <Text variant="caption" hasValue={false}>
            Não preenchido
          </Text>
        </StyledPOneLine>
      </>
    )
  }, [resignationFlowInfo])

  return (
    <StyledAccordion
      style={{
        marginBottom: "24px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            marginRight: "20px",
          }}
        >
          <AccordionTitle variant="headline7">
            Atualização de documentação
          </AccordionTitle>
          <AccordionDescription variant="body4">
            Crie um checklist para organizar e acompanhar a evolução de cada
            etapa de atualização da documentação
          </AccordionDescription>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px 44px 40px 44px" }}>
        <Title variant={"headline8"}>Checklist de tarefas</Title>
        {renderChecklist()}
      </AccordionDetails>
    </StyledAccordion>
  )
}
