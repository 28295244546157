import { gql } from "@apollo/client"
import { request } from "../client"
import { IModelProposedLetter } from "../../types"

const GET_MODEL_PROPOSED_LETTER_BY_ID = gql`
  query GetModelProposedLetterById($modelId: ID) {
    getModelProposedLetterById(modelId: $modelId) {
      _id
      name
      flowId
      companyId
      description
      status
      createdAt
      proposedLetter {
        aboutCompany
        benefits
        role
        department
        group
        assignments
        salary
        variableSalary
        workload
        workship
      }
    }
  }
`

export const getModelProposedLetterById = async (modelId: string) => {
  const { getModelProposedLetterById } = await request(
    GET_MODEL_PROPOSED_LETTER_BY_ID,
    {
      modelId,
    },
  )
  return getModelProposedLetterById as IModelProposedLetter
}
