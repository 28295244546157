import styled from "styled-components"

const Wrapper = styled.div`
  border-bottom: 1px solid #ebe6e9;

  &:last-child {
    border: none;
  }
`

const ItemContainer = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.secondary[50]} !important;
    }

    svg {
      color: ${({ theme }) => theme.colors.secondary[50]};
    }
  }
`

export { Wrapper, ItemContainer }
