import { Radio } from "@flash-tecnologia/hros-web-ui-v2"
import { Container, StyledText } from "./styles"

export const LeadDecisionToggle = ({
  text,
  checked,
  onOptionSelect,
}: {
  text: string
  checked: boolean
  onOptionSelect: () => void
}) => {
  return (
    <Container>
      <Radio checked={checked} onChange={onOptionSelect} />
      <StyledText variant="body3">{text}</StyledText>
    </Container>
  )
}
