import React from "react"
import { Loader, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  Banner,
  FocusedFlow,
  Footer,
  Header,
  HeaderBreadcrumb,
} from "../../components"
import { useTheme } from "styled-components"
import {
  Container,
  ContentContainer,
  LeftColumnContainer,
  RightColumnContainer,
  SpinnerContainer,
  Wrapper,
} from "./styles"
import {
  Commentary,
  Expiration,
  FormRef,
  Movimentation,
  Probationary,
} from "./Forms"
import { useNavigate, useParams } from "react-router-dom"
import { trpc } from "src/api/client"
import { useQuery } from "@tanstack/react-query"
import { api } from "src/api"
import { NotificationsConfig } from "bff/src/types/hiring"
import i18n from "@i18n"
import { useTranslation } from "react-i18next"

export enum EditNotificationTypeEnum {
  movimentation = "movimentation",
  commentary = "commentary",
  expiration = "expiration",
  probationary = "probationary",
}

type PageContent = {
  title: string
  subtitle: string
  description: string
}

type EditNotificationProps = {
  type: EditNotificationTypeEnum
}

const breadcrumbs: { [key in EditNotificationTypeEnum]: HeaderBreadcrumb[] } = {
  [EditNotificationTypeEnum.commentary]: [
    {
      label: i18n.t(
        "page.settings.editNotification.breadcrumbs.notificationLabel",
      ),
      route: `/flows/hiring`,
    },
    {
      label: i18n.t(
        "page.settings.editNotification.breadcrumbs.commentaryLabel",
      ),
    },
  ],
  [EditNotificationTypeEnum.movimentation]: [
    {
      label: i18n.t(
        "page.settings.editNotification.breadcrumbs.notificationLabel",
      ),
      route: `/flows/hiring`,
    },
    {
      label: i18n.t(
        "page.settings.editNotification.breadcrumbs.movimentationLabel",
      ),
    },
  ],
  [EditNotificationTypeEnum.expiration]: [
    {
      label: i18n.t(
        "page.settings.editNotification.breadcrumbs.notificationLabel",
      ),
      route: `/flows/hiring`,
    },
    {
      label: i18n.t(
        "page.settings.editNotification.breadcrumbs.expirationLabel",
      ),
    },
  ],
  [EditNotificationTypeEnum.probationary]: [
    {
      label: i18n.t(
        "page.settings.editNotification.breadcrumbs.notificationLabel",
      ),
      route: `/flows/hiring`,
    },
    {
      label: i18n.t(
        "page.settings.editNotification.breadcrumbs.probationaryPeriodLabel",
      ),
    },
  ],
}

const pageContents: { [key in EditNotificationTypeEnum]: PageContent } = {
  [EditNotificationTypeEnum.commentary]: {
    title: i18n.t("page.settings.editNotification.commentary.title"),
    subtitle: i18n.t("page.settings.editNotification.commentary.subtitle"),
    description: i18n.t(
      "page.settings.editNotification.commentary.description",
    ),
  },
  [EditNotificationTypeEnum.expiration]: {
    title: i18n.t("page.settings.editNotification.expiration.title"),
    subtitle: i18n.t("page.settings.editNotification.expiration.subtitle"),
    description: i18n.t(
      "page.settings.editNotification.expiration.description",
    ),
  },
  [EditNotificationTypeEnum.movimentation]: {
    title: i18n.t("page.settings.editNotification.movimentation.title"),
    subtitle: i18n.t("page.settings.editNotification.movimentation.subtitle"),
    description: i18n.t(
      "page.settings.editNotification.movimentation.description",
    ),
  },
  [EditNotificationTypeEnum.probationary]: {
    title: i18n.t("page.settings.editNotification.probationaryPeriod.title"),
    subtitle: i18n.t(
      "page.settings.editNotification.probationaryPeriod.subtitle",
    ),
    description: i18n.t(
      "page.settings.editNotification.probationaryPeriod.description",
    ),
  },
}

const forms = {
  [EditNotificationTypeEnum.commentary]: Commentary,
  [EditNotificationTypeEnum.expiration]: Expiration,
  [EditNotificationTypeEnum.movimentation]: Movimentation,
  [EditNotificationTypeEnum.probationary]: Probationary,
}

export const EditNotification = ({ type }: EditNotificationProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { flowId } = useParams()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.settings.editNotification",
  })

  const formRef = React.useRef<FormRef<any>>(null)

  const { data: flow } = useQuery(
    ["flows", "no-cards", flowId],
    () =>
      api.query.hiring.flow.getOnlyFlowById({
        flowId: flowId as string,
      }),
    {
      enabled: !!flowId,
      refetchOnWindowFocus: false,
    },
  )

  const { data: notificationConfig } =
    trpc.notification.getNotificationConfig.useQuery(
      {
        category: flow?.category ?? "hiring",
      },
      {
        enabled: !!flow,
        retry: 0,
        cacheTime: 0,
        refetchOnWindowFocus: false,
      },
    )

  const breadcrumb = React.useMemo(() => breadcrumbs[type], [])
  const pageContent = React.useMemo(() => pageContents[type], [])

  const handleSubmit = () => {
    if (!formRef?.current) throw new Error("Form not defined")
    formRef.current?.handleSubmit()
  }

  const Content = React.useMemo(() => forms[type], [type])

  if (!flow || !notificationConfig) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  return (
    <FocusedFlow>
      <Header
        breadcrumbs={breadcrumb}
        style={{
          position: "fixed",
          zIndex: 2,
          top: "0px",
        }}
      />

      <Container>
        <Typography
          variant="headline6"
          variantColor={theme.colors.neutral[20]}
          weight={700}
          style={{ marginBottom: 28 }}
        >
          {pageContent.title}
        </Typography>

        <Wrapper>
          <LeftColumnContainer>
            <Typography
              variant="headline7"
              variantColor={theme.colors.secondary[50]}
              weight={700}
            >
              {pageContent.subtitle}
            </Typography>
            <Typography
              variant="body3"
              variantColor={theme.colors.neutral[50]}
              weight={400}
            >
              {pageContent.description}
            </Typography>
          </LeftColumnContainer>

          <RightColumnContainer>
            <ContentContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <Typography
                  variant="headline8"
                  variantColor={theme.colors.neutral[30]}
                  weight={700}
                >
                  {pageContent.subtitle}
                </Typography>

                <Typography
                  variant="body3"
                  variantColor={theme.colors.neutral[30]}
                >
                  <span style={{ color: theme.colors.feedback.error[40] }}>
                    *
                  </span>{" "}
                  {t("requiredField")}
                </Typography>
              </div>

              <Banner
                icon="IconInfoCircle"
                title={t("banner.title")}
                message={t("banner.description")}
                type="info"
                hasHideBanner={false}
              />

              <Content
                ref={formRef}
                notificationConfig={notificationConfig as NotificationsConfig}
                flow={flow}
              />
            </ContentContainer>
          </RightColumnContainer>
        </Wrapper>
      </Container>

      <Footer
        options={{
          cancelButton: {
            text: t("footer.leaveWithoutSave"),
            onClick: () => navigate(`/flows/settings/${flow._id}/notification`),
          },
          primaryButton: {
            text: t("footer.save"),
            onClick: () => handleSubmit(),
            loading: formRef.current?.isSubmitting,
            icon: "IconCheck",
            iconPosition: "right",
          },
        }}
        style={{
          position: "fixed",
          zIndex: 2,
          bottom: "0px",
        }}
      />
    </FocusedFlow>
  )
}
