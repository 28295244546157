import React from "react"
import { StepsEnum, massSignatureContext } from "../../index"
import {
  Button,
  IconButton,
  Icons,
  Table,
  Tooltip,
  Typography,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2"
import { CandidateSignInformation } from "../../../../../types"
import { Container, RowContainer } from "./styles"
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination"
import { useTheme } from "styled-components"
import { useNavigate } from "react-router-dom"

type TableData = {
  candidate: CandidateSignInformation
}

export const MassSignatureContracts = () => {
  const [search, setSearch] = React.useState<string>("")
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageNumber: 1,
    pageSize: 5,
  })
  const [filteredData, setFilteredData] = React.useState<TableData[]>([])

  const { validCandidates, removeCandidates, setFooter, setCurrentStep } =
    React.useContext(massSignatureContext)
  const theme = useTheme()
  const navigate = useNavigate()

  React.useEffect(() => {
    setFooter({
      cancelButton: {
        text: "Sair sem salvar",
        onClick: () => navigate("/flows/hiring"),
      },
      primaryButton: {
        text: "Continuar",
        icon: "IconArrowRight",
        iconPosition: "right",
        onClick: () => setCurrentStep(StepsEnum.confirmation),
      },
    })
  }, [])

  React.useEffect(() => {
    if (search?.length > 0) setSearch("")
    setFilteredData(validCandidates.map((candidate) => ({ candidate })))
  }, [validCandidates])

  React.useEffect(() => {
    if (search?.length > 0) {
      setFilteredData((prev) =>
        prev.filter(
          ({ candidate }) =>
            candidate.name.includes(search) || candidate.email.includes(search),
        ),
      )
      return
    }

    setFilteredData(validCandidates.map((candidate) => ({ candidate })))
  }, [search])

  const table = tableControllers.useTableColumns<TableData>({
    columns: [
      {
        header: "Candidato",
        accessorKey: "candidate",
        cell: (context) => (
          <RowContainer>
            <Typography variant="body3" variantColor={theme.colors.neutral[40]}>
              {context.cell.row.original.candidate.name}
            </Typography>
            <Typography variant="body4" variantColor={theme.colors.neutral[60]}>
              {context.cell.row.original.candidate.email}
            </Typography>
          </RowContainer>
        ),
      },
      {
        header: "Líder",
        accessorKey: "candidate",
        cell: (context) => (
          <RowContainer>
            <Typography variant="body3" variantColor={theme.colors.neutral[40]}>
              {context.cell.row.original.candidate.leader?.name || ""}
            </Typography>
          </RowContainer>
        ),
      },
      {
        header: "Cargo",
        accessorKey: "candidate",
        cell: (context) => (
          <RowContainer>
            <Typography variant="body3" variantColor={theme.colors.neutral[40]}>
              {context.cell.row.original.candidate.role?.name || ""}
            </Typography>
          </RowContainer>
        ),
      },
      {
        header: "Departamento",
        accessorKey: "candidate",
        cell: (context) => (
          <RowContainer>
            <Typography variant="body3" variantColor={theme.colors.neutral[40]}>
              {context.cell.row.original.candidate.department?.name || ""}
            </Typography>
          </RowContainer>
        ),
      },
      {
        header: "Ações",
        accessorKey: "candidate",
        cell: (context) => (
          <RowContainer direction="row" style={{ gap: 20 }}>
            <Button
              variant="secondary"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                const contractOutput =
                  context.cell.row.original.candidate.lastContractOutput
                window.open(contractOutput?.value, "_blank")
              }}
            >
              Visualizar contrato
              <Icons name="IconFileDescription" fill="transparent" />
            </Button>
            <Tooltip title="Remover da seleção" placement="top">
              <span>
                <IconButton
                  size="small"
                  variant="line"
                  icon="IconUserMinus"
                  onClick={() =>
                    removeCandidates(
                      context.cell.row.original.candidate.flowCardId,
                    )
                  }
                />
              </span>
            </Tooltip>
          </RowContainer>
        ),
      },
    ],
    data: filteredData.slice(
      (pagination.pageNumber - 1) * pagination.pageSize,
      pagination.pageNumber * pagination.pageSize,
    ),
    pagination: pagination,
    onPaginationChange: setPagination,
  })

  return (
    <Container>
      <Typography
        variant="headline8"
        variantColor={theme.colors.neutral[30]}
        weight={700}
        style={{ marginBottom: 15 }}
      >
        Contratos
      </Typography>

      <Table.Root>
        <Table.Search
          clearIcon={<Icons name="IconX" onClick={() => setSearch("")} />}
          label={"Buscar candidatos na seleção de contratos"}
          onChange={({ target }) => setSearch(target.value)}
        />
        <Table.Grid.Root>
          <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table.rows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <Table.Pagination
          count={validCandidates?.length || 0}
          pagination={pagination}
          onPaginationChange={(pagination) => setPagination(pagination)}
          pageSizeOptions={[
            {
              label: "5 itens",
              value: 5,
            },
            {
              label: "10 itens",
              value: 10,
            },
          ]}
        />
      </Table.Root>
    </Container>
  )
}
