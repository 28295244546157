import { BigNumber } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const StyledBigNumber = styled(BigNumber)`
  max-width: none;
  flex: none;
  height: 100%;

  & > :last-child {
    flex: 1;
    gap: 0;
  }
`

export const Container = styled.div`
  width: 100%;

  ul,
  li {
    height: 100%;
  }

  & > :last-child {
    margin-bottom: 0px;
  }
`
