import React from "react"
import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  Container,
  Header,
  Body,
  BodyContent,
  StyledImage,
  List,
  ListItem,
} from "./styles"
import { useTheme } from "styled-components"

const Advertising: React.FC<{
  title: string
  description: string
  imageSrc: string
  listItems: React.ReactNode[]
  name: string
  link: {
    href: string
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    disabled?: boolean
  }
}> = ({ title, description, imageSrc, listItems, name: key, link }) => {
  const theme = useTheme()
  return (
    <Container>
      <Header>
        <Typography variant={"headline8"}>{title}</Typography>
        <Typography variant={"body4"} variantColor={theme.colors.neutral50}>
          {description}
        </Typography>
      </Header>
      <Body>
        <StyledImage src={imageSrc} />
        <BodyContent>
          <List>
            {listItems.map((item, index) => (
              <ListItem key={`${key}-list-item-${index}`}>
                <Icons name="IconCheck" color={theme.colors.primary} />
                <Typography
                  variant="body4"
                  variantColor={theme.colors.neutral50}
                >
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
          <a
            href={link.href}
            onClick={(e) => {
              if (link.onClick && !link?.disabled) {
                e.preventDefault()
                link.onClick(e)
              }
              if (link.disabled) {
                e.preventDefault()
              }
            }}
            target="_blank"
          >
            <Button
              variant="secondary"
              size="medium"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Saiba Mais
              <span style={{ marginLeft: 5 }}>
                <Icons name="IconArrowRight" />
              </span>
            </Button>
          </a>
        </BodyContent>
      </Body>
    </Container>
  )
}

export default Advertising
