import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
`

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};

  > * {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};

  > button {
    width: 100%;
  }
`

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 8px;

  overflow: scroll;

  & > :nth-child(2) {
    text-decoration: underline;
  }

  & > :nth-child(3) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
`

export const ValidationButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  gap: 24px;
`

export const StyleStatusButton = styled(Button)<{
  variantType: "success" | "error"
  active: boolean
}>`
  ${({ active, variantType }) =>
    active &&
    css`
      background: ${variantType === "success"
        ? "#d7f9f3"
        : "#ffecd6"} !important;
      border: none !important;
    `}

  > button {
    width: 100%;
  }
`

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > :nth-child(2) {
    margin-right: 40px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
