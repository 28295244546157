import styled, { css } from "styled-components"

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const Tab = styled.div<{ active?: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: #83727d;
  padding: 8px 16px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      color: #f20d7a;
      font-weight: 700;
      border-bottom: 2px solid #f20d7a;
    `}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 35px;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`
