import { useState, useEffect } from "react"

import {
  Typography,
  TagCheckboxFilter,
  TagDateFilter,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"

import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

import { Container } from "./styled"

import { Filter, Option } from "../types"

interface TableFiltersProps {
  disabled?: boolean
  filters?: Filter[]
  data?: any[]
  onFilter?: (el: { [key: string]: any[] }) => any
}

export const TableFilters = ({
  data,
  filters,
  onFilter,
  disabled,
}: TableFiltersProps) => {
  const [filterStates, setFilterStates] = useState<
    | {
        [key: string]: any[]
      }
    | undefined
  >(undefined)

  useEffect(() => {
    if (filterStates === undefined) return

    const timer = setTimeout(() => {
      onFilter && onFilter(filterStates)
    }, 700)

    return () => timer && clearTimeout(timer)
  }, [filterStates])

  const renderFilters = () => {
    return filters?.map((value) => {
      const renderOptions =
        value?.options?.map((e: Option) => {
          return { label: e.label || "", value: e.value || "" }
        }) || []

      switch (value.type) {
        case "date":
          return (
            <TagDateFilter
              variant="secondary"
              badgeNumber={filterStates?.[value.accessor]?.length || 0}
              hasLeftIcon={false}
              filterLabel={value?.label || ""}
              options={renderOptions || []}
              onSubmit={(date) => {
                const filteredOptions = (data || []).filter((item) => {
                  if (date?.to) {
                    return (
                      dayjs(item[value.accessor]).isSameOrAfter(
                        date.from,
                        "day",
                      ) &&
                      dayjs(item[value.accessor]).isSameOrBefore(date.to, "day")
                    )
                  }

                  if (date?.from && !date?.to)
                    return dayjs(item[value.accessor]).isSame(date.from, "day")

                  return true
                })

                const parsedValue = filteredOptions.map(
                  (data) => data[value.accessor],
                )

                const stateUpdated = {
                  ...filterStates,
                  [value.accessor]: parsedValue,
                }

                if (!date.from) delete stateUpdated[value.accessor]
                setFilterStates(stateUpdated)
              }}
              key={(renderOptions || []).length + value.accessor}
            />
          )

        case "checkbox":
        default:
          return (
            <TagCheckboxFilter
              variant="secondary"
              badgeNumber={filterStates?.[value.accessor]?.length || 0}
              hasLeftIcon={false}
              filterLabel={value?.label || ""}
              options={renderOptions || []}
              onClick={(e) => {
                if (!e.length && !(filterStates?.[value.accessor]?.length || 0))
                  return

                const stateUpdated = {
                  ...filterStates,
                  [value.accessor]: e,
                }

                if (!e.length) delete stateUpdated[value.accessor]
                setFilterStates(stateUpdated)
              }}
              showClearButton={true}
              key={(renderOptions || []).length + value.accessor}
            />
          )
      }
    })
  }

  return (
    <Container disabled={disabled}>
      <Typography variant="body3">Filtrar por</Typography>
      {renderFilters()}
    </Container>
  )
}
