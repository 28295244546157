export * from "./Commentary"
export * from "./Expiration"
export * from "./Movimentation"
export * from "./Probationary"

export function parseBeforeDeadlineValue({
  notifyBeforeDeadline,
  interval,
}: {
  notifyBeforeDeadline?: boolean
  interval?: number
}) {
  if (!notifyBeforeDeadline) return "Não"
  return `Sim, com ${interval ?? 0} dias de antecedência`
}
