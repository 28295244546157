import React from "react"
import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import { SignContainer } from "./styles"

export const Texting = () => {
  const [content, setContent] = React.useState("")

  return (
    <>
      <SignContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "16px",
          }}
        >
          <LinkButton
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={() => setContent("")}
          >
            Limpar
          </LinkButton>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            marginBottom: "40px",
            height: "154px",
          }}
        >
          <input
            id="textSignature"
            type={"text"}
            value={content}
            style={{
              width: "100%",
              marginRight: "16px",
              marginLeft: "16px",
              background: "#F8F6F8",
              outline: "none",
              borderBottom: "1px solid #D1C7CE",
              fontFamily: "LiuJianMaoCao",
              fontWeight: 400,
              fontSize: "72px",
            }}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
      </SignContainer>

      <span
        id="textToUpload"
        style={{
          fontFamily: "LiuJianMaoCao",
          fontWeight: 400,
          fontSize: "72px",
          display: "none",
          paddingBottom: "10px",
          width: "min-content",
        }}
      >
        {content}
      </span>
    </>
  )
}
