import React from "react"
import { useFormik } from "formik"
import { FormRef } from "../../Forms"
import * as yup from "yup"
import { SelectField } from "@flash-tecnologia/hros-web-ui-v2"
import { CheckboxHugeField } from "../../components/CheckboxHugeField"
import dispatchToast from "../../../../utils/dispatchToast"
import { Flow, NotificationsConfig } from "bff/src/types/hiring"
import { trpc } from "src/api/client"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

type FormData = {
  notifyLeaders: boolean
  notifyFollowers: boolean
  notifyBeforeDeadline: boolean
  notifyBeforeInterval: number
  notifyOnDeadline: boolean
}

const notifyIntervalDays = [1, 3, 5]

const validationSchema = yup.object({
  notifyLeaders: yup.boolean(),
  notifyFollowers: yup.boolean(),
  notifyBeforeDeadline: yup.boolean(),
  notifyBeforeInterval: yup.string().when("notifyBeforeDeadline", {
    is: true,
    then: (schema) => schema.required("Campo obrigatório"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  notifyOnDeadline: yup.boolean(),
})

type ProbationaryProps = {
  notificationConfig: NotificationsConfig
  flow: Flow
}

export const Probationary = React.forwardRef(
  (
    { notificationConfig, flow }: ProbationaryProps,
    ref: React.Ref<FormRef<FormData>>,
  ) => {
    const navigate = useNavigate()
    const { mutateAsync: updateConfig } =
      trpc.notification.updateNotificationConfig.useMutation()

    const [t] = useTranslation("translations", {
      keyPrefix: "page.settings.editNotification.probationaryPeriod",
    })

    const formik = useFormik<FormData>({
      initialValues: {
        notifyFollowers:
          notificationConfig.probationDeadline?.notifyFollowers ?? true,
        notifyLeaders:
          notificationConfig.probationDeadline?.notifyLeaders ?? true,
        notifyBeforeDeadline:
          notificationConfig.probationDeadline?.notifyBeforeDeadline ?? true,
        notifyBeforeInterval:
          notificationConfig.probationDeadline?.notifyBeforeInterval ??
          notifyIntervalDays[0],
        notifyOnDeadline:
          notificationConfig.probationDeadline?.notifyOnDeadline ?? true,
      },
      validationSchema,
      validate: (values) => {
        if (
          (!values.notifyOnDeadline && !values.notifyBeforeDeadline) ||
          (!values.notifyLeaders && !values.notifyFollowers)
        ) {
          dispatchToast({
            content: t("errorAtLeastOne"),
            type: "error",
          })
          return { hasError: true }
        }
      },
      onSubmit: async (values) => {
        try {
          await updateConfig({
            notificationConfigId: notificationConfig._id,
            body: {
              probationDeadline: {
                active: true,
                notifyFollowers: values.notifyFollowers,
                notifyLeaders: values.notifyLeaders,
                notifyBeforeDeadline: values.notifyBeforeDeadline,
                notifyBeforeInterval: values.notifyBeforeInterval,
                notifyOnDeadline: values.notifyOnDeadline,
              },
            },
          })
          dispatchToast({
            content: t("successUpdate"),
            type: "success",
          })
          navigate(`/flows/settings/${flow._id}/notification`)
        } catch (err: any) {
          dispatchToast({
            content: err.message,
            type: "error",
          })
        }
      },
    })

    React.useImperativeHandle(ref, () => formik)

    return (
      <>
        <CheckboxHugeField
          checked={formik.values.notifyLeaders}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyLeaders", value: checked },
            })
          }}
          title={t("notifyLeader")}
          description={t("notifyLeaderDescription")}
        />

        <CheckboxHugeField
          checked={formik.values.notifyFollowers}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyFollowers", value: checked },
            })
          }}
          title={t("notifyFollowers")}
          description={t("notifyFollowersDescription")}
        />

        <CheckboxHugeField
          checked={formik.values.notifyBeforeDeadline}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyBeforeDeadline", value: checked },
            })
          }}
          title={t("notifyBeforeDeadline")}
          description={t("notifyBeforeDeadlineDescription")}
        >
          <SelectField
            label={
              <>
                {t("notifyBeforeInterval") + " "}
                {formik.values.notifyBeforeInterval && (
                  <span style={{ color: "#C96C01" }}>*</span>
                )}
              </>
            }
            value={formik.values.notifyBeforeInterval}
            disabled={!formik.values.notifyBeforeDeadline}
            options={notifyIntervalDays.map((day) => ({
              label: day,
              value: day,
            }))}
            onSelectChange={(_, value) => {
              formik.handleChange({
                target: {
                  name: "notifyBeforeInterval",
                  value: value.value,
                },
              })
            }}
            error={
              formik.touched.notifyBeforeInterval &&
              Boolean(formik.errors.notifyBeforeInterval)
            }
            helperText={
              formik.touched.notifyBeforeInterval &&
              formik.errors.notifyBeforeInterval?.toString()
            }
          />
        </CheckboxHugeField>

        <CheckboxHugeField
          checked={formik.values.notifyOnDeadline}
          handleChange={(checked) => {
            formik.handleChange({
              target: { name: "notifyOnDeadline", value: checked },
            })
          }}
          title={t("notifyOnDeadline")}
          description={t("notifyOnDeadlineDescription")}
        />
      </>
    )
  },
)
