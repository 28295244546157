import { FlowCategory } from "./flow"

export enum FlowStage {
  ADD_PERSON = "ADD_PERSON",
  PROPOSAL = "PROPOSAL",
  SEND_DOCUMENTS = "SEND_DOCUMENTS",
  VALIDATE_DOCUMENTS = "VALIDATE_DOCUMENTS",
  REQUEST_MEDICAL_EXAM = "REQUEST_MEDICAL_EXAM",
  SIGN_CONTRACT = "SIGN_CONTRACT",
  ARCHIVED = "ARCHIVED",
  INITIAL_SETUP = "INITIAL_SETUP",
  DONE = "DONE",
  ON_HOLD = "ON_HOLD",
  START_PROCESS = "START_PROCESS",
  INTERVIEW = "INTERVIEW",
  EXAM = "EXAM",
  RESCISSION = "RESCISSION",
  DOCUMENT_UPDATE = "DOCUMENT_UPDATE",
}

export type NotificationStageType = {
  active: boolean
  notifyLeaders: boolean
  notifyFollowers: boolean
}

export type CardMovement = NotificationStageType & {
  stages: FlowStage[]
}

export type NewComments = NotificationStageType & {
  notifyMentions: boolean
}

export type ResponseDeadline = NotificationStageType & {
  notifyBeforeDeadline: boolean
  notifyBeforeInterval: number
  notifyOnDeadline: boolean
}

export type ProbationDeadline = NotificationStageType & {
  notifyBeforeDeadline: boolean
  notifyBeforeInterval: number
  notifyOnDeadline: boolean
}

export type NotificationsConfig = {
  _id: string
  companyId: string
  active: boolean
  category: FlowCategory
  cardMovement?: CardMovement
  newComment?: NewComments
  responseDeadline?: ResponseDeadline
  probationDeadline?: ProbationDeadline
}
