import {
  Checkbox,
  SelectField,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { CheckboxContainer, FormContainer, FormLine } from "./styles"
import { CEPMask, cepParser } from "../../../../../utils"
import { useFormik } from "formik"
import { CompanyAddress } from "../../../../../types"
import { useTheme } from "styled-components"
import TextFieldInput from "./TextFieldInput"
import * as yup from "yup"

export const addressValidationSchema = yup.object({
  name: yup.string().when("saveNewAddress", {
    is: true,
    then: yup.string().required("Campo obrigatório"),
  }),
  zipCode: yup.string().required("Campo obrigatório"),
  street: yup.string().required("Campo obrigatório"),
  number: yup.string().required("Campo obrigatório"),
  neighborhood: yup.string().required("Campo obrigatório"),
  city: yup.string().required("Campo obrigatório"),
  state: yup.string().required("Campo obrigatório"),
  complement: yup.string(),
  reference: yup.string(),
  saveNewAddress: yup.boolean(),
})

const stateOptions = [
  "AC",
  "AL",
  "AM",
  "AP",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
].map((state) => ({
  label: state,
  value: state,
}))

type Parser = (value: string) => string
type handleChangeCallback = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  parser?: Parser,
  maxLenght?: number,
) => void

export const AddressForm: React.FC<{
  formik: ReturnType<
    typeof useFormik<CompanyAddress & { saveNewAddress: boolean }>
  >
  disabled?: boolean
  disableSaveCheckbox?: boolean
}> = ({ formik, disabled, disableSaveCheckbox }) => {
  const theme = useTheme()

  const handleChange: handleChangeCallback = (e, parser, maxLenght) => {
    const value = parser ? parser(e.target.value) : e.target.value
    if (parser) {
      e.target.value = value
    }
    if (maxLenght && value.length > maxLenght) return

    formik.handleChange(e)
  }

  return (
    <FormContainer>
      <FormLine className="custom-width">
        <TextFieldInput
          label="CEP *"
          placeholder="CEP *"
          name="zipCode"
          onChange={(e) => handleChange(e, cepParser, 8)}
          onBlur={formik.handleBlur}
          value={CEPMask(formik.values.zipCode)}
          error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
          helperText={formik.touched.zipCode && formik.errors.zipCode}
          disabled={disabled}
          autoComplete="postal-code"
        />
        <TextFieldInput
          label="Endereço *"
          placeholder="Endereço *"
          name="street"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.street}
          error={formik.touched.street && Boolean(formik.errors.street)}
          helperText={formik.touched.street && formik.errors.street}
          disabled={disabled}
          autoComplete="address-line1"
        />
      </FormLine>

      <FormLine className="custom-width">
        <TextFieldInput
          label="Número *"
          placeholder="Número *"
          name="number"
          onChange={(e) =>
            handleChange(e, (s: string) => s?.toUpperCase() ?? "")
          }
          onBlur={formik.handleBlur}
          value={formik.values.number}
          error={formik.touched.number && Boolean(formik.errors.number)}
          helperText={
            (formik.touched.street && formik.errors.number) ??
            "Caso não tenha número, escreva S/N"
          }
          disabled={disabled}
          autoCapitalize="characters"
        />

        <TextFieldInput
          label="Complemento"
          placeholder="Complemento"
          name="complement"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.complement}
          error={formik.touched.complement && Boolean(formik.errors.complement)}
          helperText={formik.touched.street && formik.errors.complement}
          disabled={disabled}
        />
      </FormLine>

      <FormLine>
        <TextFieldInput
          label="Bairro *"
          placeholder="Bairro *"
          name="neighborhood"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.neighborhood}
          error={
            formik.touched.neighborhood && Boolean(formik.errors.neighborhood)
          }
          helperText={formik.touched.street && formik.errors.neighborhood}
          disabled={disabled}
        />

        <TextFieldInput
          label="Cidade *"
          placeholder="Cidade *"
          name="city"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.city}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.street && formik.errors.street}
          disabled={disabled}
        />

        <SelectField
          label="Estado *"
          placeholder="Estado *"
          name="state"
          options={stateOptions}
          onBlur={formik.handleBlur}
          onSelectChange={formik.handleChange}
          value={formik.values.state.toUpperCase()}
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.touched.street && formik.errors.state}
          disabled={disabled}
        />
      </FormLine>

      <FormLine className="full-width">
        <TextFieldInput
          label="Ponto de referência"
          placeholder="Ponto de referência"
          name="reference"
          onChange={handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.reference}
          error={formik.touched.reference && Boolean(formik.errors.reference)}
          helperText={formik.touched.street && formik.errors.reference}
          disabled={disabled}
        />
      </FormLine>

      <Tooltip
        placement="top"
        title={
          disableSaveCheckbox
            ? "Você já possui 5 endereços salvos. Para salvar um novo endereço, é necessário excluir um dos endereços salvos na lista acima."
            : ""
        }
      >
        <CheckboxContainer>
          <Checkbox
            name="saveNewAddress"
            onChange={(e) => {
              formik.setFieldValue("saveNewAddress", e.target.checked)
            }}
            disabled={disabled || disableSaveCheckbox}
          />
          <Typography
            variant="body3"
            variantColor={
              disabled ? theme.colors.neutral70 : theme.colors.neutral30
            }
            weight={600}
          >
            Salvar novo endereço
          </Typography>
        </CheckboxContainer>
      </Tooltip>
      {formik.values.saveNewAddress && (
        <FormLine className="full-width">
          <TextFieldInput
            name="name"
            label="Nome do endereço *"
            placeholder="Nome do endereço *"
            value={formik.values.name}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={
              (formik.touched.name && formik.errors.name) ??
              "O nome do endereço te ajuda a idetificá-lo caso seja reutilizado em outros pedidos"
            }
            disabled={disabled}
            autoComplete={"none"}
          />
        </FormLine>
      )}
    </FormContainer>
  )
}

export default AddressForm
