import styled from "styled-components"
import { Button } from "@flash-tecnologia/hros-web-ui-v2"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 24px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    flex-direction: column;
    overflow: auto;
  }
`

export const FlexContainer = styled.div`
  > div {
    margin-bottom: 24px;
  }
`

export const DocumentSentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 0px auto;
  flex-direction: column;
  max-width: 426px;
  text-align: center;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const InlineTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`

export const AccordionFormFieldContainer = styled.div`
  margin-bottom: 24px;
`

export const TextContainer = styled.div`
  display: flex;
  alignitems: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`

export const FormButton = styled(Button)`
  width: 100%;
`
