import { Accordion } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    border: ${({ theme }) =>
      `${theme.borders.width.xs2} solid ${theme.colors.neutral80}`};
    border-radius: ${({ theme }) => theme.borders.radius.m};
  }
`

export const AccordionHeader = styled.div`
  color: inherit;
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacings.xs2};
  width: 100%;

  .text-container {
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacings.xs5};
  }
`

export const AccordionChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
