import styled from "styled-components"

import { Avatar } from "@mui/material"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const TableDataArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .MuiAvatar-root {
    margin-right: 12px;
    background: ${({ theme }) => theme.colors.primary};
  }
`

export const TableData = styled.div`
  display: flex;
  align-items: center;

  .MuiAvatar-root {
    margin-right: 12px;
    background: ${({ theme }) => theme.colors.primary};
  }
`

export const EmployeeName = styled(Typography)`
  text-transform: capitalize;
`

export const EmailField = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
`
