import styled from "styled-components"

export const RowContainer = styled.div<{ direction?: "column" | "row" }>`
  display: flex;
  flex-direction: ${({ direction = "column" }) => direction};
  width: fit-content;
  min-width: 100px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 40px;
`
