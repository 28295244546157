import styled, { css } from "styled-components"

export const ProfileImage = styled.img`
  border: 1px solid #ccc;
  borderradius: 50%;
  margin-top: 30px;
`

export const ProfileInitials = styled.div<{
  width: string | number
  height: string | number
  fontSize: string | number
}>`
  ${({ width, height, fontSize }) => css`
    width: ${width};
    height ${height};
    font-size: ${fontSize};
  `}

  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffeef6;
  color: #fe2b8f;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
