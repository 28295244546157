import { useEditor as useEditorBase } from "@tiptap/react"

import Color from "@tiptap/extension-color"
import FontFamily from "@tiptap/extension-font-family"
import Bold from "@tiptap/extension-bold"
import Italic from "@tiptap/extension-italic"
import Underline from "@tiptap/extension-underline"
import Strike from "@tiptap/extension-strike"
import Typography from "@tiptap/extension-typography"
import History from "@tiptap/extension-history"
import Highlight from "@tiptap/extension-highlight"
import TextAlign from "@tiptap/extension-text-align"
import BulletList from "@tiptap/extension-bullet-list"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import Heading from "@tiptap/extension-heading"

import TextStyle from "@tiptap/extension-text-style"

import Placeholder from "@tiptap/extension-placeholder"

export const useEditor = ({
  placeholder,
  onUpdate = () => {},
  onBlur = () => {},
}: {
  placeholder?: string
  onUpdate?: any
  onBlur?: any
}) => {
  return useEditorBase({
    extensions: [
      Placeholder.configure({
        placeholder: placeholder || "",
      }),
      FontFamily,
      Bold,
      Italic,
      Underline,
      Strike,
      Highlight,
      Heading,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      BulletList,
      ListItem,
      OrderedList,
      Document,
      Paragraph,
      Text,
      Color,
      TextStyle,
      Typography,
      History,
    ],
    onUpdate: onUpdate,
    onBlur: onBlur,
  })
}
