import gql from "graphql-tag"
import { request } from "../client"
import { Follower, HiringCard, Leader } from "../../types"

const CREATE_HIRING_CARD = gql`
  mutation CreateHiringCard($params: CreateHiringCardInput) {
    createHiringCard(params: $params) {
      _id
      setupId
      name
      description
      status
      code
      color
      active
      position
      columnId
      employeeId
      candidateId
      companyId
      modelProposedLetterId
      companyName
      flowId
      version
      statusHistory {
        status
        columnId
      }
      metadata
      limitDates
      followers
      checklists {
        columnId
        items {
          checked
          label
        }
      }
      hiringFlowId
      approvalStatus
      approvalCommentary
      updatedAt
      createdAt
      formConfigId
      formExtensionId
      emailLink {
        name
        columnId
        link
        expired
      }
      admissionalExam
      attachments
      roles
      comments {
        message
        authorEmployeeId
        authorName
        sentDate
        timeSince
      }
      candidate {
        _id
        active
        nationality
        jobId
        companyId
        employeeId
        registrationDate
        name
        socialName
        legalName
        documentNumber
        cnpj
        gender
        race
        maritalStatus
        schooling
        email
        phone
        isPCD
        disabilityType
        leader
        linkedinProfileUrl
        indicator
        jobUrl
        corporateEmail
        inviteDate
        curriculum
        academicCareer
        professionalCareer
        extracurricularActivities
        documents
        proposalLetterInfo
        internship
        validations
        workshiftId
        externalCardDeliveryId
        probationPeriod {
          option
          firstDate
          secondDate
        }
      }
    }
  }
`

export const createHiringCard = async (params: {
  flowId: string
  columnId: string
  followers?: Follower[]
  candidateInfo: {
    email: string
    phone: string
    documentNumber?: string
    cnpj?: string
    nationality: string
    legalName?: string
    socialName?: string
    name: string
    leader: Leader
    probationPeriod?: {
      option?: string
      firstDate?: string
      secondDate?: string
    }
    proposalLetterInfo?: {
      hiringDate?: string
    }
  }
}): Promise<HiringCard> => {
  const { createHiringCard } = await request<{ createHiringCard: HiringCard }>(
    CREATE_HIRING_CARD,
    { params },
  )
  return createHiringCard
}
