import React, { Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import emptyChecklist from "../../../../assets/emptyChecklist.png"
import ModalChecklist from "../ModalChecklist"

import { Column, Card, FlowCategory } from "../../../../types"

import {
  Checkbox,
  Icons,
  Skeleton,
  Tooltip,
  Typography,
  useDisclosure,
} from "@flash-tecnologia/hros-web-ui-v2"

import {
  ButtonWrapper,
  ChecklistItem,
  ChecklistWrapper,
  Container,
  Content,
  EmptyStateContainer,
  FloatingIconButton,
  StyledButton,
} from "./styles"
import { api } from "../../../../api"

interface CardChecklist {
  column: Column
  card: Card
  flowType: FlowCategory
  style?: React.CSSProperties
}

const CardChecklist = ({ column, card, flowType, style }: CardChecklist) => {
  const modalChecklist = useDisclosure()
  const navigate = useNavigate()

  const checklist = card.checklists?.find(
    (checklist) => checklist.columnId === column._id,
  )

  const hasChecklist = !!checklist?.items?.length

  const queryClient = useQueryClient()

  const urls = {
    ["hiring"]: {
      key: "hiring-card",
      fn: api.mutation.hiring.checklist.toggleItem,
    },
    ["resignation"]: {
      key: "resignation-card",
      fn: api.mutation.resignation.checklist.toggleItem,
    },
  }

  const flowData = urls[flowType]

  const { mutate: mutateCheckChecklist } = useMutation(flowData.fn, {
    onMutate: (params) => {
      const currentItems = checklist?.items || []

      const checklistArray = Array.from(card?.checklists)
      const checklistItem = Array.from(currentItems)

      const foundChecklist = checklistArray?.findIndex(
        (checklist) => checklist.columnId === column._id,
      )

      const foundItemIndex = checklistItem.findIndex(
        (i) => i.label == params.item,
      )

      const [checklistRemoved] = checklistArray.splice(foundChecklist, 1)
      const [itemRemoved] = checklistItem.splice(foundItemIndex, 1)

      checklistItem.splice(foundItemIndex, 0, {
        ...itemRemoved,
        checked: !itemRemoved.checked,
      })
      checklistArray.splice(foundChecklist, 0, {
        ...checklistRemoved,
        items: checklistItem,
      })

      queryClient.setQueryData(
        [flowData.key, card._id],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            version: oldQueryData.version + 1,
            checklists: checklistArray,
          }
        },
      )
      return
    },
  })

  const renderEmptyState = () => (
    <EmptyStateContainer style={{ marginTop: 20 }}>
      <img src={emptyChecklist} alt="Empty state" />
      <Typography variant="body3" style={{ color: "#53464F" }}>
        Você ainda não criou um template de checklist para esta etapa.
      </Typography>
      <ButtonWrapper>
        <StyledButton
          size="large"
          variant="secondary"
          onClick={modalChecklist.onOpen}
        >
          Criar checklist manualmente
        </StyledButton>

        <StyledButton
          size="large"
          variant="primary"
          onClick={() =>
            navigate(
              `/flows/settings/${card.flowId}/checklist?column=${column._id}`,
            )
          }
        >
          Criar template de checklists
        </StyledButton>
      </ButtonWrapper>
    </EmptyStateContainer>
  )

  const handleChangeChecklist = (item: string) => {
    mutateCheckChecklist({
      columnId: column._id,
      flowCardId: card._id,
      item,
      version: card.version,
    })
  }

  const renderChecklist = () => (
    <ChecklistWrapper>
      {checklist?.items?.map((checklist, idx) => (
        <ChecklistItem key={idx}>
          <Checkbox
            checked={checklist.checked}
            onChange={() => handleChangeChecklist(checklist.label)}
          />
          {checklist.label}
        </ChecklistItem>
      ))}
    </ChecklistWrapper>
  )

  return (
    <Container style={style}>
      <Content hasChecklist={hasChecklist}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="headline7" style={{ color: "#53464F" }}>
            Checklist de tarefas
          </Typography>
          <Tooltip
            title="Você pode editar esta checklist sem afetar o template criado."
            placement="top"
          >
            <span>
              <Icons
                name="IconInfoCircle"
                style={{ color: "#53464F", marginLeft: 5 }}
                fill="transparent"
                size={16}
              />
            </span>
          </Tooltip>
        </div>

        <Typography variant="body4" style={{ color: "#83727D", marginTop: 5 }}>
          {`Organize e gerencie abaixo sua lista de atividades e ganhe agilidade para acompanhar as etapas do ${column.name.toLocaleLowerCase()}.`}
        </Typography>

        {card ? (
          <Fragment>
            {hasChecklist ? renderChecklist() : renderEmptyState()}
          </Fragment>
        ) : (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"300px"}
            style={{ marginTop: 25 }}
          />
        )}
      </Content>

      {!!checklist && (
        <div>
          <FloatingIconButton
            size="medium"
            variant="line"
            icon="IconPencil"
            onClick={modalChecklist.onOpen}
          />
        </div>
      )}

      <ModalChecklist
        isOpen={modalChecklist.isOpen}
        handleClose={modalChecklist.onClose}
        checklist={checklist}
        flowType={flowType}
        cardId={card._id}
        columnId={column._id}
        version={card.version}
      />
    </Container>
  )
}

export default CardChecklist
