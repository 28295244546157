import gql from "graphql-tag"
import { request } from "../client"

const RESIGNATION_POST_COMMENT = gql`
  mutation ResignationPostComment($params: PostComment) {
    resignationPostComment(params: $params) {
      _id
      version
    }
  }
`

export const resignationPostComment = async (params: {
  message: string
  flowCardId: string
  version: number
}) => {
  const { resignationPostComment } = await request(RESIGNATION_POST_COMMENT, {
    params,
  })
  return resignationPostComment as { _id: string; version: number }
}
