import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  background: #ebe6e9;
`

const StyledPdfHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f6f8;
  border: 1px solid #ebe6e9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
  user-select: none;
`

const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.primary};
      &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0px 16px 40px ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

const CanvasContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: auto;
  margin: 12px;
`

export { Container, StyledPdfHeaderContainer, StyledButton, CanvasContainer }
