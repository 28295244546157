import gql from "graphql-tag"
import { request } from "../client"

export const SAVE_WORKSHIFT = gql`
  mutation SaveWorkshift($params: SaveWorkshiftInput) {
    saveWorkshift(params: $params)
  }
`

export interface SaveWorkshiftParams {
  candidateId: string
  workshiftId: string
  flowCardId: string
  version: number
}

export const saveWorkshift = async (
  params: SaveWorkshiftParams,
): Promise<void> => {
  await request(SAVE_WORKSHIFT, {
    params,
  })
}
