import {
  StyledAccordion,
  SubTitle,
  Title,
  StyledContainerIcon,
  AccordionTitle,
  AccordionDescription,
  Text,
} from "./styles"

import { Icons, dayjs } from "@flash-tecnologia/hros-web-ui-v2"

import { AccordionDetails, AccordionSummary } from "@mui/material"
import { UploadPreview } from "../../../components/common/DeprecatedUploadPreview"

export const StartProcessContent = ({ resignationFlowInfo }) => {
  return (
    <StyledAccordion
      style={{
        marginBottom: "24px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            marginRight: "20px",
          }}
        >
          <AccordionTitle variant="headline7">Processo iniciado</AccordionTitle>
          <AccordionDescription variant="body4">
            Preencha as informações como carta de desligamento, o último dia de
            trabalho e se terá aviso prévio.
          </AccordionDescription>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px 44px 40px 44px" }}>
        <Title variant={"headline8"}>Dados do desligamento</Title>

        <div style={{ marginTop: "24px" }}>
          <SubTitle variant="body4" style={{ fontWeight: 700 }}>
            Desligamento
          </SubTitle>
          <Text variant={"body3"} hasValue={!!resignationFlowInfo?.resignation}>
            {resignationFlowInfo?.resignation === "voluntary"
              ? "Voluntário"
              : resignationFlowInfo?.resignation === "involuntary"
                ? "Involuntário"
                : "Não preenchido"}
          </Text>
        </div>

        <div style={{ marginTop: "24px" }}>
          <SubTitle variant="body4" style={{ fontWeight: 700 }}>
            Tipo de desligamento
          </SubTitle>
          <Text
            variant="body3"
            hasValue={!!resignationFlowInfo?.resignationType}
          >
            {resignationFlowInfo?.resignationType || "Não preenchido"}
          </Text>
        </div>

        <div style={{ marginTop: "24px" }}>
          <SubTitle variant="body4" style={{ fontWeight: 700 }}>
            Motivo do desligamento
          </SubTitle>
          <Text
            variant="body3"
            hasValue={!!resignationFlowInfo?.resignationReason}
          >
            {resignationFlowInfo?.resignationReason || "Não preenchido"}
          </Text>
        </div>

        <div style={{ marginTop: "24px" }}>
          {resignationFlowInfo?.resignationLetter?.value ? (
            <UploadPreview
              uploadItem={resignationFlowInfo?.resignationLetter}
              hasShareLink
            />
          ) : (
            <></>
          )}
        </div>

        <div style={{ marginTop: "24px" }}>
          <SubTitle variant="body4" style={{ fontWeight: 700 }}>
            Irá cumprir aviso prévio
          </SubTitle>
          <Text
            variant="body3"
            hasValue={resignationFlowInfo?.serveNoticePeriod !== null}
          >
            {resignationFlowInfo?.serveNoticePeriod === null
              ? "Não preenchido"
              : resignationFlowInfo?.serveNoticePeriod
                ? "Sim"
                : "Não"}
          </Text>
        </div>

        <div style={{ marginTop: "24px" }}>
          <SubTitle variant="body4" style={{ fontWeight: 700 }}>
            Último dia de trabalho efetivo
          </SubTitle>
          <Text
            variant="body3"
            hasValue={!!resignationFlowInfo?.lastWorkingDate}
          >
            {resignationFlowInfo?.lastWorkingDate
              ? dayjs(resignationFlowInfo?.lastWorkingDate).format("DD/MM/YYYY")
              : "Não preenchido"}
          </Text>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  )
}
