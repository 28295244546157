import gql from "graphql-tag"

export const HIRING_REFUSE_PROPOSAL = gql`
  mutation Mutation($candidateId: ID!, $refuseReason: String) {
    hiringRefuseProposal(
      candidateId: $candidateId
      refuseReason: $refuseReason
    ) {
      _id
    }
  }
`
