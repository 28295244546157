import { gql } from "graphql-request"
import { request } from "../client"
import { IFormGroup } from "../../types"

const SEND_FORMCONFIG_EXTENSION = gql`
  mutation SendFormConfigExtension($params: SendFormConfigExtensionInput) {
    sendFormConfigExtension(params: $params) {
      _id
      baseId
      companyId
      extensionId
    }
  }
`

export const sendFormConfigExtension = async (params: {
  companyId: string
  baseId: string
  name: string
  formGroups: IFormGroup[]
}) => {
  const { sendFormConfigExtension } = await request(SEND_FORMCONFIG_EXTENSION, {
    params,
  })
  return sendFormConfigExtension as {
    _id: string
    baseId: string
    companyId: string
  }
}
