import gql from "graphql-tag"
import { request } from "../client"

const CREATE_RESIGNATION_CARD = gql`
  mutation CreateResignationCard($params: CreateResignationCard) {
    createResignationCard(params: $params) {
      _id
      setupId
      name
      description
      status
      code
      color
      active
      position
      columnId
      employeeId
      companyId
      companyName
      flowId
      version
      statusHistory {
        status
        columnId
      }
      metadata
      checklists {
        columnId
        items {
          checked
          label
        }
      }
      resignationInterviewObservation
      asoDate
      asoResult
      lastWorkingDate
      resignationRequestDate
      serveNoticePeriod
      resignation
      resignationReason
      resignationType
      comments {
        message
        authorEmployeeId
        authorName
        sentDate
        timeSince
      }
      resignationInterviewFile {
        key
        path
        value
        type
      }
      asoFile {
        key
        path
        value
        type
      }
      resignationLetter {
        key
        path
        value
        type
      }
      emailLink {
        name
        columnId
        link
        expired
      }
    }
  }
`

export const createResignationCard = async (params: {
  employeeId: string
  flowId: string
}) => {
  await request(CREATE_RESIGNATION_CARD, { params })
}
