import { gql } from "graphql-request"
import { request } from "../client"

const CHECKLIST_TEMPLATE_NEW = gql`
  mutation TemplateNewChecklist($params: NewTemplateChecklistInput) {
    templateNewChecklist(params: $params)
  }
`

export const checklistTemplateNew = async (params: {
  columnId: string
  companyId: string
  flowId: string
  items: string[]
}) => {
  await request(CHECKLIST_TEMPLATE_NEW, { params })
}
