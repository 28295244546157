import { gql } from "graphql-request"
import { request } from "../client"
import { Workshift } from "../../types"

const GET_WORKSHIFTS = gql`
  query Workshifts {
    getWorkshifts {
      id
      name
    }
  }
`

type GetWorkshiftsResponse = Pick<Workshift, "id" | "name">

export const getWorkshifts = async (): Promise<GetWorkshiftsResponse[]> => {
  const { getWorkshifts } = await request(GET_WORKSHIFTS)
  return getWorkshifts
}
