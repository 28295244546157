import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 1095px;
  margin-bottom: 32px;
`
