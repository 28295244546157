import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2"
import { NavigateOptions } from "react-router-dom"

import { Header, Footer, FooterProps } from "./components"

import { Container, ContentWrapper } from "./styled"

interface RoutesProps {
  route?: string
  label: string
  options?: NavigateOptions
}

interface StepperProps {
  steps: string[]
  activeStep: number
}

type PageTemplateProps = {
  children: React.ReactNode
  routes: RoutesProps[]
  footer?: FooterProps
  stepper?: StepperProps
  hasHeader?: boolean
  containerStyle?: any
}

export const SettingPageTemplate = ({
  children,
  routes,
  stepper,
  footer,
  hasHeader = true,
  containerStyle = {},
}: PageTemplateProps) => {
  return (
    <Container style={containerStyle}>
      {hasHeader ? <Header routes={routes} stepper={stepper} /> : <></>}
      <ContentWrapper>
        <PageContainer>{children}</PageContainer>
      </ContentWrapper>
      {footer ? <Footer
        cancelProps={footer.cancelProps}
        goBackProps={footer.goBackProps}
        confirmProps={footer.confirmProps}
        skipProps={footer.skipProps}
        draftProps={footer.draftProps}
      /> : null}
    </Container>
  )
}
