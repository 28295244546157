import { PhoneNumberUtil } from "google-libphonenumber"

const phoneUtil = PhoneNumberUtil.getInstance()

export const CpfMask = (cpfValue: string): string => {
  if (!cpfValue) return ""
  return cpfValue
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
}

export const CnpjMask = (cnpjValue: string): string => {
  if (!cnpjValue) return ""
  return cnpjValue
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
}

export const PhoneMask = (value, countryCode = "BR"): string => {
  if (!value) return ""
  const parsedNumber =
    value.length > 2 ? phoneUtil.parseAndKeepRawInput(value, countryCode) : ""

  const isValid = parsedNumber ? phoneUtil.isValidNumber(parsedNumber) : false

  if (isValid) {
    return phoneUtil.formatInOriginalFormat(parsedNumber, countryCode)
  }

  return value.replace(/\D/g, "")
}

export const CEPMask = (cnpjValue: string): string => {
  if (!cnpjValue) return ""
  return cnpjValue.replace(/\D+/g, "").replace(/(\d{5})(\d)/, "$1-$2")
}

export const EmailMask = (emailValue: string): string => {
  if (!emailValue) return ""
  return emailValue
    .replace(/\s/g, "")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w\.@|-|+-]/g, "")
    .toLowerCase()
}
export const PasswordMask = (passwordValue: string): string => {
  if (!passwordValue) return ""
  return passwordValue.replace(/\s/g, "")
}

export const DateMask = (value): string => {
  if (!value) return ""
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1")
}

type ContractDurationMap = {
  label: string
  value:
    | "three-months"
    | "six-months"
    | "twelve-months"
    | "twenty-four-months"
    | "indefinite-period"
}

const contractOptions: ContractDurationMap[] = [
  { label: "3 meses", value: "three-months" },
  { label: "6 meses", value: "six-months" },
  { label: "12 meses", value: "twelve-months" },
  {
    label: "24 meses",
    value: "twenty-four-months",
  },
  {
    label: "Prazo indeterminado",
    value: "indefinite-period",
  },
]
export const ContractDurationMask = (value: string): string | null => {
  return contractOptions.find((option) => option.value === value)?.label || null
}

type WorkshipMap = {
  label: string
  value: "morning" | "afternoon"
}
const workshipMask: WorkshipMap[] = [
  { label: "Manhã", value: "morning" },
  { label: "Tarde", value: "afternoon" },
]
export const WorkshipMask = (value?: string): string | undefined => {
  if (!value) return undefined
  return workshipMask.find((option) => option.value === value)?.label
}

type WorkloadMap = {
  label: string
  value: "twenty-hours-week" | "thiry-hours-week" | "forty-hours-week"
}

const workloadMask: WorkloadMap[] = [
  { value: "twenty-hours-week", label: "20 horas semanais" },
  { value: "thiry-hours-week", label: "30 horas semanais" },
  { value: "forty-hours-week", label: "40 horas semanais" },
]

export const WorkloadMask = (value?: string): string | undefined => {
  if (!value) return undefined
  return workloadMask.find((option) => option.value === value)?.label
}

export const minutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${hours}:${remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes}`
}
