import styled from "styled-components"

export const IconContainer = styled.div<{ _size: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ _size }) => _size};
  height: ${({ _size }) => _size};
  background-color: ${({ theme }) => theme.colors.neutral90};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
`
