import styled from "styled-components"
import { SearchField } from "@flash-tecnologia/hros-web-ui-v2"

const Search = styled(SearchField)`
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 30px;
  }

  width: 100%;
  height: 60px;
`

export { Search }
