import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 24px;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
`

export const DocumentsContainer = styled.div`
  width: calc(30% - 12px);
  display: flex;
  flex-direction: column;
`

export const FieldsContainer = styled.div`
  width: calc(70% - 12px);
  display: flex;
  flex-direction: column;

  margin-left: 24px;
`

export const Title = styled(Typography)`
  color: #3b2e37;
`

export const Description = styled(Typography)`
  color: #83727d;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Badge = styled.div`
  color: #00244c;
  font-weight: 600;
  font-size: 12px;
  margin: 0 10px;
  padding: 4px 12px;
  background: #d4e8ff;
  border-radius: 24px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CustomButton = styled.div`
  cursor: pointer;
  color: #83727d;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid #83727d;

  display: flex;
  flex-direction: row;
`
