import {
  IconButton,
  Menu,
  PageContainer,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

import { Grid } from "@mui/material"
import styled from "styled-components"

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-top: 8px;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const StyledTextButton = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700 !important;
`

const StyledSectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const StyledFlex = styled.div`
  display: flex;
`

const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
`

const CardIconButton = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.secondary[95]};
      svg {
        stroke: ${({ theme }) => theme.colors.secondary[50]};
      }
    }
  }
`

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

const OptionText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const StyledMenu = styled(Menu)`
  && {
    &.menu-base-custom-theme {
      ul {
        &.MuiList-root {
          padding: 12px;

          li {
            padding: 16px;
            margin-bottom: 0px;

            &.MuiButtonBase-root {
              div {
                text-align: start;
              }
              &:hover {
                color: ${({ theme }) => theme.colors.neutral.light.pure};
                background-color: ${({ theme }) => theme.colors.primary};
                border-radius: 0px;
                p {
                  color: ${({ theme }) => theme.colors.neutral.light.pure};
                }
              }
              &:last-child {
                margin-bottom: 0px;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
`

const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`

const StyledGrid = styled(Grid)``

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledTypography = styled(Typography)``

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`

export {
  Title,
  Description,
  StyledTitle,
  StyledDescription,
  StyledSectionTitle,
  StyledFlex,
  StyledFlexCenter,
  CardContainer,
  CardIconButton,
  FlexBetween,
  OptionText,
  StyledMenu,
  StyledPageContainer,
  StyledGrid,
  StyledSectionContainer,
  StyledTextField,
  StyledTypography,
  StyledSubtitleContainer,
  StyledTextButton,
}
