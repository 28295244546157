import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Accordion } from "@mui/material"
import styled from "styled-components"

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: 1px solid #ebe6e9;
    border-radius: 8px;
    box-shadow: none;

    margin-bottom: 40px;

    &::before {
      background-color: transparent;
    }
  }
  .MuiButtonBase-root {
    padding: 40px 44px;
  }
`

export const StyledContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;

  width: 48px;
  height: 48px;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 40px;
`

export const AccordionTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const AccordionDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`
