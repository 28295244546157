import { useNavigate, useOutletContext } from "react-router-dom"
import { Flow, FlowSubcategory, IFormConfigList } from "../../../../types"
import { Dispatch, useCallback, useContext, useState } from "react"
import {
  CardContainer,
  CardDescription,
  CardIconButton,
  CardTitle,
  FlexBetween,
  OptionText,
  PageContainer,
  StyledFlexCenter,
  StyledIconsHoverable,
  StyledMenu,
  StyledWarningOption,
} from "./styles"
import { EnumGlobalActions, globalContext, IActions } from "../../../../context"
import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import {
  CreateTemplateModal,
  RemoveTemplateModal,
  RenameTemplateModal,
} from "./Modal"
import { Grid } from "@mui/material"
import { modalAction } from "../../../../utils"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../../api"
import dispatchToast from "../../../../utils/dispatchToast"

const DocumentTemplate = () => {
  const [modalCreateTemplate, setModalCreateTemplate] = useState<boolean>(false)

  const [flow] = useOutletContext<[flow: Flow]>()
  const { companyId, dispatch } = useContext(globalContext)
  const navigate = useNavigate()

  const { data: documentList, isInitialLoading: isLoading } = useQuery(
    ["form-config-list", flow.companyId],
    () =>
      api.query.hiring.modelDocuments.getFormConfigByCompany(flow.companyId),
    {
      enabled: !!flow.companyId,
      retry: 2,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Houve um problema ao buscar os modelos de documentos.",
        })
      },
    },
  )

  const renderSectionCard = useCallback((document: IFormConfigList) => {
    return (
      <CardContainer
        newSection={false}
        onClick={() => {
          const id = document.extensionId || document._id
          navigate(`/flows/settings/${flow._id}/documents/${id}`)
        }}
      >
        <FlexBetween style={{ marginBottom: "32px" }}>
          <CardIconButton
            size="medium"
            variant="filled"
            icon={"IconLayoutCards"}
          />
          {!document?.default && (
            <>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div onClick={(e) => e.stopPropagation()}>
                <StyledMenu
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  options={[
                    {
                      onClick: async () => {
                        modalAction({
                          param: {
                            name: "renameTemplateModal",
                            open: true,
                            configId: document._id,
                            companyId: flow.companyId,
                          },
                          dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
                        })
                      },
                      children: (
                        <StyledFlexCenter>
                          <Icons
                            name="IconPencil"
                            fill="transparent"
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "8px",
                            }}
                          />
                          <OptionText
                            variant="body3"
                            style={{ fontWeight: 600 }}
                          >
                            Renomear template
                          </OptionText>
                        </StyledFlexCenter>
                      ),
                    },
                    {
                      onClick: () => {
                        modalAction({
                          param: {
                            name: "removeTemplateModal",
                            open: true,
                            configId: document._id,
                            companyId: flow.companyId,
                          },
                          dispatch: dispatch as Dispatch<IActions<EnumGlobalActions>>,
                        })
                      },
                      children: (
                        <StyledWarningOption>
                          <Icons
                            name="IconTrash"
                            fill="transparent"
                            style={{
                              width: "24px",
                              height: "24px",
                              marginRight: "8px",
                            }}
                          />
                          <OptionText
                            variant="body3"
                            style={{ fontWeight: 600 }}
                          >
                            Deletar fluxo
                          </OptionText>
                        </StyledWarningOption>
                      ),
                    },
                  ]}
                  type="default"
                >
                  <StyledIconsHoverable
                    name="IconDotsVertical"
                    style={{ width: "24px", height: "24px" }}
                  />
                </StyledMenu>
              </div>
            </>
          )}
        </FlexBetween>
        <CardTitle variant="headline8">{document.name}</CardTitle>
        <CardDescription variant="body4">{`${document.numberOfGroups} seções`}</CardDescription>
      </CardContainer>
    )
  }, [])

  return (
    <>
      <PageContainer style={{ marginBottom: "40px", marginTop: "40px" }}>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CardContainer
              newSection={true}
              onClick={() => setModalCreateTemplate(true)}
            >
              <FlexBetween style={{ marginBottom: "32px" }}>
                <CardIconButton
                  size="medium"
                  variant="filled"
                  icon="IconPlus"
                />
              </FlexBetween>
              <CardTitle variant="headline8">
                Novo template de lista de documentos
              </CardTitle>
            </CardContainer>
          </Grid>

          {isLoading || !documentList
            ? [0, 1, 2].map((_, idx) => (
              <Grid key={idx} item xs={12} sm={6} md={6} lg={3}>
                <Skeleton variant="rectangular" width={"100%"} height={180} />
              </Grid>
            ))
            : documentList.map((document, idx) => (
              <Grid key={idx} item xs={12} sm={6} md={6} lg={3}>
                {renderSectionCard(document)}
              </Grid>
            ))}
        </Grid>
      </PageContainer>

      <CreateTemplateModal
        isOpen={modalCreateTemplate}
        handleClose={() => {
          setModalCreateTemplate(false)
        }}
        subcategory={flow.subcategory as FlowSubcategory}
        companyId={companyId as string}
      />
      <RenameTemplateModal />
      <RemoveTemplateModal />
    </>
  )
}

export default DocumentTemplate
