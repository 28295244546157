import {
  Button,
  Icons,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { ActionWrapper, Container, ContentWrapper } from "./styles"
import { EmailLink } from "../../../types"

import dispatchToast from "../../../utils/dispatchToast"
import { CSSProperties } from "react"

interface WizardCardLinkProps {
  multiplesEmailLinks?: {
    title?: string
    emailLink: EmailLink
  }[]
  style?: CSSProperties
}

const Link = ({
  title,
  emailLink,
}: {
  title?: string
  emailLink: EmailLink
}) => {
  return (
    <>
      {title ? <Typography variant="body4">{title}</Typography> : <></>}

      <ActionWrapper>
        <TextField
          name={"link"}
          label={"Link"}
          fullWidth={true}
          value={emailLink?.link}
          type={"text"}
          InputProps={{
            endAdornment: (
              <Icons
                name="IconLink"
                fill={"transparent"}
                style={{ marginLeft: "10px" }}
              />
            ),
          }}
          style={{
            width: "70%",
          }}
        />

        <Button
          size={"small"}
          variant="primary"
          style={{
            width: "30%",
            height: "60px",
            alignSelf: "center",
          }}
          onClick={() => {
            navigator.clipboard.writeText(emailLink.link)
            dispatchToast({
              content: "Link copiado",
              type: "success",
            })
          }}
        >
          <>
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              Copiar link
            </Typography>
            <Icons name="IconCopy" fill="transparent" />
          </>
        </Button>
      </ActionWrapper>
    </>
  )
}

export const WizardCardLink = ({
  multiplesEmailLinks,
  style,
}: WizardCardLinkProps) => {
  const filteredEmailLinks = (multiplesEmailLinks || []).filter(
    ({ emailLink }) => !emailLink.expired,
  )
  if (!filteredEmailLinks?.length) return <></>

  return (
    <Container style={style}>
      <ContentWrapper>
        <Typography
          variant="headline8"
          style={{
            color: "#53464F",
            lineHeight: "24px",
          }}
        >
          Compartilhe o link
        </Typography>

        <Typography
          variant="body4"
          style={{
            color: "#83727D",
            lineHeight: "20px",
            marginBottom: 24,
          }}
        >
          Copie e compartilhe o link abaixo caso a pessoa tenha dificuldades de
          acessar sua caixa de e-mail{" "}
        </Typography>

        {filteredEmailLinks.map(({ title, emailLink }, idx) => (
          <Link key={idx} title={title} emailLink={emailLink} />
        ))}
      </ContentWrapper>
    </Container>
  )
}
