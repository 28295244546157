import styled, { css } from "styled-components"

const InputContainedContainer = styled.div<{
  error?: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 19px 16px;

  border: 1px solid #baabb2;
  border-radius: 4px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  input {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: rgb(133, 111, 126);
  }
  label {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #baabb2;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: rgb(133, 111, 126) !important;
  }

  ${({ error, disabled }) =>
    disabled
      ? css`
          border: 1px solid #d1c7ce;
          pointer-events: none;
          user-select: none;
          opacity: 0.5;
          label {
            color: #b9acb4;
          }
          &:hover {
            border: 1px solid #d1c7ce;
          }
        `
      : error
        ? css`
            background-color: #ffecd6;
            border: 1px solid #fea034;
            &:hover {
              border: 1px solid #fea034;
            }
          `
        : ""}
`
export { InputContainedContainer }
