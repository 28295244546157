import {
  Button,
  Icons,
  Table,
  Typography,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2"
import { RowContainer } from "./styles"
import BenefitBadge from "../BenefitBadge"
import React from "react"
import { Benefit } from "../../../../../../types"
import { checkUnleashToggles, useUnleashToggles } from "src/hooks"

type TableData = {
  benefit: Benefit
}

type Props = {
  data: TableData[] | null
  callback: (benefits: TableData[]) => void
  loading?: boolean
  companyId: string
}
const BenefitSelector: React.FC<Props> = ({ data, callback, loading, companyId }) => {
  const [filteredData, setFilteredData] = React.useState<TableData[]>(
    data ?? [],
  )
  const unleashToggles = useUnleashToggles({ companyId },)

  const hasBenefitManagement = React.useMemo(() => {
    return checkUnleashToggles(unleashToggles, "EVOLUTION_HROS_BENEFIT_MANAGEMENT_XP")
  }, [unleashToggles])

  const table = tableControllers.useTableColumns<TableData>({
    options: {
      selectable: true,
    },
    columns: [
      {
        header: "Benefício",
        accessorKey: "benefit",
        cell: (context) => (
          <RowContainer>
            <BenefitBadge
              src={context.cell.row.original.benefit.icon}
              name={context.cell.row.original.benefit.name}
              size="l"
            />
            <div className="text-container">
              <Typography variant="body3">
                {context.cell.row.original.benefit.name}
              </Typography>
              <Typography variant="caption">
                {context.cell.row.original.benefit.description}
              </Typography>
            </div>
          </RowContainer>
        ),
      },
    ],
    data: filteredData,
    onPaginationChange: () => { },
    pagination: {
      pageNumber: 1,
      pageSize: 10,
    },
  })

  React.useEffect(() => {
    if (table.selected.allSelected) {
      callback(data ?? [])
      return
    }

    callback(table.selected.selected.map((row) => row.original))
  }, [table.selected.selected])

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value
    if (!data) {
      setFilteredData([]);
      return;
    }
    if (!value) {
      setFilteredData(data)
      return
    }
    setFilteredData(
      data.filter((item) =>
        item.benefit.name.toLowerCase().includes(value.toLowerCase()),
      ),
    )
  }

  return (
    <Table.Root>
      <Table.Header title="Benefícios" />
      <Table.Search
        label="Buscar por benefício"
        onChange={onSearchInputChange}
      />
      <Table.Grid.Root
        empty={{
          isFiltered: true,
          message: "Os benefícios dessa empresa ainda não foram configurados",
          children: (
            <a
              href={
                hasBenefitManagement
                  ? "/benefit-management"
                  : "/benefits/dashboard/company/benefits"
              }
            >
              <Button variant="primary" size="medium" onClick={() => { }}>
                Configurar benefícios{" "}
                <span style={{ marginLeft: 5 }}>
                  <Icons name="IconArrowRight" />
                </span>
              </Button>
            </a>
          ),
        }}
        loading={loading}
      >
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table.rows.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
    </Table.Root>
  )
}

export default BenefitSelector
