import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacings.xs} 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.xs};
  align-self: stretch;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral90}`};
`

export const Header = styled.div`
  display: flex;
  padding: 0px ${({ theme }) => theme.spacings.xs};
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

export const Body = styled.div`
  display: flex;
  padding: ${({ theme }) =>
    `${theme.spacings.xs} ${theme.spacings.xs} 0 ${theme.spacings.xs}`};
  flex-direction: column;
  align-self: stretch;
  border-top: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.neutral90}`};
`

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.secondary99};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs};
`

export const StyledImage = styled.img`
  border-radius: ${({ theme }) =>
    `${theme.borders.radius.s} ${theme.borders.radius.s} 0 0`};
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
`
