import { useOutletContext } from "react-router-dom"
import { Flow } from "../../../../../types"
import { Container, Wrapper } from "./styles"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { Table } from "./Table"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../../../api"

export const ProposedLetterModelHome = () => {
  const [flow] = useOutletContext<[flow: Flow]>()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.settings.proposedLetterModel",
  })

  const {
    data: listModelProposedLetter,
    isInitialLoading: isLoading,
    isRefetching,
  } = useQuery(
    ["all-model-proposed-letter", flow._id],
    () => api.query.hiring.modelProposedLetter.getByFlowId(flow._id),
    {
      enabled: !!flow._id,
    },
  )

  return (
    <Container>
      <Wrapper>
        <Typography
          variant="body3"
          style={{
            color: "#6B5B66",
          }}
        >
          {t("tableDescription")}
        </Typography>
      </Wrapper>

      <Table
        loading={isLoading || isRefetching}
        flowId={flow?._id}
        subcategory={flow.subcategory}
        data={listModelProposedLetter || []}
        filters={[
          {
            accessor: "status",
            label: "Status",
            options: [
              { label: "Publicado", value: "published" },
              { label: "Rascunho", value: "draft" },
            ],
            type: "checkbox",
          },
          {
            accessor: "createdAt",
            label: "Data de criação",
            options: [],
            type: "date",
          },
        ]}
      />
    </Container>
  )
}
