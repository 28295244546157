import styled from "styled-components"

export const CircleIconContainer = styled.div<{
  color: string
  backgroundColor: string
}>`
  border-radius: 50%;
  width: 80px;
  height: 80px;

  background-color: ${({ backgroundColor }) => backgroundColor};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  > svg {
    width: 48px;
    height: 48px;
    color: ${({ color }) => color};
  }
`
