import { Accordion } from "@flash-tecnologia/hros-web-ui-v2"
import { FlexContainer } from "./styles"

interface SignatureFormProps {
  title: string
  description: string
  children: React.ReactNode
}

export const SignatureAccordion: React.FC<SignatureFormProps> = ({
  title,
  children,
  description,
}) => {
  return (
    <Accordion
      variant="default"
      tagVariant="primary"
      tagName={undefined}
      title={title}
      description={description}
    >
      <FlexContainer>{children}</FlexContainer>
    </Accordion>
  )
}
