import gql from "graphql-tag"
import { request } from "../client"
import { Employee } from "../../types"

const GET_EMPLOYEE_BY_ID = gql`
  query Query($employeeId: ID!) {
    getEmployeeById(employeeId: $employeeId)
  }
`

export const getEmployeeById = async (employeeId: string) => {
  const { getEmployeeById } = await request(GET_EMPLOYEE_BY_ID, {
    employeeId,
  })

  return getEmployeeById as Employee
}
