import {
  Icons,
  Tag,
  dayjs,
  useDisclosure,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  AccordionBody,
  FieldContainer,
  FieldText,
  FieldTitle,
  IconContainer,
  ItemHeader,
  ItemTitle,
  StyledPOneLine,
  StyledDownloadIcons,
  StyledTextContainer,
  ChecklistItem,
  ChecklistWrapper,
  StyledCheckbox,
  ContainedInput,
  ContainedText,
  CounterContainer,
  Container,
  AccordionHeader,
  Title,
  AccordionButton,
  StatusContainer,
  StatusSignature,
  StatusDescription,
  Counter,
  HtmlDiv,
} from "./styles"
import { useCallback } from "react"
import { HistoryField, HistoryItems } from "../../../types"
import { downloadArchive, getArchiveNameFromKey } from "../../../utils"
import { useNavigate } from "react-router-dom"

interface AccordionData {
  title: string
  description: string
  items: HistoryItems[]
}

const AccordionHistory = ({ title, description, items }: AccordionData) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const navigate = useNavigate()

  const renderFieldByType = (field: HistoryField) => {
    switch (field.type) {
      case "checklist":
        return field?.checklist ? (
          <>
            <ChecklistWrapper>
              {field.checklist?.items?.map((checklist, idx) => (
                <ChecklistItem key={idx}>
                  <StyledCheckbox
                    checked={checklist.checked}
                    disabled
                    checkedIcon={<Icons name="CheckboxCheckedIcon" size={18} />}
                    icon={<Icons name="CheckboxIcon" size={18} />}
                  />
                  {checklist.label}
                </ChecklistItem>
              ))}
            </ChecklistWrapper>
          </>
        ) : (
          <>
            <StyledPOneLine numberOfLines={1}>
              <FieldText variant="caption" hasValue={false}>
                Não preenchido
              </FieldText>
            </StyledPOneLine>
          </>
        )
      case "signature":
        return (
          <div style={{ marginLeft: "8px" }}>
            <FieldTitle variant="body4" style={{ marginTop: "8px" }}>
              Nome completo
            </FieldTitle>
            <FieldText variant="body4" hasValue={!!field?.signatureInfo?.name}>
              {field?.signatureInfo?.name || "Não preenchido"}
            </FieldText>

            <FieldTitle variant="body4" style={{ marginTop: "8px" }}>
              E-mail
            </FieldTitle>
            <FieldText variant="body4" hasValue={!!field?.signatureInfo?.email}>
              {field?.signatureInfo?.email || "Não preenchido"}
            </FieldText>

            <FieldTitle variant="body4" style={{ marginTop: "8px" }}>
              Celular
            </FieldTitle>
            <FieldText variant="body4" hasValue={!!field?.signatureInfo?.phone}>
              {field?.signatureInfo?.phone || "Não preenchido"}
            </FieldText>

            <FieldTitle variant="body4" style={{ marginTop: "8px" }}>
              Assinar como
            </FieldTitle>
            <FieldText
              variant="body4"
              hasValue={
                !!(
                  field?.signatureInfo?.type === "company" ||
                  field?.signatureInfo?.type === "person"
                )
              }
            >
              {field?.signatureInfo?.type === "person"
                ? "Pessoa"
                : field?.signatureInfo?.type === "company"
                  ? "Empresa"
                  : "Não preenchido"}
            </FieldText>
          </div>
        )
      case "text":
        return (
          <StyledTextContainer hasValue={!!field.text}>
            <StyledPOneLine numberOfLines={1}>
              <FieldText variant="caption" hasValue={!!field.text}>
                {field.text || "Não preenchido"}
              </FieldText>
            </StyledPOneLine>
          </StyledTextContainer>
        )
      case "file":
        if (field.file) {
          if (Array.isArray(field.file)) {
            return field.file.map((file, idx) => (
              <ContainedInput key={idx}>
                <ContainedText>
                  {getArchiveNameFromKey(file?.key)}
                </ContainedText>
                <StyledDownloadIcons
                  name="IconDownload"
                  fill="transparent"
                  color="#55444F"
                  size={18}
                  onClick={() =>
                    downloadArchive(
                      file?.value,
                      getArchiveNameFromKey(file?.key),
                    )
                  }
                />
              </ContainedInput>
            ))
          }

          const { key, value } = field.file
          if (key && value) {
            return (
              <ContainedInput>
                <ContainedText>{getArchiveNameFromKey(key)}</ContainedText>
                <StyledDownloadIcons
                  name="IconDownload"
                  fill="transparent"
                  color="#55444F"
                  size={18}
                  onClick={() =>
                    downloadArchive(value, getArchiveNameFromKey(key))
                  }
                />
              </ContainedInput>
            )
          }
        }

        return (
          <StyledPOneLine numberOfLines={1}>
            <FieldText variant="caption" hasValue={false}>
              Não preenchido
            </FieldText>
          </StyledPOneLine>
        )
      case "link":
        return (
          <ContainedInput>
            <ContainedText>{field.text}</ContainedText>
            <StyledDownloadIcons
              name="IconExternalLink"
              fill="transparent"
              color="#55444F"
              size={18}
              onClick={() => window.open(field.link, "_blank")}
            />
          </ContainedInput>
        )
      case "counter":
        return (
          <CounterContainer>
            <Counter>{field.text}</Counter>
            {/* <RedirectContainer
              onClick={() => {
                if (!field.employeeId) {
                  throw new Error("Employee ID not provided");
                }

                navigate(`/employees/${field?.employeeId}/manage`);
              }}
            >
              <RedirectText>Visualizar todos os documentos</RedirectText>
              <Icons
                name="IconChevronRight"
                fill="transparent"
                size={18}
                color="#FE2B8F"
              />
            </RedirectContainer> */}
          </CounterContainer>
        )
      case "status":
        return (
          <StatusContainer>
            <Tag variant="disabled" style={{ color: "#1D161B" }}>
              {field.signature?.type === "candidate" ? "Candidato" : "Empresa"}
            </Tag>

            <StatusDescription style={{ marginTop: "5px" }}>
              {field.signature?.email}
            </StatusDescription>

            <StatusSignature signed={field.signature?.status === "Signed"}>
              <Icons
                name={
                  field.signature?.status === "Signed"
                    ? "IconCheck"
                    : "IconClock"
                }
                fill="transparent"
                size={16}
                style={{
                  marginRight: "8px",
                  stroke:
                    field.signature?.status === "Signed"
                      ? "#83727D"
                      : "#C96C01",
                }}
              />
              <StatusDescription
                style={
                  field.signature?.status !== "Signed"
                    ? {
                      color: "#C96C01",
                    }
                    : {}
                }
              >
                {field.signature?.status === "Signed"
                  ? `Assinado em: ${field.signature?.signedAt
                    ? dayjs(field.signature?.signedAt).format(
                      "DD/MM/YYYY [às] HH:mm",
                    )
                    : ""
                  }`
                  : "Aguardando assinatura"}
              </StatusDescription>
            </StatusSignature>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Icons
                name="IconDevices"
                fill="transparent"
                size={16}
                style={{ marginRight: "8px" }}
              />
              <StatusDescription>
                IP: {field.signature?.ip || "Não registrado"}
              </StatusDescription>
            </div>
          </StatusContainer>
        )
      case "html":
        return <HtmlDiv dangerouslySetInnerHTML={{ __html: field.text as string }} />
    }
  }

  const renderItems = useCallback(() => {
    return items.map((item, idx) => (
      <AccordionBody key={idx}>
        <ItemHeader>
          <IconContainer>
            <Icons name="IconNews" fill="transparent" size={16} />
          </IconContainer>
          <div style={{ color: "#53464F" }}>
            <StyledPOneLine numberOfLines={1}>
              <ItemTitle>{item.title}</ItemTitle>
            </StyledPOneLine>
          </div>
        </ItemHeader>

        {item.fields.map((field, idy) => (
          <FieldContainer key={idy}>
            {field?.title ? (
              <StyledPOneLine numberOfLines={2}>
                <FieldTitle variant="caption">{field.title}</FieldTitle>
              </StyledPOneLine>
            ) : (
              <></>
            )}
            {renderFieldByType(field)}
          </FieldContainer>
        ))}
      </AccordionBody>
    ))
  }, [items])

  return (
    <Container>
      <AccordionHeader>
        <div
          style={{ display: "flex", flexDirection: "column", marginRight: 8 }}
        >
          <StyledPOneLine numberOfLines={2}>
            <Title variant="headline8">{title}</Title>
          </StyledPOneLine>
        </div>

        <AccordionButton onClick={() => (isOpen ? onClose() : onOpen())}>
          <Icons
            name={isOpen ? "IconChevronUp" : "IconChevronDown"}
            fill="transparent"
            width={16}
          />
        </AccordionButton>
      </AccordionHeader>

      {isOpen && renderItems()}
    </Container>
  )
}

export default AccordionHistory
