import styled from "styled-components"

import CopyBoardIcon from "../../../../../assets/copy_board.svg"

export const CopyBoard = styled(CopyBoardIcon)`
  cursor: pointer;
  margin-left: 7px;
  &:hover {
    opacity: 0.5;
  }
`
