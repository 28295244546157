import { Button, ButtonGroup } from "@mui/material"
import styled from "styled-components"

export const StyledGroupButton = styled(ButtonGroup)`
  &.MuiButtonGroup-root {
    border: 1px solid var(--color-neutral-light2);
    border-radius: 20px;

    & button {
      &.MuiButtonBase-root {
        padding: 8px 16px;
        border: none;

        &:hover {
          border-color: var(--color-neutral-light2);
        }
      }

      :first-child {
        padding-left: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-right: 1px solid var(--color-neutral-light2);
      }

      :last-child {
        padding-right: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
`

export const StyledSwitchButton = styled(Button)<{ selected: boolean }>`
  svg {
    stroke: ${({ theme, selected }) =>
      selected
        ? theme.colors.secondary.dark.dark5
        : theme.colors.neutral.dark.dark5};
  }
`
