import React from "react"
import { useParams } from "react-router-dom"
import { HiringCardPage } from "../../../components"
import { DynamicRoute } from "../../../components/screen/CardPage/types"
import { CardDeliveryConfigByType } from "./CardDelivery/CardDeliveryConfig"
import {
  BenefitsAdvertisingByType,
  WorkshiftAdvertisingByType,
} from "./advertisings"
import { BenefitsConfigByType } from "./Benefits/BenefitsConfig"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../api"
import { WorkshiftConfigByType } from "./Workshift/WorkshiftConfig"
import { useUnleashToggles } from "src/hooks"
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility"

type FeaturePermissions = [boolean, boolean, boolean]
type FlashProducts = [boolean, boolean]

const InitialConfigPage = () => {
  const { id: cardId = "" } = useParams()
  const { selectedCompany } = useSelectedCompany()

  const unleashToggles = useUnleashToggles({ companyId: selectedCompany.id })
  const { data, isInitialLoading: isLoading } = useQuery(
    ["company.contracts"],
    () => api.query.company.getCompanyContracts(),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  )

  const featurePermissions: FeaturePermissions = React.useMemo(() => {
    if (!unleashToggles) return [false, false, false]

    const newColumnToggle = unleashToggles.find(
      (toggle) => toggle.name === "FLASH_OS_PEOPLE_HIRING_NEW_COLUMN",
    )

    if (!newColumnToggle?.variant?.payload) return [false, false, false]

    const { type, value } = newColumnToggle.variant.payload
    if (type === "json") {
      const json = JSON.parse(value)
      return [json?.benefits, json?.cards, json?.workshift]
    }
    return [false, false, false]
  }, [unleashToggles])

  const flashProducts: FlashProducts = React.useMemo(() => {
    const hasBenefits =
      data?.benefits?.reduce(
        (acc, contract) =>
          acc ||
          contract.planIds.some((id) =>
            [
              "benefits_plastic_self_default",
              "legacy_migration_benefits",
              "benefits_home_office",
              "benefits_plastic",
              "benefits_awards",
              "active_migration_benefits_points_api",
              "active_migration_benefits_api",
              "active_migration_benefits_points",
              "active_migration_benefits",
            ].includes(id),
          ),
        false,
      ) || false

    const hasWorkshift =
      data?.people?.modules?.includes("time-and-attendance") || false

    return [hasBenefits, hasWorkshift]
  }, [data])

  const pageHistory: DynamicRoute[] = [
    { name: "Admissão", href: "/flows/hiring" },
    {
      name: "Configuração inicial",
      href: `/flows/hiring/initial-config/${cardId}`,
    },
  ]

  const contents = React.useMemo(() => {
    const [hasBenefits, hasWorkshift] = flashProducts
    const [hasBenefitVariant, hasCardsVariant, hasWorkshiftVariant] =
      featurePermissions
    const contents: any[] = []

    if (hasBenefits) {
      if (hasBenefitVariant) {
        contents.push(BenefitsConfigByType)
      }
      if (hasCardsVariant) {
        contents.push(CardDeliveryConfigByType)
      }
    } else {
      contents.push(BenefitsAdvertisingByType)
    }

    if (hasWorkshiftVariant) {
      contents.push(
        hasWorkshift ? WorkshiftConfigByType : WorkshiftAdvertisingByType,
      )
    }

    return contents
  }, [...flashProducts, ...featurePermissions])

  return (
    <HiringCardPage
      cardId={cardId || ""}
      pageHistory={pageHistory}
      contents={contents}
      loading={isLoading}
    />
  )
}

export default React.memo(InitialConfigPage)
