import React from "react"
import { BoxAdornment, Divider, Radio } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { Container, RadioContainer } from "./styles"
import { ResignationCard } from "src/types"
import { ExamGuideContent } from "./ExamGuideContent"
import { AlreadyHaveContent } from "./AlreadyHaveContent"
import { ResignationContentProps } from "src/components/screen/CardPage/types"
import { ResignationExamStatus } from "bff/src/types/hiring"
import { resignationCardContext } from "@components/screen/CardTemplate/Resignation"
import { trpc } from "@api/client"
import dispatchToast from "@utils/dispatchToast"
import { useQueryClient } from "@tanstack/react-query"

enum ExamOptions {
  noApply = "noApply",
  examGuide = "examGuide",
  alreadyHave = "alreadyHave",
}

export type ContentProps = {
  card: ResignationCard
}

const contents: Record<ExamOptions, React.ElementType<ContentProps>> = {
  [ExamOptions.alreadyHave]: AlreadyHaveContent,
  [ExamOptions.examGuide]: ExamGuideContent,
  [ExamOptions.noApply]: () => <></>,
}

function getInitialOption(status: ResignationExamStatus): ExamOptions {
  switch (status) {
    case ResignationExamStatus.sent:
    case ResignationExamStatus.resent:
    case ResignationExamStatus.filled:
      return ExamOptions.examGuide
    case ResignationExamStatus.manual:
      return ExamOptions.alreadyHave
    case ResignationExamStatus.pending:
    default:
      return ExamOptions.noApply
  }
}

export const Exam = ({ card }: ResignationContentProps) => {
  const [examOption, setExamOption] = React.useState<ExamOptions>(
    getInitialOption(
      card.resignationExam?.status ?? ResignationExamStatus.pending,
    ),
  )

  const { setResetStep } = React.useContext(resignationCardContext)
  const queryClient = useQueryClient()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignation.exam",
  })

  const { mutateAsync: resetAso, isLoading: isResetingAso } =
    trpc.resignationCard.resetAso.useMutation()

  React.useEffect(() => {
    const canReset =
      !!card.resignationExam?.status &&
      card.resignationExam?.status !== ResignationExamStatus.pending
    setResetStep({
      canReset,
      confirmCallback: () => {
        resetAso(
          { cardId: card._id },
          {
            onSuccess: () => {
              dispatchToast({
                type: "success",
                content: t("resetSuccessMessage"),
              })
              queryClient.invalidateQueries(["resignation-card", card._id])
            },
          },
        )
      },
      isLoading: isResetingAso,
    })
  }, [card])

  const Content = React.useMemo(() => contents[examOption], [examOption])

  const alreadySentToEmployee = [
    ResignationExamStatus.sent,
    ResignationExamStatus.resent,
    ResignationExamStatus.filled,
  ].includes(card.resignationExam?.status ?? ResignationExamStatus.pending)

  const alreadyValidatedDocument =
    card.resignationExam?.validation?.status &&
    card.resignationExam?.validation?.status !== "waiting"

  const shouldShowOptions = !alreadySentToEmployee && !alreadyValidatedDocument

  return (
    <Container>
      {shouldShowOptions ? (
        <RadioContainer>
          {Object.values(ExamOptions)?.map((option) => (
            <BoxAdornment
              key={`box_option_${option}`}
              title={t(`options.${option}.title`)}
              description={t(`options.${option}.description`)}
              leftAdornment={
                <Radio
                  name="options-radio-buttons"
                  value={option}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setExamOption(e.target.value as ExamOptions)
                  }
                  checked={option === examOption}
                />
              }
            />
          ))}
        </RadioContainer>
      ) : (
        <></>
      )}

      {examOption !== ExamOptions.noApply && (
        <Divider orientation="horizontal" />
      )}

      <Content card={card} />
    </Container>
  )
}
