import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding-right: 12px;
`

export const IconCircleContainer = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;

  border: 1px solid #d1c7ce;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  > svg {
    width: 16px;
    height: 16px;
  }
`
