import { useParams } from "react-router-dom"
import { ResignationCardPage } from "../../../components"
import { InterviewContent } from "./InterviewContent"

const Interview = () => {
  const { id: cardId = "" } = useParams()

  return (
    <ResignationCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Desligamento", href: "/flows/resignation" },
        {
          name: "Entrevista de desligamento",
          href: `/flows/resignation/interview/${cardId}`,
        },
      ]}
      contents={{ default: InterviewContent }}
    />
  )
}

export default Interview
