import { gql } from "@apollo/client"
import { request } from "../client"
import { PresignedS3 } from "../../types"

export type GetMultipleS3File = {
  identifier: string
  path: string
  module: string
}

export type MultiplePresignedS3 = PresignedS3 & { identifier: string }

const GET_MULTIPLE_S3_FILE = gql`
  mutation GetMultipleS3File($params: [GetMultipleS3File]) {
    getMultipleS3File(params: $params) {
      identifier
      signedUrl
      key
      region
    }
  }
`

export const getMultipleS3File = async (params: GetMultipleS3File[]) => {
  const { getMultipleS3File } = await request(GET_MULTIPLE_S3_FILE, { params })
  return getMultipleS3File as MultiplePresignedS3[]
}
