import styled from "styled-components"
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

export const Container = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #8ac1fe;
`

export const TextContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: flex-start;
`

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.info[10]};
`

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.info[40]};
  padding: 12px;
  background: ${({ theme }) => theme.colors.feedback.info[90]};
  border-radius: 48px;
  height: 48px;
  width: 48px;
`
