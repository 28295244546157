import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { IFormConfig, IFormGroup } from "../../../types"
import styled from "styled-components"
import { useState } from "react"
import { ModalDocumentInstructions } from "../ModalDocumentInstructions"
import { useNavigate } from "react-router-dom"
import { Drawer } from "../Drawer"

export type ModalPreviewInstructionProps = {
  isOpen: boolean
  handleClose: () => void
  flowId: string
  formConfig: IFormConfig
  section: IFormGroup
}

const Instructions = styled.div`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 16px;
`

export const ModalPreviewInstruction = ({
  isOpen,
  handleClose,
  flowId,
  formConfig,
  section,
}: ModalPreviewInstructionProps) => {
  const [isInstructionModalOpen, setIsInstructionModalOpen] =
    useState<boolean>(false)

  const navigate = useNavigate()

  const handleExtension = (newExtensionId) => {
    if (newExtensionId !== formConfig.extensionId) {
      navigate(
        `/flows/settings/${flowId}/documents/${newExtensionId}/section/${section._id}`,
      )
    }
  }

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      zIndex={isInstructionModalOpen ? 1200 : 1400}
      title="Instruções de preenchimento"
      subtitle="Como encontrar ou preencher as informações deste documento"
    >
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            gap: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              gap: 24,
            }}
          >
            <Typography variant="headline8" variantColor="#53464F">
              {section.title}
            </Typography>

            <Instructions
              style={{
                maxHeight: "55vh",
                overflowY: "scroll",
                overflowX: "hidden",
                wordWrap: "break-word",
              }}
              dangerouslySetInnerHTML={{
                __html: section.fillInstruction?.html as string,
              }}
            />
          </div>

          <Button
            variant="secondary"
            size="large"
            style={{ width: "100%" }}
            onClick={() => setIsInstructionModalOpen(true)}
          >
            Editar instruções de preenchimento
            <Icons fill="transparent" name="IconPencil" />
          </Button>
        </div>

        {isInstructionModalOpen && (
          <ModalDocumentInstructions
            isOpen={isInstructionModalOpen}
            handleClose={() => setIsInstructionModalOpen(false)}
            handleExtension={handleExtension}
            formConfig={formConfig}
            section={section}
          />
        )}
      </>
    </Drawer>
  )
}
