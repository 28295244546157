import gql from "graphql-tag"
import { request } from "../client"
import { ResignationCard } from "../../types"

const GET_RESIGNATION_CARD = gql`
  query Query($cardId: ID!) {
    getResignationCardById(cardId: $cardId) {
      _id
      setupId
      name
      description
      status
      code
      color
      active
      position
      columnId
      employeeId
      companyId
      companyName
      flowId
      version
      statusHistory {
        status
        columnId
      }
      metadata
      checklists {
        columnId
        items {
          checked
          label
        }
      }
      resignationInterviewObservation
      asoDate
      asoResult
      lastWorkingDate
      resignationRequestDate
      serveNoticePeriod
      resignation
      resignationEmail
      resignationReason
      resignationType
      comments {
        message
        authorEmployeeId
        authorName
        sentDate
        timeSince
      }
      resignationInterviewFile {
        key
        path
        value
        type
      }
      asoFile {
        key
        path
        value
        type
      }
      resignationLetter {
        key
        path
        value
        type
      }
      employee
      emailLink {
        name
        columnId
        link
        expired
      }
      limitDates
      resignationExam {
        validation
        medicalReferral {
          key
          path
          value
          type
        }
        limitDate
        date
        status
        file {
          key
          path
          value
          type
        }
      }
    }
  }
`

export const getResignationCardById = async (cardId: string) => {
  const { getResignationCardById } = await request(GET_RESIGNATION_CARD, {
    cardId,
  })
  return getResignationCardById as ResignationCard
}
