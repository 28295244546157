import gql from "graphql-tag"
import { request } from "../client"
import { CompanyRoles } from "../../types"

const GET_ROLES_BY_COMPANY_ID = gql`
  query GetRolesByCompanyId($companyId: ID!) {
    getRolesByCompanyId(companyId: $companyId) {
      id
      name
      description
      cbo
    }
  }
`

export const getRolesByCompanyId = async ({
  companyId,
}: {
  companyId: string
}) => {
  const { getRolesByCompanyId } = await request(GET_ROLES_BY_COMPANY_ID, {
    companyId,
  })
  return getRolesByCompanyId as CompanyRoles[]
}
