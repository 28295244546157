import styled from "styled-components"
import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { MentionsInput } from "react-mentions"

export const Container = styled.div`
  width: 100%;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 12px;

  display: flex;
  flex-direction: column;
`

export const CommentaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-bottom: 15px;
`

export const StyledButton = styled(Button)`
  padding: 20px !important;
  border-radius: 50% !important;
`

export const CommentaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-itens: center;
  margin-bottom: 15px;
`

export const CommentaryContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  width: 100%;
  word-break: break-all;
`

export const CommentaryTitle = styled.div`
  color: #53464f;
  font-size: 16px;
  font-weight: 700;
`

export const CommentaryInfo = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #b9acb4;
  margin-left: 6px;
`

export const CommentaryText = styled.div`
  color: #83727d;
  font-size: 14px;
  font-weight: 400;
`

export const CommentaryMention = styled(CommentaryText)`
  color: #fe2b8f;
  font-weight: 600;
  margin: 0 0.2em;
`

export const InfoText = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  width: fit-content;
  font-size: 14px;
  color: #fe2b8f;
  border-bottom: 2px solid #fe2b8f;
  cursor: pointer;
`

export const TextareaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TextareaWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`

export const StyledMentionsInput = styled(MentionsInput)`
  width: 100%;
  height: 60px;
  margin-right: 15px;

  textarea {
    background-color: transparent;
    border: 2px solid var(--color-neutral-light4);
    border-radius: 12px;
    padding: 16px;
    overflow: auto !important;

    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      background: transparent;
      width: 6px;
      height: 48px;
    }

    :focus {
      outline: none !important;
      border: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }
`

export const mentionStyle = {
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        fontWeight: 600,
        backgroundColor: "#f20d7a33",
      },
    },
  },
}

export const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`
