import styled from "styled-components"
import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import { DeprecatedModal } from "../../../../../../../components"

export const StyledModal = styled(DeprecatedModal)`
  && {
    &.modal-custom-theme .modal-container {
      width: 100%;
      max-width: 871px;
      max-height: 95vh;
    }
    &.modal-custom-theme .modal-content-area {
      padding: 40px 112px;
    }
  }
`

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[80]};
`

export const CloseContainer = styled.div`
  display: flex;
  padding: 16px 40px 0px 40px;
  justify-content: flex-end;
`

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 112px 20px 112px;
  gap: 8px;
`

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: 24px 112px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Divider = styled.div`
  border: 1px solid #ebe6e9;
  height: 1px;
  width: 100%;
  margin: 40px 0;
`

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiPaper-root.MuiButtonBase-root {
      font-size: 14px;
      line-height: 16px;
  }
`

export const StyledButton = styled(Button)`
  && {
    max-width: 198px;
    width: 100%;
  }
`
