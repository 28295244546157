import gql from "graphql-tag"
import { request } from "../client"
import { CandidateSignInformation } from "../../types"

export const GET_AND_VALIDATE_SIGNING_CANDIDATES = gql`
  query GetAndValidateSigningCandidates(
    $params: getAndValidateSignCandidatesInput
  ) {
    getAndValidateSigningCandidates(params: $params) {
      flowCardId
      candidateId
      name
      email
      leader {
        _id
        name
        email
      }
      role {
        _id
        name
        email
      }
      department {
        _id
        name
        email
      }
      lastContractOutput {
        key
        path
        value
        type
      }
      isElegibleToSign
      ilegibleReason
    }
  }
`

export const getAndValidateSigningCandidates = async (
  flowCardsIds: string[],
) => {
  const response = await request<{
    getAndValidateSigningCandidates: CandidateSignInformation[]
  }>(GET_AND_VALIDATE_SIGNING_CANDIDATES, {
    params: { flowCardsIds },
  })
  return response.getAndValidateSigningCandidates
}
