import {
  Checkbox,
  Icons,
  LinkButton,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useFormik } from "formik"
import { SearchEmployeeField } from "./SearchEmployeeField"
import dispatchToast from "../../../utils/dispatchToast"
import React, { Ref, forwardRef, useImperativeHandle } from "react"
import * as yup from "yup"
import styled, { useTheme } from "styled-components"
import { validateEmail } from "src/utils"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export interface SigningInfo {
  id: string
  name: string
  email: string
  phone: string
}

export interface WitnessInfo extends Omit<SigningInfo, "id"> {
  isEmployee: boolean
  id?: string
}

export type WitnessFormRef = ReturnType<typeof useFormik<WitnessInfo>>

interface WitnessFormProps {
  title: string
  onRemove?: () => void
  oldValues?: Partial<WitnessInfo>
  isWitnessDuplicated: (employeeId: string) => boolean
}

export const WitnessForm = forwardRef(
  (props: WitnessFormProps, ref: Ref<WitnessFormRef>) => {
    const theme = useTheme()
    const formik = useFormik<WitnessInfo>({
      initialValues: {
        id: "",
        name: "",
        email: "",
        phone: "",
        isEmployee: true,
      },
      validate: (values) => {
        if (values.isEmployee) return {}

        let errors = {}
        if (!values.name || values.name === "")
          errors["name"] = "Campo obrigatório"

        if (!values.email) errors["email"] = "Campo obrigatório"
        if (!validateEmail(values.email)) errors["email"] = "E-mail inválido"

        return errors
      },
      onSubmit: () => {},
      validationSchema: yup.object({
        name: yup.string(),
        email: yup.string(),
        phone: yup.string().optional(),
      }),
      enableReinitialize: true,
      validateOnMount: true,
      validateOnBlur: true,
      validateOnChange: true,
    })

    useImperativeHandle(ref, () => formik)

    React.useEffect(() => {
      if (props.oldValues !== formik.values) {
        formik.setValues({
          id: "",
          name: "",
          email: "",
          phone: "",
          isEmployee: true,
          ...props.oldValues,
        })
      }
    }, [props.oldValues])

    const handleRemove = () => {
      if (props.onRemove) props.onRemove()
    }

    return (
      <Container>
        <Typography
          variant="headline8"
          weight={700}
          variantColor={theme.colors.neutral30}
        >
          {props.title}
        </Typography>
        {formik.values.isEmployee ? (
          <SearchEmployeeField
            value={formik.values?.name}
            onBlur={() => formik.setFieldTouched("name", true, true)}
            hasError={
              (formik.touched.name && Boolean(formik.errors.name)) ||
              (formik.touched.id && Boolean(formik.errors.id))
            }
            errorText={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ""
            }
            callback={(data) => {
              if (!data) {
                formik.resetForm()
                return
              }

              if (props.isWitnessDuplicated(data.id)) {
                formik.setFieldError(
                  "id",
                  "Colaborador já está como assinante em testemunha ou empresa",
                )
                formik.setFieldTouched("id", true, false)

                dispatchToast({
                  type: "error",
                  content:
                    "Colaborador já está como assinante em testemunha ou empresa",
                })
                return
              }
              formik.setValues({
                ...formik.values,
                name: data.name,
                id: data.id,
                email: data.email || "",
                isEmployee: true,
              })
            }}
          />
        ) : (
          <Container>
            <div>
              <TextField
                name="name"
                label="Nome"
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.touched.name && formik.errors.name?.toString()
                }
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div>
              <TextField
                name="email"
                label="E-mail"
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.touched.email && formik.errors.email?.toString()
                }
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div>
              <TextField
                name="phone"
                label="Celular"
                fullWidth
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={
                  formik.touched.phone && formik.errors.phone?.toString()
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </Container>
        )}
        <CheckboxContainer>
          <Checkbox
            name="isEmployee"
            value={!formik.values.isEmployee}
            onChange={(_, checked) => {
              formik.resetForm()
              formik.setFieldValue("isEmployee", !checked)
            }}
          />
          <Typography variant="body3">
            A testemunha não está cadastrada na plataforma
          </Typography>
        </CheckboxContainer>
        <LinkButton variant="secondary" onClick={handleRemove}>
          <Typography
            variant="body3"
            weight={700}
            variantColor={theme.colors.neutral80}
          >
            Remover
          </Typography>
          <Icons
            name="IconTrash"
            fill="transparent"
            size={16}
            color={theme.colors.neutral80}
          />
        </LinkButton>
      </Container>
    )
  },
)
