import { gql } from "graphql-request"
import { request } from "../client"
import { BackboneSection } from "../../types"

const GET_BACKBONE_SECTIONS = gql`
  query GetBackboneSections {
    getBackboneSections {
      _id
      name
      module
      type
      order
      companyId
      fields {
        _id
        name
        mask
        sensitive
        order
        component
        type
        options
        expires
      }
    }
  }
`

export const getBackboneSections = async () => {
  const { getBackboneSections } = await request(GET_BACKBONE_SECTIONS)
  return getBackboneSections as BackboneSection[]
}
