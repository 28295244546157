import { Carousel, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import { Container, StyledBigNumber } from "./styles"
import { FlowBigNumbers } from "../../../types"
import React, { CSSProperties } from "react"
import { useNavigate } from "react-router-dom"

type InsightsProps = {
  hasHeader?: boolean
  bigNumbers: FlowBigNumbers
  style?: CSSProperties
}

export const Insights = ({
  hasHeader = true,
  bigNumbers,
  style,
}: InsightsProps) => {
  const [elementSize, setElementSize] = React.useState<number>(0)
  const containerRef = React.useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  React.useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const qtyElementsShouldAppear = 3
        const pixelCarouselGap = 20
        const pixelToFixCarousel = 10

        const size =
          containerRef.current?.offsetWidth / qtyElementsShouldAppear -
          (pixelCarouselGap * 2) / qtyElementsShouldAppear -
          pixelToFixCarousel // this is bcause if the content width matches full container for some reason it add one more item do the carousel controller (ball)

        setElementSize(size)
      }
    }

    updateWidth()
    window.addEventListener("resize", updateWidth)

    return () => {
      window.removeEventListener("resize", updateWidth)
    }
  }, [])

  const ProposalPending = (
    <StyledBigNumber
      style={{ width: elementSize }}
      icon="IconMailForward"
      iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
      title="Envio de proposta pendente"
      value={bigNumbers.pendingProposalSubmission?.length ?? 0}
      button={<></>}
      orientation="horizontal"
    />
  )

  const ProposalExpired = (
    <StyledBigNumber
      style={{ width: elementSize }}
      icon="IconMailOff"
      iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
      title="Propostas com prazo expirado"
      value={bigNumbers.expiredProposals?.length ?? 0}
      button={<></>}
      orientation="horizontal"
    />
  )

  const ProposalRefused = (
    <StyledBigNumber
      style={{ width: elementSize }}
      icon="IconX"
      iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
      title="Propostas recusadas"
      value={bigNumbers.refusedProposals?.length ?? 0}
      button={<></>}
      orientation="horizontal"
    />
  )

  const SendDocsPending = (
    <StyledBigNumber
      style={{ width: elementSize }}
      icon="IconFileArrowRight"
      iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
      title="Solicitações de envio pendentes"
      value={bigNumbers.pendingDocumentsRequest?.length ?? 0}
      button={<></>}
      orientation="horizontal"
    />
  )

  const SendDocsExpired = (
    <StyledBigNumber
      style={{ width: elementSize }}
      icon="IconFileOff"
      iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
      title="Solicitações com prazo expirado"
      value={bigNumbers.expiredDocumentsRequest?.length ?? 0}
      button={<></>}
      orientation="horizontal"
    />
  )

  const SigningPending = (
    <StyledBigNumber
      style={{ width: elementSize }}
      icon="IconPencilPlus"
      iconColors={{ primary: "#F20D7A", secondary: "#FFE0EF" }}
      title="Assinaturas de contrato pendentes"
      value={bigNumbers.pendingSigning?.length ?? 0}
      button={
        bigNumbers.pendingSigning?.length > 0 ? (
          <LinkButton
            variant="primary"
            onClick={() => {
              const parsedIds = bigNumbers.pendingSigning.join(",")
              navigate(`/flows/actions/signing?flowCardsId=${parsedIds}`)
            }}
          >
            Assinar contratos
          </LinkButton>
        ) : (
          <></>
        )
      }
      orientation="horizontal"
    />
  )

  return (
    <Container ref={containerRef} style={style}>
      <Carousel
        arrows="bottom"
        gap={20}
        slides={[
          ProposalPending,
          ProposalExpired,
          ProposalRefused,
          SendDocsPending,
          SendDocsExpired,
          SigningPending,
        ]}
        header={
          hasHeader
            ? {
                showVisibilityToggle: true,
                title: "Insights",
              }
            : undefined
        }
        alignSlides="center"
      />
    </Container>
  )
}
