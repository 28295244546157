import styled, { css } from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

export const ColumnWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-right: 35px;
`

export const ContentWrapper = styled.div`
  width: 70%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
`

export const Column = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 15px;
  border: 1px solid #4a4e571a;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #6b5b66;
  margin: 5px 0;
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      color: #f20d7a;
      background-color: #ffe0ef;
      border: 1px solid #fe2b8f;
    `}
`
