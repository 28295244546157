import gql from "graphql-tag"
import { AdmissionalExam, Follower } from "../../types"
import { request } from "../client"

const HIRING_UPDATE_CARD = gql`
  mutation HiringUpdateCard($params: UpdateFlowCard) {
    hiringUpdateCard(params: $params) {
      _id
      setupId
      name
      description
      status
      code
      color
      active
      position
      columnId
      employeeId
      candidateId
      companyId
      modelProposedLetterId
      companyName
      flowId
      version
      statusHistory {
        status
        columnId
      }
      metadata
      checklists {
        columnId
        items {
          checked
          label
        }
      }
      hiringFlowId
      approvalStatus
      approvalCommentary
      updatedAt
      createdAt
      formConfigId
      formExtensionId
      emailLink {
        name
        columnId
        link
        expired
      }
      admissionalExam
      attachments
      roles
      comments {
        message
        authorEmployeeId
        authorName
        sentDate
        timeSince
      }
      candidate {
        _id
        active
        nationality
        jobId
        companyId
        employeeId
        registrationDate
        name
        socialName
        legalName
        documentNumber
        cnpj
        gender
        race
        maritalStatus
        schooling
        email
        phone
        isPCD
        disabilityType
        linkedinProfileUrl
        indicator
        jobUrl
        curriculum
        academicCareer
        professionalCareer
        extracurricularActivities
        documents
        proposalLetterInfo
        internship
      }
    }
  }
`

export const hiringUpdateCard = async (params: {
  name?: string | null
  description?: string | null
  formConfigId?: string | null
  formExtensionId?: string | null
  modelProposedLetterId?: string | null
  color?: string | null
  metadata?: any
  flowCardId: string | null
  approvalCommentary?: string | null
  approvalStatus?: "approved" | "refused" | null
  admissionalExam?: AdmissionalExam | null
  followers?: Follower[] | null
  attachments?: any
  emailLink?: any
}) => {
  const { hiringUpdateCard } = await request(HIRING_UPDATE_CARD, { params })
  return hiringUpdateCard
}
