import styled from "styled-components"

import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Accordion, Checkbox } from "@mui/material"

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
`

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
`

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: 1px solid #ebe6e9;
    border-radius: 8px;
    box-shadow: none;

    margin-bottom: 40px;

    &::before {
      background-color: transparent;
    }
  }
  .MuiButtonBase-root {
    padding: 40px;
  }
`

export const StyledContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;

  width: 48px;
  height: 48px;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 40px;
`

export const AccordionTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const AccordionDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  margin: 40px 0px;
`

export const FieldContainer = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ChecklistItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #53464f;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
`

export const Text = styled(Typography)<{ hasValue: boolean }>`
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.neutral[50] : "#C96C01"};
  font-weight: 400 !important;
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

export const StyledCheckbox = styled(Checkbox)`
  &.MuiButtonBase-root {
    padding: 0px;
    margin-right: 10px;
  }
`
