import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.m};
  margin: auto;
`

export const TabContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacings.xs4};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral90};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  gap: ${({ theme }) => theme.spacings.xs4};
`

export const TabButton = styled.button<{ active: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral40};
  width: 50%;
  height: 45px;
  border-radius: ${({ theme }) => theme.borders.radius.m};

  ${({ active }) =>
    active
      ? css`
          background-color: ${({ theme }) => theme.colors.secondary95};
          border: ${({ theme }) => theme.borders.width.xs2} solid
            ${({ theme }) => theme.colors.secondary70};
          color: ${({ theme }) => theme.colors.secondary40};
        `
      : null}
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`
