import { Dropzone } from "@flash-tecnologia/hros-web-ui-v2"
import { S3File } from "../../../types"
import { useEffect, useState } from "react"
import { UploadPreview } from "../UploadPreview"
import dispatchToast from "../../../utils/dispatchToast"
import { api } from "../../../api"
import { useMutation } from "@tanstack/react-query"
import { uploadFile } from "../../../utils"
import { PreviewLoading } from "../PreviewLoading"

interface UploadMultiplesProps {
  values?: S3File[] | null
  label: string
  folder: string
  onUpload?: (file: S3File) => Promise<void>
  onRemove?: (file: S3File) => Promise<void>
  module?: "hiring" | "employee-pii"
  accept: string[]
  maxSize?: number
  previewOrder?: "top" | "bottom"
  previewOptions?: Omit<
    React.ComponentProps<typeof UploadPreview>["options"],
    "handleRemove"
  >
}

export const UploadMultiples = ({
  label,
  values,
  accept,
  onUpload,
  folder,
  maxSize = 5242880,
  onRemove,
  module = "hiring",
  previewOrder = "top",
  previewOptions,
}: UploadMultiplesProps) => {
  const [localFiles, setLocalFiles] = useState<S3File[] | null | undefined>(
    values,
  )

  const { mutateAsync: mutateGetS3File } = useMutation(
    api.mutation.helper.getS3File,
  )

  useEffect(() => {
    if (values === localFiles) return
    setLocalFiles(values)
  }, [values])

  const handleRemove = (file: S3File) => {
    if (!localFiles) return
    const files = [...localFiles]
    const indexToBeRemoved = files.findIndex(
      (fileFromArray) => fileFromArray.key === file.key,
    )
    if (indexToBeRemoved === -1) {
      dispatchToast({
        content: "Algo ocorreu ao remover o arquivo",
        type: "error",
      })
      return
    }

    files.splice(indexToBeRemoved, 1)
    setLocalFiles(files)
    if (onRemove) onRemove(file)
  }

  const handleChange = async (files) => {
    try {
      const { file, id: fileName } = files[0]
      const uploadedFile = await uploadFile(folder, fileName, file, module)
      const s3Response = await mutateGetS3File({
        path: uploadedFile.path,
        module: module,
      })
      uploadedFile.value = s3Response.signedUrl

      setLocalFiles((prev) => (prev ? [...prev, uploadedFile] : [uploadedFile]))
      if (onUpload) onUpload(uploadedFile)
    } catch (err: any) {
      console.error(err)
      dispatchToast({
        content: "Algo aconteceu ao fazer o upload do arquivo.",
        type: "error",
      })
    }
  }

  if (!localFiles || localFiles?.length === 0) {
    return (
      <Dropzone
        accept={accept}
        title={label}
        multiple={false}
        onChange={handleChange}
        style={{ width: "100%" }}
        maxSize={maxSize}
        customPreview={() => <PreviewLoading />}
        onFileSizeError={() => {
          dispatchToast({
            content:
              "Este arquivo ultrapassa o limite máximo. Por favor, faça o upload de um arquivo menor.",
            type: "error",
          })
        }}
      />
    )
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
      }}
    >
      {localFiles?.map((file, idx) => {
        const isLastFile = localFiles.length === idx + 1
        return (
          <div
            key={idx}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isLastFile && previewOrder === "bottom" ? (
              <Dropzone
                accept={accept}
                title={label}
                multiple={false}
                onChange={handleChange}
                style={{ width: "100%", marginBottom: 24 }}
                maxSize={maxSize}
                customPreview={() => <PreviewLoading />}
                onFileSizeError={() => {
                  dispatchToast({
                    content:
                      "Este arquivo ultrapassa o limite máximo. Por favor, faça o upload de um arquivo menor.",
                    type: "error",
                  })
                }}
              />
            ) : (
              <></>
            )}
            <UploadPreview
              key={`${idx}_preview`}
              file={file}
              hasShareLink={false}
              options={{
                canRemove: true,
                canDownload: true,
                handleRemove: handleRemove,
                ...previewOptions,
              }}
            />

            {isLastFile && previewOrder === "top" ? (
              <Dropzone
                accept={accept}
                title={label}
                multiple={false}
                onChange={handleChange}
                style={{ width: "100%", marginTop: 24 }}
                maxSize={maxSize}
                customPreview={() => <PreviewLoading />}
                onFileSizeError={() => {
                  dispatchToast({
                    content:
                      "Este arquivo ultrapassa o limite máximo. Por favor, faça o upload de um arquivo menor.",
                    type: "error",
                  })
                }}
              />
            ) : (
              <></>
            )}
          </div>
        )
      })}
    </div>
  )
}
