import {
  StyledAccordion,
  Title,
  StyledContainerIcon,
  AccordionTitle,
  AccordionDescription,
  Text,
} from "./styles"

import { Icons, Tag, Typography, dayjs } from "@flash-tecnologia/hros-web-ui-v2"

import { AccordionDetails, AccordionSummary } from "@mui/material"
import { UploadPreview } from "../../../components/common/DeprecatedUploadPreview"

export const SignatureContent = ({ contract }) => {
  return (
    <StyledAccordion
      style={{
        marginBottom: "24px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <StyledContainerIcon>
            <Icons name="IconChevronDown" fill="transparent" />
          </StyledContainerIcon>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            marginRight: "20px",
          }}
        >
          <AccordionTitle variant="headline7">
            Assinatura de rescisão
          </AccordionTitle>
          <AccordionDescription variant="body4">
            Faça o envio dos documentos rescisórios e acompanhe o status da
            assinatura.
          </AccordionDescription>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px 44px 40px 44px" }}>
        {contract?.signature?.length ? (
          <Title variant={"headline8"}>Status</Title>
        ) : (
          <></>
        )}

        <div style={{ marginTop: "24px" }}>
          {contract?.signature?.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "24px 32px",
                  border: "1px solid #EBE6E9",
                  borderRadius: "8px",
                  marginBottom: "24px",
                  width: "100%",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignSelf: "flex-start",
                    }}
                  >
                    <Text
                      variant="body4"
                      hasValue={!!item?.name || !!item?.companyName}
                      style={{ fontWeight: 700 }}
                    >
                      {item?.name ?? item?.companyName ?? ""}
                    </Text>
                    <Tag variant="disabled" style={{ marginLeft: "5px" }}>
                      {item?.type === "person" ? "Pessoa" : "Empresa"}
                    </Tag>
                  </div>
                  <Text
                    variant="body4"
                    hasValue={!!item?.email}
                    style={{ fontWeight: 700 }}
                  >
                    {item.email}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                      color: item?.status === "Signed" ? "#21A38A" : "#C96C01",
                    }}
                  >
                    <Icons
                      name={
                        item?.status === "Signed" ? "IconCheck" : "IconClock"
                      }
                      fill="transparent"
                      size={16}
                      style={{
                        marginRight: "8px",
                        stroke:
                          item?.status === "Signed" ? "#21A38A" : "#C96C01",
                      }}
                    />
                    <Typography
                      variant="body4"
                      style={{
                        color:
                          item?.status === "Signed" ? "#21A38A" : "#C96C01",
                      }}
                    >
                      {item?.status === "Signed"
                        ? `Assinado em: ${
                            item?.signedAt
                              ? dayjs(item?.signedAt).format(
                                  "DD/MM/YYYY [às] HH:mm",
                                )
                              : ""
                          }`
                        : "Aguardando assinatura"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <Icons
                      name="IconDevices"
                      fill="transparent"
                      size={16}
                      style={{ marginRight: "8px" }}
                    />

                    <Text variant="body4" hasValue={!!item?.ip}>
                      IP: {item?.ip || "Não preenchido"}
                    </Text>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div style={{ marginTop: "24px" }}>
          {contract?.contractOutput?.value ? (
            <UploadPreview
              uploadItem={contract?.contractOutput || []}
              tag={{
                label:
                  contract?.status === "Pending" ? "Não assinado" : "Assinado",
                variant: contract?.status === "Pending" ? "error" : "primary",
              }}
              onRemove={null}
              hasShareLink
            />
          ) : (
            <></>
          )}
        </div>
      </AccordionDetails>
    </StyledAccordion>
  )
}
