import gql from "graphql-tag"
import { request } from "../client"

const CHANGE_EMPLOYEE_IN_ORGCHART = gql`
  mutation changeEmployeeInOrgchart($params: changeEmployeeInput) {
    changeEmployeeInOrgchart(params: $params)
  }
`

export const changeEmployeeInOrgchart = async (params: {
  employeeId: string
  levelToSave: number
  personToBeReplaced: string
  keepChildrens: boolean
}) => {
  const { changeEmployeeInOrgchart } = await request(
    CHANGE_EMPLOYEE_IN_ORGCHART,
    { params },
  )
  return changeEmployeeInOrgchart
}
