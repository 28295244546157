import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { DownloadPanel } from "../../../../../components"
import styled, { useTheme } from "styled-components"
import React, { CSSProperties } from "react"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 32px 40px;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
`

type ErrorListProps = {
  errors: string[]
  style?: CSSProperties
}

export const ErrorList = ({ errors, style }: ErrorListProps) => {
  const theme = useTheme()

  const textBlob = React.useMemo(() => {
    const content = errors.join("\n")
    return new Blob([content], { type: "text/plain" })
  }, [])

  return (
    <Container style={style}>
      <Typography variant="headline8" variantColor={theme.colors.neutral[20]}>
        Lista de erros
      </Typography>
      <Typography
        variant="body4"
        variantColor={theme.colors.neutral[50]}
        style={{ marginTop: 5 }}
      >
        Consulte o arquivo abaixo para mais detalhes. Após realizar a correção,
        envie o arquivo novamente para finalizar o cadastro dos candidatos.
      </Typography>

      <DownloadPanel
        download={textBlob}
        fileName="Lista_de_erros.txt"
        icon="IconFileInvoice"
        variant="error"
        style={{ maxWidth: 450, marginTop: 30 }}
      />
    </Container>
  )
}
