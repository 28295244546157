import {
  Button,
  IconButton,
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { CSSProperties, useContext, useEffect, useRef, useState } from "react"
import { IFormConfig, IFormGroup } from "../../../types"
import { useTranslation } from "react-i18next"
import { ModalDocumentInstructions } from "../../common/ModalDocumentInstructions"
import { useNavigate } from "react-router-dom"
import { Container, Instruction } from "./styles"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { api } from "../../../api"
import { globalContext } from "../../../context"
import dispatchToast from "../../../utils/dispatchToast"
import { ConfirmationModal } from "../../common/ConfirmationModal"

interface InstructionCardProps {
  flowId: string
  formConfig: IFormConfig
  section: IFormGroup
  style?: CSSProperties
}

export const InstructionCard = ({
  flowId,
  section,
  formConfig,
  style = {},
}: InstructionCardProps) => {
  const [isInstructionModalOpen, setIsInstructionModalOpen] =
    useState<boolean>(false)
  const [confirmationDeleteInstruction, setConfirmationDeleteInstruction] =
    useState<boolean>(false)
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(false)

  const navigate = useNavigate()
  const instructionRef = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()
  const { companyId = '' } = useContext(globalContext)

  const { mutateAsync: sendFormConfigExtension, isLoading } = useMutation(
    api.mutation.hiring.modelDocuments.sendFormConfigExtension,
  )

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  useEffect(() => {
    const container = instructionRef.current
    if (container && container?.clientHeight >= 70) {
      setIsOverflowing(true)
    }
  }, [instructionRef])

  const handleExtension = (newExtensionId) => {
    if (newExtensionId !== formConfig.extensionId) {
      navigate(
        `/flows/settings/${flowId}/documents/${newExtensionId}/section/${section._id}`,
      )
    }
  }

  const handleDeleteInstruction = async () => {
    if (!formConfig._id) {
      dispatchToast({
        type: "error",
        content: "Não foi possível atualizar a instrução",
      })
      return
    }
    const updatedSection: IFormGroup = {
      ...section,
      fillInstruction: {
        html: "",
        json: "",
      },
    }

    const clonedFormGroups = [...formConfig.formGroups]
    const sectionIndexPosition = clonedFormGroups.findIndex(
      (s) => s._id === section._id,
    )
    clonedFormGroups.splice(sectionIndexPosition, 1, updatedSection)

    await sendFormConfigExtension(
      {
        companyId,
        baseId: formConfig._id,
        name: formConfig.name,
        formGroups: clonedFormGroups,
      },
      {
        onSuccess: async ({ _id }) => {
          dispatchToast({
            type: "success",
            content: "Instrução deletada com sucesso!",
          })

          handleExtension(_id)

          await queryClient.invalidateQueries(["form-config"])
        },
        onError: (err: any) => {
          console.log(err)
          dispatchToast({
            type: "error",
            content: "Não foi possível atualizar a instrução",
          })
        },
      },
    )
  }

  return (
    <>
      {!section?.fillInstruction?.html ? (
        <Button
          variant="primary"
          size="large"
          style={{
            width: "100%",
            ...style,
          }}
          onClick={() => setIsInstructionModalOpen(true)}
        >
          {t("documentTemplate.fields.instructionAddButton")}
          <Icons name={"IconPlus"} />
        </Button>
      ) : (
        <Container style={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="headline8" variantColor="#53464F">
              Instruções de preenchimento
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                gap: 24,
              }}
            >
              <IconButton
                variant="line"
                size="medium"
                icon="IconPencil"
                onClick={() => setIsInstructionModalOpen(true)}
              />
              <IconButton
                variant="line"
                size="medium"
                icon="IconTrash"
                onClick={() => setConfirmationDeleteInstruction(true)}
              />
            </div>
          </div>

          <Instruction
            ref={instructionRef}
            showMore={showMore}
            dangerouslySetInnerHTML={{
              __html: section.fillInstruction?.html || "",
            }}
            style={{ marginTop: 16 }}
          />

          {isOverflowing && (
            <LinkButton
              variant="primary"
              onClick={() => setShowMore((prev) => !prev)}
              style={{ marginTop: 8 }}
            >
              {showMore ? "Ver menos" : "Ver mais"}
            </LinkButton>
          )}
        </Container>
      )}

      {isInstructionModalOpen && (
        <ModalDocumentInstructions
          isOpen={isInstructionModalOpen}
          handleClose={() => setIsInstructionModalOpen(false)}
          handleExtension={handleExtension}
          formConfig={formConfig}
          section={section}
        />
      )}

      {confirmationDeleteInstruction && (
        <ConfirmationModal
          isOpen={confirmationDeleteInstruction}
          onClose={() => setConfirmationDeleteInstruction(false)}
          title="Tem certeza que deseja excluir as instruções?"
          description="Essa ação não poderá ser desfeita."
          confirmButton={{
            onClick: handleDeleteInstruction,
            isLoading,
            text: "Confirmar exclusão",
            icon: "IconTrash",
          }}
        />
      )}
    </>
  )
}
