export const externalRoutes = {
  employeesProfile: {
    path: "/employees/:employeeId/profile",
  },
  employeesManage: {
    path: "/employees/:employeeId/manage",
  },
  settingsSectionFields: {
    path: "/settings/sections-fields",
  },
  authenticationLogin: {
    path: "/authentication/login",
  },
  home: {
    path: "/home",
  },
}
