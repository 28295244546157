import React, { useState } from "react"
import { Container } from "./styles"
import { Skeleton } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { api } from "../../../api"
import { Contract } from "../../../types"
import { ResignationContentProps } from "../../../components/screen/CardPage/types"
import { RequestSignature } from "./RequestSignature"
import { ContractSent } from "./ContractSent"

export const Content: React.FC<ResignationContentProps> = ({ card }) => {
  const [contract, setContract] = useState<Partial<Contract>>()

  const { isInitialLoading: isContractLoading } = useQuery(
    ["contract", card._id],
    () => api.query.hiring.contract.getContractById(card._id),
    {
      enabled: !!card,
      refetchOnWindowFocus: false,
      onSuccess: (contract) => {
        setContract(contract)
      },
      retry: false,
    },
  )

  const hasSentSignatureRequest = !!contract?._id

  const handleChangeContract = (partialContract: Partial<Contract>) =>
    setContract((prev) => {
      if (prev === undefined) {
        return {
          ...partialContract,
        }
      }

      return { ...prev, ...partialContract }
    })

  if (isContractLoading) {
    return (
      <Container>
        <Skeleton
          variant="rectangular"
          style={{
            width: "100%",
            height: "300px",
          }}
        />
      </Container>
    )
  }

  return (
    <Container>
      {hasSentSignatureRequest ? (
        <ContractSent card={card} contract={contract as Contract} />
      ) : (
        <RequestSignature
          card={card}
          contract={contract}
          setContract={handleChangeContract}
        />
      )}
    </Container>
  )
}
