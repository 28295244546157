import { IDocumentValidation } from "./hiring"

export interface IFormConfig {
  _id?: string
  name: string
  formGroups: IFormGroup[]
  companyId?: string
  baseId?: string
  extensionId?: string
  type: "base" | "extension"
}

export interface IFormGroup {
  _id?: string
  title?: string
  order?: number
  enabled?: boolean
  category: "employee" | "dependent"
  type?: "base" | "extension"
  description: string
  fields?: IFieldConfig[]
  fillInstruction?: {
    html: string
    json?: string
  }
}

export enum FieldType {
  Text = "text",
  Number = "number",
  Date = "date",
  Boolean = "boolean",
  File = "file",
  Array = "array",
}

export enum ComponentEnum {
  ShortText = "shortText",
  LongText = "longText",
  Number = "number",
  Attachment = "attachment",
  Link = "link",
  DatePicker = "datePicker",
  TimePicker = "timePicker",
  Select = "select",
  MultiSelect = "multiSelect",
  Checkbox = "checkbox",
}

export interface IFieldConfig {
  fieldId: string
  sectionId: string
  order?: number
  fieldType?: FieldType
  options?: any[]
  component?: ComponentEnum
  placeholder?: string
  fieldRule?: IFieldRule
  type: "base" | "extension"
  enabled: boolean
}

export interface IFieldRule {
  validations?: IComparison[]
  enable?: IComparison[]
  required?: boolean
  fieldId: string
}

export enum ComparisonType {
  Equal = "equal",
  NotEqual = "notEqual",
  GreaterThan = "greaterThan",
  GreaterThanOrEqual = "greaterThanOrEqual",
  LesserThan = "lesserThan",
  LesserThanOrEqual = "lesserThanOrEqual",
  Includes = "includes",
  NotIncludes = "notIncludes",
  Exists = "exists",
  NotExists = "notExists",
  Or = "or",
  And = "and",
}

export interface IComparison {
  _id?: string
  compared?: ICompareField
  comparedTo?: ICompareField
  comparisonType: ComparisonType
  comparisons?: IComparison[]
}

export interface ICompareField {
  _id?: string
  name?: string
  sensitive?: boolean
  type: FieldType
  isFixedValue?: boolean
  value?: any
}

export interface IFormConfigList {
  extensionId: string
  name: string
  numberOfGroups: number
  _id: string
  default: boolean
}

export interface BackboneField {
  _id: string
  name: string
  mask?: any
  sensitive: boolean
  order: number
  component: string
  type: string
  options?: any
  expires?: any
}

export interface BackboneSection {
  _id: string
  name: string
  module: string
  type: string
  order: number
  companyId?: string
  fields: BackboneField[]
}

export interface FieldValue extends IFieldConfig {
  value?: any
  candidateId: string
}

export interface ConfirmationData {
  candidateId: string
  formConfigId: string
  extensionId?: string
  candidateFields: FieldValue[]
  documentFields: ConfirmationDocumentsWithValue[]
  dependents?: ConfirmationDependent[]
}

export interface ConfirmationDependent {
  _id: any
  name: string
  active: boolean
  kinship: string
  birthDate: string
  candidateId: string
  formConfig: string
  extensionId: string
  documents: ConfirmationDocumentsWithValue[]
}

export interface ConfirmationDocumentsWithValue {
  _id: string
  formConfigId: string
  extensionId?: string
  name: string
  title: string
  description?: string
  enabled: boolean
  fields: FieldValue[]
  validation: IDocumentValidation
}
