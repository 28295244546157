import styled from "styled-components"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

const PageHeaderSubcontainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const PageHeaderOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const MenuText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export {
  PageHeaderSubcontainer,
  PageHeaderOptionsContainer,
  FlexColumn,
  MenuText,
}
