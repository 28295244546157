import gql from "graphql-tag"
import { request } from "../client"
import { Candidate, Leader } from "../../types"

const UPDATE_CANDIDATE_BY_ID = gql`
  mutation UpdateCandidateById($params: UpdateCandidateByIdInput) {
    updateCandidateById(params: $params) {
      _id
    }
  }
`

export const updateCandidateById = async (params: {
  candidateId: string
  fields: {
    name?: string
    socialName?: string
    legalName?: string
    nationality?: string
    documentNumber?: string
    cnpj?: string
    registrationDate?: string
    corporateEmail?: string
    inviteDate?: string
    birthDate?: string
    gender?: string
    maritalStatus?: string
    schooling?: string
    race?: string
    email?: string
    phone?: string
    isPCD?: boolean
    termAccepted?: boolean
    disabilityType?:
      | "Deficiência física"
      | "Deficiência visual"
      | "Deficiência auditiva"
      | "Deficiência mental"
      | "Deficiência intelectual"
      | "Transtorno do espectro autista"
    linkedinProfileUrl?: string
    jobUrl?: string
    leader?: Leader
    curriculum?: {
      type: string
      path?: string
      key?: string
    }
    academicCareer?: {
      course?: string
      institution?: string
      graduationLevel?: string
      startDate?: string
      endDate?: string
    }[]
    professionalCareer?: {
      organization?: string
      role?: string
      assignments?: {
        html: string
        json: string
      }
      startDate?: string
      endDate?: string
    }[]
    extracurricularActivities?: {
      organization?: string
      role?: string
      assignments?: {
        html: string
        json: string
      }
      startDate?: string
      endDate?: string
    }[]
    proposalLetterInfo?: {
      aboutCompany?: {
        key: string
        path: string
        type: string
      }[]
      benefits?: {
        key: string
        path: string
        type: string
      }[]
      assignments?: string
      role?: {
        _id: string
        name: string
      }
      department?: {
        _id: string
        name: string
      }
      group?: {
        _id: string
        name: string
      }
      hiringType?: string
      salary?: number
      variableSalary?: string
      hiringDate?: string
      proposalStatus?: string
    }
    internship?: {
      institutionName?: string
      courseName?: string
      startDate?: string
      endDate?: string
      workship?: string
      workload?: string
    }
    externalCardDeliveryId?: string
    benefitsIds?: string[]
    workshiftId?: string
    probationPeriod?: {
      firstDate?: string
      secondDate?: string
    }
  }
}) => {
  const { updateCandidateById } = await request(UPDATE_CANDIDATE_BY_ID, {
    params,
  })
  return updateCandidateById as Candidate
}
