import {
  Icons,
  Menu,
  PageContainer,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Grid } from "@mui/material"
import styled from "styled-components"

const MandatoryText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600 !important;
  margin-left: 12px;
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-top: 8px;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`

const StyledGrid = styled(Grid)``

const StyledMenu = styled(Menu)`
  && {
    &.menu-base-custom-theme {
      ul {
        &.MuiList-root {
          padding: 12px;

          li {
            padding: 16px;
            margin-bottom: 0px;

            &.MuiButtonBase-root {
              div {
                text-align: start;
              }
              &:hover {
                color: ${({ theme }) => theme.colors.neutral.light.pure};
                background-color: ${({ theme }) => theme.colors.primary};
                border-radius: 0px;
                p {
                  color: ${({ theme }) => theme.colors.neutral.light.pure};
                }
              }
              &:last-child {
                margin-bottom: 0px;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
`

const OptionText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const StyledFlex = styled.div`
  display: flex;
`

const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
`

const StyledTextButton = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700 !important;
`

const StyledSectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledIconsHoverable = styled(Icons)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const StyledFieldContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  :last-child {
    margin-bottom: 0px;
  }
`

export {
  Title,
  StyledMenu,
  Description,
  StyledPageContainer,
  StyledGrid,
  StyledTitle,
  StyledDescription,
  FlexBetween,
  OptionText,
  StyledFlexCenter,
  StyledTextButton,
  StyledSectionContainer,
  StyledSectionTitle,
  StyledTextField,
  StyledIconsHoverable,
  MandatoryText,
  StyledFieldContainer,
}
