import { HiringTemplateField, HiringTemplateFields } from "../types"

const PJFields: HiringTemplateField[] = [
  {
    id: "pj_employee_professional_fields",
    title: "Dados do contratado",
    fields: [
      {
        id: "razao_social_contratado",
        value: "Razão social do contratado",
        label: "Razão social",
      },
      {
        id: "numero_do_cnpj_contratado",
        value: "Número do CNPJ do contratado",
        label: "Número do CNPJ",
      },
      {
        id: "endereco_completo_contratado",
        value: "Endereço completo do contratado",
        label: "Endereço completo",
      },
      {
        id: "dados_bancarios_contratado",
        value: "Dados bancários do contratado",
        label: "Dados bancários",
      },
    ],
  },
  {
    id: "pj_employee_personal_fields",
    title: "Dados pessoais do contratado",
    fields: [
      {
        id: "nome_completo_contratado",
        value: "Nome completo do contratado",
        label: "Nome completo",
      },
      {
        id: "email_contratado",
        value: "E-mail do contratado",
        label: "E-mail",
      },
      {
        id: "numero_cpf_rg_contratado",
        value: "Número do CPF/RG do contratado",
        label: "Número do CPF/RG",
      },
      {
        id: "data_nascimento_contratado",
        value: "Data de nascimento do contratado",
        label: "Data de nascimento",
      },
      {
        id: "estado_civil_contratado",
        value: "Estado civil do contratado",
        label: "Estado civil",
      },
      {
        id: "data_nascimento_contratado",
        value: "Data de nascimento do contratado",
        label: "Data de nascimento",
      },
    ],
  },
  {
    id: "pj_contract_fields",
    title: "Dados contratuais",
    fields: [
      { id: "cargo", value: "Cargo", label: "Cargo" },
      { id: "departamento", value: "Departamento", label: "Departamento" },
      { id: "data_inicio", value: "Data de início", label: "Data de início" },
      {
        id: "valor_servico",
        value: "Valor do serviço",
        label: "Valor do serviço",
      },
    ],
  },
  {
    id: "pj_employer_fields",
    title: "Dados do contratante",
    fields: [
      {
        id: "razao_social_contratante",
        value: "Razão social do contratante",
        label: "Razão social",
      },
      {
        id: "numero_do_cnpj_contratante",
        value: "Número do CNPJ do contratante",
        label: "Número do CNPJ",
      },
      {
        id: "endereco_completo_contratante",
        value: "Endereço completo do contratante",
        label: "Endereço completo",
      },
    ],
  },
]

const InternshipFields: HiringTemplateField[] = [
  {
    id: "internship_candidate_personal_fields",
    title: "Dados do candidato",
    fields: [
      {
        id: "nome_completo_candidato",
        value: "Nome completo do candidato",
        label: "Nome completo",
      },
      { id: "email_candidato", value: "E-mail do candidato", label: "E-mail" },
      {
        id: "numero_rg_candidato",
        value: "Número do RG do candidato",
        label: "Número do RG",
      },
      {
        id: "numero_cpf_candidato",
        value: "Número do CPF do candidato",
        label: "Número do CPF",
      },
      {
        id: "data_nascimento_candidato",
        value: "Data de nascimento do candidato",
        label: "Data de nascimento",
      },
      {
        id: "estado_civil_candidato",
        value: "Estado civil do candidato",
        label: "Estado civil",
      },
      {
        id: "endereco_completo_candidato",
        value: "Endereço completo do candidato",
        label: "Endereço completo",
      },
      {
        id: "dados_bancarios_candidato",
        value: "Dados bancários do candidato",
        label: "Dados bancários",
      },
    ],
  },
  {
    id: "internship_candidate_professional_fields",
    title: "Dados profissionais do candidato",
    fields: [
      { id: "cargo", value: "Cargo", label: "Cargo" },
      { id: "departamento", value: "Departamento", label: "Departamento" },
      { id: "salario", value: "Salário", label: "Salário" },
      {
        id: "horario_de_estagio",
        value: "Horário de estágio",
        label: "Horário de estágio",
      },
      {
        id: "total_horas_mensais",
        value: "Total de horas mensais",
        label: "Total de horas mensais",
      },
      { id: "data_inicio", value: "Data de início", label: "Data de início" },
    ],
  },
  {
    id: "internship_educational_institution_fields",
    title: "Dados relacionados à instituição de ensino",
    fields: [
      {
        id: "nome_instituicao_ensino",
        value: "Nome da instituição de ensino",
        label: "Nome da instituição de ensino",
      },
      {
        id: "nome_curso",
        value: "Nome do curso",
        label: "Nome do curso",
      },
      {
        id: "data_prevista_termino",
        value: "Data prevista de término",
        label: "Data prevista de término",
      },
    ],
  },
]

const CLTFields: HiringTemplateField[] = [
  {
    id: "clt_candidate_personal_fields",
    title: "Dados do candidato",
    fields: [
      {
        id: "nome_completo_candidato",
        value: "Nome completo do candidato",
        label: "Nome completo",
      },
      { id: "email_candidato", value: "E-mail do candidato", label: "E-mail" },
      {
        id: "numero_rg_candidato",
        value: "Número do RG do candidato",
        label: "Número do RG",
      },
      {
        id: "numero_cpf_candidato",
        value: "Número do CPF do Candidato",
        label: "Número do CPF",
      },
      {
        id: "data_nascimento_candidato",
        value: "Data de nascimento do candidato",
        label: "Data de nascimento",
      },
      {
        id: "estado_civil_candidato",
        value: "Estado civil do candidato",
        label: "Estado civil",
      },
      {
        id: "endereco_completo_candidato",
        value: "Endereço completo do candidato",
        label: "Endereço completo",
      },
      {
        id: "dados_bancarios_candidato",
        value: "Dados bancários do candidato",
        label: "Dados bancários",
      },
    ],
  },
  {
    id: "clt_candidate_professional_fields",
    title: "Dados profissionais do candidato",
    fields: [
      { id: "cargo", value: "Cargo", label: "Cargo" },
      { id: "departamento", value: "Departamento", label: "Departamento" },
      { id: "salario", value: "Salário", label: "Salário" },
      {
        id: "data_admissao",
        value: "Data de admissão",
        label: "Data de admissão",
      },
      {
        id: "ctps",
        value: "CTPS",
        label: "CTPS",
      },
      {
        id: "pis",
        value: "PIS",
        label: "PIS",
      },
      {
        id: "primeiro_periodo_de_experiencia",
        value: "Primeiro período de experiência",
        label: "Primeiro período de experiência",
      },
      {
        id: "segundo_periodo_de_experiencia",
        value: "Segundo período de experiência",
        label: "Segundo período de experiência",
      },
    ],
  },
]

export const hiringFields: HiringTemplateFields = {
  clt: CLTFields,
  pj: PJFields,
  internship: InternshipFields,
}
