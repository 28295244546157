import styled from "styled-components"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

const MainContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
`

const StyledDescription = styled(Typography)`
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const FormWrapper = styled.div`
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  padding: 32px;
  width: 100%;
`

export { MainContainer, StyledSubtitle, StyledDescription, FormWrapper }
