import { useEffect, useState } from "react"

import { Dropzone } from "@flash-tecnologia/hros-web-ui-v2"
import { DropzoneArea } from "./styles"

export const DeprecatedUpload = ({
  label,
  onChange,
  accept,
  customPreview,
  multiple = false,
  maxSize,
  onFileSizeError,
}: {
  label: any
  onChange?: (file: { name: string; file: File }) => void
  accept?: any
  customPreview?: any
  multiple?: boolean
  maxSize?: number
  onFileSizeError?: any
}) => {
  const [file, setFile] = useState<{ [key: string]: any }>({})

  useEffect(() => {
    if (Object.keys(file).length && onChange) {
      onChange({ name: file?.name, file: file?.file })
      setFile({})
    }
  }, [file])

  const handleDropFile: React.ComponentProps<
    typeof Dropzone
  >["onChange"] = async (acceptedFiles) => {
    const file = acceptedFiles?.[0]?.file || ""
    const name = acceptedFiles?.[0]?.id || ""

    if (file) setFile({ name, file })
  }

  return (
    <DropzoneArea>
      <Dropzone
        accept={accept}
        title={label}
        multiple={multiple}
        onChange={handleDropFile}
        customPreview={customPreview}
        style={{ width: "100%%" }}
        maxSize={maxSize}
        onFileSizeError={onFileSizeError}
      />
    </DropzoneArea>
  )
}
