import gql from "graphql-tag"
import { request } from "../client"

const VERIFY_MODEL_CONTRACT = gql`
  query VerifyModelContract($params: IVerifyModelContract) {
    verifyModelContract(params: $params) {
      ids
    }
  }
`
export const verifyModelContract = async (params: {
  modelContracts: string[]
  candidateId: string
}) => {
  const { verifyModelContract } = await request(VERIFY_MODEL_CONTRACT, {
    params,
  })
  return (verifyModelContract || { ids: [] }).ids || []
}
