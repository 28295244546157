import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const ChecklistContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const ChecklistItem = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
`

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #53464f;
  font-size: 16px;
  font-weight: 600;
`

export const ChecklistIcon = styled(Icons)`
  color: #9f939b;
  margin: 0px 3px;
  cursor: pointer;
`

export const ChecklistActionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

export const EmptyStateContainer = styled.div`
  background-color: #f8f6f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
  padding: 25px;
`
