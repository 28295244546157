import { IResignationContext } from "./FlowContext"
import { IGlobalContext } from "./GlobalContext"

export enum EnumGlobalActions {
  SET_EMPLOYEE = "SET_EMPLOYEE",
  SET_LOGGED_USER_DATA = "SET_LOGGED_USER_DATA",
  SET_MODAL_STATUS = "SET_MODAL_STATUS",
  GET_GROUPS_COMPANY = "GET_GROUPS_COMPANY",
}

export enum EnumResignationActions {
  SET_LEADERS = "SET_LEADERS",
}

export interface IActions<ActionEnum> {
  type: ActionEnum
  payload?: any
}

export const globalReducer = (
  state: IGlobalContext,
  action: IActions<EnumGlobalActions>,
): IGlobalContext => {
  switch (action.type) {
    case EnumGlobalActions.SET_MODAL_STATUS:
      return {
        ...state,
        modal: { ...action.payload },
      }
    case EnumGlobalActions.SET_EMPLOYEE:
      return {
        ...state,
        employee: action.payload,
      }
    case EnumGlobalActions.SET_LOGGED_USER_DATA:
      return {
        ...state,
        companyId: action.payload?.companyId,
        employeeId: action.payload?.employeeId,
        economicGroupId: action.payload?.economicGroupId,
        role: action.payload?.role,
      }
    default:
      throw new Error("Error in context reducer")
  }
}

export const resignationReducer = (
  state: IResignationContext,
  action: IActions<EnumResignationActions>,
): IResignationContext => {
  switch (action.type) {
    case EnumResignationActions.SET_LEADERS:
      return {
        ...state,
        leaders: action.payload,
      }
    default:
      throw new Error("Error in context reducer")
  }
}
