import { useParams } from "react-router-dom"
import { HiringCardPage } from "../../../components"

import GenericProposedLetter from "./GenericProposedLetter"
import { HiringContent } from "../../../components/screen/CardPage/types"

const ProposalPage = () => {
  const { id: cardId = "" } = useParams()

  const contents: HiringContent = {
    clt: GenericProposedLetter,
    pj: GenericProposedLetter,
    internship: GenericProposedLetter,
  }

  return (
    <HiringCardPage
      cardId={cardId}
      pageHistory={[
        { name: "Admissão", href: "/flows/hiring" },
        {
          name: "Carta proposta",
          href: `/flows/hiring/proposal/${cardId}`,
        },
      ]}
      contents={contents}
    />
  )
}

export default ProposalPage
