import { Menu, Tag } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

import { lightenDarkenColor } from "../../../utils"

const HiddenContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  user-select: none;
  writing-mode: vertical-rl;
  margin-right: 6px;
  padding: 5px;
  padding-top: 16px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #ebe6e9;
  border-radius: 6px;

  border-top: 8px solid
    ${({ theme, color }) =>
      color || lightenDarkenColor(theme.colors.primary, 180)};

  :first-child {
    margin-left: 6px;
  }
`

const ContainerInline = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  height: 800px;

  position: relative;
  overflow: auto hidden;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    background: transparent;
    height: 8px;
  }
`

const SubContainer = styled.div`
  padding-right: 6px;
`

const ColumnContainer = styled.div`
  height: 100%;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  margin-right: 6px;

  max-width: 316px;

  :last-child {
    margin-right: 0px;
  }
  overflow: hidden;
`

const HeaderColumnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`

const SubColumnContainer = styled.div<{ isColumnDraggin: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  background-color: ${({ isColumnDraggin, theme }) =>
    isColumnDraggin
      ? lightenDarkenColor(theme.colors.primary, 180)
      : "#F8F6F8"};
  border-radius: 6px;
  padding: 12px 12px 12px 12px;
  overflow: hidden scroll;
`

const TitleCardContainer = styled.div<{
  color?: string
  topColor?: string
  isDragging: boolean
}>`
  width: 100%;
  height: 178px;
  margin-bottom: 8px;
  padding: 12px 16px;

  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #ebe6e9;
  border-radius: 6px;
  border-top: 8px solid
    ${({ theme, color }) =>
      color || lightenDarkenColor(theme.colors.primary, 180)};

  background-color: ${({ isDragging, theme }) =>
    isDragging ? lightenDarkenColor(theme.colors.primary, 180) : "#fff"};

  ${({ topColor, color }) =>
    topColor && color
      ? css`
          background-color: ${color};
          border-top-color: ${topColor};
        `
      : ""}
  transition: background-color 0.2s ease;
`

const TitleCardSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleCardRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TitleCardTextContainer = styled.div`
  display: flex;
  align-items: center;
`

const TitleCardIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CardContainer = styled.div`
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  margin-bottom: 12px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  :last-child {
    margin-bottom: 0px;
  }
`

const CardSubContainer = styled.div`
  padding: 10px 16px 18px 16px;
  display: flex;
  align-item: center;
  flex-direction: column;
`

const StatusCardContainer = styled.div`
  white-space: nowrap;
  width: fit-content;
  padding: 4px 8px;
  display: flex;
  align-self: flex-start;
  background-color: #f6f8f9;
  border-radius: 24px;
`

const StatusCircle = styled.div<{ statusColor: string }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 6px;
  background-color: ${({ statusColor }) => statusColor};
`

const SubTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #83727d;
`

const Title = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`

const TitleTextCard = styled.p`
  font-family: Cabinet Grotesk;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #53464f;
`

const SubTitleTextCard = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #9f939b;
`

const getBackgroundColor = (
  isDraggingOver: boolean,
  isDraggingFrom: boolean,
  primary: string,
) => {
  if (isDraggingOver) {
    return lightenDarkenColor(primary, 180)
  }
  if (isDraggingFrom) {
    return lightenDarkenColor(primary, 180)
  }
  return "#F8F6F8"
}

const Wrapper = styled.div<{
  isDraggingOver: boolean
  isDraggingFrom: boolean
}>`
  background-color: ${(props) =>
    getBackgroundColor(
      props.isDraggingOver,
      props.isDraggingFrom,
      props.theme.colors.primary,
    )};
  display: flex;
  flex-direction: column;

  transition:
    background-color 0.2s ease,
    opacity 0.1s ease;
  user-select: none;

  width: 100%;
  height: 100%;
`

const DropZone = styled.div`
  min-height: 100%;
`

const ScrollContainer = styled.div`
  overflow: hidden auto;

  &:not(:hover) {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      background: transparent;
      width: 0px;
      height: 0px;
    }
  }

  height: 100%;
`

const IconSelectedContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  svg {
    stroke: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }

  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: rgba(254, 43, 143, 0.16);
          svg {
            stroke: ${({ theme }) => theme.colors.primary};
          }
        `
      : ""};
  border-radius: 50%;
  padding: 4px;

  &:hover {
    opacity: 0.5;
  }
`

const IconRoundedContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.neutral.light.light2};
  ${({ selected }) =>
    selected
      ? css`
          background-color: rgba(254, 43, 143, 0.16);
        `
      : ""};
  border-radius: 50%;
  padding: 4px;
`

const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

const HoverContainer = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const CustomDropDown = styled.div`
  position: absolute;
  top: 32px;
  right: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;

  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #53464f;
  }
`

const CustomSubDropDown = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  min-width: 170px;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: #ffe0ef;
          span {
            color: ${theme.colors.primary};
          }
        `
      : ""}
  &:hover {
    opacity: 0.5;
  }
`

const MenuSubItemContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: #ffe0ef;
          color: ${theme.colors.primary};
        `
      : ""}
  &:hover {
    opacity: 0.5;
  }
`

const StyledMenu = styled(Menu)`
  && {
    &.menu-base-custom-theme {
      ul {
        &.MuiList-root {
          padding: 12px;

          li {
            padding: 16px;

            margin-bottom: 0px;

            &.MuiButtonBase-root {
              div {
                text-align: start;
              }
              &:hover {
                color: ${({ theme }) => theme.colors.neutral.light.pure};
                background-color: ${({ theme }) => theme.colors.primary};
              }
              &:last-child {
                margin-bottom: 0px;
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
`

const StyledTag = styled(Tag)`
  padding: 1px 6px;

  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.neutral.light.light2};
    }
  }
`

const StyledDescriptionTwoLine = styled.p`
  display: block;
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.neutral[60]};
`

export {
  StyledDescriptionTwoLine,
  CustomSubDropDown,
  HoverContainer,
  IconRoundedContainer,
  IconSelectedContainer,
  ContainerInline,
  ColumnContainer,
  TitleTextCard,
  SubTitleTextCard,
  TitleCardContainer,
  TitleCardSubContainer,
  TitleCardIconContainer,
  TitleCardTextContainer,
  TitleCardRowContainer,
  CardContainer,
  CardSubContainer,
  StatusCardContainer,
  StatusCircle,
  Title,
  SubTitle,
  Container,
  SubContainer,
  SubColumnContainer,
  HeaderColumnContainer,
  Wrapper,
  HiddenContainer,
  DropZone,
  ScrollContainer,
  StyledPOneLine,
  CustomDropDown,
  MenuSubItemContainer,
  StyledMenu,
  StyledTag,
}
