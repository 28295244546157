import { gql } from "graphql-request"
import { request } from "../client"

const GET_COMPANY_CONTRACTS = gql`
  query CompanyContracts {
    getCompanyContracts {
      benefitsContracts {
        planIds
      }
      peopleContract {
        modules
      }
    }
  }
`

type BenefitsContract = {
  planIds: string[]
}

type PeopleContract = {
  modules: string[]
}

type CompanyContracts = {
  benefits: BenefitsContract[]
  people: PeopleContract
}

export const getCompanyContracts = async (): Promise<CompanyContracts> => {
  const { getCompanyContracts } = await request(GET_COMPANY_CONTRACTS)
  return {
    benefits: getCompanyContracts.benefitsContracts,
    people: getCompanyContracts.peopleContract,
  }
}
