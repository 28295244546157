import { gql } from "@apollo/client"
import { request } from "../client"

const SEND_ADMISSIONAL_EXAM_REQUEST = gql`
  mutation SendAdmissionalExamRequest(
    $params: SendAdmissionalExamRequestInput
  ) {
    sendAdmissionalExamRequest(params: $params) {
      _id
    }
  }
`

export const sendAdmissionalExamRequest = async (params: {
  flowCardId: string
  columnId: string
  candidateId: string
  version: number
}) => {
  const { sendAdmissionalExamRequest } = await request(
    SEND_ADMISSIONAL_EXAM_REQUEST,
    { params },
  )
  return sendAdmissionalExamRequest
}
