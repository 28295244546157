import gql from "graphql-tag"
import { request } from "../client"

export const RESET_CONTRACT = gql`
  mutation ResetContract($contractId: ID!) {
    resetContract(contractId: $contractId)
  }
`

export const resetContract = async (contractId: string) => {
  const { resetContract } = await request(RESET_CONTRACT, { contractId })
  return resetContract as boolean
}
