import React from "react"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { LeftColumnContainer, RightColumnContainer, Container } from "./styles"
import { ColumnBadge } from "../../../components"
import { useTheme } from "styled-components"
import { Commentary, Expiration, Movimentation, Probationary } from "./screens"
import { trpc } from "src/api/client"
import { Skeleton } from "@mui/material"
import { useOutletContext } from "react-router-dom"
import { Flow } from "src/types"
import { NotificationsConfig } from "bff/src/types/hiring"
import i18n from "@i18n"
import { useTranslation } from "react-i18next"

enum ColumnEnum {
  movimentation = "movimentation",
  expiration = "expiration",
  commentary = "commentary",
  probationary = "probationary",
}

const columns: { [key in ColumnEnum]: string } = {
  [ColumnEnum.movimentation]: i18n.t(
    "page.settings.notification.movimentation.title",
  ),
  [ColumnEnum.commentary]: i18n.t(
    "page.settings.notification.commentary.title",
  ),
  [ColumnEnum.expiration]: i18n.t(
    "page.settings.notification.expiration.title",
  ),
  [ColumnEnum.probationary]: i18n.t(
    "page.settings.notification.probationaryPeriod.title",
  ),
}

export const Notification = () => {
  const [activeColumn, setActiveColumn] = React.useState<ColumnEnum>(
    ColumnEnum.movimentation,
  )

  const [t] = useTranslation("translations", {
    keyPrefix: "page.settings.notification",
  })

  const [flow] = useOutletContext<[flow: Flow]>()
  const theme = useTheme()

  const { data: notificationConfig, isFetching: isNotificationLoading } =
    trpc.notification.getNotificationConfig.useQuery(
      {
        category: flow.category,
      },
      {
        retry: 0,
        cacheTime: 0,
        refetchOnWindowFocus: false,
      },
    )

  const component = React.useMemo(() => {
    switch (activeColumn) {
      case ColumnEnum.commentary:
        return (
          <Commentary
            flow={flow}
            notificationConfig={notificationConfig as NotificationsConfig}
          />
        )
      case ColumnEnum.expiration:
        return (
          <Expiration
            flow={flow}
            notificationConfig={notificationConfig as NotificationsConfig}
          />
        )
      case ColumnEnum.movimentation:
        return (
          <Movimentation
            flow={flow}
            notificationConfig={notificationConfig as NotificationsConfig}
          />
        )
      case ColumnEnum.probationary:
        return (
          <Probationary
            flow={flow}
            notificationConfig={notificationConfig as NotificationsConfig}
          />
        )
      default:
        throw new Error("No screen for this column")
    }
  }, [activeColumn, notificationConfig])

  return (
    <Container>
      <LeftColumnContainer>
        <Typography
          variant="headline7"
          variantColor={theme.colors.secondary[50]}
          weight={700}
        >
          {t("title")}
        </Typography>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[50]}
          weight={400}
        >
          {t("description")}
        </Typography>

        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          {Object.keys(columns).map((key: string, idx) => (
            <ColumnBadge
              key={`column_badge_${idx}`}
              active={activeColumn === key}
              onClick={() => setActiveColumn(key as ColumnEnum)}
              text={columns[key]}
            />
          ))}
        </div>
      </LeftColumnContainer>

      <RightColumnContainer>
        {notificationConfig && !isNotificationLoading ? (
          component
        ) : (
          <Skeleton height={400} style={{ transform: "none" }} />
        )}
      </RightColumnContainer>
    </Container>
  )
}
