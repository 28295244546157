import { gql } from "graphql-tag"
import { request } from "../client"
import { Workshift } from "../../types"

const GET_WORKSHIFT_BY_ID = gql`
  query Workshift($workshiftId: ID!) {
    getWorkshift(workshiftId: $workshiftId) {
      id
      name
      description
      isDefault
      type
      initialHour
      finalHour
      interval
      intervalStart
      intervalDuration
      preSignedInterval
      hollydays
      schedule {
        day
        initialHour
        breakStarts
        breakEnds
        finalHour
        type
        total
      }
    }
  }
`

export const getWorkshiftById = async (
  workshiftId: string,
): Promise<Workshift> => {
  const { getWorkshift } = await request(GET_WORKSHIFT_BY_ID, {
    workshiftId,
  })
  return getWorkshift
}
