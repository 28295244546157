import {
  DataGrid,
  SearchField,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Grid } from "@mui/material"
import styled from "styled-components"

export const StyledGridContainer = styled(Grid)`
  padding: 40px 24px;
`

export const StyledTableGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
  border-radius: 12px;
  border: 1px solid #ebe6e9;
  padding: 40px;
`

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`

export const StyledSecondaryTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: 16px;
`

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const StyledTableText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const StyledEmptyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`

export const StyledSearchField = styled(SearchField)``

export const StyledDataGrid = styled(DataGrid)``

export const Avatar = styled.div`
  width: 43px;
  min-width: 43px;
  min-height: 43px;
  height: 43px;
  background: ${({ theme }) => theme.colors.secondary[95]};
  border: "3.75px solid #FFFFFF";
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AvatarName = styled(Typography)`
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.secondary[50]};
`

export const StyledSelectField = styled(SelectField)`
  & {
    .MuiInputBase-root {
      border: none !important;
    }
  }
`

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: #ffecd6;
`
