import { useParams } from "react-router-dom"
import { ResignationContext } from "../../../context"
import ReplaceLeader from "../../Resignation/ReplaceLeader"
import SelectPeople from "../../Resignation/SelectPeople"

export const ResignationConfigurationPage = () => {
  const { flowId = '' } = useParams()

  const pages = [
    {
      route: `/flows/resignation/selectPeople/${flowId}`,
      category: "selectPeople",
    },
    {
      route: `/flows/resignation/selectPeople/${flowId}/replaceLeader`,
      category: "replaceLeader",
    },
  ]

  const currentPage = pages?.find((page) => location.pathname == page.route)

  return (
    <ResignationContext>
      {currentPage?.category == "selectPeople" ? (
        <SelectPeople />
      ) : (
        <ReplaceLeader />
      )}
    </ResignationContext>
  )
}
