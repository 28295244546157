import styled from "styled-components"
import ArrowRight from "../../../assets/arrow_right.svg"
import { DeprecatedModal } from "../../../components"

const CloseRightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    display: none;
  }
`

export const StyledAContainer = styled.a`
  cursor: pointer;
  margin-bottom: 24px;
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

const StyledArrow = styled(ArrowRight)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    flex-direction: column;
    overflow: auto;
  }
`

const Wrapper = styled.div`
  width: 100%;

  overflow: hidden auto;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    overflow: visible;
  }
`

const Title = styled.span`
  font-family: Cabinet Grotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #50494c;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const IconContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding: 16px;
  border: 1px solid #e8e3e6;
  border-radius: 50%;

  &:hover {
    opacity: 0.7;
  }
`

const CardContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;

  border: 1px solid #d1c7cc;
  border-radius: 8px 32px 8px 8px;
  margin-right: 24px;

  ${({ selected, theme }) =>
    selected
      ? `background-color: rgba(251, 42, 129, 0.1);border: 1px solid ${theme.colors.primary};filter: brightness(80%);`
      : ""}

  &:last-child {
    margin-right: 0px;
  }
`

const CardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`

const CardContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0px;
  }
`

const CardSubContentContainer = styled.div`
  display: flex;

  flex-direction: column;
`

const PreviewButton = styled.div`
  margin-top: 40px;
  margin-bottom: 45px;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 12px 44px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px 16px 4px 4px;
  color: #fb2a81;
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
  }

  &:hover {
    color: #fff;
    background-color: #fb2a81;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`

const TagContainer = styled.div`
  display: flex;

  padding: 4px 12px;

  background: rgba(254, 43, 143, 0.18);
  border-radius: 24px;
  margin-top: 4px;
  margin: 10px;
`

const PerformanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px 4px 10px;

  border: 1px solid rgba(254, 43, 143, 0.18);
  border-radius: 4px;
`

const ConfirmButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  background-color: #fb2a81;

  border-radius: 4px 16px 4px 4px;
  margin-top: 80px;
  margin-bottom: 50px;

  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    pointer-events: none;
  }

  p {
    color: #fff;
    font-weight: 700;
  }

  &:hover {
    opacity: 0.5;
  }
`

const FormContentContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 48px 48px 0px 48px;
  text-align: left;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;

  color: #83727d;

  :last-child {
    margin-bottom: 0px;
  }
`

const ProposalFieldContainer = styled.div`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0px;
  }
`

const ToggleContainer = styled.div`
  border: 1px solid #e8e3e6;
  border-radius: 6px;
  padding: 12px 15px;
  margin-top: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`

const ProposalAcceptedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 48px auto;
  flex-direction: column;
  max-width: 426px;
  text-align: center;
`

const ProposalRefusedContainer = styled.div``

const RefusedReasonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  padding: 40px 44px;
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
`

const ButtonToArchived = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  cursor: pointer;
  border: 1px solid #fe2b8f;
  border-radius: 12px;
  margin-top: 24px;
  margin-bottom: 24px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #fe2b8f;
  }
  &:hover {
    opacity: 0.5;
  }
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #f8f6f8;
  border-radius: 50%;
  margin-left: 7px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      min-width: 90vw;
      min-height: 90vh;
      .modal-content-area {
        padding: 0px;
      }
    }
  }
`

const StyledPreviewProgress = styled.div`
  width: 100%;
  height: 4px;
  background: #fe67af;
  border-radius: 12px;
  margin-top: 10px;

  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: wave;
  animation-timing-function: linear;
  background: linear-gradient(to right, #fe67af 50%, #da0b6d 80%);
`

const Divider = styled.div`
  border: 1px solid #ebe6e9;
  height: 1px;
  width: 100%;
  margin: 40px 0;
`

export {
  Divider,
  StyledPreviewProgress,
  StyledModal,
  StyledIconContainer,
  SubContainer,
  ButtonToArchived,
  ToggleContainer,
  Container,
  Wrapper,
  Title,
  IconContainer,
  HeaderContainer,
  TagContainer,
  ContentContainer,
  FlexSpaceBetween,
  CloseRightContainer,
  CardContainer,
  CardHeaderContainer,
  CardContentContainer,
  CardSubContentContainer,
  PreviewButton,
  PerformanceContainer,
  ProposalFieldContainer,
  ConfirmButton,
  FormContentContainer,
  StyledArrow,
  ErrorContainer,
  ProposalAcceptedContainer,
  ProposalRefusedContainer,
  RefusedReasonContainer,
}
