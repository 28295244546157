import { useRef, useCallback, useMemo, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  IconButton,
  Typography,
  SelectField,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2"

import Banner from "../Banner"
import { FormTextEditor, FormTextEditorHandle } from "../FormTextEditor"

import { hiringFields } from "../../../../../../../mock/templateFields"
import { Flow } from "../../../../../../../types"

import { request } from "../../../../../../../api/client"
import dispatchToast from "../../../../../../../utils/dispatchToast"

import { cloneDeep, uniqBy, sortBy } from "lodash"

import {
  StyledModal,
  Header,
  CloseContainer,
  HeaderTitle,
  ContentContainer,
  Divider,
  DescriptionContainer,
  ContentArea,
  Footer,
  StyledButton,
  StyledLinkButton,
} from "./styles"

import { CREATE_UPDATE_CUSTOM_MODEL_CONTRACT } from "../../../../../../../api/mutations/create-update-custom-model-contract"
import { GET_CANDIDATE_MODEL_CONTRACT_BY_ID } from "../../../../../../../api/queries/get_candidate_model_contract_by_id"

interface ModalProps {
  isOpen: boolean
  modelId: string
  customModelContractId?: string
  candidateId?: string
  hiringType: Flow["subcategory"]
  onClose: () => void
  onConfirm: (data) => void
}

type TagItem = { label: string; tag: string; value: string }

export const EditTemplateModal = ({
  isOpen,
  hiringType,
  modelId,
  candidateId,
  customModelContractId,
  onClose,
  onConfirm,
}: ModalProps) => {
  const formRef = useRef<FormTextEditorHandle>(null)
  const [verifiedTags, setVerifiedTags] = useState<TagItem[]>([])

  const hiringTypeFields = useMemo(
    () => (hiringType ? hiringFields[hiringType] : undefined),
    [hiringType],
  )

  const { data, isFetching } = useQuery(
    ["model-contract", modelId],
    async () => {
      const { getCandidateModelContractById: data } = await request(
        GET_CANDIDATE_MODEL_CONTRACT_BY_ID,
        {
          params: {
            customModelContractId,
            modelContractId: modelId,
            candidateId,
          },
        },
      )
      return data
    },
    {
      enabled: !!modelId,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setVerifiedTags(response?.verifiedTags || [])
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar o modelo de contrato!",
        })

        onClose()
      },
    },
  )

  const { mutate: createUpdateMutate, isLoading } = useMutation(
    async ({ params }: { params: any }) => {
      const { createUpdateCustomModelContract: data } = await request(
        CREATE_UPDATE_CUSTOM_MODEL_CONTRACT,
        {
          params,
        },
      )
      return data
    },
    {
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro na criação/edição do contrato customizado.",
        })
      },
    },
  )

  const handleSubmit = useCallback(
    (text: string) => {
      const textContract = formRef?.current?.parseToText(text)

      if (data?.html !== textContract) {
        createUpdateMutate(
          {
            params: {
              candidateId,
              html: textContract,
              modelContractId: modelId,
              customModelContractId,
            },
          },
          {
            onSuccess: (data) => {
              const errorTags = data?.verifiedTags?.filter((d) => !d?.value)

              if (!errorTags.length) return onConfirm(data)

              if (errorTags.length)
                dispatchToast({
                  type: "error",
                  content:
                    "Não é possível atualizar o contrato, existem campos não preenchidos!",
                })

              setVerifiedTags(data.verifiedTags)
            },
          },
        )
        return
      }
    },
    [candidateId, modelId],
  )

  const handleTagRemoved = useCallback(
    (tag: string) => {
      const tags = cloneDeep(verifiedTags)
      const index = tags.findIndex((item) => item.tag === tag)

      if (index >= 0) tags.splice(index, 1)

      setVerifiedTags(tags)
    },
    [verifiedTags],
  )
  const errorFields = verifiedTags?.filter((d) => !d.value) || []

  if (!hiringTypeFields) return null
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      header={
        <Header>
          <CloseContainer>
            <IconButton
              icon="IconX"
              variant="line"
              size="small"
              onClick={onClose}
            />
          </CloseContainer>
          <HeaderTitle>
            <Typography variant="headline6">Editar contrato</Typography>
            <Typography
              variant="body3"
              style={{ color: "#83727D", fontWeight: 400 }}
            >
              Edite abaixo o conteúdo do contrato.
            </Typography>
          </HeaderTitle>
        </Header>
      }
      footer={
        <Footer>
          <StyledLinkButton
            variant="primary"
            children="Cancelar"
            style={{ alignSelf: "center" }}
            onClick={onClose}
          />
          <StyledButton
            size="large"
            variant="primary"
            style={{ alignSelf: "center" }}
            loading={isLoading}
            onClick={() => {
              formRef?.current?.handleSubmit()
            }}
          >
            Salvar
          </StyledButton>
        </Footer>
      }
    >
      <ContentContainer>
        <ContentArea>
          <Typography
            variant="body3"
            style={{ color: "#53464F", fontWeight: 700 }}
          >
            Candidato
          </Typography>
          <Banner
            type="info"
            icon="IconAlertCircle"
            hasHideBanner={false}
            title="As alterações realizadas aqui serão aplicadas somente ao contrato desta pessoa. O modelo original do contrato não é modificado."
          />
          <SelectField
            label="Candidato"
            value={data?.candidateName || ""}
            options={[
              {
                label: data?.candidateName || "",
                value: data?.candidateName || "",
              },
            ]}
            disabled
          />
        </ContentArea>

        <Divider />

        <ContentArea>
          <Typography
            variant="body3"
            style={{ color: "#53464F", fontWeight: 700 }}
          >
            Contrato
          </Typography>

          {Boolean(errorFields.length) && (
            <Banner
              type="error"
              icon="IconCircleX"
              hasHideBanner={false}
              title="Os campos dinâmicos listados abaixo não foram preenchidos em etapas anteriores para este candidato, portanto não poderão ser preenchidos automaticamente. Remova os campos dinâmicos ou substitua-os pela informação desejada no campo de texto."
            >
              <ul style={{ listStylePosition: "inside" }}>
                {sortBy(uniqBy(errorFields, "label"), ["label"]).map(
                  (item, idx) => (
                    <li key={`${idx}_${item}`}>{item.tag}</li>
                  ),
                )}
              </ul>
            </Banner>
          )}

          <DescriptionContainer>
            <Icons
              name="IconInfoCircle"
              size={15}
              fill="transparent"
              stroke="#83727D"
            />
            <Typography
              variant="caption"
              style={{ color: "#83727D", fontWeight: "600" }}
            >
              Para adicionar um campo dinâmico no texto, insira os caracteres
              {" {{"} e selecione qual informação dinâmica deseja adicionar ao
              contrato.
            </Typography>
          </DescriptionContainer>
          <FormTextEditor
            ref={formRef}
            hiringFields={hiringTypeFields}
            hiringType={hiringType}
            data={data}
            loading={isFetching}
            onSubmit={handleSubmit}
            onTagRemoved={handleTagRemoved}
          />
        </ContentArea>
      </ContentContainer>
    </StyledModal>
  )
}
