export const EmptyStateSVG = ({ width = "311", height = "182", ...rest }) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox="0 0 311 182"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.3724 110.697H191.576C192.217 110.697 192.841 110.628 193.442 110.497C194.044 110.628 194.668 110.697 195.308 110.697H259.996C264.806 110.697 268.704 106.798 268.704 101.989C268.704 97.1799 264.806 93.2812 259.996 93.2812H252.532C247.723 93.2812 243.824 89.3825 243.824 84.5732C243.824 79.7639 247.723 75.8652 252.532 75.8652H276.168C280.978 75.8652 284.876 71.9665 284.876 67.1572C284.876 62.3479 280.978 58.4492 276.168 58.4492H248.8C253.61 58.4492 257.508 54.5505 257.508 49.7412C257.508 44.9319 253.61 41.0332 248.8 41.0332H169.184C173.994 41.0332 177.892 37.1345 177.892 32.3252C177.892 27.5159 173.994 23.6172 169.184 23.6172H98.2764C93.4671 23.6172 89.5684 27.5159 89.5684 32.3252C89.5684 37.1345 93.4671 41.0332 98.2764 41.0332H48.5164C43.7071 41.0332 39.8084 44.9319 39.8084 49.7412C39.8084 54.5505 43.7071 58.4492 48.5164 58.4492H79.6164C84.4257 58.4492 88.3244 62.3479 88.3244 67.1572C88.3244 71.9665 84.4257 75.8652 79.6164 75.8652H29.8564C25.0471 75.8652 21.1484 79.7639 21.1484 84.5732C21.1484 89.3825 25.0471 93.2812 29.8564 93.2812H78.3724C73.5631 93.2812 69.6644 97.1799 69.6644 101.989C69.6644 106.798 73.5631 110.697 78.3724 110.697ZM281.144 110.697C285.954 110.697 289.852 106.798 289.852 101.989C289.852 97.1799 285.954 93.2812 281.144 93.2812C276.335 93.2812 272.436 97.1799 272.436 101.989C272.436 106.798 276.335 110.697 281.144 110.697Z"
        fill="#F8F6F8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M191.168 23.6205L202.739 108.017L203.779 116.484C204.114 119.212 202.174 121.694 199.446 122.029L126.597 130.974C123.87 131.309 121.387 129.369 121.052 126.641L109.836 35.2976C109.669 33.9337 110.639 32.6924 112.003 32.5249C112.011 32.5239 112.02 32.5228 112.029 32.5219L118.072 31.8439M122.959 31.2957L128.666 30.6556L122.959 31.2957Z"
        fill="white"
      />
      <path
        d="M192.406 23.4507C192.312 22.7667 191.682 22.2883 190.998 22.382C190.314 22.4758 189.835 23.1063 189.929 23.7903L192.406 23.4507ZM202.739 108.017L203.98 107.864C203.979 107.859 203.978 107.853 203.978 107.847L202.739 108.017ZM203.779 116.484L205.019 116.332L203.779 116.484ZM199.446 122.029L199.599 123.27L199.446 122.029ZM126.597 130.974L126.75 132.215L126.597 130.974ZM121.052 126.641L122.293 126.489L121.052 126.641ZM109.836 35.2976L111.077 35.1452L109.836 35.2976ZM112.029 32.5219L112.168 33.7641L112.029 32.5219ZM118.212 33.0861C118.898 33.0092 119.391 32.3906 119.315 31.7046C119.238 31.0185 118.619 30.5248 117.933 30.6017L118.212 33.0861ZM122.82 30.0535C122.134 30.1305 121.64 30.749 121.717 31.4351C121.794 32.1211 122.413 32.6149 123.099 32.5379L122.82 30.0535ZM128.805 31.8978C129.491 31.8209 129.985 31.2023 129.908 30.5163C129.831 29.8302 129.212 29.3365 128.526 29.4134L128.805 31.8978ZM189.929 23.7903L201.501 108.187L203.978 107.847L192.406 23.4507L189.929 23.7903ZM201.498 108.169L202.538 116.636L205.019 116.332L203.98 107.864L201.498 108.169ZM202.538 116.636C202.789 118.679 201.336 120.538 199.294 120.789L199.599 123.27C203.012 122.851 205.438 119.744 205.019 116.332L202.538 116.636ZM199.294 120.789L126.445 129.733L126.75 132.215L199.599 123.27L199.294 120.789ZM126.445 129.733C124.403 129.984 122.543 128.532 122.293 126.489L119.811 126.794C120.23 130.207 123.337 132.634 126.75 132.215L126.445 129.733ZM122.293 126.489L111.077 35.1452L108.596 35.4499L119.811 126.794L122.293 126.489ZM111.077 35.1452C110.994 34.4666 111.476 33.8489 112.155 33.7656L111.85 31.2842C109.801 31.5358 108.344 33.4009 108.596 35.4499L111.077 35.1452ZM112.155 33.7656C112.159 33.7651 112.164 33.7646 112.168 33.7641L111.889 31.2797C111.876 31.2811 111.863 31.2826 111.85 31.2842L112.155 33.7656ZM112.168 33.7641L118.212 33.0861L117.933 30.6017L111.889 31.2797L112.168 33.7641ZM123.099 32.5379L128.805 31.8978L128.526 29.4134L122.82 30.0535L123.099 32.5379Z"
        fill="#83727D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M188.018 28.9271L198.492 105.413L199.434 113.086C199.737 115.558 198.003 117.805 195.56 118.105L130.323 126.115C127.88 126.415 125.654 124.654 125.35 122.182L115.152 39.1257C115.017 38.0294 115.797 37.0315 116.893 36.8969L125.448 35.8465"
        fill="#EBE6E9"
      />
      <path
        d="M133.949 10.2031C133.949 8.68434 135.18 7.45312 136.699 7.45312H194.752C195.481 7.45312 196.18 7.74262 196.696 8.25798L213.991 25.5426C214.507 26.0583 214.797 26.7581 214.797 27.4877V106.699C214.797 108.218 213.566 109.449 212.047 109.449H136.699C135.18 109.449 133.949 108.218 133.949 106.699V10.2031Z"
        fill="white"
        stroke="#83727D"
        stroke-width="2.5"
      />
      <path
        d="M194.9 9.1875V23.6144C194.9 25.6755 196.571 27.3464 198.632 27.3464H208.502"
        stroke="#83727D"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M146.792 90.7877H179.136M146.792 27.3438H179.136H146.792ZM146.792 42.2717H200.284H146.792ZM146.792 58.4437H200.284H146.792ZM146.792 74.6157H200.284H146.792Z"
        stroke="#B9ACB4"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
