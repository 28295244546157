import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 24px 40px;
  gap: 16;
`

export const Instruction = styled.div<{ showMore: boolean }>`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 16px;
  font-weight: 600;

  ${({ showMore }) =>
    !showMore &&
    css`
      max-height: 70px;
      overflow: hidden;
    `};
`
